import moment from 'moment';
import 'moment/locale/zh-hk';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../AppContext';
import ProfileInfoRow from '../pageComponents/profile/ProfileInfoRow';
import SubscriptionYearCard from '../pageComponents/profile/SubscriptionYearCard';

export default function ProfileMembership() {
  const { session } = useContext(AppContext);
  const { user } = session;
  const { t } = useTranslation('profile');
  const validSubscriptions =
    user.subscriptions &&
    user.subscriptions
      .filter((subscription) => {
        return moment(subscription.expireDate).diff(moment(), 'seconds') >= 0;
      })
      .sort((a, b) => {
        return moment(a.expireDate).isBefore(moment(b.expireDate)) ? -1 : 1;
      });
  const pastSubscriptions =
    user.subscriptions &&
    user.subscriptions.filter((subscription) => {
      return moment(subscription.expireDate).diff(moment(), 'seconds') < 0;
    });
  return (
    <div>
      {validSubscriptions && validSubscriptions.length > 0 && (
        <>
          <ProfileInfoRow title={t('profile:membership.valid_membership')}>
            {validSubscriptions &&
              validSubscriptions.map((subscription) => (
                <SubscriptionYearCard
                  t={t}
                  subscription={subscription}
                  isValid={true}
                />
              ))}
          </ProfileInfoRow>
          <div
            style={{
              width: '100%',
              opacity: 0.3,
              marginTop: 60,
              marginBottom: 60,
              border: '1px #9A9A9A solid',
            }}
          ></div>
        </>
      )}
      {pastSubscriptions && pastSubscriptions.length > 0 && (
        <ProfileInfoRow title={t('profile:membership.past_membership')}>
          {pastSubscriptions &&
            pastSubscriptions.map((subscription) => (
              <SubscriptionYearCard
                t={t}
                subscription={subscription}
                isValid={false}
              />
            ))}
        </ProfileInfoRow>
      )}
    </div>
  );
}
