import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { EVENT_ON_KEY_DOWN, EVENT_ON_KEY_UP, VK_ARROW_LEFT, VK_ARROW_RIGHT, createModule, useController, useLightboxState } from "yet-another-react-lightbox";


const DEFAULT_NAVIGATION_SPEED = 500

const useCustomNavigationAnimation = (lightboxRef, navigationSpeed = DEFAULT_NAVIGATION_SPEED) => {

  const [nextNavigateConfig, setNextNavigateConfig] = useState({ animation: { swipe: 250, fade: 0, navigation: navigationSpeed }, diff: 0 })


  useEffect(() => {
    console.log("navigate after manually change the next tick animation time", nextNavigateConfig.animation)
    if (lightboxRef.current) {
      if (nextNavigateConfig.diff > 0) {
        lightboxRef.current.next({ count: nextNavigateConfig.diff })
      } else if (nextNavigateConfig.diff < 0) {
        lightboxRef.current.prev({ count: Math.abs(nextNavigateConfig.diff) })
      }
    }

  }, [nextNavigateConfig])
  // keyboard handler
  const handleKeyboardNavigate = (e) => {
    console.log("🚀 ~ file: useCustomNavigationAnimation.js:31 ~ handleKeyboardNavigate ~ e:", e)
    if (e.key === VK_ARROW_LEFT) {
      setNextNavigateConfig({ animation: { swipe: 250, fade: 0, navigation: navigationSpeed }, diff: -1 })
    } else if (e.key === VK_ARROW_RIGHT) {
      setNextNavigateConfig({ animation: { swipe: 250, fade: 0, navigation: navigationSpeed }, diff: 1 })
    }
  }

  // thumbnail handler
  const handleThumbnailClick = (e, index) => {
    e.stopPropagation(); //override the default behavior when click thumbnail provided by the library
    lightboxRef.current.focus();
    const { currentIndex } = lightboxRef.current.getLightboxState()
    const diff = index - currentIndex
    if (diff !== 0) {

      setNextNavigateConfig({ animation: { swipe: 250, fade: 0, navigation: navigationSpeed / Math.abs(diff), }, diff })
    }
  }
  // arrow handler
  const handleArrowClick = (e, isNext) => {
    e.stopPropagation()
    setNextNavigateConfig((prev) => ({ animation: { swipe: 250, fade: 0, navigation: navigationSpeed }, diff: isNext ? 1 : -1 }))
  };

  return {
    animation: nextNavigateConfig.animation,
    diff: nextNavigateConfig.diff,
    handleThumbnailClick,
    handleArrowClick,
    handleKeyboardNavigate
  }
}





export default useCustomNavigationAnimation