import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoint } from "../../../utils/responsive";
const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    @media ${breakpoint.desktop} {
        display: flex;
        width: 100%;
    }
    @media ${breakpoint.mobile} {
        display: flex;
        width: 100%;
    }
`
const StyledToggleButton = styled(ToggleButton)`
    && {
        border-radius: 0;
        width: 293px;
        height: 53px;
        color: #111111;
        font-family: 'Open Sans';
        font-weight: 600;
        line-height: 25px;
        text-align: center;
        ${props => props.textTransform ? `text-transform: ${props.textTransform};` : ''}
        :hover {
            background-color: #ffffff;
            color: #111111;
        }
        &.Mui-selected, &.Mui-selected:hover {
            background-color: #FF5C00;
            color: white;
        }
        @media ${breakpoint.desktop} {
            width: 100%;
            font-size: 16px;
        }
        @media ${breakpoint.mobile} {
            width: 50%;
            font-size: 14px;
        }
    }
`

export default function LanguageToggleButton({ value, onChange, textTransform }) {
    const { t } = useTranslation('common');
    return (
        <div style={{ display: "flex", width: '100%' }}>
            <StyledToggleButtonGroup value={value} onChange={onChange} exclusive>
                <StyledToggleButton value="chinese" textTransform={textTransform} >
                    {t('language.chinese')}
                </StyledToggleButton>
                <StyledToggleButton value="english" textTransform={textTransform} >
                    {t('language.english')}
                </StyledToggleButton>
            </StyledToggleButtonGroup>
        </div>
    )
}
