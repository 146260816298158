import React, { useState } from 'react';
import styled from 'styled-components';
import CommonButton from './CommonButton';
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";

function FilterToggleButton({open, setOpen, ...props}) {

    const onClickHandler=()=>{
        setOpen(!open);
    }

    return (
        <ResponsiveUI
            mobile={
                <CommonButton fontSize="14px" width="153px" height="50px" type='filter' onClick={onClickHandler}>{open? '-' : '+'}{props.children}</CommonButton>
            }

            desktop={
                <CommonButton type='filter' onClick={onClickHandler}>{open? '-' : '+'}{props.children}</CommonButton>
            }
        />
    );
}

export default FilterToggleButton;