import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getActivity } from '../apis';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import CommonText from '../components/common/typographies/CommonText';
import VerticalDivider from '../components/common/divider/VerticalDivider';
import CommonImage from '../components/common/images/CommonImage';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RightArrow from '../assets/Arrow_black.svg';
import CommonButton from '../components/common/buttons/CommonButton';
import Polygon from '../assets/Polygon_purple.png';
import ApplyForm from '../pageComponents/activity/detail/CustomForm';
import ApplyForm2 from '../pageComponents/activity/detail/CustomForm2';
import Loading from '../components/common/loading';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import TitleGroup from '../components/common/typographies/TitleGroup';
import MetaTag from '../utils/MetaTag';
import moment from 'moment';
import { breakpoint, ResponsiveUI } from '../utils/responsive';
import Space from '../components/common/Space';
import Player from '../components/Player';
import dayjs from 'dayjs';
import LiveIndicator from '../pageComponents/activity/detail/LiveIndicator';
import BackArrowButton from '../components/common/buttons/BackArrowButton';
import Formio from '../pageComponents/activity/detail/Formio';

export default function ActivityDetail() {
  const { t, i18n } = useTranslation('activity');
  let navigate = useNavigate();
  const { id } = useParams();
  const { session } = useContext(AppContext);
  const [registerResult, setRegisterResult] = useState(false);
  const [isLive, setIsLive] = useState(false);

  const { isLoading, isError, data, error } = useQuery(
    ['getActivity', id, session?.accessToken],
    () => getActivity(session?.accessToken, id)
  );

  useEffect(() => {
    if (data?.data && data?.data?.isLive) {
      let liveStart = dayjs(data?.data?.liveStartTime);
      let liveEnd = dayjs(data?.data?.liveEndTime);
      let currentTime = dayjs();
      if (currentTime > liveStart && currentTime < liveEnd) setIsLive(true);
      else if (liveStart > currentTime && currentTime < liveEnd) {
        setTimeout(() => {
          setIsLive(true);
        }, liveStart - currentTime);
      }
    }
  }, [data]);

  if (isLoading) {
    return <Loading></Loading>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const onClickBackHandler = () => {
    navigate(`../activity`);
  };

  let termsString = '';
  if (i18n.language == 'en') {
    termsString = data?.data?.detailsEn;
  } else {
    termsString = data?.data?.detailsZh;
  }
  // const terms = termsString.split(/\r?\n/);
  return (
    <StyledMainDiv>
      <MetaTag page="activity" />
      <StyledColorDiv>
        <BackArrowButton onClick={onClickBackHandler} />
        <LiveIndicatorContainer>
          <LiveIndicator t={t} isLive={isLive} />
        </LiveIndicatorContainer>
        <ResponsiveUI
          mobile={
            <StyledDateDiv style={{ flexWrap: 'wrap' }}>
              <CommonText lineHeight="25px" fontSize="16px">
                {t('activityDate')}
              </CommonText>
              <VerticalDivider
                style={{ marginTop: '2px' }}
                color="#666666"
                size="20px"
              />
              {moment(data.data.startTime)
                .locale(i18n.language === 'zh' ? 'zh-hk' : 'en')
                .format('LL') ==
              moment(data.data.endTime)
                .locale(i18n.language === 'zh' ? 'zh-hk' : 'en')
                .format('LL') ? (
                <CommonText lineHeight="25px" fontSize="16px">
                  {moment(data.data.startTime)
                    .locale(i18n.language === 'zh' ? 'zh-hk' : 'en')
                    .format(
                      i18n.language === 'zh' ? 'YYYY年M月D日' : 'D MMMM, YYYY'
                    )}
                </CommonText>
              ) : (
                <CommonText lineHeight="25px" fontSize="16px">
                  {moment(data.data.startTime)
                    .locale(i18n.language === 'zh' ? 'zh-hk' : 'en')
                    .format(
                      i18n.language === 'zh' ? 'YYYY年M月D日' : 'D MMMM, YYYY'
                    )}{' '}
                  {t('to')}{' '}
                  {moment(data.data.endTime)
                    .locale(i18n.language === 'zh' ? 'zh-hk' : 'en')
                    .format(
                      i18n.language === 'zh' ? 'YYYY年M月D日' : 'D MMMM, YYYY'
                    )}
                </CommonText>
              )}
            </StyledDateDiv>
          }
          desktop={
            <StyledDateDiv style={{ flexWrap: 'wrap' }}>
              <CommonText lineHeight="40px" fontSize="20px">
                {t('activityDate')}
              </CommonText>
              <VerticalDivider
                style={{ marginTop: '10px' }}
                color="#666666"
                size="20px"
              />
              {moment(data.data.startTime)
                .locale(i18n.language === 'zh' ? 'zh-hk' : 'en')
                .format('LL') ==
              moment(data.data.endTime)
                .locale(i18n.language === 'zh' ? 'zh-hk' : 'en')
                .format('LL') ? (
                <CommonText lineHeight="40px" fontSize="20px">
                  {moment(data.data.startTime)
                    .locale(i18n.language === 'zh' ? 'zh-hk' : 'en')
                    .format(
                      i18n.language === 'zh' ? 'YYYY年M月D日' : 'D MMMM, YYYY'
                    )}
                </CommonText>
              ) : (
                <CommonText lineHeight="40px" fontSize="20px">
                  {moment(data.data.startTime)
                    .locale(i18n.language === 'zh' ? 'zh-hk' : 'en')
                    .format(
                      i18n.language === 'zh' ? 'YYYY年M月D日' : 'D MMMM, YYYY'
                    )}{' '}
                  {t('to')}{' '}
                  {moment(data.data.endTime)
                    .locale(i18n.language === 'zh' ? 'zh-hk' : 'en')
                    .format(
                      i18n.language === 'zh' ? 'YYYY年M月D日' : 'D MMMM, YYYY'
                    )}
                </CommonText>
              )}
            </StyledDateDiv>
          }
        />

        <ResponsiveUI
          mobile={
            <StyledTitleDiv>
              <CommonText lineHeight="40px" fontSize="24px">
                {i18n.language === 'en' ? data.data.titleEn : data.data.titleZh}
              </CommonText>
            </StyledTitleDiv>
          }
          desktop={
            <StyledTitleDiv>
              <CommonText lineHeight="68px" fontSize="50px">
                {i18n.language === 'en' ? data.data.titleEn : data.data.titleZh}
              </CommonText>
            </StyledTitleDiv>
          }
        />
      </StyledColorDiv>
      {isLive ? (
        <StyledImgDiv>
          <div
            style={{
              width: '100%',
              maxWidth: '1512px',
            }}
          >
            <Player
              title={
                i18n.language === 'en' ? data.data.titleEn : data.data.titleZh
              }
              contentId={data.data.contentId}
              isMultiCDN={false}
              activityId={id}
            />
          </div>
        </StyledImgDiv>
      ) : (
        <ResponsiveUI
          mobile={
            <StyledImgDiv>
              <CommonImage
                style={{
                  objectFit: 'cover',
                  aspectRatio: '21/9',
                  maxWidth: '1512px',
                }}
                height="auto"
                width="100%"
                src={data.data.coverImage + '-thumbnail'}
              ></CommonImage>
            </StyledImgDiv>
          }
          desktop={
            <StyledImgDiv>
              <CommonImage
                style={{
                  objectFit: 'cover',
                  aspectRatio: '21/9',
                  maxWidth: '1512px',
                }}
                height="auto"
                width="100%"
                src={data.data.coverImage + '-thumbnail'}
              ></CommonImage>
            </StyledImgDiv>
          }
          largeDesktop={
            <StyledImgDiv>
              <CommonImage
                style={{
                  objectFit: 'cover',
                  aspectRatio: '21/9',
                  maxWidth: '1512px',
                }}
                height="auto"
                width="100%"
                src={data.data.coverImage + '-thumbnail'}
              ></CommonImage>
            </StyledImgDiv>
          }
        />
      )}

      <ResponsiveUI
        mobile={
          !registerResult && (
            <StyledTermsDiv>
              {/* {terms.map((term) => {
                            return (
                                <StyledTermDiv>
                                    <StyledDot style={{objectFit:'contain'}} height="10px" width="10px" src={Polygon}></StyledDot>
                                    <CommonText lineHeight="25px" fontSize="16px">{term}</CommonText>
                                </StyledTermDiv>
                            )
                        })} */}

              <div dangerouslySetInnerHTML={{ __html: termsString }} />
            </StyledTermsDiv>
          )
        }
        desktop={
          !registerResult && (
            <StyledTermsDiv>
              {/* {terms.map((term) => {
                            return (
                                <StyledTermDiv>
                                    <StyledDot style={{objectFit:'contain'}} height="13px" width="13px" src={Polygon}></StyledDot>
                                    <CommonText lineHeight="40px" fontSize="18px">{term}</CommonText>
                                </StyledTermDiv>
                            )
                        })} */}

              <div dangerouslySetInnerHTML={{ __html: termsString }} />
            </StyledTermsDiv>
          )
        }
        largeDesktop={
          !registerResult && (
            <StyledTermsDiv>
              {/* {terms.map((term) => {
                            return (
                                <StyledTermDiv>
                                    <StyledDot style={{objectFit:'contain'}} height="13px" width="13px" src={Polygon}></StyledDot>
                                    <CommonText lineHeight="40px" fontSize="20px">{term}</CommonText>
                                </StyledTermDiv>
                            )
                        })} */}

              <div dangerouslySetInnerHTML={{ __html: termsString }} />
            </StyledTermsDiv>
          )
        }
      />
      <div style={{ width: '100%', marginBottom: '154px' }}>
        {data.data.applicationForm &&
          !registerResult &&
          (data.data.enrolled ||
            (moment().isAfter(moment(data.data.startTime)) &&
              moment().isBefore(moment(data.data.endTime)))) && (
            <div>
              {data.data._id == '635a168f47d2d268c42db1c9' ||
              data.data._id == '6363685f6270564368961778' ? (
                <ApplyForm2
                  tncPath={data.data.tnc}
                  eventname={data.data.titleEn}
                  setRegisterResult={setRegisterResult}
                  id={id}
                ></ApplyForm2>
              ) : data.data.enrolled && !data.data.participant.formData ? (
                <ApplyForm
                  activity={data.data}
                  tncPath={data.data.tnc}
                  eventname={data.data.titleEn}
                  setRegisterResult={setRegisterResult}
                  participant={data.data.participant}
                  disabled={
                    data.data.enrolled ||
                    moment().isBefore(moment(data.data.startTime)) ||
                    moment().isAfter(moment(data.data.endTime))
                  }
                  id={id}
                ></ApplyForm>
              ) : (
                <Formio
                  form={data.data.form}
                  activity={data.data}
                  tncPath={data.data.tnc}
                  eventname={data.data.titleEn}
                  setRegisterResult={setRegisterResult}
                  participant={data.data.participant}
                  disabled={
                    data.data.enrolled ||
                    moment().isBefore(moment(data.data.startTime)) ||
                    moment().isAfter(moment(data.data.endTime))
                  }
                  id={id}
                ></Formio>
              )}
            </div>
          )}

        {
          // Get Enrolled from API
          data.data.enrolled && !registerResult && (
            <ResponsiveUI
              mobile={
                <CommonButton disabled width="320px" type={'primary'}>
                  {t('enrolled')}
                </CommonButton>
              }
              desktop={
                <CommonButton disabled size="large" type={'primary'}>
                  {t('enrolled')}
                </CommonButton>
              }
            />
          )
        }

        {registerResult && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ResponsiveUI
              mobile={
                <CommonText lineHeight="25px" fontSize="16px">
                  {t('registrationSuccess')}
                </CommonText>
              }
              desktop={
                <CommonText lineHeight="25px" fontSize="20px">
                  {t('registrationSuccess')}
                </CommonText>
              }
              largeDesktop={
                <CommonText lineHeight="45px" fontSize="30px">
                  {t('registrationSuccess')}
                </CommonText>
              }
            />
            {/* <TitleGroup
                        // zhTitle={t("registrationSuccess")}
                        enTitle="congratulations"
                        color={"black"}
                    /> */}
            {i18n.language === 'zh' && (
              <CommonText>{data.data?.successMsgZh}</CommonText>
            )}
            {i18n.language === 'en' && (
              <CommonText>{data.data?.successMsgEn}</CommonText>
            )}
            <StyledLink to="../activity">
              <ResponsiveUI
                mobile={
                  <CommonButton width="320px" type={'primary'}>
                    {t('backToActivity')}
                  </CommonButton>
                }
                desktop={
                  <CommonButton size="large" type={'primary'}>
                    {t('backToActivity')}
                  </CommonButton>
                }
              />
            </StyledLink>
          </div>
        )}
      </div>

      {/* {JSON.stringify(data)} */}
    </StyledMainDiv>
  );
}

const LiveIndicatorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  @media ${breakpoint.desktop} {
    padding-top: 120px;
    padding-bottom: 16px;
  }
  @media ${breakpoint.mobile} {
    padding-top: 80px;
    padding-bottom: 12px;
  }
`;

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledTitleDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledDateDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: flex;
`;

const PlayerContainer = styled.div`
  @media ${breakpoint.mobile} {
    width: 100%;
    height: 200px;
  }
  @media ${breakpoint.desktop} {
    width: 768px;
    height: calc(768px * 0.5625);
  }
  @media ${breakpoint.largeDesktop} {
    width: 875px;
    height: calc(875px * 0.5625);
  }
`;

const StyledColorDiv = styled.div`
  position: relative;
  background: #c3a8ff;
  width: 100%;
  @media ${breakpoint.mobile} {
    padding: 0 20px;
    padding-bottom: 16px;
  }
  @media ${breakpoint.desktop} {
    padding: 0 80px;
    width: 100%;
    padding-bottom: 50px;
  }
`;

const StyledImgDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: auto;
  width: 100%;
  background: linear-gradient(
    to top,
    white 0%,
    white 50%,
    #c3a8ff 50%,
    #c3a8ff 100%
  ); /* W3C */
  @media ${breakpoint.mobile} {
    margin: 0 auto 30px;
    padding: 0 20px;
  }
  @media ${breakpoint.desktop} {
    margin: 0 auto 50px;
    padding: 0 50px;
  }
`;

const StyledTermsDiv = styled.div`
  text-align: left;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 50px;
  @media ${breakpoint.desktop} {
    max-width: 1364px;
    padding: 0 50px;
    margin-bottom: 86.5px;
  }
  ol,
  ul {
    padding-left: 18px;
  }
`;

const StyledTermDiv = styled.div`
  display: flex;
  align-items: top;
  @media ${breakpoint.mobile} {
    margin-bottom: 8px;
  }
  @media ${breakpoint.desktop} {
    margin-bottom: 8px;
  }
  @media ${breakpoint.largeDesktop} {
    margin-bottom: 12px;
  }
`;

const StyledDot = styled.img`
  margin-right: 10px;
  @media ${breakpoint.mobile} {
    margin-top: 8px;
  }
  @media ${breakpoint.desktop} {
    margin-top: 12px;
  }
  @media ${breakpoint.largeDesktop} {
    margin-top: 14px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-top: 50px;
`;

const StyledBackDiv = styled.div`
  position: absolute;
  cursor: pointer;
  @media ${breakpoint.mobile} {
    top: 30px;
    left: 20px;
  }

  @media ${breakpoint.desktop} {
    top: 61px;
    left: 71px;
  }
`;
