import React from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import CommonText from '../typographies/CommonText';
import CommonMenuItem from './CommonMenuItem';
import { Link } from 'react-router-dom';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';
import { useTranslation } from 'react-i18next';
import { isNullOrBlank } from '../../../utils/common';

import { ReactComponent as DownArrow } from '../../../assets/down_arrow.svg';

// Options(boolean): disabled
// Options(string): zhTitle, enTitle, Dropdown menu:(option1, option2)
// Options(others): onClick, open
// Sample Usage: <DropDownMenuItem open={open} onClick={OnClickHandler} zhTitle="組合介紹" enTitle="we are mirror" option1="組合" option2="成員"></DropDownMenuItem>

function DropDownMenuItem(props) {
  let {
    option1,
    option2,
    link1,
    link2,
    zhTitle,
    enTitle,
    link,
    disabled,
    open,
    setDropdown,
    handleClose,
  } = props;
  const { i18n } = useTranslation();
  return (
    <StyledMainDiv open={open} lang={i18n.language}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CommonMenuItem
          zhTitle={zhTitle}
          enTitle={enTitle}
          link={''}
          disabled={disabled}
          onClick={() => {
            setDropdown(!open);
          }}
          isSubtitle={!isNullOrBlank(option1)}
        ></CommonMenuItem>
        <DownArrow
          transform={open ? 'scale(1.25) rotate(180)' : 'scale(1.25) rotate(0)'}
        />
      </div>

      <ResponsiveUI
        mobile={
          open && (
            <StyledMobileInnerDiv open={open}>
              <StyledLink to={link1} onClick={handleClose}>
                <CommonText
                  style={{
                    lineHeight: '25px',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                >
                  {option1}
                </CommonText>
              </StyledLink>

              <StyledLink to={link2} onClick={handleClose}>
                <CommonText
                  style={{
                    lineHeight: '25px',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                >
                  {option2}
                </CommonText>
              </StyledLink>
            </StyledMobileInnerDiv>
          )
        }
        desktop={
          open && (
            <StyledDropDownInnerDiv>
              <StyledDropDownMenuItem disableRipple>
                <StyledLink to={link1} onClick={handleClose}>
                  <CommonText
                    style={{
                      lineHeight: '25px',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    {option1}
                  </CommonText>
                </StyledLink>
              </StyledDropDownMenuItem>

              <div>
                <StyledVerticalDivider></StyledVerticalDivider>
              </div>

              <StyledDropDownMenuItem disableRipple>
                <StyledLink to={link2} onClick={handleClose}>
                  <CommonText
                    style={{
                      lineHeight: '25px',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    {option2}
                  </CommonText>
                </StyledLink>
              </StyledDropDownMenuItem>
            </StyledDropDownInnerDiv>
          )
        }
      />
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.open ? '113px' : '40px')};
  transition: height 0.3s ease-in-out;

  margin-bottom: 32px;
`;

const StyledDropDownMenuItem = styled(MenuItem)`
  display: flex;

  &.MuiMenuItem-root {
    height: 25px;
    &:hover {
      background-color: transparent;
    }
  }
`;

const StyledDropDownInnerDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 25px;

  @media ${breakpoint.desktop} {
    z-index: 2;
    margin-top: -15px;
  }
`;

const StyledMobileInnerDiv = styled.div`
  display: flex;
  grid-gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const StyledVerticalDivider = styled.div`
  border-right: 1px solid black;
  opacity: 0.3;
  height: 20px;
  margin: 3px 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default DropDownMenuItem;
