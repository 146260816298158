import styled from "styled-components";
import PaymentStep from "../miroregister/PaymentStep";
import { useState, useContext } from "react";
import { AppContext } from "../../AppContext";
import { useTranslation } from "react-i18next";
import { subscribe } from "../../apis";

const Step3 = ({ handlePageChange, setSessionExpired }) => {
  const [loading, setLoading] = useState(false);
  const { session, setSession } = useContext(AppContext);
  const [payment, setPayment] = useState(null);
  const [globalPaymentFormData, setGlobalPaymentFormData] = useState(null);
  const { t, i18n } = useTranslation(["common", "signin"]);

  const handlerSelectPayment = async (value) => {
    if (!loading && payment != value && value == "credit_card") {
      setLoading(true);
      const body = {
        locale: i18n.language,
      };
      console.log(body);
      const result = await subscribe(session.accessToken, body);
      console.log(result);
      if (result.success) {
        setGlobalPaymentFormData(result.data);
      } else {
        if (result.error?.code == "E0002") {
          setSessionExpired(true);
        }
      }
      setPayment(value);
      setLoading(false);
    }
  };
  const handlePayment = async () => {};

  return (
    <PaymentStep
      step=" 3/3"
      price={session?.user?.renewPrice}
      handlerSelectPayment={handlerSelectPayment}
      handlePayment={handlePayment}
      globalPaymentFormData={globalPaymentFormData}
      payment={payment}
      handlePageChange={() => handlePageChange("step2")}
    />
  );
};

export default Step3;
