import moment from 'moment';
import 'moment-timezone';

export const dateMonthConvert = (date, lang) => {
    if (lang == 'zh') {
        return moment(date).locale('zh-hk').tz('Asia/Hong_Kong').format("MMMDo");
    } else {
        return moment(date).locale('en').tz('Asia/Hong_Kong').format("D MMMM");
    }
}

export const fulldateConvert = (date, lang) => {
    if (lang == 'zh') {
        return moment(date).locale('zh-hk').tz('Asia/Hong_Kong').format("LL");
    } else {
        return moment(date).locale('en').tz('Asia/Hong_Kong').format("D MMMM yyyy");
    }
}