import GoogleLogin from "react-google-login";
import CommonButton from "../../components/common/buttons/CommonButton";
import GoogleIcon from "../../assets/login-google-icon.svg";
import { socialLogin } from "../../apis";
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import constants from "../../constants";
import ReactGA from "react-ga4";

function GoogleSigninButton({ login = false, handlePageChange }) {
  const { t } = useTranslation("signin");
  const { setSession } = useContext(AppContext);
  let navigate = useNavigate();

  const onLoginClick = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      // call api google token
      console.log("gogole login success", access_token);
      if (access_token) {
        const result = await socialLogin("google", access_token);
        console.log(result);
        if (result.success) {
          localStorage.setItem("session", JSON.stringify(result.data));
          setSession(result.data);
          if (result.data.user.memberType == "TMP") {
            navigate("/miro-register", {
              replace: true,
              state: { page: "step1" },
            });
          } else {
            ReactGA.event("login", {
              method: "google",
            });
          }
        } else if (result.error?.code === "C0001") {
          navigate("/miro-register", {
            replace: true,
            state: {
              page: "social",
              params: { type: "google", profile: result.error.profile },
            },
          });
          // handlePageChange("social", {
          //   type: "google",
          //   profile: result.error.profile,
          // });
        }
      }
    },
  });

  return (
    <CommonButton
      size="large"
      startIcon={<img src={GoogleIcon} />}
      onClick={onLoginClick}
    >
      {login ? t("signin.google_login") : t("register.google_register")}
    </CommonButton>
  );
}

export default function GoogleSignin(props) {
  return (
    <GoogleOAuthProvider clientId={constants.GOOGLE_ID}>
      <GoogleSigninButton {...props} />
    </GoogleOAuthProvider>
  );
}
