import { max } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../utils/responsive';

const ShadowImage = ({
  backdropStyles,
  src,
  imgWrapperStyle,
  imgStyle,
  onMouseOver,
  onMouseLeave,
  isBackdrop = true,
  ...props
}) => {
  return (
    <ImageContainer {...props} className={props.className}>
      <ImageWrapper
        isBackdrop={isBackdrop}
        style={{ ...imgWrapperStyle }}
        className="img-wrapper"
      >
        <StyledImage
          alt="media item"
          src={src}
          style={imgStyle}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          className="img"
        />

        {isBackdrop && (
          <StyledBackdrop
            style={backdropStyles}
            className="img-backdrop"
          ></StyledBackdrop>
        )}
      </ImageWrapper>
      {/* <h3 style={{ background: 'green', margin: '10px' }}>sdfds</h3> */}
    </ImageContainer>
  );
};

export const HomeMediaImage = ({ ...props }) => {
  return (
    <ShadowImage
      src={props.src}
      imgStyle={{
        cursor: 'pointer',
        aspectRatio: '16/9',
      }}
      backdropStyles={{
        background: '#305681',
        opacity: 0.2,
        visibility: 'hidden',
      }}
      {...props}
    />
  );
};

export const HomeShopImage = ({ ...props }) => {
  return (
    <StyledHomeShopImage
      src={props.src}
      isBackdrop={false}
      backdropStyles={{
        background: 'black',
        opacity: 0.1,
        visibility: 'hidden',
      }}
      {...props}
      imgStyle={{
        aspectRatio: '1/1',
        cursor: 'pointer',
      }}
      className={props.className}
    />
  );
};

const ImageContainer = styled.div`
  --backdrop-default-rotate: -6deg;
`;

const ImageWrapper = styled.div`
  position: relative;

  ${(props) =>
    props.isBackdrop &&
    `
      margin: calc(100% * sin(calc(-1 * var(--backdrop-default-rotate))) / 2) 0; // add margin to backdrop
    `}

  &:hover {
    img {
      transform: rotate(2deg);
    }
    .img-backdrop {
      transform: rotate(-8deg);
    }
  }
`;

export const StyledBackdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${(props) => props.background};
  opacity: ${(props) => props.opacity};
  top: 0;
  transition: transform 0.25s ease-out;
  transform: rotate(var(--backdrop-default-rotate));
  border-radius: 5px;
  @media ${breakpoint.desktop} {
    border-radius: 10px;
  }
`;

const StyledImage = styled.img`
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.25s ease-out;
  border-radius: 5px;
  @media ${breakpoint.desktop} {
    border-radius: 10px;
  }
`;

const StyledHomeShopImage = styled(ShadowImage)`
  .img-wrapper {
    margin: 0 var(--image-margin);
  }
`;

export default ShadowImage;
