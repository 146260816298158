import React from 'react';
import styled from 'styled-components';

// options:
// type: 'fill'(default), 'contain', 'cover', 'none', 'scale-down'
// height: '433px'(default) Discography size in Figma
// width: '433px'(default)
// discography: Boolean, for discography hover transform to circle
// alt: for description

// Sample Usage: <CommonImage type="cover" height="200px" width="200px" src={"https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"} alt={"bird"}></CommonImage>

function CommonImage(props) {
    let {
        discography,
    } = props;

    return (
        <StyledDiv
            height={props.height}
            width={props.width}
            discography={discography}
        >
            <StyledImage
                {...props}
            >
            </StyledImage>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    height: ${props => props.height ? props.height : '433px'};
    width: ${props => props.width ? props.width : '433px'};
    &:hover img{
        border-radius: ${props => props.discography? '50%' : 'none'};
        transition: 300ms;
        cursor: pointer;
    }
`;

const StyledImage = styled.img`
    user-drag: none;
    height: ${props => props.height ? props.height : '433px'};
    width: ${props => props.width ? props.width : '433px'};
`;

export default CommonImage;
