import styled from 'styled-components';
import CommonButton from '../../components/common/buttons/CommonButton';
import TextButton from '../../components/common/buttons/TextButton';
import Space from '../../components/common/Space';
import PasswordIcon from '../../assets/login-password-icon.svg';
import CommonText from '../../components/common/typographies/CommonText';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FacebookSignin from './FacebookSignin';
import GoogleSignin from './GoogleSignin';
import AppleSignin from './AppleSignin';

export default function RootSignin({ handlePageChange, disableMiroReg }) {
  const { t } = useTranslation('signin');
  return (
    <Container>
      <CommonText fontSize="30px" fontWeight="400" lineHeight="45px">
        {t('signin.title1')}
      </CommonText>
      <CommonText
        type="primary"
        fontSize="60px"
        fontWeight="400"
        lineHeight="65px"
      >
        {t('signin.title2')}
      </CommonText>
      <Space size="90px" />
      <CommonButton
        size="large"
        type="primary"
        startIcon={<img src={PasswordIcon} />}
        onClick={() => handlePageChange('password')}
      >
        {t('signin.password_login')}
      </CommonButton>
      {/* <Space size="20px" />
      <AppleSignin handlePageChange={handlePageChange} /> */}
      <Space size="20px" />
      <FacebookSignin login handlePageChange={handlePageChange} />
      <Space size="20px" />
      <GoogleSignin login handlePageChange={handlePageChange} />
      <Space size="20px" />
      {!disableMiroReg ? (
        <RegisterView>
          <CommonText>{t('signin.no_account')}</CommonText>
          <Link
            to="/miro-register"
            style={{ marginLeft: 5, textDecoration: 'none' }}
          >
            <TextButton fontWeight={600} size="large">
              {t('signin.register')}
            </TextButton>
          </Link>
        </RegisterView>
      ) : (
        <RegisterView>
          <CommonText whiteSpace="pre-wrap">{t('signin.miro_end')}</CommonText>
        </RegisterView>
      )}
      {/* <TopSpace>
        <CommonText
          style={{ padding: 10, color: 'white' }}
          fontSize="14px"
          fontWeight="800"
        >
          {t('signin.warning')}
        </CommonText>
      </TopSpace> */}
    </Container>
  );
}

// const TopSpace = styled.div`
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   width: 100%;
//   background: #f44434;
// `;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const RegisterView = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
