import { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import MirrorSelectGrid from "../../../components/common/selects/MirrorSelectGrid"
import Space from "../../../components/common/Space"
import CommonText from "../../../components/common/typographies/CommonText"
import CommonButton from "../../../components/common/buttons/CommonButton"

function MirrorMemberSelect(props) {
  const { onSubmit, selectedMirrorMember, isMobile } = props
  const { t } = useTranslation("profile")
  const [selectedMembers, setSelectedMembers] = useState(selectedMirrorMember || [])

  function canSubmit() {
    return true
  }

  return (
    <Container isMobile={isMobile}>
      <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
        {t("profile:mirror_supporting.change_member")}
      </CommonText>
      <Space size="50px" />
      <CommonText>
        {t("profile:mirror_supporting.select_supporting_mirror_member")}
      </CommonText>

      <Space size="20px" />

      <MirrorSelectGrid
        isMobile={isMobile}
        handleMemberClick={(member) => {
          if (selectedMembers.includes(member.nameZh)) {
            setSelectedMembers(
              selectedMembers.filter((item) => item !== member.nameZh)
            )
          } else {
            setSelectedMembers([...selectedMembers, member.nameZh])
          }
        }}
        isMemberSelected={(member) => selectedMembers.includes(member.nameZh)}
      />

      <Space size="20px" />
      <ButtonContainer>
        <CommonButton
          { ...(isMobile ? { width: '100%' } : {})}
          disabled={!canSubmit()}
          normalCase={true}
          type={"primary"}
          onClick={() => onSubmit(selectedMembers)}
        >
          {t("profile:button.submit_button")}
        </CommonButton>
      </ButtonContainer>
      <Space size="20px" />
    </Container>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.isMobile ? '100%' : '592px'};
  justify-content: center;
  align-items: center;
`

export default MirrorMemberSelect
