import React, { useMemo } from 'react';
import styled from 'styled-components';
import PaperBackgroundDesktop from '../assets/paper_popup_background_desktop.png';
import PaperBackgroundMobile from '../assets/paper_popup_background_mobile.png';
import WelcomeMIROImage from '../assets/welcome_miro_image.png';
import CommonText from './common/typographies/CommonText';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog, useMediaQuery } from '@mui/material';
import { ResponsiveUI, breakpoint } from '../utils/responsive';
import MirrorLogoOrange from '../assets/mirror_logo_orange.svg';
import IconClose from '../assets/icon_close.svg';
import moment from 'moment';

const wordings = {
  zh: {
    renew_title_1: '感謝您今年',
    renew_title_2: '繼續成為MIRO!',
    join_title_1: '歡迎您加入',
    join_title_2: '成為MIRO!',
  },
  en: {
    renew_title_1: 'Thank you',
    renew_title_2: 'for being MIRO this year!',
    join_title_1: 'Welcome',
    join_title_2: 'to join us as MIRO!',
  },
};
function WelcomeMIRODialog({ isNew, onClose }) {
  const { t, i18n } = useTranslation('profile');
  const matches = useMediaQuery(breakpoint.mobile);
  return (
    <Dialog
      open
      fullScreen={true}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          gap: 24,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <Container>
          <BackgroundImage
            src={matches ? PaperBackgroundMobile : PaperBackgroundDesktop}
          />
          <Content>
            <MirrorLogo src={MirrorLogoOrange} />
            <MiroText fontSize="32px" mFontSize="22px" style={{ whiteSpace: "pre-line" }}>
              {isNew
                ? wordings[i18n.language].join_title_1
                : wordings[i18n.language].renew_title_1}
              <br />
              <b>
                {isNew
                  ? wordings[i18n.language].join_title_2
                  : wordings[i18n.language].renew_title_2}
              </b>
            </MiroText>
            <MemberImage src={WelcomeMIROImage} />
          </Content>
        </Container>
      </div>
      <CloseButton src={IconClose} onClick={onClose} />
    </Dialog>
  );
}

const Container = styled.div`
  display: flex;
  background: transparent;
  position: relative;
  width: 588px;
  height: 380px;
  overflow: hidden;
  @media ${breakpoint.mobile} {
    width: 320px;
    height: 280px;
  }
`;
const BackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100;
`;
const Content = styled.div`
  position: absolute;
  display: flex;
  top: 45px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 8px;
  @media ${breakpoint.mobile} {
    top: 34px;
  }
`;
const MirrorLogo = styled.img`
  width: 60px;
  height: 62px;
  @media ${breakpoint.mobile} {
    width: 40px;
    height: 41px;
  }
`;
const MemberImage = styled.img`
  width: 360px;
  height: 180px;
  @media ${breakpoint.mobile} {
    width: 280px;
    height: 140px;
  }
`;

const MiroText = styled(CommonText)`
  color: #fb5917;
  text-align: center;
  line-height: 120%;
  &:hover {
    color: #fb5917;
  }
`;

const CloseButton = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 50px;
  cursor: pointer;
`;
export default WelcomeMIRODialog;
