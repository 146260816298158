import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CommonCarousel = ({ images, carouselItems, gotoSlick, ...props }) => {
  const sliderRef = useRef();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    variableWidth: true,
    centerMode: true,
    arrows: false,
  };

  useEffect(() => {
    if (typeof gotoSlick == 'number') {
      sliderRef.current.slickGoTo(gotoSlick);
    }
  }, [gotoSlick]);

  return (
    <StyledDiv {...props}>
      <StyledSlider {...settings} ref={sliderRef}>
        {!!images &&
          Object.values(images).map((image, index) => {
            return (
              <Container>
                <StyledImg key={index} src={image + '-thumbnail'}></StyledImg>
              </Container>
            );
          })}
      </StyledSlider>
    </StyledDiv>
  );
};

const Container = styled.div`
  height: 503px;
  padding: 0 15px;
`;

const StyledImg = styled.img`
  height: 100%;
`;

const StyledDiv = styled.div`
  width: 100%;
`;

const StyledSlider = styled(Slider)`
  .slick-slide {
    opacity: 0.3;
    transition: all 0.5s;
  }
  .slick-center {
    opacity: 1;
  }
`;

export default CommonCarousel;
