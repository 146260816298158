import React from 'react';
import styled from 'styled-components';
import TitleWithCounterBar from '../../components/common/bars/TitleWithCounterBar';
import DiscographyItem from './DiscographyItem';
import CommonDivider from '../../components/common/divider/CommonDivider';
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

function ItemGroup(props) {
    let {
        data
    } = props;

    return (
        <StyledDiv>
            <TitleWithCounterBar title={data._id} type="song" number={data.discographies.length} color="white"></TitleWithCounterBar>
            <CommonDivider></CommonDivider>
            <StyledItemsDiv>
                {
                    data.discographies.map((item, index) => {
                        return <DiscographyItem key={index} data={item}></DiscographyItem>
                    })
                }
            </StyledItemsDiv>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    width: 100%;
    margin-bottom: 27px;
    @media ${breakpoint.desktop} {
        margin-bottom: 100px;
    }
`;

const StyledItemsDiv = styled.div`
    display: grid;
    justify-content: center;
    margin: auto;

    @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 30px;
        max-width: 1920px;
    }

    @media (max-width: 1023px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }

    @media ${breakpoint.mobile} {
        grid-template-columns: 1fr 1fr;
        max-width: 600px;
    }
`;

export default ItemGroup;