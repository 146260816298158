import React, { useContext } from 'react';
import styled from 'styled-components';
import MiroCardImage from '../../../assets/revamp2025/home_miro_card.png';
import { Tooltip, useMediaQuery } from '@mui/material';
import CopyButton from '../../../components/common/buttons/CopyButton';
import { breakpoint, WindowSize } from '../../../utils/responsive';
import CommonText from '../../../components/common/typographies/CommonText';
import { AppContext } from '../../../AppContext';
import { max } from 'moment';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
const MiroWelcomeText = ({ isInsideImage }) => {
  const context = useContext(AppContext);
  const userName = context.session?.user?.username;
  const miroId = context.session?.user?.memberNo;
  const { t } = useTranslation('home');
  return (
    <WelcomeTextContainer>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CommonText
          type="primary"
          fontSize="40px"
          fontSizeFor1024="24px"
          largeFontSize="40px"
          style={{
            marginRight: '5px',
            marginTop: 'auto',
            marginBottom: '0.25rem',
          }}
          color={isInsideImage ? 'white' : 'black'}
        >
          Hi,
        </CommonText>
        <CommonText
          fontSize="40px"
          fontSizeFor1024="36px"
          largeFontSize="60px"
          type="primary"
          whiteSpace="nowrap"
          color={isInsideImage ? 'white' : 'black'}
          style={{
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          {userName}
        </CommonText>
      </div>
      <CommonText
        fontSize="16px"
        fontSizeFor1024="14px"
        largeFontSize="20px"
        fontWeight="500"
        color={isInsideImage ? 'white' : 'black'}
        style={{ opacity: !isInsideImage && 0.7 }}
      >
        {t('home:miroCard.miroNumber')}
        <span
          style={{
            fontWeight: 700,
            borderLeft: isInsideImage
              ? '1px solid #ffffff3b'
              : '1px solid #0000001a',
            paddingLeft: 5,
            marginLeft: 5,
          }}
        >
          {miroId}
        </span>
        <CopyButton
          copyText={miroId}
          color={isInsideImage ? 'white' : 'black'}
        />
      </CommonText>
    </WelcomeTextContainer>
  );
};

const MiroCard = () => {
  const isLargerThan1024 = useMediaQuery(breakpoint[1024]);
  const { session } = useContext(AppContext);
  const memberDayCount = session?.user?.miroDayCount;
  const { t, i18n } = useTranslation('profile');
  return (
    <StyledContainer>
      {!isLargerThan1024 && (
        <MiroWelcomeText isInsideImage={isLargerThan1024} />
      )}
      <div
        style={{
          boxShadow: '0px 39px 42.4px -15px #00000030',
          borderRadius: isLargerThan1024 ? 30 : 20,
        }}
      >
        <ImageContainer>
          <img src={MiroCardImage} alt="miro card" />
          {isLargerThan1024 && (
            <MiroWelcomeText isInsideImage={isLargerThan1024} />
          )}
        </ImageContainer>
        <DayCountContainer>
          <DayCount
            type={i18n.language === 'en' ? 'primary' : 'common'}
            color="white"
            mFontSize="16px"
            fontSize="20px"
            fontSizeFor1024="16px"
            largeFontSize="20px"
          >
            <Trans
              t={t}
              values={{ day: memberDayCount }}
              i18nKey="profile:membership.miro_day_counter"
              components={[
                <span
                  style={{
                    padding:
                      i18n.language === 'zh'
                        ? isLargerThan1024
                          ? '0 7px'
                          : '0 5px'
                        : null,
                  }}
                ></span>,
              ]}
            />
          </DayCount>
        </DayCountContainer>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  border-radius: 30px;
  max-width: 540px;

  @media ${breakpoint[1024]} {
    max-width: 500px;
  }
  @media ${breakpoint.largeDesktop} {
    max-width: 600px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  img {
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    @media ${breakpoint[1024]} {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }
    object-fit: cover;
    object-position: bottom;
  }
`;

const WelcomeTextContainer = styled(CommonText)`
  text-align: center;
  width: 100%;
  color: black;
  padding-bottom: 20px;

  @media ${breakpoint[1024]} {
    position: absolute;
    bottom: 0px;
    padding-bottom: 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 78.7%);
  }
`;

const DayCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  background-color: #ff5c00;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  @media ${breakpoint.desktop} {
    height: 66px;
  }
  @media ${breakpoint[1024]} {
    height: 47px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  @media ${breakpoint.largeDesktop} {
    height: 78px;
  }
  @media ${breakpoint.extraLargeDesktop} {
    height: 88px;
  }
`;

const DayCount = styled(CommonText)`
  text-align: center;

  span {
    font-size: 30px;
    font-family: 'Philosopher';
    font-weight: 700;
    @media ${breakpoint[1024]} {
      font-size: 24px;
    }

    @media ${breakpoint.largeDesktop} {
      font-size: 36px;
    }
  }
`;
export default MiroCard;
