import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getMenu } from '../../../apis';
import { AppContext } from '../../../AppContext';

/**
 *
 * @typedef {Object} MenuItem
 * @property {string} id
 * @property {string| undefined} link
 * @property {string | undefined} externalLink
 * @property {string} name
 * @property {boolean} disabled
 * @property {Menu[]} children
 */

const useMenus = () => {
  const { session } = useContext(AppContext);
  const { t, i18n } = useTranslation('menu');
  const isMiro = session?.user?.memberType === 'MIRO';
  const { isLoading, isError, data, error } = useQuery(
    ['getMenu', session?.accessToken],
    () => getMenu(session?.accessToken)
  );

  const allMenuLinks = {
    'WE ARE MIRROR': {
      id: 'WE_ARE_MIRROR',
      children: [
        {
          id: 'WE_ARE_MIRROR_TEAM_PROFILE',
          name: t('team'),
          link: '/team-profile',
        },
        {
          id: 'WE_ARE_MIRROR_MEMBER_PROFILE',
          name: t('members'),
          link: '/member-profile',
        },
      ],
    },
    HOME: {
      id: 'HOME',
      link: '/',
    },
    DISCOGRAPHY: {
      id: 'DISCOGRAPHY',
      link: '/discography',
    },
    ACTIVITY: {
      id: 'ACTIVITY',
      link: '/activity',
      disabled: !isMiro,
    },
    MEDIA: {
      id: 'MEDIA',
      link: '/media-series',
      disabled: !isMiro,
    },
    SHOP: {
      id: 'SHOP',
      externalLink: 'https://shop.mirrorweare.com',
    },
  };

  const allFooterMenuLinks = {
    'WE ARE MIRROR': {
      id: 'WE_ARE_MIRROR',
      children: [
        {
          id: 'WE_ARE_MIRROR_TEAM_PROFILE',
          name: t('teamIntro'),
          link: '/team-profile',
        },
        {
          id: 'WE_ARE_MIRROR_MEMBER_PROFILE',
          name: t('membersIntro'),
          link: '/member-profile',
        },
      ],
    },
    HOME: {
      id: 'HOME',
      link: '/',
    },
    DISCOGRAPHY: {
      id: 'DISCOGRAPHY',
      link: '/discography',
    },
    ACTIVITY: {
      id: 'ACTIVITY',
      link: '/activity',
      disabled: !isMiro,
    },
    MEDIA: {
      id: 'MEDIA',
      link: '/media-series',
      disabled: !isMiro,
    },
    SHOP: {
      id: 'SHOP',
      externalLink: 'https://shop.mirrorweare.com',
    },
  };

  /**
   * @type {MenuItem[]}
   */
  const menusFromApi = useMemo(() => {
    return (
      data?.data?.results
        .filter((menu) => menu.menuItemEn !== 'HOME')
        .map((menu) => {
          if (allMenuLinks[menu.menuItemEn]) {
            return {
              ...allMenuLinks[menu.menuItemEn],
              name: i18n.language === 'en' ? menu.menuItemEn : menu.menuItemZh,
            };
          }
          return null;
        }) || []
    );
  }, [data, i18n.language, isMiro]);

  /**
   * @type {MenuItem[]}
   * flatten the children
   */
  const footerMenusFromApi = useMemo(() => {
    const allItems =
      data?.data?.results
        .filter((menu) => menu.menuItemEn !== 'HOME')
        .map((menu) => {
          const items = [];
          const matchedMenu = allFooterMenuLinks[menu.menuItemEn];
          if (!matchedMenu) return [];
          if (matchedMenu.children) {
            items.push(matchedMenu.children);
          } else {
            items.push({
              ...allFooterMenuLinks[menu.menuItemEn],
              name: i18n.language === 'en' ? menu.menuItemEn : menu.menuItemZh,
            });
          }
          return items;
        }) || [];

    return allItems.reduce((acc, val) => acc.concat(...val), []);
  }, [data, i18n.language, isMiro]);

  return { menusFromApi, footerMenusFromApi };
};

export default useMenus;
