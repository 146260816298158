import styled from "styled-components";
import { useTranslation } from "react-i18next";

import CommonText from "../../components/common/typographies/CommonText";
import colors from "../../utils/colors";
import IconInstagram from "../../assets/memProfile_instagramIcon.svg";
import IconFacebook from "../../assets/memProfile_facebookIcon.svg";
import IconMobileInstagram from "../../assets/memProfile_instagramMobileIcon.svg";
import IconMobileFacebook from "../../assets/memProfile_facebookMobileIcon.svg";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";
import ReactGA from "react-ga4";

const FollowMember = ({ data }) => {
  const { t, i18n } = useTranslation("memberProfile");

  const _renderMobile = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <StyledStarIcon
          src={IconMobileInstagram}
          onClick={() => {
            if (data && data.instagram) {
              ReactGA.event("outbound_click_from_member_profile", {
                param: data.instagram,
              });
              window.open(data.instagram, "_blank");
            }
          }}
        />
        <StyledStarIcon
          src={IconMobileFacebook}
          onClick={() => {
            if (data && data.facebook) {
              ReactGA.event("outbound_click_from_member_profile", {
                param: data.facebook,
              });
              window.open(data.facebook, "_blank");
            }
          }}
        />
      </div>
    );
  };

  const _renderDesktop = () => {
    return (
      <div style={{ marginTop: 50 }}>
        <StyledStarIcon
          src={IconInstagram}
          onClick={() => {
            if (data && data.instagram) {
              ReactGA.event("outbound_click_from_member_profile", {
                param: data.instagram,
              });
              window.open(data.instagram, "_blank");
            }
          }}
        />
        <StyledStarIcon
          src={IconFacebook}
          onClick={() => {
            if (data && data.facebook) {
              ReactGA.event("outbound_click_from_member_profile", {
                param: data.facebook,
              });
              window.open(data.facebook, "_blank");
            }
          }}
        />
      </div>
    );
  };

  return (
    <StyledMainDiv>
      <CommonText
        color={colors.white}
        fontSize="30px"
        mFontSize="16px"
        uppercase
      >
        {t("label.follow")}
        {data &&
          (i18n.language == "zh"
            ? data.memberNameZh
            : data.memberNameEn.split(" ")[0])}
      </CommonText>

      <ResponsiveUI mobile={_renderMobile()} desktop={_renderDesktop()} />
    </StyledMainDiv>
  );
};

const StyledMainDiv = styled.div`
  margin-top: 40px;

  @media ${breakpoint.desktop} {
    margin-top: 100px;
  }
`;

const StyledStarIcon = styled.img`
  cursor: pointer;
  user-drag: none;
`;

export default FollowMember;
