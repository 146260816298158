import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import CommonCloseDialog from '../../components/common/dialogs/CommonCloseDialog'
import styled from 'styled-components'
import Checkbox from "../../components/common/checkboxs/Checkbox";
import CommonText from "../../components/common/typographies/CommonText";
import CommonButton from "../../components/common/buttons/CommonButton";
import { ResponsiveUI, breakpoint } from '../../utils/responsive'
import { useTranslation } from 'react-i18next';
import TnC from './TnC';
import moment from 'moment';
import "moment/locale/zh-hk"
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AppContext } from '../../AppContext';
import { getMediaTnC } from '../../apis';
import { StyledMediaCloseDialog } from '../../components/common/dialogs/MediaDialog';



const initState = {
  state: "DEFAULT",
  reading: false,
  read: false,
  termsCheckboxError: false,
  notReadError: false
}

function reducer(state, action) {
  switch (action.type) {
    case "DEFAULT":
      return {
        state: action.type,
        open: true,
        reading: false,
        read: false,
        termsCheckboxError: false,
        notReadError: false
      }
    case "START_READING":
      return {
        state: action.type,
        open: true,
        reading: true,
        read: false,
        termsCheckboxError: false,
        notReadError: true
      }
    case "FINISH_READING":
      return {
        state: action.type,
        open: true,
        reading: false,
        read: true,
        termsCheckboxError: false,
        notReadError: false
      }
    case "CONTINUE_BEFORE_AGREE":
      return {
        state: action.type,
        open: true,
        reading: false,
        read: true,
        termsCheckboxError: true,
        notReadError: false
      }
    default:
      return initState
  }

}



const LOCALSTORAGE_AGREEMENT_ACCEPTED_EXPIRY = "AgreementPopupExpiry"

const AgreementDialog = () => {
  const { session } = useContext(AppContext);
  const { t, i18n } = useTranslation('mediaSeries')
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);

  const [isTandcChecked, setTandcChecked] = useState(false);
  const [state, dispatch] = useReducer(reducer, initState)

  const dailyAccepted = localStorage.getItem(LOCALSTORAGE_AGREEMENT_ACCEPTED_EXPIRY) && moment.unix(localStorage.getItem(LOCALSTORAGE_AGREEMENT_ACCEPTED_EXPIRY)).isAfter(moment())

  const { isLoading, isError: apiError, data, error, refetch } = useQuery(
    ["mediaTnC", session?.accessToken],
    () => getMediaTnC(session?.accessToken),
    { enabled: !dailyAccepted })



  useEffect(() => {
    if (!dailyAccepted) {
      setIsOpen(true)
    }
  }, [])

  const handleScrolling = () => {
    if (!state.read) {
      dispatch({ type: "START_READING" })
    }
  }

  const handleScrollToBottom = () => {
    dispatch({ type: "FINISH_READING" })
  }
  const onAcceptAgreement = () => {
    if (isTandcChecked) {
      localStorage.setItem(
        LOCALSTORAGE_AGREEMENT_ACCEPTED_EXPIRY,
        moment().add(1, 'day').locale('zh-hk').startOf('day').unix()
      )
      setIsOpen(false)
    } else {
      if (state.read) {
        dispatch({ type: "CONTINUE_BEFORE_AGREE" })
      } else {
        dispatch({ type: "START_READING" })
      }
    }
  }

  const onRejectAgreement = () => {
    if (document.referrer || window.history.length === 0) {
      navigate("/")
    } else {
      // if from mofc page
      navigate(-1)
    }
  }

  const handleTermsCheckboxChecked = () => {
    if (state.read) {
      setTandcChecked(!isTandcChecked);
      dispatch({ type: "FINISH_READING" })
    } else {
      dispatch({ type: "START_READING" })
    }
  }

  return (
    <StyledMediaCloseDialog showCloseButton={false} visible={isOpen} padding="50px" >
      <Container>
        <CommonText textAlign='center' mFontSize="18px" fontSize="24px" fontWeight={500}>{t("TandCTitle")}</CommonText>
        <DialogContent>
          <TnC onTnCScroll={handleScrolling} onScrollToBottom={handleScrollToBottom} html={i18n.language === 'zh' ? data?.data.tncZh : data?.data.tncEn} />
          <AcceptTermsContainer>
            <Checkbox
              onClick={handleTermsCheckboxChecked}
              disabled={state.reading}
              isChecked={isTandcChecked}>

              <StyledAgreeText mFontSize="14px" fontSize="16px" lineheight="20px" disabled={state.reading} onClick={handleTermsCheckboxChecked}>
                {t("iArgeeTandC")}
              </StyledAgreeText>
            </Checkbox>
            {state.termsCheckboxError && (
              <CommonText
                mFontSize="14px"
                fontSize="16px"
                lineheight="20px"
                color="#FF5C00"
                textAlign="left"
                style={{ marginLeft: 42 }}
              >
                {t("pleaseAgreeTandC")}
              </CommonText>
            )}
          </AcceptTermsContainer>
          <ButtonGroup>
            <CommonButton
              size="large"
              onClick={onRejectAgreement}
            >
              {t("exit")}
            </CommonButton>

            <CommonButton
              type="primary"
              size="large"
              disableRipple={!state.read}
              style={{
                marginLeft: 16,
                cursor: !state.read ? 'not-allowed' : 'default',
                filter: !state.read && 'opacity(35%)'
              }}
              hoverBgColor='linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FF5C00'
              onClick={onAcceptAgreement}
            >
              {t("continue")}
            </CommonButton>
          </ButtonGroup>
        </DialogContent>
        {(state.reading) && <CommonText
          mFontSize="14px"
          fontSize="16px"
          lineheight="20px"
          color="#FF5C00"
          textAlign="center"
          style={{ marginTop: 8 }}
        >
          {t("pleaseReadTandC")}
        </CommonText>}
      </Container>
    </StyledMediaCloseDialog >
  )
}



const Container = styled.div`
  width: 800px;
  max-width: 100%;
`;



const DialogContent = styled.div`
  width: 100%;
  margin-top: 20px;
`;



const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
 
`;



const AcceptTermsContainer = styled.div`
  @media ${breakpoint.mobile} {
    margin-bottom: 10px;
  }
  @media ${breakpoint.desktop} {
    margin-bottom: 15px;
  }
`;


const StyledAgreeText = styled(CommonText)`
  &:hover{
    cursor: ${props => props.disabled ? 'not-allowed' : 'default'}
  }
`





export default AgreementDialog