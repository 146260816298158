import React, { useMemo } from 'react';
import styled from 'styled-components';
import PaperBackgroundDesktop from '../../assets/paper_popup_background_desktop.png';
import PaperBackgroundMobile from '../../assets/paper_popup_background_mobile.png';
import MemberImage1 from '../../assets/daycounter/01.png';
import MemberImage2 from '../../assets/daycounter/02.png';
import MemberImage3 from '../../assets/daycounter/03.png';
import MemberImage4 from '../../assets/daycounter/04.png';
import MemberImage5 from '../../assets/daycounter/05.png';
import MemberImage6 from '../../assets/daycounter/06.png';
import MemberImage7 from '../../assets/daycounter/07.png';
import MemberImage8 from '../../assets/daycounter/08.png';
import MemberImage9 from '../../assets/daycounter/09.png';
import MemberImage10 from '../../assets/daycounter/10.png';
import MemberImage11 from '../../assets/daycounter/11.png';
import MemberImage12 from '../../assets/daycounter/12.png';
import CommonText from '../../components/common/typographies/CommonText';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog, useMediaQuery } from '@mui/material';
import { ResponsiveUI, breakpoint } from '../../utils/responsive';
import CommonButton from '../../components/common/buttons/CommonButton';
import IconClose from '../../assets/icon_close.svg';
import moment from 'moment';
import 'moment-timezone';
import MiroDayCountShareButton from '../../components/MiroDayCountShareButton';
const memberImages = [
  MemberImage1,
  MemberImage1,
  MemberImage2,
  MemberImage3,
  MemberImage4,
  MemberImage5,
  MemberImage6,
  MemberImage7,
  MemberImage8,
  MemberImage9,
  MemberImage10,
  MemberImage11,
  MemberImage12,
];
function MIRODayCounterDialog({ t, plus = false, count, since, onClose }) {
  const { i18n } = useTranslation();
  const member_image = useMemo(() => {
    return memberImages[Math.floor(Math.random() * memberImages.length)];
  }, []);
  const matches = useMediaQuery(breakpoint.mobile);
  return (
    <Dialog
      open
      fullScreen={true}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          gap: 24,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <Container>
          <BackgroundImage
            src={matches ? PaperBackgroundMobile : PaperBackgroundDesktop}
          />

          <MemberImage src={member_image} />
          <MiroText fontSize="24px" mFontSize="18px">
            {i18n.language === 'zh'
              ? '成為MIRO的第'
              : 'Days of joining as a MIRO:'}
          </MiroText>
          <DayCountText fontSize="110px" mFontSize="80px">
            {count}
            {plus && (
              <PlusText fontSize="50px" mFontSize="30px">
                +
              </PlusText>
            )}
            <DayText fontSize="22px" mFontSize="16px">
              {i18n.language === 'zh' ? '天' : 'Days'}
            </DayText>
          </DayCountText>
          <DateText fontSize="15px" mFontSize="14px">
            Since{' '}
            <b>{moment(since).tz('Asia/Hong_Kong').format('YYYY.MM.DD')}</b>
          </DateText>
        </Container>
        {plus && (
          <CommonText color="rgb(255, 255, 255, 0.78)" fontSize="16px">
            {i18n.language === 'zh'
              ? '您可在「我的會籍」頁面中的會員卡下方找到此紀錄。'
              : 'Go to "My Membership Page" and discover more in "Membership card" section.'}
          </CommonText>
        )}
        <MiroDayCountShareButton
          memberImage={member_image}
          count={count}
          since={moment(since).tz('Asia/Hong_Kong').format('YYYY-MM-DD')}
          plus={plus}
          language={i18n.language}
        />
      </div>
      <CloseButton src={IconClose} onClick={onClose} />
    </Dialog>
  );
}

const Container = styled.div`
  display: flex;
  background: transparent;
  position: relative;
  width: 588px;
  height: 380px;
  overflow: hidden;
  @media ${breakpoint.mobile} {
    width: 320px;
    height: 280px;
  }
`;
const BackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100;
`;
const MemberImage = styled.img`
  position: absolute;
  left: 78px;
  bottom: 0px;
  width: 120px;
  height: 140px;
  z-index: 1;
  @media ${breakpoint.mobile} {
    width: 100px;
    height: 116px;
    left: 12px;
  }
`;

const MiroText = styled(CommonText)`
  position: absolute;
  font-family: Philosopher;
  top: 94px;
  left: 153px;
  @media ${breakpoint.mobile} {
    width: 100%;
    text-align: center;
    left: 0px;
    top: 80px;
  }
`;
const DayText = styled(CommonText)`
  color: #383534;
  font-family: Philosopher;
  position: relative;
  :hover {
    color: #383534;
  }
  top: -20px;
  @media ${breakpoint.mobile} {
    top: -16px;
  }
`;
const DayCountText = styled(CommonText)`
  position: absolute;
  font-family: Philosopher;
  color: #fb5917;
  top: 122px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  :hover {
    color: #fb5917;
  }
  @media ${breakpoint.mobile} {
    top: 100px;
  }
`;

const PlusText = styled(CommonText)`
  font-family: Philosopher;
  color: #fb5917;
  padding-bottom: 60px;
  :hover {
    color: #fb5917;
  }
  @media ${breakpoint.mobile} {
    padding-bottom: 50px;
  }
`;

const DateText = styled(CommonText)`
  position: absolute;
  color: #727272;
  top: 272px;
  left: 235px;
  :hover {
    color: #727272;
  }
  @media ${breakpoint.mobile} {
    top: 190px;
    left: 104px;
  }
`;

const CloseButton = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 50px;
  cursor: pointer;
`;
export default MIRODayCounterDialog;
