import React from 'react';

import styled from 'styled-components';
import CommonText from '../typographies/CommonText';
import { useTranslation } from 'react-i18next';
import DisabledLinkTooltip from '../../common/tooltip/DisabledLinkTooltip';
import { useNavigate } from 'react-router-dom';
import colors from '../../../utils/colors';
import DownArrow from '../../../pageComponents/home/asset/DownArrow';
import ExternalLinkIcon from '../../../pageComponents/home/asset/ExternalLinkIcon';
import Lock from '../../../pageComponents/home/asset/Lock';

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => !props.disabled && 'pointer'};
  white-space: pre;
  &:hover {
    ${(props) =>
      !props.disabled &&
      `
    text-decoration: underline;
    text-decoration-color: ${
      props.theme == 'black' ? colors.black : colors.white
    };
    text-underline-offset: 8px;
    `}
  }
`;

const MenuItem = React.forwardRef(({ menuItem, theme, ...props }, ref) => {
  const { disabled, children: hasPopup, externalLink, name, link } = menuItem;
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const handleClick = (e) => {
    if (link) {
      navigate(link);
      return;
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };
  const themedColor = theme == 'black' ? colors.black : colors.white;
  return (
    <MenuItemContainer
      {...props}
      theme={theme}
      disabled={disabled}
      onClick={handleClick}
      ref={ref}
    >
      <CommonText
        fontSize="16px"
        largeFontSize="18px"
        fontWeight="700"
        lineHeight="23px"
        className="menu-item"
        color={themedColor}
        uppercase
      >
        {name}
      </CommonText>
      {hasPopup && <DownArrow viewBox="2 2 15 15" color={themedColor} />}
      {disabled && <Lock color={themedColor} />}
      {externalLink && (
        <ExternalLinkIcon width={20} height={20} color={themedColor} />
      )}
    </MenuItemContainer>
  );
});

/**
 * a hoc for disable and anchor menu (need to wrapped the original component by some parent)
 * @param {*} WrappedComponent
 * @returns
 */
const withStyledNonDefaultMenuItems = (WrappedComponent) => {
  const ComponentWrappedByParent = (props) => {
    const { t } = useTranslation();
    const { menuItem } = props;
    if (menuItem.disabled) {
      return (
        <DisabledLinkTooltip title={t('common:miro_only')}>
          <WrappedComponent {...props} />
        </DisabledLinkTooltip>
      );
    }

    if (menuItem.externalLink) {
      return (
        <a
          href={menuItem.externalLink}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none', display: 'flex', gap: 4 }}
        >
          <WrappedComponent {...props} />
        </a>
      );
    }
    return <WrappedComponent {...props} />;
  };

  return ComponentWrappedByParent;
};

export default withStyledNonDefaultMenuItems(MenuItem);
