import React from 'react';
import 'moment-timezone';
import styled from 'styled-components';
import CalendarIcon from '../../assets/calender_icon.png';
import IconArrowBlack from '../../assets/ic-arrowRight-black.svg';
import CommonText from '../../components/common/typographies/CommonText';
import { Trans } from 'react-i18next';
function MIRODayCounter({ t, count, onClick }) {
  return (
    <Container onClick={onClick}>
      <img width={44} src={CalendarIcon} />
      <CommonText
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          marginRight: 10,
        }}
        fontSize="15px"
      >
        <Trans
          t={t}
          values={{ day: count }}
          i18nKey="profile:membership.miro_day_counter"
          components={[
            <CommonText
              fontSize="20px"
              fontWeight={700}
              style={{
                position: 'relative',
                top: -1,
                color: '#FF6B1C',
              }}
            />,
          ]}
        />
      </CommonText>
      <img width={8} src={IconArrowBlack} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 24px;
  border-radius: 500px;
  gap: 4px;
  padding: 0px 20px;
  background: #ffe6dc;
  cursor: pointer;
`;

export default MIRODayCounter;
