import React from 'react';
import styled from 'styled-components';

function VerticalDivider(props) {

    return (
        <StyledDivider
        {...props}
        />
    );
}

const StyledDivider = styled.div`;
    border-right: 1px solid ${props => props.color};
    height: ${props => props.size};
    margin: 0 10px;
`;


export default VerticalDivider;