import React from 'react';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';

// default: 16px, black, 100% width as parent
function CommonLabel(props) {
  return <StyledInputLabel {...props}>{props.children}</StyledInputLabel>;
}

const StyledInputLabel = styled(InputLabel)`
  && {
    font-family: 'Open Sans', 'Noto Sans TC', 'Microsoft JhengHei', Tahoma,
      Helvetica, 'Microsoft Yahei', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #111111;
    white-space: normal;
    @media ${breakpoint.mobile} {
      font-size: 14px;
    }
    @media ${breakpoint.desktop} {
      font-size: 15px;
    }
    @media ${breakpoint.largeDesktop} {
      font-size: 16px;
    }
  }
`;

export default CommonLabel;
