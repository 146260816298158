import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './AppContext';
import './i18n';
import constants from './constants';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/react';
moment.tz.setDefault('Asia/Hong_Kong');
ReactGA.initialize(constants.GA4_ID);
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: constants.IS_DEV ? 'dev' : 'prod',
    release: constants.RELEASE,
    dsn: 'https://687ff359f15e59c76d2b10be604e6d22@o4506347424382976.ingest.us.sentry.io/4507622289965056',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api-dev.mirrorweare\.com/,
      /^https:\/\/api.mirrorweare\.com/,
    ],
    // Session Replay
    replaysSessionSampleRate: constants.IS_DEV ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (!constants.IS_DEV) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
const container = document.getElementById('root');
createRoot(container).render(
  <React.StrictMode>
    <Suspense fallback={<div />}>
      <AppProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
