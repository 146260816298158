import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../utils/responsive';
import DecoratingBottomLine from '../../../assets/revamp2025/ic-home-discography-decorating-line.svg';
import { useMediaQuery } from '@mui/material';

const HomeContainer = ({ children }) => {
  const isLargerThan1024 = useMediaQuery(breakpoint[1024]);
  return (
    <div style={{ position: 'relative' }}>
      <StyledContainer>
        {isLargerThan1024 && (
          <StyledDecoratingBottomLine
            src={DecoratingBottomLine}
            alt="decorating"
          />
        )}
        {children}
      </StyledContainer>
    </div>
  );
};

const StyledContainer = styled.div`
  margin: auto;
  max-width: 1920px;
  @media ${breakpoint[1024]} {
    padding: 0 60px;
  }
`;

const StyledDecoratingBottomLine = styled.img`
  position: absolute;
  width: 600px;
  top: 16%;
  left: -26%;
  @media ${breakpoint.largeDesktop} {
    width: auto;
    top: 20%;
    left: -35%;
  }
`;

export default HomeContainer;
