import React, { useEffect, useRef, useState } from 'react'
import { breakpoint } from '../../utils/responsive';
import styled from 'styled-components';
import CommonText from '../../components/common/typographies/CommonText';


// fire first time/ every time
const TnC = ({ html, onTnCScroll, onScrollToBottom, checkOnlyFirstTimeRead = true }) => {

  const [isRead, setIsRead] = useState(false)
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const tncRef = useRef();


  const checkScrollToBottom = () => {
    const tncContainer = tncRef.current;
    let scrollHeight = tncContainer.scrollHeight;
    let scrollTop = tncContainer.scrollTop;
    let clientHeight = tncContainer.clientHeight;
    const isScrolled = (clientHeight + scrollTop) >= (scrollHeight - 20)
    if (isScrolled) {
      setScrollToBottom(true);
      setIsRead(true)
    } else {
      setScrollToBottom(false);
    }
  }

  const handleScroll = () => {
    if (onTnCScroll) {
      onTnCScroll()
    }
    checkScrollToBottom()
  }
  useEffect(() => {
    if (checkOnlyFirstTimeRead && isRead && onScrollToBottom) {
      console.log("scroll to bottom")
      onScrollToBottom()
    }
  }, [isRead])



  useEffect(() => {
    if (!checkOnlyFirstTimeRead && scrollToBottom && onScrollToBottom) {
      console.log("trigger every time")
      onScrollToBottom()
    }
  }, [scrollToBottom])




  return (
    <TNCOuterContainer>
      <TandCContainer ref={tncRef} /* onClick={checkScrollToBottom} */ onScroll={handleScroll} scrollToBottom={scrollToBottom}>
        <TNCText
          fontSize="14px"
          lineHeight="24px"
          textAlign="left"
          dangerouslySetInnerHTML={{ __html: html }} />
        {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
      </TandCContainer>
    </TNCOuterContainer >
  )
}

const TandCContainer = styled.div`
  width: 100%;
  height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  @media ${breakpoint.mobile} {
    padding: 10px;
  }
  @media ${breakpoint.desktop} {
    padding: 20px;
  }


  // gradient
  &:before{
    content: '';
    position: absolute;
    bottom: -1px; //offset border
    left: 0;
    background: ${props => props.scrollToBottom ? 'none' : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%)'};
    height: 30%;
    width: 100%;
    pointer-events: none;
  }
`;

const TNCOuterContainer = styled.div`
  width: 100%;
  height: 280px;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
`;

const TNCText = styled(CommonText)`
  // handle css reset
  ol, ul{
    padding-left: 1rem;
  }
  ol ol, ul ul{
    padding-left: 2rem;
  }
  ol ol ol, ul ul ul{
    padding-left: 3rem;
  }
  ol ol ol ol, ul ul ul ul{
    padding-left: 4rem;
  }
  ol ol ol ol ol, ul ul ul ul ul{
    padding-left: 5rem;
  }
`;

export default TnC