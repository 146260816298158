import React from "react";
import styled from "styled-components";
import ActivityFilter from "./ActivityFilter";
import TitleWithCounterBar from "../../components/common/bars/TitleWithCounterBar";
import CommonDivider from "../../components/common/divider/CommonDivider";
import ActivityCard from "./ActivityCard";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

export default function ActivitySection({
  sort,
  setSort,
  search,
  setSearch,
  data,
}) {
  const { t, i18n } = useTranslation("activity");

  return (
    <StyledDiv>
      <StyledFilterDiv>
        <ActivityFilter
          sort={sort}
          setSort={setSort}
          setSearch={setSearch}
        ></ActivityFilter>
      </StyledFilterDiv>

      {data.live.length >= 1 || data.ongoing.length >= 1 ? (
        <div>
          {data.live.length >= 1 && (
            <div style={{ width: "100%" }}>
              <TitleWithCounterBar
                title={t("live_activities")}
                type="activity"
                number={Object.keys(data.live).length}
                color="black"
              ></TitleWithCounterBar>
              <StyledDividerDiv>
                <CommonDivider style={{ borderColor: "black" }}></CommonDivider>
              </StyledDividerDiv>
              <StyledOnGoingDiv>
                {data.live.map((activity, index) => {
                  return (
                    <ActivityCard
                      key={index}
                      activity={activity}
                    ></ActivityCard>
                  );
                })}
              </StyledOnGoingDiv>
            </div>
          )}
          {data.ongoing.length >= 1 && (
            <div style={{ width: "100%" }}>
              <TitleWithCounterBar
                title={t("latest")}
                type="activity"
                number={Object.keys(data.ongoing).length}
                color="black"
              ></TitleWithCounterBar>
              <StyledDividerDiv>
                <CommonDivider style={{ borderColor: "black" }}></CommonDivider>
              </StyledDividerDiv>
              <StyledOnGoingDiv>
                {data.ongoing.map((activity, index) => {
                  return (
                    <ActivityCard
                      key={index}
                      activity={activity}
                    ></ActivityCard>
                  );
                })}
              </StyledOnGoingDiv>
            </div>
          )}
        </div>
      ) : (
        !search && (
          <StyledNoActivityDiv>
            <div>
              <CommonText
                mFontSize="16px"
                fontSize="20px"
                largeFontSize="30px"
                type="common"
              >
                敬請期待未來最新活動！
              </CommonText>
              <CommonText mFontSize="33px" fontSize="40px" largeFontSize="60px">
                Stay tuned for our upcoming activities!
              </CommonText>
            </div>
          </StyledNoActivityDiv>
        )
      )}

      {data.past.length >= 1 && (
        <div>
          <TitleWithCounterBar
            title={t("past")}
            type="activity"
            number={Object.keys(data.past).length}
            color="black"
          ></TitleWithCounterBar>
          <StyledDividerDiv>
            <CommonDivider style={{ borderColor: "black" }}></CommonDivider>
          </StyledDividerDiv>
          <StyledPastDiv>
            {data.past.map((activity, index) => {
              return (
                <ActivityCard key={index} activity={activity}></ActivityCard>
              );
            })}
          </StyledPastDiv>
        </div>
      )}

      {data.live.length == 0 &&
        data.ongoing.length == 0 &&
        data.past.length == 0 &&
        search && (
          <StyledNotFoundDiv>
            <CommonText fontSize="30px" type="common">
              {t("noResult")}
            </CommonText>
          </StyledNotFoundDiv>
        )}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledFilterDiv = styled.div`
  margin-bottom: 40px;
  @media ${breakpoint.mobile} {
    grid-template-columns: repeat(auto-fill, 428px);
  }
`;

const StyledOnGoingDiv = styled.div`
  margin-bottom: 100px;
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  grid-template-columns: 320px;
  @media ${breakpoint.desktop} {
    display: grid;
    grid-gap: 30px;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 428px);
  }
`;

const StyledPastDiv = styled.div`
  /* margin-bottom: 52px; */
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  grid-template-columns: 320px;
  @media ${breakpoint.desktop} {
    display: grid;
    grid-gap: 30px;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 428px);
  }
`;

const StyledDividerDiv = styled.div`
  width: 100%;
  margin-bottom: 34px;
`;

const StyledNotFoundDiv = styled.div`
  padding-bottom: 370px;
`;

const StyledNoActivityDiv = styled.div`
  margin-bottom: 80px;
`;
