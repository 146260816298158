import { useState, useEffect } from "react";
import styled from "styled-components";
import CommonText from "../typographies/CommonText";
import { useTranslation } from "react-i18next";
import colors from "../../../utils/colors";
import { breakpoint } from "../../../utils/responsive";

// Sample usage:
// <SelectTag type="color" data={data}/>

const SelectTag = ({
  type,
  title,
  selectList,
  onChange,
  isDefaultSelect,
  ...other
}) => {
  const { t, i18n } = useTranslation();
  const [isSelect, setIsSelect] = useState();

  useEffect(() => {
    //default select
    if (isDefaultSelect) {
      handleChange(selectList[0], 0);
    }
  }, []);

  const handleChange = (item, idx) => {
    setIsSelect(idx);
    onChange(item);
  };

  return (
    <div {...other}>
      <CommonText fontSize="14px" lineHeight="20px" textAlign="left">
        {title}
      </CommonText>
      <Container>
        {selectList &&
          selectList.map((item, idx) => {
            if (type === "color") {
              return (
                <StyledColorDiv
                  isSelect={isSelect === idx}
                  onClick={() => {
                    handleChange(item, idx);
                  }}
                >
                  <ColorItem bgColor={item.bgColor} />
                </StyledColorDiv>
              );
            } else {
              return (
                <StyledTextDiv
                  isSelect={isSelect === idx}
                  onClick={() => {
                    handleChange(item, idx);
                  }}
                >
                  <CommonText
                    color={colors.black}
                    fontSize="20px"
                    mFontSize="16px"
                  // fontWeight="600"
                  >
                    {item}
                  </CommonText>
                </StyledTextDiv>
              );
            }
          })}
      </Container>
    </div>
  );
};

const Container = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
`;

const StyledTextDiv = styled.div`
  box-shadow: ${(props) =>
    props.isSelect === true ? "0 0 0 3px #FF5C00" : "0 0 0 1px #00000020"};
  width: auto;
  height: auto;
  padding: 10px 30px;
  display: inline-block;
  cursor: pointer;

  @media ${breakpoint.mobile} {
    margin: 0px 8px 8px 0px;
  }
  @media ${breakpoint.desktop} {
    margin: 0px 16px 8px 0px;
  }
`;

const StyledColorDiv = styled.div`
  box-shadow: ${(props) =>
    props.isSelect === true ? "0 0 0 3px #FF5C00" : "0 0 0 1px #00000020"};
  width: auto;
  height: auto;
  padding: 3px;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
`;

const ColorItem = styled.div`
  background-color: ${(props) => props.bgColor};
  height: 45px;
  width: 95px;
`;
export default SelectTag;
