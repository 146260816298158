import styled from "styled-components";
import { ResponsiveUI } from "../../../utils/responsive";
import { Grid } from "@mui/material";
import CommonText from "../typographies/CommonText";
import { Link } from "react-router-dom";
import MenuItem from "../../../pageComponents/faq/menuItem";
import GreyBorderButton from "../../../pageComponents/contactUs/optionItem";
import Space from "../Space";
import { useTranslation } from "react-i18next";

const GreySelectMenu = ({
  pageId,
  title,
  subTitle,
  children,
  menuItems,
  currentId,
}) => {
  const { t, i18n } = useTranslation(pageId);
  const _renderDesktop = () => {
    return (
      <Grid
        container
        direction={"column"}
        style={{
          whiteSpace: "pre-line",
          padding: "20px 50px 120px 50px",
          margin: "auto",
          maxWidth: "1820px",
        }}
      >
        <Grid item xs>
          {i18n.language === "zh" && (
            <CommonText
              style={{ textAlign: "center", fontSize: 30 }}
              type="common"
            >
              {title}
            </CommonText>
          )}
        </Grid>
        <Grid item xs style={{ marginTop: 5 }}>
          <CommonText fontSize="60px" textAlign="center" type="primary">
            {subTitle}
          </CommonText>
        </Grid>
        <div style={{ whiteSpace: "pre-line" }}>
          <Grid item xs container direction="row" style={{ marginTop: 50 }}>
            <Grid item xs={3}>
              {menuItems.map((itemId) => {
                return (
                  <Link
                    to={`/${pageId}?id=${itemId}`}
                    style={{ textDecoration: "none" }}
                    key={itemId}
                  >
                    <MenuItem
                      label={t(`menu.${itemId}`)}
                      mb={1}
                      selected={currentId === itemId}
                    />
                  </Link>
                );
              })}
            </Grid>

            <Grid item xs style={{ marginLeft: 30 }}>
              {children}
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  };

  const _renderMobile = () => {
    return (
      <div>
        <CommonText fontSize="16px" textAlign="center" type="common">
          {title}
        </CommonText>
        <CommonText fontSize="33px" textAlign="center" type="primary">
          {subTitle}
        </CommonText>
        <MobileMenu>
          {menuItems.map((itemId) => {
            return (
              <div style={{ marginRight: 20 }}>
                <Link
                  to={`/${pageId}?id=${itemId}`}
                  style={{ textDecoration: "none" }}
                  key={itemId}
                >
                  <StyledGreyButton selected={currentId === itemId}>
                    {t(`menu.${itemId}`)}
                  </StyledGreyButton>
                </Link>
              </div>
            );
          })}
        </MobileMenu>
        <Space size="20px" />
        <div style={{ margin: "0 20px" }}>{children}</div>
      </div>
    );
  };

  return <ResponsiveUI desktop={_renderDesktop()} mobile={_renderMobile()} />;
};

const MobileMenu = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: scroll;
  margin: 30px 20px;
`;

const StyledGreyButton = styled(GreyBorderButton)`
  && {
    min-height: 89px;
    padding: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default GreySelectMenu;
