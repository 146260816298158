import { Snackbar } from "@mui/material"
import styled from "styled-components"
import colors from "../../../utils/colors"

export default function CommonSnackbar({ children, open, onClose }) {
  return (
    <Snackbar 
    style={{ bottom: "0", left: '0', right: '0', transform: 'unset' }}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
     open={open} onClose={onClose}>
      <Container>
        {children}
      </Container>
    </Snackbar>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  border-width: 1px;
  border-color: ${colors.black20Alpha};
  border-style: solid;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  background: ${colors.white};
  box-shadow: ${colors.black20Alpha} 0px 8px 24px;
`


