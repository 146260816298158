import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Circle } from '../../../assets/MenuSelectAnimation.svg';
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import { useTranslation } from "react-i18next";

function MenuSelect() {
    const { i18n } = useTranslation();
    return (
        <StyledAnimationImgDiv lang={i18n.language}>
            <Circle style={{ objectFit: 'cover', marginLeft: '-7vw'}}></Circle>
        </StyledAnimationImgDiv>
    );
}

const StyledAnimationImgDiv = styled.div`
    position: absolute;
    margin: auto;
    z-index: 1;
    svg {
        stroke-dasharray: 1500;
        stroke-dashoffset: 1500;
        pointer-events: none;
        animation: theAnimation 0.6s ease-in-out forwards 1;
    }

    @media ${breakpoint.mobile} {
        width: calc(100% + 7vw);
        height: 70px;
        margin-top: ${props => props.lang === "en" ? '-15px' : '0px'};
    }

    @media ${breakpoint.desktop} {
        width: calc(100% + 13vw);
        height: 120px;
        margin-top: ${props => props.lang === "en" ? '-25px' : '0px'};
    }


    @keyframes theAnimation {
        to {
            stroke-dashoffset: 0;
        }
    }
`;

export default MenuSelect;
