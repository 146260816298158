import React from 'react';
import styled from 'styled-components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SelectArrow from '../../../assets/SelectArrow.svg';

// req: options
// props(string): defaultValue
// props(boolean): disabled, required, readOnly
// props(others): onChange
// size full width depends on parents div
// Sample Usage:
// let options = ['A','B','C'];
// <CommonSelect options={options}></CommonSelect>

function CommonSelect(props) {
  const iconStyle = {
    position: 'absolute',
    right: 13,
    pointerEvents: 'none',
    height: 9.5,
    width: 16.5,
    opacity: props.disabled ? '50%' : '100%',
  };

  const SelectIcon = () => {
    return (
      <svg
        width="17"
        style={iconStyle}
        height="10"
        viewBox="0 0 17 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 9.125L16.125 2.125C16.625 1.625 16.625 0.875 16.125 0.375C15.625 -0.125 14.875 -0.125 14.375 0.375L8.25 6.5L2.125 0.375C1.875 0.125 1.625 -6.50207e-07 1.25 -6.66599e-07C0.499998 -6.99382e-07 -1.9292e-06 0.499999 -1.96199e-06 1.25C-1.97838e-06 1.625 0.124998 1.875 0.374998 2.125L7.5 9.25C7.75 9.625 8.5 9.625 9 9.125Z"
          fill="#111111"
        />
      </svg>
    );
  };

  return (
    <StyledSelect
      {...props}
      fullWidth
      variant="outlined"
      IconComponent={SelectIcon}
      placeholder="test"
      sx={{
        borderRadius: 0,
        '&:hover': {
          '&& fieldset': {
            border: props.disabled ? '1px solid #C5C5C5' : '1px solid #FF5C00',
          },
        },
        '&.Mui-focused': {
          '&& fieldset': {
            border: props.disabled ? '1px solid #C5C5C5' : '1px solid #FF5C00',
          },
        },
      }}
    >
      {props.children ||
        props.options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  height: 54px;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  text-align: left;
  background: white;
`;

export default CommonSelect;
