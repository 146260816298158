import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export default function WebTnc(contentZh, contentEn) {
  const { i18n } = useTranslation('');

  return (
    <div>
      {i18n.language === 'en' ? (
        // ----------------------ENGLISH VERSION----------------------
        <div style={{ textAlign: 'justify' }}>
          <StyledTitle>Website Terms and Conditions</StyledTitle>
          <StyledContent dangerouslySetInnerHTML={{ __html: contentEn }} />
        </div>
      ) : (
        // ----------------------CHINESE VERSION----------------------
        <div style={{ textAlign: 'justify' }}>
          <StyledTitle>網站條款及條件</StyledTitle>
          <StyledContent dangerouslySetInnerHTML={{ __html: contentZh }} />
        </div>
      )}
    </div>
  );
}

const StyledContent = styled.div`
  ol {
    padding-left: 50px;
  }
  li {
    padding-left: 20px;
    margin-bottom: 10px;
  }
  ul {
    padding-left: 50px;
  }
  h1,
  h1 > span {
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 20px;
  }
  div {
    display: block;
    margin-bottom: 20px;
  }
  p {
    display: block;
    margin-bottom: 20px;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  text-decoration: underline;
`;