import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CommonText from "../../components/common/typographies/CommonText";
import colors from "../../utils/colors";
import { breakpoint } from "../../utils/responsive";

const ViewNext = ({ data }) => {
  const { i18n } = useTranslation("memberProfile");

  return (
    <StyledMainDiv>
      <Link
        to={`/member-profile/${data && data.nextMember?.slug}`}
        style={{ textDecoration: "none" }}
      >
        <StyledDiv>
          <StyledImage
            src={data && data.nextMember && data.nextMember.coverImage}
          />
          <StyledTextDiv>
            {i18n.language === "zh" && (
              <CommonText color={colors.white} fontSize="30px" mFontSize="16px">
                觀看下一位成員
              </CommonText>
            )}
            <OutlinedText>View Next</OutlinedText>
          </StyledTextDiv>
        </StyledDiv>
      </Link>
    </StyledMainDiv>
  );
};
const StyledImage = styled.img`
  filter: grayscale(100%);
  mix-blend-mode: overlay;
  object-fit: cover;
  user-drag: none;

  @media ${breakpoint.mobile} {
    width: 50%;
  }

  @media ${breakpoint.desktop} {
    height: 400px;
    width: 711px;
  }
`;

const OutlinedText = styled.div`
  font-size: 45px;
  font-weight: 400;
  color: transparent;
  white-space: nowrap;
  font-family: Open Sans;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: ${colors.white};
  text-transform: uppercase;

  @media ${breakpoint.desktop} {
    font-size: 150px;
    font-weight: 600;
  }
`;

const StyledDiv = styled.div`
  position: relative;
  cursor: pointer;
  @media ${breakpoint.desktop} {
    &:hover {
      ${StyledImage} {
        filter: none;
        mix-blend-mode: none;
      }
      ${OutlinedText} {
        -webkit-text-stroke-width: none;
        -webkit-text-stroke-color: none;
        color: ${colors.white};
      }
    }
  }
`;

const StyledMainDiv = styled.div`
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${breakpoint.desktop} {
    height: 70vh;
  }
`;

const StyledTextDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover {
    ${StyledImage} {
      filter: none;
      mix-blend-mode: none;
    }
  }
`;

export default ViewNext;
