import React, { useState } from "react";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import IconEdit from "../../../assets/ic-edit.svg";
import CommonLabel from "../../../components/common/labels/CommonLabel";
import { useTranslation } from "react-i18next";
import CommonText from "../../../components/common/typographies/CommonText";
import Space from "../../../components/common/Space";
import { formatAddress } from "../../../utils/addressFormat";

export default function AddressEditBox(props) {
  const { t, i18n } = useTranslation("common");
  const { label, address, onClickEdit } = props;
  return (
    <StyledDiv>
      {/* <CommonLabel style={{ textAlign: "start", width: "100%" }}>
        {label ? label : ""}
      </CommonLabel> */}
      <StyledAddressDiv>
        <div style={{ textAlign: "left" }}>
          <CommonText color="#515151" fontSize="14px">
            {t("address.type")}
          </CommonText>
          <CommonText>{t("address." + address.type)}</CommonText>
          <Space size="20px" />
          <CommonText color="#515151" fontSize="14px">
            {t("address.Express_address")}
          </CommonText>
          <CommonText>{formatAddress(address, i18n.language)}</CommonText>
          <Space size="20px" />
          <CommonText color="#515151" fontSize="14px">
            {t("address.name")}
          </CommonText>
          <CommonText>{address.name}</CommonText>
          <Space size="20px" />
          <CommonText color="#515151" fontSize="14px">
            {t("address.phone")}
          </CommonText>
          <CommonText>{address.phone}</CommonText>
        </div>
        <IconButton onClick={onClickEdit}>
          <img src={IconEdit} />
        </IconButton>
      </StyledAddressDiv>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const StyledAddressDiv = styled.div`
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: white;
`;
