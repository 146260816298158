import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CommonLabel from '../labels/CommonLabel';
import CommonTextField from './CommonTextField';
import FormHelperText from '@mui/material/FormHelperText';
import CommonSelect from '../selects/CommonSelect';
import TextButton from '../buttons/TextButton';
import { requestPhoneOTP } from '../../../apis';
import { useTranslation } from 'react-i18next';

function PhoneTextfield(props) {
  let { helperText, errorText, label, required, value, onChange, requestOTP } =
    props;
  const { t } = useTranslation('common');
  const [phoneCode, setPhoneCode] = useState(
    (props.value && props.value.substring(0, 4)) || '+852'
  );
  const [phoneNumber, setPhoneNumber] = useState(
    props.value && props.value.substring(4, props.length)
  );
  useEffect(() => {
    if (value) {
      setPhoneCode(value.substring(0, 4));
      setPhoneNumber(value.substring(4, props.length));
    } else {
      setPhoneCode('+852');
      setPhoneNumber(null);
    }
  }, [value]);
  const handleRequestOTP = async () => {
    const phone = phoneCode + phoneNumber;
    if (phone && phone.length > 0) {
      const result = await requestPhoneOTP(phoneCode + phoneNumber);
    }
  };
  const handleChange = (event) => {
    if (event.target.name == 'phoneNumber') {
      let number = event.target.value.replace(/[^0-9]+/g, '');
      setPhoneNumber(number);
      if (onChange) onChange(event.target.value ? phoneCode + number : '');
    } else if (event.target.name == 'phoneCode') {
      setPhoneCode(event.target.value);
      if (onChange)
        onChange(phoneNumber ? event.target.value + phoneNumber : '');
    }
  };
  return (
    <StyledDiv>
      <StyledLabelDiv>
        <CommonLabel>{label ? label : ''}</CommonLabel>
        {required && <span style={{ color: '#eb0000', marginLeft: 4 }}>*</span>}
      </StyledLabelDiv>
      <Row>
        <CommonSelect
          name="phoneCode"
          onChange={handleChange}
          value={phoneCode}
          style={{ width: 103 }}
          options={['+852']}
          disabled
        />
        <CommonTextField
          {...props}
          style={{ flex: 1, marginLeft: 10 }}
          label=""
          name="phoneNumber"
          value={phoneNumber}
          InputProps={{
            endAdornment: requestOTP ? (
              <TextButton
                style={{ width: 300, textAlign: 'right' }}
                onClick={handleRequestOTP}
              >
                {t('request_otp')}
              </TextButton>
            ) : null,
          }}
          onChange={handleChange}
        />
      </Row>
      <StyledErrorText>{errorText ? errorText : ''}</StyledErrorText>
      <StyledHelperText>{helperText ? helperText : ''}</StyledHelperText>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
`;

const StyledLabelDiv = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 8px;
`;

const StyledErrorText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 0;
    color: #ff5c00;
  }
`;

const StyledHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 0;
  }
`;

export default PhoneTextfield;
