import React from 'react';
import styled from 'styled-components';
import SongInfoBox from "./SongInfoBox";
import LyricBox from "./LyricBox";
import CommonText from '../../../components/common/typographies/CommonText';
import VerticalDivider from '../../../components/common/divider/VerticalDivider';
import AlbumSong from './AlbumSong';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import TitleGroup from '../../../components/common/typographies/TitleGroup';

export default function InformationSection({data}) {
    const { t, i18n } = useTranslation("discography");

    return (
        <StyledDiv>
            <StyledTitleDiv>
                <TitleGroup zhTitle="作品資訊" enTitle="INFORMATION" color="white"/>
            </StyledTitleDiv>
            <ResponsiveUI 
                mobile={
                    <StyledInfoMobileDiv>
                        
                        <StyledDateMobileDiv>
                        {
                            i18n.language === "zh" && 
                            <StyledFlexDiv>
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{t('launchDate')}</CommonText>
                                <VerticalDivider color="#666666" size="20px"/> 
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{moment(data.launchDate).locale('zh-hk').tz('Asia/Hong_Kong').format('LL')} </CommonText>
                            </StyledFlexDiv>
                        }
                        {
                            i18n.language === "en" && 
                            <StyledFlexDiv>
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{t('launchDate')}</CommonText>
                                <VerticalDivider color="#666666" size="20px"/> 
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{moment(data.launchDate).locale('en').tz('Asia/Hong_Kong').format('LL')} </CommonText>
                            </StyledFlexDiv>
                        }
                        </StyledDateMobileDiv>

                        {
                            data.type==="SINGLE" &&
                            (i18n.language === "zh" ?
                            <SongInfoBox
                                composer={data.composerZh}
                                lyricist={data.lyricistZh}
                                arranger={data.arrangerZh}
                                producer={data.producerZh}
                            />
                            :
                            <SongInfoBox
                                composer={data.composerEn}
                                lyricist={data.lyricistEn}
                                arranger={data.arrangerEn}
                                producer={data.producerEn}
                            />)
                        }

                        {
                            data.type==="SINGLE" &&
                            <LyricBox lyric={data.lyrics}></LyricBox>
                        }
                        {
                            data.type==="ALBUM" &&
                            <AlbumSong songs={data.songs}></AlbumSong>
                        }

                    </StyledInfoMobileDiv>
                }
                desktop={
                    <StyledInfoDiv>
                        <StyledInfoLeftDiv>
                                <div>
                                    {
                                        i18n.language === "zh" && 
                                        <StyledFlexDiv>
                                            <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{t('launchDate')}</CommonText>
                                            <VerticalDivider color="#666666" size="20px"/> 
                                            <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{moment(data.launchDate).locale('zh-hk').tz('Asia/Hong_Kong').format('LL')} </CommonText>
                                        </StyledFlexDiv>
                                    }
                                    {
                                        i18n.language === "en" && 
                                        <StyledFlexDiv>
                                            <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{t('launchDate')}</CommonText>
                                            <VerticalDivider color="#666666" size="20px"/> 
                                            <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{moment(data.launchDate).locale('en').tz('Asia/Hong_Kong').format('LL')} </CommonText>
                                        </StyledFlexDiv>
                                    }

                                    {
                                        data.type==="SINGLE" &&
                                        (i18n.language === "zh" ?
                                        <SongInfoBox
                                            composer={data.composerZh}
                                            lyricist={data.lyricistZh}
                                            arranger={data.arrangerZh}
                                            producer={data.producerZh}
                                        />
                                        :
                                        <SongInfoBox
                                            composer={data.composerEn}
                                            lyricist={data.lyricistEn}
                                            arranger={data.arrangerEn}
                                            producer={data.producerEn}
                                        />)
                                    }
                                </div>
                            </StyledInfoLeftDiv>

                            <StyledInfoRightDiv>
                                {
                                    data.type==="SINGLE" &&
                                    <LyricBox lyric={data.lyrics}></LyricBox>
                                }
                                {
                                    data.type==="ALBUM" &&
                                    <AlbumSong songs={data.songs}></AlbumSong>
                                }
                            </StyledInfoRightDiv>
                        </StyledInfoDiv>
                }
            />

        </StyledDiv>
    )
}

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const StyledTitleDiv = styled.div`
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledInfoDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 108px;
`;

const StyledInfoMobileDiv = styled.div`
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

const StyledInfoLeftDiv = styled.div`
    width: 50%;
    display: flex;
    flex-direction:column;
    align-items: center;
`;

const StyledInfoRightDiv = styled.div`
    width: 50%;
`;

const StyledFlexDiv = styled.div`;
    display: flex;
    align-items: center;
    @media ${breakpoint.desktop} {
        margin-left: 50px;
        margin-bottom: 80px;
    }
`;

const StyledDateMobileDiv = styled.div`;
    width: 100%;
    margin-bottom: 25px;
`;
