import styled from 'styled-components';
import { useState, useContext, useEffect } from 'react';
import CloseButton from '../components/common/buttons/CloseButton';
import LoginImage2 from '../assets/login-image2.jpg';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { breakpoint } from '../utils/responsive';
import { useTranslation } from 'react-i18next';
import DisableMiroRegister from '../pageComponents/miroregister/DisableMiroRegister';
import MiroOnlyView from '../pageComponents/miroregister/MiroOnlyView';

export default function WebRegister() {
  const { t } = useTranslation('signin');
  const { session, setSession, disableMiroReg } = useContext(AppContext);
  const navigate = useNavigate();
  const [page, setPage] = useState(null);

  useEffect(() => {
    if (disableMiroReg) {
      setPage('disableMiroRegister');
    }
  }, [disableMiroReg]);

  const handleCloseDialog = () => {
    navigate('/');
  };

  const getLeftImage = () => {
    return LoginImage2;
  };

  const renderPage = () => {
    switch (page) {
      case 'disableMiroRegister':
        if (session?.user?.memberType === 'MIRO')
          return (
            <MiroOnlyView
              isMiro={session?.user?.memberType === 'MIRO'}
              handlePageChange={() => navigate('/miro-register')}
              onClick={handleCloseDialog}
            />
          );
        else return <DisableMiroRegister onClick={handleCloseDialog} />;

      default:
        return (
          <MiroOnlyView
            isMiro={session?.user?.memberType === 'MIRO'}
            handlePageChange={() => navigate('/miro-register')}
            onClick={handleCloseDialog}
          />
        );
    }
  };
  return (
    <Container>
      <LeftImageView src={getLeftImage()} alt="" />
      <RightView>
        <RightViewContainer>{renderPage()}</RightViewContainer>
      </RightView>
      <CloseButton onClick={handleCloseDialog} />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
`;
const LeftImageView = styled.img`
  width: 50%;
  object-fit: cover;
  max-width: 950px;
  height: 100%;
  display: block;
  @media ${breakpoint.mobile} {
    display: none;
  }
`;
const RightView = styled.div`
  flex: 1;
  margin: auto;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
const RightViewContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
