import React from 'react';
import { ReactComponent as IOSLikeArrow } from '../../../assets/ic-arrowDown.svg';
import { IconButton } from '@mui/material';

import { styled } from '@mui/material/styles';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#7B7B7B4D',
  width: 75,
  height: 75,
  '&:hover': {
    border: '4px solid #7B7B7B80',
  },
  '&:focus': {
    background: '#7B7B7B99',
  },
}));

const SliderArrowButton = ({ direction, classes, ...props }) => {
  return (
    <StyledIconButton
      aria-label={direction === 'left' ? 'previous item' : 'next item'}
      {...props}
      disableRipple
    >
      {direction === 'left' ? (
        <IOSLikeArrow
          style={{ transform: 'rotate(90deg)' }}
          width={28}
          height={28}
        />
      ) : (
        <IOSLikeArrow
          style={{ transform: 'rotate(-90deg)' }}
          width={28}
          height={28}
        />
      )}
    </StyledIconButton>
  );
};

export default SliderArrowButton;
