import { Chip, styled } from '@mui/material';
const StyledNewChip = styled(Chip)({
  background: '#f6b100',
  height: 'auto',
  padding: 0,
  borderRadius: 4,
  fontSize: 12,
  fontFamily: 'Open Sans',
  fontWeight: 600,
  '& .MuiChip-label': {
    padding: '4.5px 5px',
  },
});

export default StyledNewChip;
