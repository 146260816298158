import styled from 'styled-components';
import ImgVisitorBg from '../../assets/home_visitorBg.jpg';
import ImgVisitorMobileBg from '../../assets/home_visitorMobileBg.jpg';
import { breakpoint } from '../../utils/responsive';
import { mediaQueryForSpanFullHeight } from './constant';

const VisitorHero = styled.div`
  border-radius: 20px;
  background-image: url(${ImgVisitorMobileBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  image-rendering: --webkit-optimize-contrast;
  @media (min-aspect-ratio: 3/4) {
    // some arbitrary value such that people will not be cropped, determined per image
    background-image: url(${ImgVisitorBg});
  }

  @media ${breakpoint[1024]} {
    background-image: url(${ImgVisitorBg});
    margin-bottom: 0;
    padding-bottom: 100%;
  }
  @media ${mediaQueryForSpanFullHeight} {
    padding-bottom: 0;
    flex: 1;
    height: auto;
    overflow: hidden;
    position: relative;
    margin-bottom: 15px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 20px;

  object-fit: cover;
  aspect-ratio: 1/1; // the desktop photo will not be cropped
  @media ${mediaQueryForSpanFullHeight} {
    aspect-ratio: auto;
    max-height: none;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default VisitorHero;
