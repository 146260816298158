import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { AppContext } from "../../../AppContext"
import CommonButton from "../../../components/common/buttons/CommonButton"
import CommonCloseDialog from "../../../components/common/dialogs/CommonCloseDialog"
import { logout } from "../../../utils/logout"
import { breakpoint, ResponsiveUI } from "../../../utils/responsive"
import ResetPassword from "../ResetPassword"

function PwManageForm(props) {
  const { setSession } = useContext(AppContext)
  const { t } = useTranslation("profile")
  let navigate = useNavigate()
  const [dialogOpen, setDialogOpen] = useState(false)

  const _renderContent = (isMobile) => {
    return (
      <StyledMainDiv>
        <CommonButton
          width={isMobile ? "100%" : "440px"}
          normalCase={true}
          onClick={() => setDialogOpen(true)}
          type="outlined"
        >
          {t("profile:button.reset_pw")}
        </CommonButton>

        <CommonCloseDialog
          fullScreen={isMobile}
          onClose={() => setDialogOpen(false)}
          visible={dialogOpen}
        >
          <ResetPassword
            isMobile={isMobile}
            handleResetSuccess={() => {
              logout({ navigate, navigateTo: "/signin", setSession })
            }}
          />
        </CommonCloseDialog>
      </StyledMainDiv>
    )
  }

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  )
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default PwManageForm
