import { register } from '../../apis';
import styled from 'styled-components';
import CommonButton from '../../components/common/buttons/CommonButton';
import FormTextField from '../../components/common/textfields/FormTextField';
import EmailTextField from '../../components/common/textfields/EmailTextField';
import PhoneTextField from '../../components/common/textfields/PhoneTextField';
import PasswordTextField from '../../components/common/textfields/PasswordTextField';
import { useState, useRef, useContext } from 'react';
import LanguageToggleButton from '../../components/common/buttons/LanguageToggleButton';
import CheckBox from '../../components/common/checkboxs/Checkbox';
import CommonText from '../../components/common/typographies/CommonText';
import { Trans, useTranslation } from 'react-i18next';
import Space from '../../components/common/Space';
import { AppContext } from '../../AppContext';
import constants from '../../constants';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Grid } from '@mui/material';
import ReactGA from 'react-ga4';

export default function EmailRegister({ handlePageChange }) {
  const { t } = useTranslation(['common', 'profile', 'signin']);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(null);
  const [language, setLanguage] = useState('chinese');
  const [promotion, setPromotion] = useState(false);
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState(null);
  const [recaptcha, setRecaptcha] = useState(null);
  const { setSession } = useContext(AppContext);
  const recaptchaRef = useRef(null);
  const usernameRef = useRef();
  const emailRef = useRef();
  const emailOTPRef = useRef();
  const passwordRef = useRef();
  const retypePasswordRef = useRef();
  const onReCaptchaChange = (value) => {
    setRecaptcha(value);
  };
  const validate = () => {
    let error = {};
    if (
      usernameRef.current.value.length < 2 ||
      usernameRef.current.value.length > 50
    )
      error.username = t('signin:register.invalid_username');
    const validEmail = new RegExp(constants.EMAIL_REGEX);
    if (!validEmail.test(emailRef.current.value))
      error.email = t('signin:register.invalid_email');
    if (emailOTPRef.current.value.length != 6)
      error.emailOTP = t('signin:register.invalid_email_otp');
    if (phone && phone != '') {
      const validPhone = new RegExp(constants.PHONE_REGEX);
      if (!validPhone.test(phone))
        error.phone = t('signin:register.invalid_phone');
    }
    const validPassword = new RegExp(constants.PASSWORD_REGEX);
    if (!validPassword.test(passwordRef.current.value))
      error.password = t('signin:register.invalid_password');
    else if (passwordRef.current.value != retypePasswordRef.current.value)
      error.retypePassword = t('signin:register.invalid_retype_password');
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };

  const handleRegister = async () => {
    if (!loading && validate()) {
      setLoading(true);
      const body = {
        username: usernameRef.current.value,
        email: emailRef.current.value,
        emailOTP: emailOTPRef.current.value,
        phone: phone,
        password: passwordRef.current.value,
        retypePassword: retypePasswordRef.current.value,
        language,
        promotion,
        recaptcha,
      };
      console.log(body);
      const result = await register(body);
      console.log(result);
      if (result.success) {
        localStorage.setItem('session', JSON.stringify(result.data));
        setSession(result.data);
        ReactGA.event('sign_up', {
          method: 'email',
        });
      } else {
        // set api error
        if (result.error?.code == 'C0001') {
          setError({ emailOTP: t('signin:register.used_username') });
        } else if (result.error?.code == 'C0002') {
          setError({ emailOTP: t('signin:register.used_email') });
        } else if (result.error?.code == 'C0003') {
          setError({ emailOTP: t('signin:register.invalid_email_otp') });
        }
      }
      recaptchaRef.current.reset();
      setLoading(false);
    }
  };
  return (
    <Container>
      <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
        {t('signin:register.title')}
      </CommonText>
      <Space size="82px" />
      <FormTextField
        inputRef={usernameRef}
        errorText={error?.username}
        label={t('signin:register.username')}
      />
      <Space size="20px" />
      <EmailTextField
        purpose="web"
        inputRef={emailRef}
        errorText={error?.email}
        label={t('signin:register.email')}
        requestKey="web_emailOTP"
        requestOTP
      />
      <Space size="20px" />
      <FormTextField
        inputRef={emailOTPRef}
        errorText={error?.emailOTP}
        label={t('signin:register.email_otp')}
      />
      <Space size="20px" />
      <PhoneTextField
        onChange={(value) => setPhone(value)}
        errorText={error?.phone}
        label={t('signin:register.phone')}
      />
      <Space size="20px" />
      <PasswordTextField
        inputRef={passwordRef}
        errorText={error?.password}
        helperText={t('common:password_hint')}
        label={t('signin:register.password')}
      />
      <Space size="20px" />
      <PasswordTextField
        inputRef={retypePasswordRef}
        errorText={error?.retypePassword}
        label={t('signin:register.retype_password')}
      />
      <Space size="25px" />
      <Grid container justifyContent="flex-start">
        <CheckBox
          onClick={() => setPromotion(!promotion)}
          isChecked={promotion}
        >
          <Trans
            t={t}
            i18nKey="signin:register.promote"
            components={[
              <Link
                style={{
                  color: '#FF5C00',
                  whiteSpace: 'nowrap',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
                key={0}
                target="_blank"
                to="/privacy-terms"
              />,
            ]}
          />
        </CheckBox>
      </Grid>
      <Space size="22px" />
      <Grid container justifyContent="flex-start">
        <CheckBox onClick={() => setAgree(!agree)} isChecked={agree}>
          <Trans
            t={t}
            i18nKey="signin:register.web_agreement"
            components={[
              <Link
                style={{
                  color: '#FF5C00',
                  whiteSpace: 'nowrap',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
                key={0}
                target="_blank"
                to="/tnc?id=web"
              />,
              <Link
                style={{
                  color: '#FF5C00',
                  whiteSpace: 'nowrap',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
                key={2}
                target="_blank"
                to="/privacy-terms"
              />,
            ]}
          />
        </CheckBox>
      </Grid>
      <Space size="20px" />
      <CommonText style={{ width: '100%', textAlign: 'left' }} mFontSize="14px">
        {t('profile:info.receive_promote_language')}
      </CommonText>
      <Space size="9px" />
      <LanguageToggleButton
        value={language}
        onChange={(event) => setLanguage(event.target.value)}
      />
      <Space size="18px" />
      <ReCAPTCHA
        sitekey={constants.RECAPTCHA}
        onChange={onReCaptchaChange}
        ref={recaptchaRef}
      />
      <Space size="18px" />
      <CommonButton
        disabled={!agree}
        size={'large'}
        type={'primary'}
        onClick={handleRegister}
      >
        {t('signin:register.register')}
      </CommonButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
`;
