import WhiteTick from "../../../assets/white_tick.svg"
import OrangeCircle from "../../../assets/circleWithTick.svg"
import styled from "styled-components"
import CircleOutlinedIcon from "../../../assets/home_circleOutlinedIcon.svg"
import CircleGrayOutlinedIcon from "../../../assets/home_circleOutlinedGrayIcon.svg"

export default function SmallTickIcon(props) {
  const {
    isChecked,
    onClick,
    type
  } = props;
  let uncheckedImage = null
  switch (type) {
    case "gray": {
      uncheckedImage = CircleGrayOutlinedIcon
      break;
    }
    default: {
      uncheckedImage = CircleOutlinedIcon
    }
  }
  return (
    <Container onClick={onClick}>
      <CircleDiv image={isChecked ? OrangeCircle : uncheckedImage} {...props}>
        {/* {isChecked && <Tick src={WhiteTick} {...props} />} */}
      </CircleDiv>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: ${(props) => props.size || "25px"};
  height: ${(props) => props.size || "25px"};
`
const CircleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${props => props.image});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  width: ${(props) => props.size || "25px"};
  height: ${(props) => props.size || "25px"};
`
const Tick = styled.img`
  width: ${(props) => props.size || "17px"};
  height: ${(props) => props.size || "17px"};
`
