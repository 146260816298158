const Lock = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.55564 9.37467C4.1211 9.37467 3.70841 9.75594 3.70841 10.3027V16.03C3.70841 16.5767 4.1211 16.958 4.55564 16.958H15.4445C15.8791 16.958 16.2917 16.5767 16.2917 16.03V10.3027C16.2917 9.75594 15.8791 9.37467 15.4445 9.37467H4.55564ZM2.29175 10.3027C2.29175 9.042 3.27195 7.95801 4.55564 7.95801H15.4445C16.7282 7.95801 17.7084 9.042 17.7084 10.3027V16.03C17.7084 17.2907 16.7282 18.3747 15.4445 18.3747H4.55564C3.27195 18.3747 2.29175 17.2907 2.29175 16.03V10.3027Z"
        fill={color ?? 'black'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0001 2.37467C9.12074 2.37467 8.28129 2.71448 7.66542 3.31324C7.05022 3.91135 6.70841 4.71824 6.70841 5.55523V8.66634C6.70841 9.05754 6.39128 9.37467 6.00008 9.37467C5.60888 9.37467 5.29175 9.05754 5.29175 8.66634V5.55523C5.29175 4.32943 5.79279 3.15801 6.67789 2.2975C7.56231 1.43765 8.75769 0.958008 10.0001 0.958008C11.2425 0.958008 12.4379 1.43765 13.3223 2.2975C14.2074 3.15801 14.7084 4.32943 14.7084 5.55523V8.66634C14.7084 9.05754 14.3913 9.37467 14.0001 9.37467C13.6089 9.37467 13.2917 9.05754 13.2917 8.66634V5.55523C13.2917 4.71824 12.9499 3.91135 12.3347 3.31324C11.7189 2.71448 10.8794 2.37467 10.0001 2.37467Z"
        fill={color ?? 'black'}
      />
    </svg>
  );
};

export default Lock;
