import React from 'react';
import styled from 'styled-components';

// options:
// type: 'fill'(default), 'contain', 'cover', 'none', 'scale-down'
// height: '433px'(default) Discography size in Figma
// width: '433px'(default)
// alt: for description

// Sample Usage: <CommonImage type="cover" height="200px" width="200px" src={"https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"} alt={"bird"}></CommonImage>

function CircleImage(props) {

    return (
        <StyledDiv
            height={props.height}
            width={props.width}
        >
            <StyledImage
                {...props}
            >
            </StyledImage>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    width: ${props => props.width ? props.width : '95px'};
`;

const StyledImage = styled.img`
    user-drag: none;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: ${props => props.selected ? '3px solid #FF5C00' : 0};
    filter: ${props => props.selected ? 'none' : 'grayscale(100%)'};
`;

export default CircleImage;
