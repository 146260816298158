import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';

const useJoinBannerControl = (props) => {
  const { session, disableMiroReg, memberType } = useContext(AppContext);
  const [openBanner, setOpenBanner] = useState(null); // this should be singleton by context
  const getOpenBannerType = () => {
    let openBannerType = null;
    if (session?.user?.renewPrice < 0) {
      // renewed
      if (memberType != session?.user?.memberType) {
        // waiting for 2024
        let lastShowWaitingBanner = localStorage.getItem(
          'waitingMiroBanner',
          null
        );
        console.log(
          'kazaf ',
          moment().diff(moment(lastShowWaitingBanner), 'hours')
        );
        if (
          !lastShowWaitingBanner ||
          moment().diff(moment(lastShowWaitingBanner), 'hours') >= 24
        ) {
          // setOpenBanner('waitingMiro');
          openBannerType = 'waitingMiro';
        }
      } else {
        // existing MIRO
      }
    } else if (!disableMiroReg) {
      // need renew
      if (session?.user?.memberType === 'MIRO') {
        // renew
        let lastShowRenewBanner = localStorage.getItem('renewMiroBanner', null);
        if (
          !lastShowRenewBanner ||
          moment().diff(moment(lastShowRenewBanner), 'hours') > 24
        ) {
          openBannerType = 'renewMiro';
        }
      } else {
        // new join
        let lastShowNewJoinBanner = localStorage.getItem(
          'joinMiroBanner',
          null
        );

        if (
          !lastShowNewJoinBanner ||
          moment().diff(moment(lastShowNewJoinBanner), 'hours') > 24
        ) {
          openBannerType = 'joinMiro';
        }
      }
    }

    return openBannerType;
  };

  useEffect(() => {
    props?.onOpenBanner && props.onOpenBanner(openBanner);
  }, [openBanner]);

  useEffect(() => {
    setOpenBanner(getOpenBannerType());
  }, [session]);

  const handleCloseBanner = () => {
    localStorage.setItem(openBanner + 'Banner', moment().format());
    setOpenBanner(null);
  };

  return { openBanner, setOpenBanner, handleCloseBanner };
};

export default useJoinBannerControl;
