import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import SearchImg from "../../../assets/Search.svg";
import SearchBlackImg from "../../../assets/Search_black.svg";
import ArrowImg from "../../../assets/RightArrow.svg";
import ArrowBlackImg from "../../../assets/Arrow_black.svg";
import SearchTextfield from '../textfields/SearchTextfield';
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";

function SearchButton({ open, setOpen, setSearch, setSelect, ...props}) {
    let [input, setInput] = useState('');

    const onClickHandler=()=>{
        setOpen(!open);
    }

    const onSearchHandler=(search)=>{
        setSearch(search);
        if(setSelect){setSelect({ nameZh:'全部', nameEn:'all'});}
    }

    return (
        <StyledMainDiv open={open} color={props.color}>
            <StyledSearchImg src={props.color=="white"? SearchImg: SearchBlackImg} onClick={onClickHandler}></StyledSearchImg>
            {
                open && 
                <StyledInnerDiv>
                    <StyledDiv>
                        <SearchTextfield 
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={(e) => { 
                                if(e.key === 'Enter'){
                                    onSearchHandler(e.target.value);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }} 
                            theme={props.color} 
                        >
                        </SearchTextfield>
                    </StyledDiv>
                    <StyledArrowImg style={{ transform: props.color=="white"? "none":"rotate(180deg)"}} src={props.color=="white"? ArrowImg: ArrowBlackImg} onClick={(e) => onSearchHandler(input)}></StyledArrowImg>
                </StyledInnerDiv>
            }
        </StyledMainDiv>
    );
}

const StyledMainDiv = styled.div`
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    border-bottom: ${props => props.open ? (props.color=="white"? '1px solid #F6C790;' : '1px solid rgba(17, 17, 17, 0.3);') : "1px solid transparent;"}
    width: ${props => props.open ? '250px' : "25px"};
    transition: 0.5s ease;
    margin-left: 10px;
    @media ${breakpoint.desktop} {
        width: ${props => props.open ? '550px' : "25px"};
        margin-left: 10px;
    }
`;

const StyledSearchImg = styled.img`
    height: 20px;
    width: 20px;
    cursor: pointer;
    @media ${breakpoint.desktop} {
        height: 25px;
        width: 25px;
    }
`;

const StyledArrowImg = styled.img`
    height: 12px;
    width: 17.6px;
    cursor: pointer;
    color: ${props => props.color};
    @media ${breakpoint.desktop} {
        height: 15px;
        width: 22px;
    }
`;

const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    @media ${breakpoint.desktop} {
        width: 550px;
    }
`;

const StyledInnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media ${breakpoint.desktop} {
        width: 525px;
    }
`;


export default SearchButton;