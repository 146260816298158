import React from "react";
import styled from 'styled-components';
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";

const YouTubeIdFromLink = (url) => {
    return url.match(/(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/)[1]
};


const YoutubeEmbed = ({ url }) => {
    const embedId = YouTubeIdFromLink(url);
    console.log("utubelink", embedId)
    return (
        <StyledDiv>
            <StyledIframe

                src={`https://www.youtube.com/embed/${embedId}?rel=0&enablejsapi=1`}
                frameBorder="0"
                allowFullScreen
                title="Embedded youtube"
            />
        </StyledDiv>
    )
};

const StyledDiv = styled.div`
    position: relative;
    height: min-content;
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;

    @media ${breakpoint.mobile}{
        width: calc(100vw - 40px);
        max-width: 640px;
        padding-top: 56.25%;
        overflow: hidden;
    }
`;

const StyledIframe = styled.iframe`
    @media ${breakpoint.desktop} {
        overflow: hidden;
        position: relative;
        width:895px;
        height:503px;
    }

    @media ${breakpoint.mobile}{
        width:100%;
        height:100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

export default YoutubeEmbed;