import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import Space from "../../components/common/Space";
import CommonButton from "../../components/common/buttons/CommonButton";

const MiroOnly = ({ isMiro, handlePageChange, onClick }) => {
  const { t } = useTranslation(["common", "signin"]);
  return (
    <Container>
      <CommonText
        fontSize="30px"
        mFontSize="24px"
        fontWeight={600}
        whiteSpace="pre-wrap"
      >
        {t("signin:register.miro_only_page")}
      </CommonText>
      <Space size="8px" />
      <CommonText fontSize="20px" mFontSize="16px" whiteSpace="pre-wrap">
        {isMiro
          ? t("signin:register.no_valid__current_membership")
          : t("signin:register.become_miro_now")}
      </CommonText>
      <Space size="30px" />
      {isMiro ? (
        <CommonButton type="primary" size="large" onClick={onClick}>
          {t("common:ok")}
        </CommonButton>
      ) : (
        <CommonButton
          type="primary"
          size="large"
          onClick={() => handlePageChange("introduction")}
        >
          {t("signin:register.go_to_register")}
        </CommonButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 100px 20px;
`;

export default MiroOnly;
