import React, { useState } from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import CommonText from '../typographies/CommonText';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MenuSelect from '../animations/MenuSelect';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';
import TitleGroup from '../typographies/TitleGroup';
import ExternalLinkIcon from '../../../assets/external_link.svg';

// Options(boolean): disabled
// Options(string): zhTitle, enTitle
// Options(others): onClick
// Sample Usage: <CommonMenuItem zhTitle="首頁" enTitle="HOME" onClick={onClick}></CommonMenuItem>

function CommonMenuItem(props) {
  const { t, i18n } = useTranslation();
  let {
    zhTitle,
    enTitle,
    link,
    disabled,
    onClick,
    isSubtitle,
    external = false,
  } = props;
  const [show, setShow] = useState(false);

  return (
    <StyledDiv onClick={onClick}>
      {external ? (
        <a
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          href={link}
          target="_blank"
          style={{ textDecoration: 'none' }}
          rel="noreferrer"
        >
          <StyledMenuItem
            disabled={disabled}
            disableRipple
            isSubtitle={isSubtitle}
          >
            <CommonText fontSize="30px" color="black" fontWeight="600">
              {i18n.language === 'zh' ? zhTitle : enTitle}
            </CommonText>
            <img style={{ marginLeft: 10 }} src={ExternalLinkIcon} />
            {show && <MenuSelect></MenuSelect>}
          </StyledMenuItem>
        </a>
      ) : (
        <Link
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          to={link}
          style={{ textDecoration: 'none' }}
        >
          <StyledMenuItem
            disabled={disabled}
            disableRipple
            isSubtitle={isSubtitle}
          >
            <CommonText fontSize="30px" color="black" fontWeight="600">
              {i18n.language === 'zh' ? zhTitle : enTitle}
            </CommonText>
            {show && <MenuSelect></MenuSelect>}
          </StyledMenuItem>
        </Link>
      )}
    </StyledDiv>
  );
}

const StyledMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root{
        margin-bottom: ${(props) => (props.isSubtitle ? '0px' : '15px')};
        &:hover{
            background-color: transparent;
        }
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export default CommonMenuItem;
