import styled from 'styled-components';
import { breakpoint } from '../../../../utils/responsive';
import { StyledBackdrop } from '../../../../components/common/images/ShadowImage';
import HomeCarousel from '../../../../components/common/carousels/HomeCarousel';

export const StyledHomeCarousel = styled(HomeCarousel)`
  .slick-arrow {
    transition: opacity 0.25s ease-out;
    opacity: 0;
  }
  .slick-slider:hover {
    .slick-arrow {
      opacity: 1;
    }
  }
`;
export const MirrorShopBackdrop = styled(StyledBackdrop)`
  position: absolute;
  width: calc(100% - 100px - 2 * var(--image-margin));
  height: 100%;
  top: 50%;
  left: 50%;
  transform: ${(props) =>
    props.isHovered
      ? 'translate(-50%, -50%) rotate(-8deg)'
      : 'translate(-50%, -50%) rotate(-6deg)'};
`;

export const SingleLineMediaWrapper = styled.div`
  padding-top: 50px;
  width: 100%;
  background-color: ${(props) => props.background};

  border-radius: 30px;
  .home-title-group {
    justify-content: center;
  }
`;

export const CarouselWrapper = styled(SingleLineMediaWrapper)`
  // apply to only e-shop session
  position: relative;
  --image-margin: 20px;
  @media ${breakpoint.desktop} {
    --image-margin: 35px;
  }
  @media ${breakpoint[1024]} {
    --image-margin: 30px;
  }
  @media ${breakpoint.largeDesktop} {
    --image-margin: 40px;
  }

  overflow: hidden;
  border-radius: 0px;
  > div.media-carousel {
    padding: 0 20px;
  }

  @media ${breakpoint[1024]} {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    // width: 50%;
  }
`;
