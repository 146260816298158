import { useTranslation } from "react-i18next"
import styled from "styled-components"
import ProfileInfoRow from "../ProfileInfoRow"
import AddressForm from "./AddressForm"

function AddressInfo(props) {
  const { t } = useTranslation("profile")

  return (
    <StyledMainDiv>
      <ProfileInfoRow
        title={t("profile:submenu.mail_order")}
        titleStyle={{ alignItems: "flex-start" }}
      >
        <AddressForm />
      </ProfileInfoRow>
    </StyledMainDiv>
  )
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default AddressInfo
