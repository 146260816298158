import { React } from 'react';
import styled from 'styled-components';
import VerticalDivider from '../../../components/common/divider/VerticalDivider';
import { useTranslation } from 'react-i18next';
import CommonText from '../../../components/common/typographies/CommonText';
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";

// Props(String): releaseDate, composer, lyricist, arranger, producer
// Sample Usage:
// <SongInfoBox composer="陳懷恩YNot/吳汶芳/蔡欣弦/邵建維" lyricist="小克" arranger="王雙駿" producer="王雙駿"></SongInfoBox>

function AlbumSong({songs}) {
    const { t } = useTranslation("discography");

    return (
        <StyledMainDiv>
            <ResponsiveUI 
                mobile={
                    <StyledFlexDiv>
                        <CommonText fontSize="16px" lineHeight="25px" color="white">{t("albumSongs")}</CommonText>
                        <VerticalDivider style={{ marginTop: '10px'}} color="#666666" size="20px"/>
                        <div>
                            {songs.map((song, index) => {
                                return <div key={index}><CommonText fontSize="16px" lineHeight="25px" color="white">{song.name}</CommonText></div>
                            })}
                        </div>
                    </StyledFlexDiv>
                }

                desktop={
                    <StyledFlexDiv>
                        <CommonText fontSize="20px" lineHeight="40px" color="white">{t("albumSongs")}</CommonText>
                        <VerticalDivider style={{ marginTop: '10px'}} color="#666666" size="20px"/>
                        <div>
                            {songs.map((song, index) => {
                                return <div key={index}><CommonText fontSize="20px" lineHeight="40px" color="white">{song.name}</CommonText></div>
                            })}
                        </div>
                    </StyledFlexDiv>
                }
            />

        </StyledMainDiv>
    );
}

const StyledMainDiv = styled.div`;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    align-items: flex-start;
    width: 100%;
`;

const StyledFlexDiv = styled.div`;
    display: flex;
    min-height: 25px;
    @media ${breakpoint.desktop} {
        align-items: flex-start;
        min-height: 40px;
    }
`;

export default AlbumSong;