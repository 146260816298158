import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StyledNewChip from '../../../components/common/chip/NewChip';
import CommonText from '../../../components/common/typographies/CommonText';
import { breakpoint, WindowSize } from '../../../utils/responsive';
import styled from 'styled-components';
const CurrentItemDescription = ({ arrayOfItem, idx }) => {
  const { t, i18n } = useTranslation('home');
  const isNew =
    arrayOfItem[idx]?.publishedDate &&
    dayjs().diff(dayjs(arrayOfItem[idx]?.publishedDate), 'hours') < 72;
  return (
    <Container>
      {isNew && (
        <div style={{ marginBottom: 8 }}>
          <StyledNewChip label="NEW" />
        </div>
      )}
      <CommonText
        mFontSize="18px"
        fontSize="24px"
        fontSizeFor1024="20px"
        fontWeight="700"
        lineHeight="1.25em"
      >
        {arrayOfItem[idx]
          ? i18n.language === 'en'
            ? arrayOfItem[idx].nameEn
            : arrayOfItem[idx].nameZh
          : ''}
      </CommonText>
    </Container>
  );
};

const Container = styled.div`
  min-height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 40px;

  @media ${breakpoint.desktop} {
    min-height: 70px;
  }

  @media ${breakpoint[1024]} {
    min-height: 60px;
  }
`;

export default CurrentItemDescription;
