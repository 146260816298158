import { Tooltip, styled, tooltipClasses } from '@mui/material';

const DisabledLinkTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#ff5c00',
    fontWeight: 700,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ff5c00',
    padding: '6px 19px',
    borderRadius: 30,
    fontSize: 16,
    fontFamily: 'Open Sans, Noto Sans TC',
  },
});

export default DisabledLinkTooltip;
