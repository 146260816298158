export default function checkAdult(birthdayDate){
    let now = new Date();
    if ((now.getFullYear() - birthdayDate.getFullYear()) > 18) {
        return true;
    } else if (now.getFullYear() - birthdayDate.getFullYear() == 18) {
        //check month
        if (birthdayDate.getMonth() < now.getMonth()) {
            return true;
        } else if (birthdayDate.getMonth() == now.getMonth()) {
            if (birthdayDate.getDate() <= now.getDate()) {
                return true;
            } else {
                return false
            }
        }
    } else {
        return false;
    }
}