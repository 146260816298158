export const logout = ({ navigate, navigateTo, setSession } = {}, callback) => {
  // TODO API logout

  // Local storage logout
  localLogout(setSession);

  //remove cookies
  document.cookie =
    'userId=; domain=.mirrorweare.com; path=/; secure; SameSite=none; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie =
    'orderUuid=; domain=.mirrorweare.com; path=/; secure; SameSite=none; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

  // If useNavigate set, navigate to root main page.
  if (navigate) {
    navigate(navigateTo || '/');
  }

  // Logout success. callback true
  if (callback) {
    callback(true);
  }
};

export const localLogout = (setSession) => {
  // Local storage logout
  localStorage.removeItem('session');

  if (setSession) {
    setSession(null);
  }
};
