import React from "react";
import styled from "styled-components";
import CommonImage from "../../components/common/images/CommonImage";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";
import ArrowBlackImg from "../../assets/Arrow_black.svg";
import CommonDivider from "../../components/common/divider/CommonDivider";
import moment from "moment";
import CommonLabel from "../../components/common/labels/CommonLabel";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MediaSeriesItem = ({ item }) => {
  const { t, i18n } = useTranslation("mediaSeries");
  const navigate = useNavigate();
  const isNewlyPublished =
    moment().diff(moment(item.publishedDate), "hours") < 72;
  const handleItemClick = () => {
    navigate(`/media-series/${item._id}`);
  };
  return (
    <StyledContainer onClick={handleItemClick}>
      <div style={{ maxWidth: 1100, margin: "auto" }}>
        <StyledMediaSeriesItem>
          <StyledImageDiv>
            <StyledImage
              src={item.thumbnail + "-thumbnail"}
              alt={item.nameEn}
            />
          </StyledImageDiv>
          <StyledDescription>
            {isNewlyPublished && (
              <ResponsiveUI
                mobile={
                  <CommonLabel
                    style={{
                      backgroundColor: "#E9A800",
                      padding: "8px",
                      borderRadius: 6,
                      fontWeight: 600,
                      lineHeight: "15px",
                      fontSize: "13px",
                    }}
                  >
                    NEW
                  </CommonLabel>
                }
                desktop={
                  <CommonLabel
                    style={{
                      backgroundColor: "#E9A800",
                      padding: "8px 10px",
                      borderRadius: 6,
                      fontWeight: 600,
                      lineHeight: "15px",
                    }}
                  >
                    NEW
                  </CommonLabel>
                }
              />
            )}
            <StyledTitle>
              <ResponsiveTitle>
                {i18n.language === "en" ? item.nameEn : item.nameZh}
              </ResponsiveTitle>
              <img src={ArrowBlackImg} alt="arrow" className="arrow" />
            </StyledTitle>
            <ResponsiveItemCount>
              {t("list.numberOfItem", { total: item.total })}
            </ResponsiveItemCount>
          </StyledDescription>
        </StyledMediaSeriesItem>
        <CommonDivider style={{ borderColor: "black", borderBottom: 'none' }} />
      </div>
    </StyledContainer>
  );
};




const StyledContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  
  // hover effects
  &:hover {
    transition: ease-out 200ms;
    background-color: #90a2b4;
    cursor: pointer;
    .arrow {
      opacity: 1;
      transition: ease-out 200ms;
      left: 0;
    }
  }

  .arrow {
    opacity: 0;
    position: relative;
    transform: rotate(180deg);
     @media (min-width: 600px) {
        left: -10px;
      }
      @media ${breakpoint.desktop} {
        left: -20px;
      }
  }
`;
// different flex layout for diff. breakpoint

const StyledMediaSeriesItem = styled.div`
 
  @media  ${breakpoint.mobile}{
    padding-top: 24px;
    padding-bottom: 24px;
  } 
  @media ${breakpoint.onlyMobile}{
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media ${breakpoint.desktop}{
    padding-top: 32px;
    padding-bottom: 32px;
  }
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const StyledImageDiv = styled.div`
  flex-shrink: 0;
  @media (min-width: 600px) {
    width: 300px;
  }
  // gap between image and desc.
  @media (min-width: 601px) {
    margin-right: 20px;
  }
  @media (max-width: 600px) {
    margin-bottom: 12px;
  }
  // min = 860
  @media ${breakpoint.desktop} {
    margin-right: 48px;
  }
`;

const StyledImage = styled.img`
  aspect-ratio: 16/9;
  object-fit: cover;
  width: 100%;
  border-radius: 5px;
`;

const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  > * + * {
    margin-top: 6px;
  }
`;

const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ResponsiveTitle = styled(CommonText)`
  font-size: 24px;
  line-height: 1.2;
  text-align: start;
  
  @media (min-width: 600px) {
    margin-right: 10px;
  }
  @media ${breakpoint.desktop} {
    font-size: 32px;
    margin-right: 20px;
  }
  @media ${breakpoint.largeDesktop} {
    font-size: 36px;
  }
`;

const ResponsiveItemCount = styled(CommonText)`
  font-size: 14px;
  text-align: start;
  @media ${breakpoint.desktop} {
    font-size: 16px;
  }
  @media ${breakpoint.largeDesktop} {
    font-size: 18px;
  }
`;

export default MediaSeriesItem;
