import React from 'react';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import CommonText from '../typographies/CommonText';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import CommonLabel from '../labels/CommonLabel';
import CONSTANTS from '../../../constants';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { MEMBER_RENEWAL_STATUS } from '../../../pages/ProfileMenu';
import GiftImg from '../../../assets/gift_2025.svg';
import { breakpoint } from '../../../utils/responsive';

function UpgradeButton(props) {
  const status = props.status;
  const { t, i18n } = useTranslation('profile');

  const hasRenewed = [
    MEMBER_RENEWAL_STATUS.RENEWED,
    MEMBER_RENEWAL_STATUS.PENDING_ACTIVE,
  ].includes(status);
  const hasExpired = status === MEMBER_RENEWAL_STATUS.EXPIRED;

  return (
    <StyledDiv>
      <StyledLeftDiv>
        {hasRenewed ? (
          <GiftContainer>
            <GiftIcon src={GiftImg}></GiftIcon>
            <div>
              <CommonText fontWeight="600" fontSize="18px" mFontSize="16px">
                {t('profile:upgrade.gift_miro_text')}
              </CommonText>
              <CommonText fontSize="14px" mFontSize="13px">
                {t('profile:upgrade.gift_miro_sub_text')}
              </CommonText>
            </div>
          </GiftContainer>
        ) : (
          <CommonText fontWeight="600" fontSize="18px">
            {t(
              hasExpired
                ? 'profile:upgrade.become_miro_text'
                : 'profile:upgrade.renew_miro_text'
            )}
          </CommonText>
        )}

        {/* {t(
  hasRenewed
    ? 'profile:upgrade.gift_miro_text'
    : hasExpired
    ? 'profile:upgrade.become_miro_text'
    : 'profile:upgrade.renew_miro_text' */}

        {/* <CommonLabel>
          {t("profile:upgrade.price", {
            price: CONSTANTS.MEMBER_PRICE_PER_YEAR,
          })}
        </CommonLabel> */}
      </StyledLeftDiv>

      <StyledRightDiv>
        <StyledUpgradeButton
          variant="contained"
          disableElevation
          onClick={() => {}}
        >
          <Link
            to={hasRenewed ? '/join/gift' : '/join'}
            onClick={() => {
              if (hasRenewed) {
                ReactGA.event('button_click', {
                  button_text: 'Membership page_Gift unlock button',
                });
              } else if (hasExpired) {
                ReactGA.event('button_click', {
                  button_text: 'Membership page_Join now button',
                });
              } else {
                ReactGA.event('button_click', {
                  button_text: 'Membership page_Renewal button',
                });
              }
            }}
            style={{ textDecoration: 'none' }}
          >
            <CommonText
              color={colors.white}
              fontSize={'17px'}
              fontWeight={600}
              lineHeight={'25px'}
              style={{ whiteSpace: 'nowrap' }}
            >
              {t(
                hasRenewed
                  ? 'profile:upgrade.gift_button'
                  : hasExpired
                  ? 'profile:upgrade.upgrade_button'
                  : 'profile:upgrade.renew_button'
              )}
            </CommonText>
          </Link>
        </StyledUpgradeButton>
      </StyledRightDiv>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
  padding: 24px 20px 24px 20px;
  border: 1px solid #ff5c00;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

const StyledRightDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;

  @media ${breakpoint.mobile} {
    margin-left: 10px;
  }
`;

const StyledUpgradeButton = styled(Button)`
  && {
    background: #ff5c00;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    width: 120px;
    color: ${colors.white};
  }
`;

const GiftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GiftIcon = styled.img`
  padding-right: 18px;

  @media ${breakpoint.mobile} {
    padding-right: 8px;
  }
`;

export default UpgradeButton;
