import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Router } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const MetaTag = ({ page = "other" }) => {
  const { t } = useTranslation("metaTag");
  const [pathname, setPathname] = useState("other");
  const keyword =
    "MIRO, MIRROR, MIRROR Official Fan Club, MakerVillie, MIRROR Activity, MIRROR Discography, MIRROR Shop, MIRROR Profile, MIRROR 官方歌迷會, MIRROR 最新活動, MIRROR 音樂作品, MIRROR 商店, MIRROR 組合介紹, MIRROR 成員介紹";

  useEffect(() => {
    setPathname(page);
  }, [page]);

  const getCurrentTitle = () => {
    let title = "";

    if (page.includes("team-profile")) {
      title = `${t("teamProfile")} - `;
    } else if (page.includes("member-profile")) {
      title = `${t("memberProfile")} - `;
    } else if (page.includes("discography")) {
      title = `${t("discography")} - `;
    } else if (page.includes("activity")) {
      title = `${t("activity")} - `;
    }

    title += t("mirrorFanClub");
    return title;
  };

  return (
    <Helmet>
      <title>{getCurrentTitle()}</title>
      <meta name="title" content={getCurrentTitle()} />
      <meta name="description" content={t("description")} />
      <meta name="keywords" content={keyword} />
    </Helmet>
  );
};

export default MetaTag;
