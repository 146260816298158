import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import styled from "styled-components"
import CommonSnackbar from "../components/common/snackbar/CommonSnackbar"
import CommonText from "../components/common/typographies/CommonText"
import colors from "../utils/colors"
import { breakpoint } from "../utils/responsive"

const LOCALSTORAGE_CONSENT_ACCEPTED_EXPIRY = "ConsentPopupExpiry"
export default function ConsentSnackbar() {
  const { t } = useTranslation("cookie")

  const handleAccept = () => {
    localStorage.setItem(
      LOCALSTORAGE_CONSENT_ACCEPTED_EXPIRY,
      Date.now() + 86400000 * 365
    )
    setOpen(false)
  }

  useEffect(() => {
    const accepted = localStorage.getItem(
      LOCALSTORAGE_CONSENT_ACCEPTED_EXPIRY,
      null
    )
    if (!accepted || accepted < Date.now()) {
      setOpen(true)
    }
  }, [])

  const [open, setOpen] = useState(false)
  return (
    <CommonSnackbar open={open}>
      <ContentContainer>
        <ConsentContent>
          {t("consent_popup_content")}
          <Link style={{ textDecoration: "underline" }} to="/privacy-terms">
            <CommonText
              mFontSize="13px"
              fontWeight={600}
              style={{ display: "inherit" }}
            >
              {t("button.learn_more_button")}
            </CommonText>
          </Link>
        </ConsentContent>

        <SpaceDiv />
        <OkButtonContainer onClick={handleAccept}>
          <CommonText
            whiteSpace="nowrap"
            color={colors.white}
            mFontSize="13px"
            fontWeight={600}
          >
            {t("button.ok_button")}
          </CommonText>
        </OkButtonContainer>
      </ContentContainer>
    </CommonSnackbar>
  )
}

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`
const ConsentContent = styled.span`
  text-align: start;

  @media ${breakpoint.mobile} {
    font-size: 13px;
  }
`
const SpaceDiv = styled.div`
  width: 20px;
`
const OkButtonContainer = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  background: #111;
`
