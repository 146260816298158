import { useMemo } from 'react';
import { Grid } from '@mui/material';
import CommonText from '../components/common/typographies/CommonText';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CommonNavBar from '../components/common/navbar/CommonNavBar';
import Content from '../pageComponents/privatePolicy/Content';
import { ResponsiveUI } from '../utils/responsive';
import GreyBorderButton from '../pageComponents/contactUs/optionItem';
import MenuItem from '../pageComponents/faq/menuItem';
import CommonImage from '../components/common/images/CommonImage';
import ExternalLinkIcon from '../assets/external_link.svg';
import Space from '../components/common/Space';
import Loading from '../components/common/loading';
import { getStaticPage } from '../apis';

function PrivatePolicy() {
  const { t, i18n } = useTranslation('privatePolicy');
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoading, isError, data, error } = useQuery(['privacy_policy'], () =>
    getStaticPage('privacy_policy')
  );

  const contentZh = useMemo(() => {
    if (data && data.data) {
      return data.data.zh.replace(/\n/g, '');
    }
    return '';
  }, [data]);

  const contentEn = useMemo(() => {
    if (data && data.data) {
      return data.data.en.replace(/\n/g, '');
    }
    return '';
  }, [data]);

  if (isLoading || !data) {
    return <Loading></Loading>;
  }

  return (
    <StyledMainDiv>
      <CommonNavBar theme="black" />
      <Grid
        container
        direction={'column'}
        style={{
          whiteSpace: 'pre-line',
          padding: '20px 50px 120px 50px',
          margin: 'auto',
          maxWidth: '1820px',
        }}
      >
        <Grid item xs>
          <ResponsiveUI
            mobile={
              i18n.language === 'zh' && (
                <CommonText
                  style={{ textAlign: 'center', fontSize: 16 }}
                  type="common"
                >
                  {t('landing.title')}
                </CommonText>
              )
            }
            desktop={
              i18n.language === 'zh' && (
                <CommonText
                  style={{ textAlign: 'center', fontSize: 30 }}
                  type="common"
                >
                  {t('landing.title')}
                </CommonText>
              )
            }
          />
        </Grid>
        <Grid item xs style={{ marginTop: 5 }}>
          <ResponsiveUI
            mobile={
              <CommonText
                style={{ textAlign: 'center', fontSize: 33 }}
                type="primary"
              >{`Privacy Terms`}</CommonText>
            }
            desktop={
              <CommonText
                style={{ textAlign: 'center', fontSize: 60 }}
                type="primary"
              >{`Privacy Terms`}</CommonText>
            }
          />
        </Grid>

        <ResponsiveUI
          mobile={
            <Grid container direction="column">
              <Grid
                spacing={3}
                alignItems="stretch"
                justifyContent="space-around"
                container
                direction="row"
                style={{ marginTop: 30 }}
              >
                <Grid item xs={12} sm={6}>
                  <Link
                    to={`/privacy-terms`}
                    style={{ textDecoration: 'none' }}
                    key="privacy_statement"
                  >
                    <GreyBorderButton
                      style={{
                        minHeight: '89px',
                        padding: '18px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      selected={true}
                    >
                      {t(`menu.pics`)}
                    </GreyBorderButton>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <a
                    href={`http://www.pccw.com/privacy-statement/index.page?locale=zh`}
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                    key="pics"
                  >
                    <GreyBorderButton
                      style={{
                        minHeight: '89px',
                        padding: '18px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      selected={false}
                    >
                      {t(`menu.privacy_statement`)}
                      <CommonImage
                        style={{ marginLeft: 8.5 }}
                        height="16.5px"
                        width="16.5px"
                        src={ExternalLinkIcon}
                      ></CommonImage>
                    </GreyBorderButton>
                  </a>
                </Grid>
              </Grid>
              <Grid item xs container direction="row" style={{ marginTop: 15 }}>
                <Grid item xs>
                  <Content
                    lang={i18n.language}
                    contentZh={contentZh}
                    contentEn={contentEn}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
          desktop={
            <Grid item xs container direction="row" style={{ marginTop: 50 }}>
              <Grid minWidth={168} item xs={3}>
                <MenuItem
                  style={{ cursor: 'pointer' }}
                  label={t(`menu.pics`)}
                  mb={1}
                  selected={true}
                />
                <a
                  href={`http://www.pccw.com/privacy-statement/index.page?locale=${i18n.language}`}
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                  key="pics"
                >
                  <MenuItem
                    label={t(`menu.privacy_statement`)}
                    mb={1}
                    selected={false}
                    isExternal={true}
                  />
                </a>
              </Grid>
              <Grid item xs style={{ marginLeft: 30 }}>
                <Content
                  lang={i18n.language}
                  contentZh={contentZh}
                  contentEn={contentEn}
                />
              </Grid>
            </Grid>
          }

          // mobileOffset={100}
        />
      </Grid>
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  background: white;
  position: relative;
`;

export default PrivatePolicy;
