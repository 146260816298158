import React from "react";
import styled from "styled-components";
import CommonLabel from "../labels/CommonLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const OragneSelect = (props) => {
  const iconStyle = {
    position: "absolute",
    right: 13,
    pointerEvents: "none",
    height: 9.5,
    width: 16.5,
    opacity: props.disabled ? "50%" : "100%",
  };

  const SelectIcon = () => {
    return (
      <svg
        width="17"
        style={iconStyle}
        height="10"
        viewBox="0 0 17 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 9.125L16.125 2.125C16.625 1.625 16.625 0.875 16.125 0.375C15.625 -0.125 14.875 -0.125 14.375 0.375L8.25 6.5L2.125 0.375C1.875 0.125 1.625 -6.50207e-07 1.25 -6.66599e-07C0.499998 -6.99382e-07 -1.9292e-06 0.499999 -1.96199e-06 1.25C-1.97838e-06 1.625 0.124998 1.875 0.374998 2.125L7.5 9.25C7.75 9.625 8.5 9.625 9 9.125Z"
          fill="#111111"
        />
      </svg>
    );
  };

  return (
    <StyledSelect
      {...props}
      fullWidth
      variant="outlined"
      IconComponent={SelectIcon}
      MenuProps={{
        PaperProps: {
          sx: {
            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: "#fff",
              color: "#FF5C00",
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "#FF5C00",
              color: "#fff",
            },
            "& .MuiMenuItem-root.Mui-selected:hover": {
              backgroundColor: "#FF5C00",
              color: "#fff",
            },
          },
        },
      }}
      sx={{
        borderRadius: 0,
        "&:hover": {
          "&& fieldset": {
            border: props.disabled ? "1px solid #C5C5C5" : "1px solid #FF5C00",
          },
        },
        "&.Mui-focused": {
          "&& fieldset": {
            border: props.disabled ? "1px solid #C5C5C5" : "1px solid #FF5C00",
          },
        },
      }}
    >
      {props.children ||
        props.options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  height: auto;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  text-align: left;
  background: white;
`;

function FormSelect(props) {
  let { helperText, errorText, label, children } = props;
  return (
    <StyledDiv>
      <StyledLabelDiv>
        <CommonLabel>{label ? label : ""}</CommonLabel>
      </StyledLabelDiv>
      <StyledFormControl>
        <OragneSelect {...props} label="">
          {children}
        </OragneSelect>
        <StyledErrorText>{errorText ? errorText : ""}</StyledErrorText>
        <StyledHelperText>{helperText ? helperText : ""}</StyledHelperText>
      </StyledFormControl>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
`;

const StyledLabelDiv = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 8px;
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const StyledErrorText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 0;
    color: #ff5c00;
  }
`;

const StyledHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 0;
  }
`;

export default FormSelect;
