import { useEffect, useState, useContext } from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Arrow from "../../../assets/scrollArrow.svg";
import ArrowWhite from "../../../assets/scrollArrowWhite.svg";
import styled from "styled-components";
import { breakpoint, WindowSize } from "../../../utils/responsive";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../AppContext";

const whiteThemeList = ["/discography", "/team-profile", "/member-profile"];
const disableList = [
  "/profile",
  "/signin",
  "/join",
  "/web-register",
  "/miro-register",
  "/renew",
];

export default function ScrollIndicator(props) {
  const { session, memberType } = useContext(AppContext);
  const [theme, setTheme] = useState("black");
  const [disableScroll, setDisableScroll] = useState(false);
  const isMobile = WindowSize() == "mobile";
  const isLargeDesktop = WindowSize() == "largeDesktop";
  let location = useLocation();
  let rotate = 180;
  if (props.value?.scrollTop) {
    rotate = props.value?.scrollTop + 180;
  }
  if (rotate > 360) rotate = 360;

  useEffect(() => {
    let url = window.location.pathname;
    const whiteTheme = whiteThemeList.some((item) => {
      return url.includes(item);
    });

    if (whiteTheme) {
      setTheme("white");
    } else {
      setTheme("black");
    }

    const urlDisable = disableList.some((item) => {
      return url.includes(item);
    });

    if (urlDisable || (url.includes("/") && (!session || memberType !== "MIRO"))) {
      setDisableScroll(true);
    } else {
      setDisableScroll(false);
    }
  }, [location]);

  return (
    !disableScroll && (
      <Container onClick={props.onArrowClick} stopScroll={props.value?.percentage > 92}>
        <CircularProgress
          size={isMobile ? 40 : isLargeDesktop ? 60 : 50}
          thickness={2}
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            color:
              theme === "black"
                ? "rgba(17, 17, 17, 0.3)"
                : "rgba(249,249,249,0.3)",
          }}
          variant="determinate"
          value={100}
        />
        <CircularProgress
          size={isMobile ? 40 : isLargeDesktop ? 60 : 50}
          thickness={2}
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            color: theme === "black" ? "black" : "white",
          }}
          variant="determinate"
          value={props.value?.percentage || 0}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ArrowImg
            rotate={rotate}
            src={theme === "black" ? Arrow : ArrowWhite}
          />
        </Box>
      </Container>
    )
  );
}

const Container = styled(Box)`
  cursor: pointer;
  position: fixed;
  top: auto;
  right: auto;
  z-index: 8888;
  transition: bottom 1s;

  @media ${breakpoint.mobile} {
    width: 40px;
    height: 40px;
    left: 16px;
    bottom: ${props => props.stopScroll ? "150px" : "30px"};
  }

  @media ${breakpoint.desktop} {
    width: 50px;
    height: 50px;
    left: 40px;
    bottom: 24px;
    bottom: ${props => props.stopScroll ? "70px" : "24px"};
  }

  @media ${breakpoint.largeDesktop} {
    width: 60px;
    height: 60px;
    left: 40px;
    bottom: ${props => props.stopScroll ? "70px" : "24px"};
  }
`;

const ArrowImg = styled.img`
  transform: ${(props) => `rotate(${props.rotate}deg);`};

  @media ${breakpoint.mobile} {
    width: 20px;
  }

  @media ${breakpoint.desktop} {
    width: 30px;
  }
`;
