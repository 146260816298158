import { resetPasswordWithOldPassword } from "../../apis";
import CommonButton from "../../components/common/buttons/CommonButton";
import PhoneTextField from "../../components/common/textfields/PhoneTextField";
import FormTextField from "../../components/common/textfields/FormTextField";
import PasswordTextField from "../../components/common/textfields/PasswordTextField";

import EmailPhoneToggleButton from "../../components/common/buttons/EmailPhoneToggleButton";
import { useContext, useRef, useState } from "react";
import CommonText from "../../components/common/typographies/CommonText";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Space from "../../components/common/Space";
import TickIcon from "../../components/common/icons/TickIcon";
import { AppContext } from "../../AppContext";
import Districts from "../../utils/districts.json";
import ConfirmView from "../../components/common/dialogs/ConfirmView";
import AddressInputForm from "../../components/AddressInputForm";

export default function ProfileAddAddressForm(props) {
  const { editingAddress, onSubmit, onDelete, isMobile } = props;
  const { t } = useTranslation("signin");
  const [deleting, setDeleting] = useState(false);
  const [address, setAddress] = useState(editingAddress);
  const [disableChecker, setDisableChecker] = useState(true);

  if (deleting) {
    return (
      <ConfirmView
        isMobile={isMobile}
        onConfirmClick={onDelete}
        onCancelClick={() => setDeleting(false)}
      />
    );
  }

  return (
    <Container isMobile={isMobile}>
      <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
        {editingAddress && Object.keys(editingAddress).length > 0
          ? t("profile:add_new_address.edit_title")
          : t("profile:add_new_address.title")}
      </CommonText>
      <Space size="50px" />

      <AddressInputForm
        address={address}
        onChange={(address) => setAddress(address)}
        setDisableChecker={setDisableChecker}
      />

      <Space size="20px" />
      <ButtonContainer isMobile={isMobile}>
        {editingAddress && (
          <>
            <CommonButton
              normalCase={true}
              width="100%"
              type={"outlined"}
              onClick={() => setDeleting(true)}
            >
              {t("profile:button.delete_address")}
            </CommonButton>
            <div style={{ width: "20px", height: "20px" }} />
          </>
        )}

        <CommonButton
          normalCase={true}
          width="100%"
          disabled={disableChecker}
          type={"primary"}
          onClick={() => onSubmit(address)}
        >
          {t("profile:button.save")}
        </CommonButton>
      </ButtonContainer>
      <Space size="20px" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? "100%" : "592px")};
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
