import React from 'react';
import styled from 'styled-components';
import CommonText from '../typographies/CommonText';
import { useTranslation } from 'react-i18next';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';
import CommonDivider from '../divider/CommonDivider';

function License(props) {
  let { color } = props;
  const { i18n } = useTranslation();
  const _renderLargeDesktop = () => {
    return (
      <StyledDiv>
        <StyledUpperDiv>
          <CommonText
            fontWeight="500"
            fontSize="12px"
            style={{
              color: color ? color : '#111111',
              whiteSpace: 'no-wrap',
            }}
          >
            ©️ 2024 MakerVille Company Limited. All Rights Reserved.
          </CommonText>
        </StyledUpperDiv>

        <StyledBottomDiv>
          <CommonText
            fontWeight="500"
            fontSize="12px"
            style={{
              color: color ? color : '#111111',
              whiteSpace: 'no-wrap',
            }}
          >
            A Member of PCCW Media Group
          </CommonText>
        </StyledBottomDiv>
      </StyledDiv>
    );
  };

  const _renderDesktop = () => {
    return (
      <StyledDiv>
        <StyledUpperDiv>
          <CommonText
            capitalize
            fontWeight="500"
            fontSize="12px"
            style={{
              color: color ? color : '#111111',
              whiteSpace: 'no-wrap',
            }}
          >
            ©️ 2024 MakerVille Company Limited. All Rights Reserved.
          </CommonText>
        </StyledUpperDiv>

        <StyledBottomDiv>
          <CommonText
            capitalize
            fontWeight="500"
            fontSize="12px"
            style={{
              color: color ? color : '#111111',
              whiteSpace: 'no-wrap',
            }}
          >
            A Member of PCCW Media Group
          </CommonText>
        </StyledBottomDiv>
      </StyledDiv>
    );
  };

  const _renderMobile = () => {
    return (
      <StyledDiv>
        <StyledUpperDiv>
          <CommonText
            fontWeight="500"
            fontSize="12px"
            style={{
              color: color ? color : '#111111',
            }}
          >
            ©️ 2024 MakerVille Company Limited. All Rights Reserved.
          </CommonText>
        </StyledUpperDiv>

        <StyledBottomDiv>
          <CommonText
            fontWeight="500"
            fontSize="12px"
            capitalize
            style={{
              color: color ? color : '#111111',
            }}
          >
            A Member of PCCW Media Group
          </CommonText>
        </StyledBottomDiv>
      </StyledDiv>
    );
  };

  return (
    <ResponsiveUI
      mobile={_renderMobile()}
      desktop={_renderDesktop()}
      largeDesktop={_renderLargeDesktop()}
    />
  );
}

const StyledDiv = styled.div`
  @media ${breakpoint.mobile} {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }
`;

const StyledUpperDiv = styled.div`
  width: 100%;
  text-align: justify;
  text-align: right;
`;

const StyledBottomDiv = styled.div`
  width: 100%;
  text-align: right;
  @media ${breakpoint.mobile} {
    width: 320px;
  }
`;

export default License;
