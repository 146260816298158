import styled from "styled-components";
import CommonCarousel from "../../../components/common/carousels/CommonCarousel";
import CommonImage from "../../../components/common/images/CommonImage";
import CommonText from "../../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import TitleGroup from "../../../components/common/typographies/TitleGroup";

export default function GallerySection({ data }) {
  const { i18n } = useTranslation();
  return (
    <div>
      {data.images && (
        <StyledDiv>
          <StyledTitleDiv>
            <TitleGroup
              zhTitle="照片集"
              enTitle="PICTURE GALLERY"
              color="white"
            />
          </StyledTitleDiv>

          {Object.values(data.images).length > 1 ? (
            <ResponsiveUI
              mobile={Object.values(data.images).map((image, index) => {
                if (image != null) {
                  return (
                    <div style={{ marginBottom: "20px" }}>
                      <GalleryImage
                        key={index}
                        src={image + "-thumbnail"}
                        width="100%"
                        height="auto"
                      ></GalleryImage>
                    </div>
                  );
                }
              })}
              desktop={<CommonCarousel images={data.images}></CommonCarousel>}
            />
          ) : (
            <ResponsiveUI
              mobile={
                <StyledImg src={data.images[0] + "-thumbnail"}></StyledImg>
              }
              desktop={
                <StyledImg src={data.images[0] + "-thumbnail"}></StyledImg>
              }
            />
          )}
        </StyledDiv>
      )}
    </div>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitleDiv = styled.div`
  margin-top: 93px;
  margin-bottom: 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImg = styled.img`
  width: 895px;
  height: 503px;
  padding: 0 15px;

  @media ${breakpoint.mobile} {
    width: 320px;
    height: 180px;
    object-fit: cover;
  }
`;

const GalleryImage = styled(CommonImage) `
  &&{
    object-fit: cover;
    
    @media ${breakpoint.mobile}{
      max-width: 640px;
      margin: auto;
    }
  }
`;



