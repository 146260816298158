import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getProfile } from "../apis";
import { AppContext } from "../AppContext";
import CommonBottomBar from "../components/common/navbar/CommonBottomBar";
import CommonNavBar from "../components/common/navbar/CommonNavBar";
import ProfileInfo from "../pageComponents/profile/info/ProfileInfo";
import colors from "../utils/colors";
import ProfileMenu, { ProfileMenuItem } from "./ProfileMenu";
import ProfileMembership from "./ProfileMembership";
import ProfileOrder from "./ProfileOrder";
import { useNavigate } from "react-router-dom";
import Space from "../components/common/Space";
import ProfileOrderDetail from "./ProfileOrderDetail";
import { breakpoint, ResponsiveUI } from "../utils/responsive";
import BackArrowButton from "../components/common/buttons/BackArrowButton";

export function ProfilePageInfo(props) {
  return (
    <Profile page={ProfileMenuItem.PROFILE_INFO}>
      <ProfileInfo />
    </Profile>
  );
}

export function ProfilePageOrder(props) {
  return (
    <Profile page={ProfileMenuItem.PROFILE_ORDER}>
      <ProfileOrder />
    </Profile>
  );
}

export function ProfilePageOrderDetail(props) {
  return (
    <Profile page={ProfileMenuItem.PROFILE_ORDER_DETAIL}>
      <ProfileOrderDetail />
    </Profile>
  );
}

export function ProfilePageMembership(props) {
  return (
    <Profile page={ProfileMenuItem.PROFILE_MEMBERSHIP}>
      <ProfileMembership />
    </Profile>
  );
}

export default function Profile(props) {
  const { children } = props;
  const navigate = useNavigate();
  const { session, setSession } = useContext(AppContext);
  const [page, setPage] = useState(props.page);
  const { isLoading, isError, data, error } = useQuery(["getProfile"], () =>
    getProfile(session.accessToken, { session, setSession })
  );

  function handleBackButtonClick() {
    navigate(-1);
  }

  const _renderMobile = () => {
    return (
      <>
        {children ? (
          // Render children page
          <div style={{ display: "flex", flexDirection: "column" }}>
            <BackArrowButton onClick={handleBackButtonClick} />
            <Space size="60px" />
            {children}
          </div>
        ) : (
          // Render Menu page
          <>
            <CommonNavBar theme="black" />
            <div style={{ display: "flex", width: "100%" }}>
              <ProfileMenu
                isLoading={isLoading}
                selectedMenu={page}
                onClickMenu={(item) => {
                  // setPage(item)
                  navigate(`/${item}`);
                }}
              />
            </div>
            <Space size="60px" />
            <CommonBottomBar theme="black" style={{ position: "relative" }} />
          </>
        )}
      </>
    );
  };

  const _renderDesktop = () => {
    return (
      <>
        <CommonNavBar theme="black" />
        <Space size="40px" />
        <StyleProfileDiv>
          <StyleProfileMenuDiv>
            <ProfileMenu
              isLoading={isLoading}
              selectedMenu={page}
              onClickMenu={(item) => {
                // setPage(item)
                navigate(`/${item}`);
              }}
            />
          </StyleProfileMenuDiv>

          {/* <StyleContentDiv>
          {page === ProfileMenuItem.PROFILE_INFO && <ProfileInfo isLoading={isLoading} user={data?.data} />}
          {page === ProfileMenuItem.PROFILE_ORDER && <ProfileOrder />}
          {page === ProfileMenuItem.PROFILE_MEMBERSHIP && (
            <ProfileMembership isLoading={isLoading} user={data?.data} />
          )}
        </StyleContentDiv> */}

          {children ? <StyleContentDiv>{children}</StyleContentDiv> : null}
        </StyleProfileDiv>
        <CommonBottomBar theme="black" style={{ position: "relative" }} />
      </>
    );
  };

  return (
    <Container>
      <ResponsiveUI mobile={_renderMobile()} desktop={_renderDesktop()} />
    </Container>
  );
}

const Container = styled.div`
  background-color: ${colors.white};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
`;

const StyleProfileDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 100px;
  margin: 0 50px;
`;

const StyleProfileMenuDiv = styled.div`
  display: flex;
`;
const StyleContentDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${breakpoint.desktop} {
    margin-left: 5%;
  }
`;
