import styled from "styled-components";
import { useState, useContext, useEffect } from "react";
import RootRegister from "../pageComponents/webregister/RootRegister";
import EmailRegister from "../pageComponents/webregister/EmailRegister";
import SocialRegister from "../pageComponents/webregister/SocialRegister";
import RegisterSuccess from "../pageComponents/webregister/RegisterSuccess";
import CloseButton from '../components/common/buttons/CloseButton';
import LoginImage from "../assets/login-image.jpg";
import LoginImage2 from "../assets/login-image2.jpg";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";
import { breakpoint } from "../utils/responsive";
export default function WebRegister() {
    const { session } = useContext(AppContext);
    const [page, setPage] = useState("root");
    const [params, setParams] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        //reset all otp countdown
        localStorage.removeItem('miro_emailOTP')
        localStorage.removeItem('web_emailOTP')
        localStorage.removeItem('parent_emailOTP')
    }, []);
    const handlePageChange = (page, params) => {
        console.log("handlePageChange", page, params);
        setPage(page)
        setParams(params)
    }
    const getLeftImage = () => {
        if (page == "root") return LoginImage;
        else return LoginImage2;
    }
    const renderPage = () => {
        if (session && session?.user?.memberType === "WEB") {
            return <RegisterSuccess handlePageChange={handlePageChange} />
        }
        switch (page) {
            case "root":
                return <RootRegister handlePageChange={handlePageChange} />
            case "password":
                return <EmailRegister handlePageChange={handlePageChange} />
            case "social":
                return <SocialRegister handlePageChange={handlePageChange} {...params} />
            default:
                return <RootRegister handlePageChange={handlePageChange} />
        }
    }
    return (
        <Container>
            <LeftImageView src={getLeftImage()} alt="" />
            <RightView>
                <RightViewContainer>
                    {renderPage()}
                </RightViewContainer>
            </RightView>
            <CloseButton onClick={() => session ? navigate('/') : navigate(-1)} />
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background: #F9F9F9;
`;
const LeftImageView = styled.img`
    width: 50%;
    object-fit: cover;
    max-width: 950px;
    height: 100%;
    display: block;
    @media ${breakpoint.mobile} {
        display: none;
    }
`;
const RightView = styled.div`
    flex: 1;
    margin: auto;
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;
const RightViewContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;