import styled from 'styled-components';
import CloseButton from '../components/common/buttons/CloseButton';
import LoginImage from '../assets/login-image2.jpg';
import CommonText from '../components/common/typographies/CommonText';
import { useNavigate, useLocation } from 'react-router-dom';
import { t } from 'i18next';
import CommonButton from '../components/common/buttons/CommonButton';
import { useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Space from '../components/common/Space';
import FailIcon from '../assets/payment_fail.svg';
import moment from 'moment';
import 'moment-timezone';
import { AppContext } from '../AppContext';
import { breakpoint, WindowSize } from '../utils/responsive';

export default function MiroRegisterFail({ session, setSession, payment }) {
  const { t } = useTranslation('signin');
  const navigate = useNavigate();
  const isMobile = WindowSize() == 'mobile';
  const reference_number = payment?._id;
  const amount = payment?.item?.amount;
  const date = payment?.updatedAt
    ? moment(payment.updatedAt)
        .tz('Asia/Hong_Kong')
        .locale('en')
        .format('YYYY.MM.DD hh:mma')
    : '';
  const reason_code =
    payment?.response?.reason_code ||
    payment?.response?.resMsg?.substring(1, 5);
  const handleRetry = () => {
    if (session?.user?.memberType === 'MIRO')
      navigate('/renew', { replace: true, state: { page: 'step3' } });
    else
      navigate('/miro-register', { replace: true, state: { page: 'step6' } });
  };
  return (
    <Container>
      <LeftImageView src={LoginImage} alt="" />
      <RightView>
        <RightViewContainer>
          <CommonText
            fontWeight={600}
            fontSize="30px"
            mFontSize="24px"
            lineHeight="35px"
          >
            {t('signin:register.miro_register_fail')}
          </CommonText>
          <Space size="24px" />
          <img src={FailIcon} alt="" />
          <Space size="24px" />
          <Grid
            container
            style={{ width: isMobile ? '100%' : 438, textAlign: 'left' }}
          >
            <Grid item xs={12} md={6}>
              <CommonText
                color="rgb(17, 17, 17, 0.3)"
                fontSize="14px"
                lineHeight="20px"
              >
                {t('signin:register.miro_register_reason_code')}
              </CommonText>
              <CommonText color="#111111" fontSize="14px" lineHeight="20px">
                {reason_code}
              </CommonText>
              <Space size="20px" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CommonText
                color="rgb(17, 17, 17, 0.3)"
                fontSize="14px"
                lineHeight="20px"
              >
                {t('signin:register.miro_register_amount')}
              </CommonText>
              <CommonText color="#111111" fontSize="14px" lineHeight="20px">
                HK${amount}
              </CommonText>
              <Space size="20px" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CommonText
                color="rgb(17, 17, 17, 0.3)"
                fontSize="14px"
                lineHeight="20px"
              >
                {t('signin:register.miro_register_date')}
              </CommonText>
              <CommonText color="#111111" fontSize="14px" lineHeight="20px">
                {date}
              </CommonText>
              <Space size="20px" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CommonText
                color="rgb(17, 17, 17, 0.3)"
                fontSize="14px"
                lineHeight="20px"
              >
                {t('signin:register.miro_register_ref_number')}
              </CommonText>
              <CommonText color="#111111" fontSize="14px" lineHeight="20px">
                {reference_number}
              </CommonText>
              <Space size="20px" />
            </Grid>
          </Grid>
          <CommonText
            style={{ width: isMobile ? '100%' : 438 }}
            fontSize="20px"
            mFontSize="16px"
            lineHeight="40px"
          >
            {reason_code != ''
              ? t('signin:register.miro_register_fail_msg')
              : t('signin:register.miro_register_cancel_msg')}
          </CommonText>
          <Space size="20px" />
          <CommonButton size="large" type={'primary'} onClick={handleRetry}>
            {t('signin:register.miro_register_fail_retry')}
          </CommonButton>
        </RightViewContainer>
      </RightView>
      <CloseButton
        onClick={() => {
          if (session?.user?.memberType == 'TMP') {
            localStorage.removeItem('session');
            setSession(null);
          }
          navigate('/', { replace: true });
        }}
      />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
`;
const LeftImageView = styled.img`
  width: 50%;
  object-fit: cover;
  max-width: 950px;
  height: 100%;
  display: block;
  @media ${breakpoint.mobile} {
    display: none;
  }
`;
const RightView = styled.div`
  flex: 1;
  margin: auto;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 438px;
  padding: 0px 20px;
`;
const RightViewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
