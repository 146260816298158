import React from 'react'
import styled from 'styled-components'
import MediaSeriesItem from './MediaSeriesItem'
import { breakpoint } from '../../utils/responsive'

const MediaSeriesList = ({ data }) => {
  return <StyledContainer>{data ? data.results.map((i, idx) => i.total > 0 && <MediaSeriesItem key={i._id} item={i} />) : null}</StyledContainer>

}


const StyledContainer = styled.div`
  padding-bottom: 100px;

  @media ${breakpoint.desktop}{
    padding-bottom: 140px;
  }
`

export default MediaSeriesList