import { Grid, Box } from '@mui/material'
import CommonText from '../../components/common/typographies/CommonText'

const FormField = ({ label, children, ...props }) => {
    return (
        <Box {...props}>
            <CommonText type='common' style={{ textAlign:'left', fontSize: 16, marginBottom: 5 }}>{label}</CommonText>
            {children}
        </Box>
    )
}

export default FormField