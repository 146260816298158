import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import "./Carousel.css";
import IconLeftArrow from "../../assets/resource_leftArrow.svg";
import IconRightArrow from "../../assets/resource_rightArrow.svg";

const Carousel = ({ children, show, imageList, isSelected, showSelected }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children && children.length);
  const [touchPosition, setTouchPosition] = useState(null);

  useEffect(() => {
    setLength(children && children.length);
  }, [children]);

  useEffect(() => {
    if (showSelected) {
      setCurrentIndex(isSelected?.slideIndex);
    } else {
      setCurrentIndex(0);
    }
  }, [show]);

  const next = () => {
    if (currentIndex < length - show) {
      if (showSelected) {
        setCurrentIndex((prevState) => prevState + 1);
      } else {
        setCurrentIndex(length - show);
      }
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      if (showSelected) {
        setCurrentIndex((prevState) => prevState - 1);
      } else {
        setCurrentIndex(0);
      }
    }
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  return (
    <Container showSelected={showSelected}>
      <Wrapper>
        {currentIndex > 0 && showSelected && (
          <SelectedOverflowImage
            onClick={prev}
            src={imageList?.[currentIndex - 1]}
            left={true}
          />
        )}
        {currentIndex > 0 && !showSelected && (
          <div onClick={prev} style={{ cursor: "pointer" }}>
            <OverflowImage src={imageList?.[currentIndex - 1]} left={true} />
            <ArrowButton left={true}>
              <CommonImage src={IconLeftArrow} />
            </ArrowButton>
          </div>
        )}
        <ContentContainer
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <Content
            style={{
              transform: `translateX(-${currentIndex * (100 / show)}%)`,
            }}
          >
            {children}
          </Content>
        </ContentContainer>
        {currentIndex < length - show && !showSelected && (
          <div onClick={next} style={{ cursor: "pointer" }}>
            <OverflowImage src={imageList?.[currentIndex + show]} />

            <ArrowButton>
              <CommonImage src={IconRightArrow} />
            </ArrowButton>
          </div>
        )}
        {currentIndex < length - show && showSelected && (
          <SelectedOverflowImage
            onClick={next}
            src={imageList?.[currentIndex + show]}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default Carousel;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-left: ${(props) => props.showSelected ? 0 : "40px"};
  padding-right: ${(props) => props.showSelected ? 0 : "40px"};
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const ContentContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ArrowButton = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  left: ${(props) => (props.left ? "10px" : "")};
  right: ${(props) => (props.left ? "" : "10px")};
`;

const CommonImage = styled.img`
  user-drag: none;
`;

const OverflowImage = styled.img`
  height: 400px;
  width: 60px;
  margin-top: 8px;
  margin-left: ${(props) => (props.left ? "" : "8px")};
  margin-right: ${(props) => (props.left ? "8px" : "")};
  object-fit: cover;
  user-drag: none;
  opacity: 0.3;
  mask-image: ${(props) =>
    props.left
      ? "linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))"
      : "linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))"};
`;

const SelectedOverflowImage = styled.img`
  height: 400px;
  width: 25%;
  margin-top: 8px;
  cursor: pointer;
  margin-left: ${(props) => (props.left ? "" : "8px")};
  margin-right: ${(props) => (props.left ? "8px" : "")};
  object-fit: cover;
  user-drag: none;
  opacity: 0.3;
`;
