import React from "react";
import styled from "styled-components";
import CommonText from "../common/typographies/CommonText";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

// Sample Usage:
// <InfiniteTextLoopBar text="Jer Lau"></InfiniteTextLoopBar>

function InfiniteTextLoopBar(props) {
  let { text, color } = props;

  return (
    <StyledMainDiv>
      <StyledSpan1>
        <CommonText
          type="common"
          fontSize="150px"
          mFontSize="40px"
          color={color}
          style={{
            textTransform: "uppercase",
            lineHeight: "100px",
            fontWeight: "600",
          }}
        >
          {text} {text} {text} {text}
        </CommonText>
      </StyledSpan1>
      {/* <StyledSpan2>
                <CommonText type="common" fontSize="150px" mFontSize="40px" color={color} style={{textTransform:'uppercase', lineHeight:"100px", fontWeight: "600"}}>
                    {text} {text} {text} {text} {text} {text} 
                </CommonText>
            </StyledSpan2> */}
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  //Temporary background color
  /* background: red; */
  width: 100%;
  height: 109px;
  overflow: hidden;
  display: flex;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  transform: rotate(-9deg) scale(1.1);
`;

const StyledSpan1 = styled.span`
  display: inline-block;
  padding-left: 20px;

  @media ${breakpoint.mobile} {
    animation: mymove 1.5s linear infinite;
    @keyframes mymove {
      0% {
        transform: 0;
      }
      100% {
        transform: translateX(-305px);
      }
    }
  }

  @media ${breakpoint.desktop} {
    animation: mymove 5s linear infinite;
    @keyframes mymove {
      0% {
        transform: 0;
      }
      100% {
        transform: translateX(-2278px);
      }
    }
  }
`;

export default InfiniteTextLoopBar;
