import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Space from '../../components/common/Space';

export default function MembershipTnc(contentZh, contentEn) {
  const { i18n } = useTranslation('');

  return (
    <div>
      {i18n.language === 'en' ? (
        // ----------------------ENGLISH VERSION----------------------
        <div style={{ textAlign: 'justify' }}>
          <StyledTitle>
            MIRROR OFFICIAL FAN CLUB Membership Terms and Conditions{' '}
          </StyledTitle>
          <StyledContent dangerouslySetInnerHTML={{ __html: contentEn }} />
        </div>
      ) : (
        // ----------------------CHINESE VERSION----------------------
        <div style={{ textAlign: 'justify' }}>
          <StyledTitle>MIRROR OFFICIAL FAN CLUB 會籍之條款及條件</StyledTitle>
          <StyledContent dangerouslySetInnerHTML={{ __html: contentZh }} />
        </div>
      )}
    </div>
  );
}

const StyledContent = styled.div`
  h1,
  h1 > span {
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }
  p {
    display: inline-block;
    margin-bottom: 20px;
  }
  ol {
    padding-left: 50px;
    counter-reset: item;
  }
  li {
    margin-bottom: 10px;
    display: block;
    :before {
      content: counters(item, '.') '. ';
      counter-increment: item;
      margin-right: 15px;
    }
  }
  div {
    display: block;
    margin-bottom: 20px;
  }
  .membership-about {
    margin-bottom: 40px;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  text-decoration: underline;
`;
