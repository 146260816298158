import styled from "styled-components";
import CommonText from "../../../components/common/typographies/CommonText";
import MoovImg from "../../../assets/star_moov.png";
import SpotifyImg from "../../../assets/star_spotify.png";
import KKBoxImg from "../../../assets/star_kkbox.png";
import JooxImg from "../../../assets/star_joox.png";
import AppleImg from "../../../assets/star_apple.png";
import YoutubeImg from "../../../assets/star_youtube.png";
import { useTranslation } from "react-i18next";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import ReactGA from "react-ga4";

export default function MusicSection({ data }) {
  const { t } = useTranslation("discography");
  return (
    <StyledDiv>
      <ResponsiveUI
        mobile={
          <StyledTitleDiv>
            <CommonText
              style={{
                textTransform: "uppercase",
                lineHeight: "25px",
                fontSize: "16px",
                color: "white",
                fontWeight: "400",
              }}
            >
              {t("playNow")}
            </CommonText>
          </StyledTitleDiv>
        }
        desktop={
          <StyledTitleDiv>
            <CommonText
              style={{
                textTransform: "uppercase",
                lineHeight: "45px",
                fontSize: "30px",
                color: "white",
                fontWeight: "400",
              }}
            >
              {t("playNow")}
            </CommonText>
          </StyledTitleDiv>
        }
      />
      <StyledMusiceDiv>
        {data && data.moov && (
          <StyledCommonImg
            src={MoovImg}
            onClick={() => {
              if (data && data.moov) {
                ReactGA.event("outbound_click_from_discography", {
                  param: data.moov,
                });
                window.open(data.moov, "_blank");
              }
            }}
          />
        )}
        {data && data.spotify && (
          <StyledCommonImg
            src={SpotifyImg}
            onClick={() => {
              if (data && data.spotify) {
                ReactGA.event("outbound_click_from_discography", {
                  param: data.spotify,
                });
                window.open(data.spotify, "_blank");
              }
            }}
          />
        )}
        {data && data.kkbox && (
          <StyledCommonImg
            src={KKBoxImg}
            onClick={() => {
              if (data && data.kkbox) {
                ReactGA.event("outbound_click_from_discography", {
                  param: data.kkbox,
                });
                window.open(data.kkbox, "_blank");
              }
            }}
          />
        )}
        {data && data.joox && (
          <StyledCommonImg
            src={JooxImg}
            onClick={() => {
              if (data && data.joox) {
                ReactGA.event("outbound_click_from_discography", {
                  param: data.joox,
                });
                window.open(data.joox, "_blank");
              }
            }}
          />
        )}
        {data && data.appleMusic && (
          <StyledCommonImg
            src={AppleImg}
            onClick={() => {
              if (data && data.appleMusic) {
                ReactGA.event("outbound_click_from_discography", {
                  param: data.appleMusic,
                });
                window.open(data.appleMusic, "_blank");
              }
            }}
          />
        )}
        {data && data.youtubeMusic && (
          <StyledCommonImg
            src={YoutubeImg}
            onClick={() => {
              if (data && data.youtubeMusic) {
                ReactGA.event("outbound_click_from_discography", {
                  param: data.youtubeMusic,
                });
                window.open(data.youtubeMusic, "_blank");
              }
            }}
          />
        )}
      </StyledMusiceDiv>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTitleDiv = styled.div`
  margin-top: 93px;
  margin-bottom: 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMusiceDiv = styled.div`
  display: grid;
  justify-content: center;
  width: 100%;
  grid-template-columns: 90px 90px 90px;
  @media ${breakpoint.desktop} {
    grid-template-columns: 150px 150px 150px;
  }
  @media ${breakpoint.largeDesktop} {
    grid-template-columns: 180px 180px 180px 180px 180px 180px;
  }
`;

const StyledCommonImg = styled.img`
  display: flex;
  cursor: pointer;
  width: 90px;
  height: 90px;
  @media ${breakpoint.desktop} {
    width: 150px;
    height: 150px;
  }
  @media ${breakpoint.largeDesktop} {
    width: 180px;
    height: 180px;
  }
`;
