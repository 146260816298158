import React, { useCallback, useEffect, useMemo, useState } from 'react';
import getCounterImage from '../utils/generateMiroDayCountImage';
import CommonButton from './common/buttons/CommonButton';
import { useWebShare } from '../hooks/useWebShare';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

function MiroDayCountShareButton({
  memberImage,
  language,
  count,
  since,
  plus,
}) {
  const { i18n } = useTranslation();
  const { onWebShareClick } = useWebShare();
  const [imageReady, setImageReady] = useState(false);
  useEffect(() => {
    const genShareImage = async () => {
      await getCounterImage(memberImage, count, since, language, !plus);
      setImageReady(true);
    };
    genShareImage();
  }, []);
  const share = useCallback(() => {
    ReactGA.event('button_click', {
      button_text: 'Day counter share button',
    });
    const canvas = document.getElementById('canvas');
    const dataURL = canvas.toDataURL();
    onWebShareClick(dataURL, 'miro');
  }, []);
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      {imageReady && (
        <CommonButton onClick={share} size="large" type={'primary'}>
          {i18n.language === 'zh' ? '分享' : 'Share'}
        </CommonButton>
      )}
      <canvas
        width="0"
        height="200"
        id="dayCounter"
        style={{ display: 'none' }}
      ></canvas>
      <canvas
        width="0"
        height="100"
        id="dateSince"
        style={{ display: 'none' }}
      ></canvas>
      <canvas
        width="901"
        height="714"
        id="whitecard"
        style={{ display: 'none' }}
      ></canvas>
      <canvas
        width="900"
        height="1600"
        id="canvas"
        style={{ display: 'none' }}
      ></canvas>
    </div>
  );
}

export default MiroDayCountShareButton;
