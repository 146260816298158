import React from 'react';
import styled from 'styled-components';
import CommonText from "../typographies/CommonText";

// Sample Usage:
// <InfiniteTextLoopBar text="Jer Lau"></InfiniteTextLoopBar>

function InfiniteTextLoopBar(props) {
    let {
        text
    } = props;

    let BoldMirror = () => <StyledBold>{text+" "}</StyledBold>;
    let NormalMirror = () => text;

    return (
        <StyledMainDiv>
            <StyledSpan1>
                <CommonText type="common" style={{display: 'flex', textTransform:'uppercase', fontStyle:"italic", lineHeight:"90px", fontSize:"100px", color:"white" }}>
                    <NormalMirror/><BoldMirror/><NormalMirror/><BoldMirror/><NormalMirror/><BoldMirror/><NormalMirror/><BoldMirror/>
                </CommonText>
            </StyledSpan1>
            <StyledSpan2>
                <CommonText type="common" style={{display: 'flex', textTransform:'uppercase', fontStyle:"italic", lineHeight:"90px", fontSize:"100px", color:"white" }}>
                    <NormalMirror/><BoldMirror/><NormalMirror/><BoldMirror/><NormalMirror/><BoldMirror/><NormalMirror/><BoldMirror/>
                </CommonText>
            </StyledSpan2>
        </StyledMainDiv>
    );
}

const StyledMainDiv = styled.div`
    //Temporary background color 
    /* background: red; */
    width: 100%;
    height: 109px;
    overflow: hidden;
    display: flex;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
`;

const StyledBold = styled.div`
    margin: 0 24px;
    font-weight: 800;
`;

const StyledSpan1 = styled.span`
    display:inline-block;
    padding-left: 20px;
    animation: scrolling-left 20s linear infinite;
    
    @keyframes scrolling-left {
        0% {
            transform: 0;
        }
        100% {
            transform: translateX(-100%);
        }
    }

`;

const StyledSpan2 = styled.span`
    display:inline-block;
    padding-left: 20px;
    animation-delay: 10s;
    animation: scrolling-left 20s linear infinite;

    @keyframes scrolling-left {
        0% {
            transform: 0;
        }
        100% {
            transform: translateX(-100%);
        }
    }
`;

export default InfiniteTextLoopBar;