import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconArrowBack from "../../assets/memProfile_arrawBackIcon.svg";
import BgLeftLine from "../../assets/memProfile_leftLineBg.svg";
import BgLeftLineMobile from "../../assets/memProfile_leftLineMobileBg.svg";
import CommonText from "../../components/common/typographies/CommonText";
import colors from "../../utils/colors";
import VerticalDivider from "../../components/common/divider/VerticalDivider";
import { dateMonthConvert } from "../../components/common/DateConverter";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";
import BackArrowButton from "../../components/common/buttons/BackArrowButton";

const Banner = ({ data }) => {
  const { t, i18n } = useTranslation("memberProfile");
  let navigate = useNavigate();

  return (
    <StyledMainDiv>
      <ResponsiveUI
        mobile={<StyledBgLine src={BgLeftLineMobile} />}
        desktop={<StyledBgLine src={BgLeftLine} />}
      />

      <StyledInfoDiv>
        <BackArrowButton
          whiteTheme
          onClick={() => navigate(`../member-profile`)}
        />

        <div style={{ marginTop: 60 }}>
          {i18n.language === "zh" && (
            <CommonText color={colors.white} fontSize="30px" mFontSize="25px">
              {data && data.memberNameZh}
            </CommonText>
          )}
          <StyledTextDiv>
            <CommonText
              color={colors.white}
              fontSize="60px"
              mFontSize="40px"
              type="primary"
            >
              {data && data.memberNameEn}
            </CommonText>
            <StyledBirth>
              {t("label.birthday")}
              <VerticalDivider color={colors.white60Alpha} size="20px" />
              {dateMonthConvert(data.birthday, i18n?.language || "zh")}
            </StyledBirth>
          </StyledTextDiv>
        </div>
      </StyledInfoDiv>
      <StyledBanner src={data && data.images[0] && data.images[0]} />
    </StyledMainDiv>
  );
};

const StyledBgLine = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
`;

const StyledMainDiv = styled.div`
  position: relative;
`;

const StyledInfoDiv = styled.div`
  position: relative;
  text-align: left;
  padding: 20px;
  padding-bottom: 40px;

  @media ${breakpoint.desktop} {
    padding: 55px 70px 70px 70px;
  }
`;

const StyledTextDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @media ${breakpoint.desktop} {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

const StyledBirth = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 40px;
  color: #f9f9f9;

  @media ${breakpoint.desktop} {
    font-size: 20px;
  }
`;

const StyledBanner = styled.img`
  user-drag: none;
  width: 100%;
  height: auto;

  @media ${breakpoint.mobile} {
    object-fit: contain;
  }

  @media ${breakpoint.desktop} {
    object-fit: cover;
  }
`;

export default Banner;
