import React, { cloneElement, createContext, useEffect, useRef, useState } from 'react'
import useCustomNavigationAnimation from './useCustomNavigationAnimation';
import { ResetFocusModule } from './Plugin';




const LightboxAnimationWrapper = ({ renderItem, isCustomAnimated }) => {
  // encapsulate the overridden animation logic

  const lightboxControllerRef = useRef();
  const { animation, handleArrowClick, handleThumbnailClick, } = useCustomNavigationAnimation(lightboxControllerRef);

  if (isCustomAnimated) { return renderItem(animation, [ResetFocusModule], { ref: lightboxControllerRef }, { handleArrowClick, handleThumbnailClick }) }
  else { return renderItem(undefined, [], {}, {}) }

}

export default LightboxAnimationWrapper