import styled from 'styled-components';
import { useState, useContext } from 'react';
import { AppContext } from '../AppContext';
import RootSignin from '../pageComponents/signin/RootSignin';
import PasswordSignin from '../pageComponents/signin/PasswordSignin';
import ForgotPassword from '../pageComponents/signin/ForgotPassword';
import SocialRegister from '../pageComponents/webregister/SocialRegister';
import CloseButton from '../components/common/buttons/CloseButton';
import LoginImage from '../assets/login-image.jpg';
import { useNavigate } from 'react-router-dom';
import { breakpoint } from '../utils/responsive';

export default function Signin() {
  const [page, setPage] = useState('root');
  const [params, setParams] = useState(null);
  const navigate = useNavigate();
  const { disableMiroReg } = useContext(AppContext);

  const handlePageChange = (page, params) => {
    console.log('handlePageChange', page, params);
    setPage(page);
    setParams(params);
  };
  const renderPage = () => {
    switch (page) {
      case 'root':
        return (
          <RootSignin
            handlePageChange={handlePageChange}
            disableMiroReg={disableMiroReg}
          />
        );
      case 'password':
        return <PasswordSignin handlePageChange={handlePageChange} />;
      case 'social':
        return (
          <SocialRegister handlePageChange={handlePageChange} {...params} />
        );
      case 'forgotpassword':
        return <ForgotPassword handlePageChange={handlePageChange} />;
      default:
        return (
          <RootSignin
            handlePageChange={handlePageChange}
            disableMiroReg={disableMiroReg}
          />
        );
    }
  };
  return (
    <Container>
      <LeftImageView src={LoginImage} alt="" />
      <RightView>
        <RightViewContainer>{renderPage()}</RightViewContainer>
      </RightView>
      <CloseButton
        onClick={() => {
          if (window.history.state && window.history.state.idx > 0)
            navigate(-1);
          else navigate('/', { replace: true });
        }}
      />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
`;
const LeftImageView = styled.img`
  width: 50%;
  object-fit: cover;
  max-width: 950px;
  height: 100%;
  display: block;
  @media ${breakpoint.mobile} {
    display: none;
  }
`;
const RightView = styled.div`
  flex: 1;
  margin: auto;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
const RightViewContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${breakpoint.mobile} {
    padding: 0 20px;
  }
`;
