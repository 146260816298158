import React from "react";
import styled from "styled-components";
import CommonImage from "../../../components/common/images/CommonImage";
import CommonText from "../../../components/common/typographies/CommonText";
import InfiniteTextLoopBar from "../../../components/common/bars/InfiniteTextLoopBar";
import { useNavigate } from "react-router-dom";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import BackArrowButton from "../../../components/common/buttons/BackArrowButton";

// Sample Usage:
// <SongBanner image="https://i.kfs.io/album/hk/70450293,0v1/fit/500x500.jpg" song="水刑物語" singer="Jer 柳應廷"></SongBanner>

function SongBanner(props) {
  let { image, song, singer, loopText } = props;
  let navigate = useNavigate();

  const onClickBackHandler = () => {
    navigate(`../discography`);
  };

  return (
    <StyledDiv>
      <StyledBackground image={image + "-thumbnail"} />
      <ResponsiveUI
        mobile={<div></div>}
        desktop={<InfiniteTextLoopBar text={loopText}></InfiniteTextLoopBar>}
      />
      <BackArrowButton whiteTheme onClick={onClickBackHandler} />

      <StyledMainDiv>
        <ResponsiveUI
          mobile={
            <CommonImage
              style={{ objectFit: "cover" }}
              height="300px"
              width="300px"
              src={image + "-thumbnail"}
            ></CommonImage>
          }
          desktop={
            <CommonImage
              style={{ objectFit: "cover" }}
              height="433px"
              width="433px"
              src={image + "-thumbnail"}
            ></CommonImage>
          }
        />
      </StyledMainDiv>

      <StyledInfoDiv>
        <ResponsiveUI
          mobile={
            <div>
              <CommonText
                type="common"
                fontWeight="400"
                fontSize="24px"
                lineHeight="40px"
                uppercase
                color="#F9F9F9"
              >
                {song}
              </CommonText>

              <CommonText
                type="common"
                fontWeight="400"
                fontSize="16px"
                lineHeight="25px"
                capitalize
                color="#F9F9F9"
              >
                {singer}
              </CommonText>
            </div>
          }
          desktop={
            <div>
              <CommonText
                type="common"
                fontWeight="400"
                fontSize="50px"
                lineHeight="68px"
                uppercase
                color="#F9F9F9"
              >
                {song}
              </CommonText>

              <CommonText
                type="common"
                fontWeight="400"
                fontSize="20px"
                lineHeight="40px"
                capitalize
                color="#F9F9F9"
              >
                {singer}
              </CommonText>
            </div>
          }
        />
      </StyledInfoDiv>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 540px;
  overflow: hidden;
  @media ${breakpoint.desktop} {
    height: 809px;
  }
`;

const StyledMainDiv = styled.div`
  position: absolute;
  z-index: 1000;
`;

const StyledInfoDiv = styled.div`
  position: absolute;
  bottom: 50px;
  z-index: 1000;
`;

const StyledBackground = styled.div`
  width: 100%;
  height: 809px;
  position: absolute;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(${(props) => props.image});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(30px);
  transform: scale(1.1);
`;

export default SongBanner;
