import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';

// default: children 20px, black, 100% width as parent
// size full width depends on parents div

function CommonTextField(props) {
  return (
    <StyledTextfield {...props} fullWidth variant="outlined">
      {props.children}
    </StyledTextfield>
  );
}

const StyledTextfield = styled(TextField)`
  font-family: 'Open Sans';
  height: 54px;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #111111;
  background: white;
  & .MuiOutlinedInput-root {
    & input {
      cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};
    }
    & fieldset {
      border-color: rgba(0, 0, 0, 0.26);
      border-radius: 0;
    }
    &:hover fieldset {
      border-color: ${(props) =>
        props.disabled ? 'rgba(0, 0, 0, 0.26)' : '#ff5c00'};
    }
    &.Mui-focused fieldset {
      border-color: ${(props) =>
        props.disabled ? 'rgba(0, 0, 0, 0.26)' : '#ff5c00'};
    }
    &.Mui-error fieldset {
      border-color: ${(props) =>
        props.disabled ? 'rgba(0, 0, 0, 0.26)' : '#ff5c00'};
    }
  }
`;

export default CommonTextField;
