import { useEffect, useMemo, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import CommonText from '../components/common/typographies/CommonText';
import CommonButton from '../components/common/buttons/CommonButton';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import FormField from '../pageComponents/contactUs/formField';
import GreyBorderButton from '../pageComponents/contactUs/optionItem';
import UploadButton, {
  handleUpload,
} from '../components/common/buttons/UploadButton';
import FormTextfield from '../components/common/textfields/FormTextField';
import CommonNavBar from '../components/common/navbar/CommonNavBar';
import PhoneTextField from '../components/common/textfields/PhoneTextField';
import { contactUs } from '../apis';
import CommonCloseDialog from '../components/common/dialogs/CommonCloseDialog';
import styled from 'styled-components';
import TickIcon from '../components/common/icons/TickIcon';
import IconWarning from '../assets/ic-warning.svg';
import { breakpoint, ResponsiveUI } from '../utils/responsive';
import ReCAPTCHA from 'react-google-recaptcha';
import constants from '../constants';
import {
  MiroShopRelatedInstruction,
  MiroShopAccountInstruction,
  i18nHelper,
} from 'mofc-component';
import * as EmailValidator from 'email-validator';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const QUERY_TYPE = [
  { key: 'ACCOUNT', value: 'Contact Form Inquiry - Account Information' },
  { key: 'MEMBER_WELCOME_GIFT', value: ' Contact Form Inquiry - Welcome Pack' },
  { key: 'ACTIVITY', value: 'Contact Form Inquiry - Our Activities' },
  { key: 'SHOP', value: 'SHOP' },
  { key: 'ORDER', value: 'ORDER' },
  { key: 'OTHER', value: 'Contact Form Inquiry - Others' },
];

function ContactUs() {
  const { t, i18n } = useTranslation('contactUs');
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const currentId = query.get('id') || '';
  const initialState = {
    type: '',
    email: '',
    message: '',
    username: '',
    name: '',
    phone: '',
    memberId: '',
  };
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState(null);
  const [file, setChosenFile] = useState();
  const [fileUrl, setChosenFileUrl] = useState();
  const [resultDialog, setResultDialog] = useState();
  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const recaptchaRef = useRef(null);
  useEffect(() => {
    if (i18n != null) {
      i18nHelper.loadEShopRes(i18n);
    }
  }, [i18n]);

  const onReCaptchaChange = (value) => {
    console.log('recapvalue', value);
    setRecaptcha(value);
  };

  const validate = () => {
    let error = {};
    if (!values.type) {
      error.type = t('error.missing_type');
      setErrors(error);
    }
    if (!values.email) {
      error.email = t('error.missing_email');
      setErrors(error);
    } else {
      // const validEmail = new RegExp(constants.EMAIL_REGEX);
      console.log(
        'Kazaf validEmail.test(values.email)',
        EmailValidator.validate(values.email)
      );
      if (!EmailValidator.validate(values.email)) {
        error.email = t('error.invalid_email');
        setErrors(error);
      }
    }
    if (!values.message) {
      error.message = t('error.missing_message');
      setErrors(error);
    }
    if (!values.name) {
      error.username = t('error.missing_username');
      setErrors(error);
    }
    if (!recaptcha) {
      error.recaptcha = t('error.missing_recaptcha');
      setErrors(error);
      setRecaptcha(null);
    }
    console.log('errors', errors);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };

  const handleOnSubmit = async (e) => {
    setErrors({});
    console.log('values', values);
    if (!loading && validate()) {
      setLoading(true);
      let body = {
        type: values.type,
        email: values.email,
        username: values.name,
        message: values.message,
        recaptcha: recaptcha,
      };
      if (values.phone) {
        body.phone = values.phone;
      }
      if (values.memberId) {
        body.memberNo = values.memberId;
      }
      if (file) {
        const urlPath = await handleUpload(file, 'contactus', '', false);
        body.file = urlPath;
        console.log('url', body.file);
      }
      const result = await contactUs(body);
      if (result && result.success) {
        setValues({ ...initialState });
        setChosenFile(null);
        setResultDialog(true);
      } else {
        setErrorDialog(true);
      }
      setRecaptcha(null);
      recaptchaRef.current.reset();
      setLoading(false);
    }
  };

  return [
    <CommonNavBar theme="black" />,
    <Container container direction={'column'} alignItems="center">
      {i18n.language === 'zh' && (
        <Grid item xs>
          <ResponsiveUI
            mobile={
              <CommonText
                style={{ textAlign: 'center', fontSize: 16 }}
                type="common"
              >
                {t('landing.title')}
              </CommonText>
            }
            desktop={
              <CommonText
                style={{ textAlign: 'center', fontSize: 30 }}
                type="common"
              >
                {t('landing.title')}
              </CommonText>
            }
          />
        </Grid>
      )}

      <Grid item xs style={{ marginTop: 5 }}>
        <ResponsiveUI
          mobile={
            <CommonText
              style={{ textAlign: 'center', fontSize: 33, marginBottom: 10 }}
              type="primary"
            >
              CONTACT US
            </CommonText>
          }
          desktop={
            <CommonText
              style={{ textAlign: 'center', fontSize: 60, marginBottom: 40 }}
              type="primary"
            >
              CONTACT US
            </CommonText>
          }
        />
      </Grid>
      <Grid item xs style={{ width: '100%' }}>
        <ResponsiveUI
          mobile={
            <CommonText
              style={{ textAlign: 'center', marginBottom: 30 }}
              fontSize="16px"
              type="common"
            >
              <Trans
                i18nKey="landing.title2"
                t={t}
                components={[
                  <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => navigate('/faq')}
                  />,
                ]}
              />
            </CommonText>
          }
          desktop={
            <CommonText
              style={{ textAlign: 'center', marginBottom: 65 }}
              fontSize="24px"
              type="common"
            >
              <Trans
                i18nKey="landing.title2"
                t={t}
                components={[
                  <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => navigate('/faq')}
                  />,
                ]}
              />
            </CommonText>
          }
        />
      </Grid>
      <Grid item xs container direction="row">
        <TypeConatiner item>
          <FormField label={t('field.queryType')} style={{ marginBottom: 20 }}>
            <Grid spacing={3} alignItems="stretch" container direction="row">
              {QUERY_TYPE.map((item, i) => {
                return (
                  <Grid item xs={6} md={4} lg={2} xl={2}>
                    <GreyBorderButton
                      selected={values?.type === item.value}
                      onClick={() => setValues({ ...values, type: item.value })}
                      style={{
                        minHeight: '89px',
                        padding: '2px 10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {t(`option.${item.key}`)}
                    </GreyBorderButton>
                  </Grid>
                );
              })}
            </Grid>
            {errors?.type && (
              <CommonText
                fontSize="12px"
                style={{ textAlign: 'left' }}
                color="#FF5C00"
              >
                {errors.type}
              </CommonText>
            )}
          </FormField>
        </TypeConatiner>
        {values?.type ? (
          values?.type == 'SHOP' ? (
            <MiroShopRelatedInstruction />
          ) : values?.type == 'ORDER' ? (
            <MiroShopAccountInstruction />
          ) : (
            <div style={{ width: '100%' }}>
              <Grid
                item
                container
                spacing={3}
                direction="row"
                style={{ marginBottom: 20 }}
              >
                <Grid item xs={12} sm={12} md={6}>
                  <FormField label={t('field.name')}>
                    <FormTextfield
                      value={values.name}
                      errorText={errors?.username}
                      onChange={(event) =>
                        setValues({ ...values, name: event.target.value })
                      }
                    />
                  </FormField>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormField label={t('field.email')}>
                    <FormTextfield
                      value={values.email}
                      errorText={errors?.email}
                      onChange={(event) =>
                        setValues({ ...values, email: event.target.value })
                      }
                    />
                  </FormField>
                </Grid>
              </Grid>

              <Grid
                item
                container
                spacing={3}
                direction="row"
                style={{ marginBottom: 20 }}
              >
                <Grid item xs={12} sm={12} md={6}>
                  <FormField label={t('field.phone')}>
                    <PhoneTextField
                      value={values.phone}
                      errorText={errors?.phone}
                      onChange={(value) =>
                        setValues({ ...values, phone: value })
                      }
                    />
                  </FormField>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormField label={t('field.memberId')}>
                    <FormTextfield
                      value={values.memberId}
                      onChange={(event) =>
                        setValues({ ...values, memberId: event.target.value })
                      }
                    />
                  </FormField>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormField
                  height="125px"
                  marginBottom="60px"
                  label={t('field.message')}
                >
                  <FormTextfield
                    value={values.message}
                    multiline
                    rows={4}
                    height={'auto'}
                    onChange={(event) =>
                      setValues({ ...values, message: event.target.value })
                    }
                  />
                  {errors?.message && (
                    <CommonText
                      fontSize="12px"
                      style={{ textAlign: 'left', marginTop: '70px' }}
                      color="#FF5C00"
                    >
                      {errors.message}
                    </CommonText>
                  )}
                </FormField>
              </Grid>

              <Grid item style={{ marginBottom: 20 }} xs={12}>
                {values.type === 'GOODS_RETURN' ? (
                  <FormField label={t('field.uploadImgMandatory')}>
                    <UploadButton
                      setChosenFileUrl={setChosenFileUrl}
                      fileUrl={fileUrl}
                      setChosenFile={setChosenFile}
                      file={file}
                    />
                  </FormField>
                ) : (
                  <FormField label={t('field.uploadImgOptional')}>
                    <UploadButton
                      setChosenFileUrl={setChosenFileUrl}
                      fileUrl={fileUrl}
                      setChosenFile={setChosenFile}
                      file={file}
                    />
                  </FormField>
                )}
              </Grid>

              <Grid item xs={12} style={{ marginBottom: 20 }}>
                {
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {errors?.recaptcha && (
                      <CommonText
                        fontSize="12px"
                        style={{ textAlign: 'left' }}
                        color="#FF5C00"
                      >
                        {errors.recaptcha}
                      </CommonText>
                    )}
                    <ReCAPTCHA
                      sitekey={constants.CONTACT_US_RECAPTCHA}
                      onChange={onReCaptchaChange}
                      ref={recaptchaRef}
                    />
                  </div>
                }
              </Grid>

              <Grid xs={12} alignItems="center" item>
                {/* {loading && <CommonButton disabled type='primary'>{t(`landing.submitBtn`)}</CommonButton>} */}
                <CommonButton
                  disabled={loading}
                  onClick={(e) => {
                    handleOnSubmit(e);
                  }}
                  type="primary"
                >
                  {t(`landing.submitBtn`)}
                </CommonButton>
              </Grid>
            </div>
          )
        ) : null}
      </Grid>
    </Container>,
    <CommonCloseDialog
      onClose={() => setResultDialog(false)}
      visible={resultDialog}
    >
      <StyledDialog>
        <ResponsiveUI
          mobile={
            <CommonText
              style={{ textAlign: 'center', fontSize: 20, fontWeight: 600 }}
              type="common"
            >
              {t(`dialog.submitted`)}
            </CommonText>
          }
          desktop={
            <CommonText
              style={{ textAlign: 'center', fontSize: 30, fontWeight: 600 }}
              type="common"
            >
              {t(`dialog.submitted`)}
            </CommonText>
          }
        />
        <StyleSpace />
        <TickIcon />
        <StyleSpace />
        <CommonButton type="primary" onClick={() => navigate('/')}>
          {t(`dialog.backToHome`)}
        </CommonButton>
      </StyledDialog>
    </CommonCloseDialog>,
    ,
    <CommonCloseDialog
      onClose={() => setErrorDialog(false)}
      visible={errorDialog}
    >
      <StyledDialog>
        <ResponsiveUI
          mobile={
            <CommonText
              style={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                fontSize: 20,
                fontWeight: 600,
              }}
              type="common"
            >
              {t(`error.submitFailDesc`)}
            </CommonText>
          }
          desktop={
            <CommonText
              style={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                fontSize: 30,
                fontWeight: 600,
              }}
              type="common"
            >
              {t(`error.submitFailDesc`)}
            </CommonText>
          }
        />
        <StyleSpace />
        <img src={IconWarning} />
      </StyledDialog>
    </CommonCloseDialog>,
  ];
}

const Container = styled(Grid)`
  padding: 20px 50px 120px 50px;
  max-width: 1820px;
  margin: auto;
  @media ${breakpoint.mobile} {
    padding: 20px 20px 100px 20px;
  }
`;
const StyledDialog = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 270px;
  min-height: 280px;
  @media ${breakpoint.desktop} {
    width: 960px;
    height: auto;
  }
`;
const StyleSpace = styled.div`
  height: 20px;
`;

const TypeConatiner = styled(Grid)`
  width: 100%;
`;
export default ContactUs;
