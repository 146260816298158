import Orange0 from '../assets/images-counter/orange-0.png';
import Orange1 from '../assets/images-counter/orange-1.png';
import Orange2 from '../assets/images-counter/orange-2.png';
import Orange3 from '../assets/images-counter/orange-3.png';
import Orange4 from '../assets/images-counter/orange-4.png';
import Orange5 from '../assets/images-counter/orange-5.png';
import Orange6 from '../assets/images-counter/orange-6.png';
import Orange7 from '../assets/images-counter/orange-7.png';
import Orange8 from '../assets/images-counter/orange-8.png';
import Orange9 from '../assets/images-counter/orange-9.png';
import OrangePlus from '../assets/images-counter/orange-plus.png';
import Grey0 from '../assets/images-counter/grey-0.png';
import Grey1 from '../assets/images-counter/grey-1.png';
import Grey2 from '../assets/images-counter/grey-2.png';
import Grey3 from '../assets/images-counter/grey-3.png';
import Grey4 from '../assets/images-counter/grey-4.png';
import Grey5 from '../assets/images-counter/grey-5.png';
import Grey6 from '../assets/images-counter/grey-6.png';
import Grey7 from '../assets/images-counter/grey-7.png';
import Grey8 from '../assets/images-counter/grey-8.png';
import Grey9 from '../assets/images-counter/grey-9.png';
import GreyDot from '../assets/images-counter/grey-dot.png';
import baseImagePath from '../assets/images-counter/background-counter.png';
import whiteImagePath from '../assets/images-counter/background-white-card.png';
import stickerImagePath from '../assets/images-counter/sticker.png';
import beingMiroSincePath from '../assets/images-counter/being-miro-since.png';
import beingMiroDayEn from '../assets/images-counter/being-miro-day-en.png';
import beingMiroDayZh from '../assets/images-counter/being-miro-day-zh.png';
import beingMiroEn from '../assets/images-counter/being-miro-en.png';
import beingMiroZh from '../assets/images-counter/being-miro-zh.png';

var day = '';
var dayLoaded = 0;
var date = '';
var dateLoaded = 0;

var beingMiroDayPath = {
  en: beingMiroDayEn,
  zh: beingMiroDayZh,
};
var beingMiroPath = {
  en: beingMiroEn,
  zh: beingMiroZh,
};

var dayNumber = {
  0: { path: Orange0, width: 139 },
  1: { path: Orange1, width: 59 },
  2: { path: Orange2, width: 126 },
  3: { path: Orange3, width: 130 },
  4: { path: Orange4, width: 144 },
  5: { path: Orange5, width: 130 },
  6: { path: Orange6, width: 128 },
  7: { path: Orange7, width: 126 },
  8: { path: Orange8, width: 132 },
  9: { path: Orange9, width: 128 },
  plus: { path: OrangePlus, width: 60 },
};

var dateNumber = {
  0: { path: Grey0, width: 69 },
  1: { path: Grey1, width: 48 },
  2: { path: Grey2, width: 69 },
  3: { path: Grey3, width: 68 },
  4: { path: Grey4, width: 74 },
  5: { path: Grey5, width: 65 },
  6: { path: Grey6, width: 69 },
  7: { path: Grey7, width: 70 },
  8: { path: Grey8, width: 69 },
  9: { path: Grey9, width: 69 },
  dot: { path: GreyDot, width: 24 },
};

var mirrorImage;
var mainImage;
var mainCtx;
var whiteCardImage;
var whiteCardCtx;
var dayImage;
var dayCtx;
var dateImage;
var dateCtx;

var getCounterImage = async function (
  memberImage,
  count,
  date,
  lang = 'zh',
  full = true
) {
  mirrorImage = memberImage;
  mainImage = document.getElementById('canvas');
  mainCtx = mainImage.getContext('2d');
  whiteCardImage = document.getElementById('whitecard');
  whiteCardCtx = whiteCardImage.getContext('2d');
  dayImage = document.getElementById('dayCounter');
  dayCtx = dayImage.getContext('2d');
  dateImage = document.getElementById('dateSince');
  dateCtx = dateImage.getContext('2d');
  console.log(mainImage, mainCtx);
  await new Promise((resolve, reject) => {
    var baseImage = new Image();
    baseImage.src = baseImagePath;
    baseImage.onload = function () {
      mainCtx.drawImage(baseImage, 0, 0);
      resolve();
    };
  });

  await genWhiteCard(count, date, lang, full);

  var angle = -0.05;
  mainCtx.rotate(angle);
  mainCtx.drawImage(whiteCardImage, 50, 460);
  mainCtx.rotate(-angle);

  await new Promise((resolve, reject) => {
    var stickerImage = new Image();
    stickerImage.src = stickerImagePath;
    stickerImage.onload = function () {
      mainCtx.drawImage(stickerImage, 0, 0);
      resolve();
    };
  });
};

var genWhiteCard = async function (count, date, lang, full) {
  var list = [];

  list.push(loadImage(whiteImagePath, 0, 0));
  if(lang === 'zh'){
    list.push(loadImage(beingMiroPath[lang], 130, 200, 283, 50));
  }
  else{
    list.push(loadImage(beingMiroPath[lang], 130, 200, 480, 50));
  }
  
  list.push(loadImage(beingMiroDayPath[lang], 645, 380, 94, 50));

  await genDayCount(count, full);
  await genDateSince(date);

  var mirrorIndex = Math.floor(Math.random() * 12);
  console.log('random mirror index: ', mirrorIndex);
  list.push(loadImage(mirrorImage, 40, 458, 220, 256));

  const imageList = await Promise.all(list);
  imageList.forEach((image) => {
    if (image.targetWidth != '')
      whiteCardCtx.drawImage(
        image,
        image.targetX,
        image.targetY,
        image.targetWidth,
        image.targetHeith
      );
    else whiteCardCtx.drawImage(image, image.targetX, image.targetY);
  });
  var dayImageHeight = 140;
  var dayImageWidth = (dayImage.width * dayImageHeight) / dayImage.height;
  var dayImageX = (901 - dayImageWidth) / 2;
  whiteCardCtx.drawImage(
    dayImage,
    dayImageX,
    280,
    dayImageWidth,
    dayImageHeight
  );

  var dateImageHeight = 20;
  var dateImageWidth = (dateImage.width * dateImageHeight) / dateImage.height;
  whiteCardCtx.drawImage(dateImage, 360, 515, dateImageWidth, dateImageHeight);
};

var genDayCount = async function (count, full) {
  var list = [];
  var width = 0;
  var dayDiff = count.toString();
  // Math
  //   .ceil(Math.abs(new Date() - new Date(date)) / (1000 * 60 * 60 * 24))
  //   .toString();
  // console.log("day diff: ", dayDiff);

  // if (!full) {
  //   var length = dayDiff.length;
  //   dayDiff =
  //     Math.floor(dayDiff / Math.pow(10, length - 1)) * Math.pow(10, length - 1);
  //   dayDiff = dayDiff.toString();
  // }
  for (var i = 0; i < dayDiff.length; i++) {
    list.push(loadImage(dayNumber[dayDiff.substr(i, 1)].path, width, 0));
    width += dayNumber[dayDiff.substr(i, 1)].width + 20;
    dayImage.width = width - 20;
  }
  if (!full) {
    list.push(loadImage(dayNumber['plus'].path, width, 0));
    width += dayNumber['plus'].width + 20;
    dayImage.width = width - 20;
  }

  const imageList = await Promise.all(list);
  imageList.forEach((image) => {
    if (image.targetWidth != '')
      dayCtx.drawImage(
        image,
        image.targetX,
        image.targetY,
        image.targetWidth,
        image.targetHeith
      );
    else dayCtx.drawImage(image, image.targetX, image.targetY);
  });
};

var genDateSince = async function (date) {
  var list = [];
  var width = 0;

  list.push(loadImage(beingMiroSincePath, 0, 0));
  width += 345;

  for (var i = 0; i < date.length; i++) {
    var target = parseInt(date.substr(i, 1));
    target = isNaN(target) ? 'dot' : target;
    list.push(loadImage(dateNumber[target].path, width, 0));
    width += dateNumber[target].width + 10;
    dateImage.width = width - 10;
  }

  const imageList = await Promise.all(list);
  imageList.forEach((image) => {
    if (image.targetWidth != '')
      dateCtx.drawImage(
        image,
        image.targetX,
        image.targetY,
        image.targetWidth,
        image.targetHeith
      );
    else dateCtx.drawImage(image, image.targetX, image.targetY);
  });
};

var loadImage = function (imagePath, x, y, width = '', height = '') {
  return new Promise((resolve, reject) => {
    var image = new Image();
    image.targetX = x;
    image.targetY = y;
    image.targetWidth = width;
    image.targetHeith = height;
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.addEventListener('error', (err) => {
      reject(err);
    });
    image.src = imagePath;
  });
};

export default getCounterImage;
