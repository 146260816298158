import React, { useState } from "react";
import styled from "styled-components";
import CommonText from "../../../components/common/typographies/CommonText";
import CommonImage from "../../../components/common/images/CommonImage";
import FilterButton from "../../../components/common/buttons/FilterButton";
import { useTranslation } from "react-i18next";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import YoutubeEmbed from "../../../components/common/embeds/Youtube";
import TitleGroup from "../../../components/common/typographies/TitleGroup";

export default function VideoSection({ data }) {
  const { t, i18n } = useTranslation("discography");
  const [selected, setSelect] = useState(null);
  const onSelectHandler = (e, song) => {
    if (song.name == e.target.innerText.toLowerCase()) {
      setSelect(song);
    } else {
      setSelect(song);
    }
  };
  if (!selected && data.type == "ALBUM" && data.songs.length > 0) {
    setSelect(data.songs[0]);
  }
  console.log("Getting Video No Problem", selected);
  return (
    <div>
      <StyledDiv>
        <StyledTitleDiv>
          <TitleGroup
            zhTitle="觀看影片"
            enTitle="OFFICIAL MUSIC VIDEO"
            color="white"
          />
        </StyledTitleDiv>

        {data.type == "SINGLE" && (
          <ResponsiveUI
            mobile={
              <StyledInfoDiv>
                {data.mvURL && (
                  <div>
                    <CommonText
                      style={{
                        textTransform: "uppercase",
                        lineHeight: "25px",
                        fontSize: "16px",
                        color: "white",
                        fontWeight: "400",
                        marginBottom: "25px",
                        marginTop: "20px",
                      }}
                    >
                      {i18n.language === "zh" && data.nameZh + " MV"}
                      {i18n.language === "en" && data.nameEn + " MV"}
                    </CommonText>
                    {/* <a href={data.mvURL}>
                                            <CommonImage style={{objectFit:"cover"}} height="181px" width="320px" src={data.mvImage}></CommonImage>
                                        </a> */}
                    <YoutubeEmbed url={data.mvURL}></YoutubeEmbed>
                  </div>
                )}

                {data.behindTheSceneURL && (
                  <div>
                    <CommonText
                      style={{
                        textTransform: "uppercase",
                        lineHeight: "45px",
                        fontSize: "30px",
                        color: "white",
                        fontWeight: "400",
                        marginBottom: "25px",
                        marginTop: "20px",
                      }}
                    >
                      {t("behindTheScenes")}
                    </CommonText>
                    {/* <a href={data.behindTheSceneURL}>
                                            <CommonImage style={{objectFit:"cover"}} height="181px" width="320px" src={data.behindTheSceneImage}></CommonImage>
                                        </a> */}
                    <YoutubeEmbed url={data.behindTheSceneURL}></YoutubeEmbed>
                  </div>
                )}
              </StyledInfoDiv>
            }
            desktop={
              <StyledInfoDiv>
                {data.mvURL && (
                  <div>
                    <CommonText
                      style={{
                        textTransform: "uppercase",
                        lineHeight: "45px",
                        fontSize: "30px",
                        color: "white",
                        fontWeight: "400",
                        marginBottom: "30px",
                        marginTop: "45px",
                      }}
                    >
                      {data.nameZh} MV
                    </CommonText>
                    {/* <a href={data.mvURL}>
                                            <CommonImage style={{objectFit:"cover"}} height="503px" width="895px" src={data.mvImage}></CommonImage>
                                        </a> */}
                    <YoutubeEmbed url={data.mvURL}></YoutubeEmbed>
                  </div>
                )}

                {data.behindTheSceneURL && (
                  <div>
                    <CommonText
                      style={{
                        textTransform: "uppercase",
                        lineHeight: "45px",
                        fontSize: "30px",
                        color: "white",
                        fontWeight: "400",
                        marginBottom: "30px",
                        marginTop: "45px",
                      }}
                    >
                      {t("behindTheScenes")}
                    </CommonText>
                    {/* <a href={data.behindTheSceneURL}>
                                            <CommonImage style={{objectFit:"cover"}} height="503px" width="895px" src={data.behindTheSceneImage}></CommonImage>
                                        </a> */}
                    <YoutubeEmbed url={data.behindTheSceneURL}></YoutubeEmbed>
                  </div>
                )}
              </StyledInfoDiv>
            }
          />
        )}

        {selected && data.songs && (
          <StyledAlbumDiv>
            <ResponsiveUI
              mobile={<div></div>}
              desktop={
                <StyledButtonsDiv>
                  {data.songs.map((song, index) => {
                    return (
                      <FilterButton
                        key={index}
                        onClick={(e) => onSelectHandler(e, song)}
                        select={selected}
                        mWidth="100px"
                        dWidth="150px"
                        mHeight="50px"
                        dHeight="55px"
                        fontColor="black"
                      >
                        {song.name}
                      </FilterButton>
                    );
                  })}
                </StyledButtonsDiv>
              }
            />

            <ResponsiveUI
              mobile={
                <StyledInfoDiv>
                  {data.songs.map((song, index) => {
                    return (
                      song.mvURL && (
                        <div>
                          <CommonText
                            style={{
                              textTransform: "uppercase",
                              lineHeight: "45px",
                              fontSize: "30px",
                              color: "white",
                              fontWeight: "400",
                              marginBottom: "30px",
                              marginTop: "45px",
                            }}
                          >
                            {song.name} MV
                          </CommonText>
                          <YoutubeEmbed url={song.mvURL}></YoutubeEmbed>
                          {/* <a href={song.mvURL}>
                                                    <CommonImage
                                                      style={{
                                                        objectFit: "cover",
                                                      }}
                                                      height="181px"
                                                      width="320px"
                                                      src={song.capture}
                                                    ></CommonImage>
                                                  </a> */}
                        </div>
                      )
                    );
                  })}
                </StyledInfoDiv>
              }
              desktop={
                <StyledInfoDiv>
                  {selected.mvURL && (
                    <div>
                      <CommonText
                        style={{
                          textTransform: "uppercase",
                          lineHeight: "45px",
                          fontSize: "30px",
                          color: "white",
                          fontWeight: "400",
                          marginBottom: "30px",
                          marginTop: "45px",
                        }}
                      >
                        {selected.name} MV
                      </CommonText>
                      <YoutubeEmbed url={selected.mvURL}></YoutubeEmbed>
                      {/* <a href={selected.mvURL}>
                        <CommonImage
                          style={{ objectFit: "cover" }}
                          height="503px"
                          width="895px"
                          src={selected.capture}
                        ></CommonImage>
                      </a> */}
                    </div>
                  )}
                </StyledInfoDiv>
              }
            />
          </StyledAlbumDiv>
        )}
      </StyledDiv>
    </div>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitleDiv = styled.div`
  margin-top: 126px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInfoDiv = styled.div`
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoint.desktop} {
    margin-top: 40px;
  }
`;

const StyledAlbumDiv = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const StyledButtonsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  justify-content: center;
`;
