import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from "react-query"
import { listActivities } from "../apis";
import styled from 'styled-components';
import CommonNavBar from "../components/common/navbar/CommonNavBar";
import MainSection from "../pageComponents/activity/MainSection";
import ActivitySection from "../pageComponents/activity/ActivitySection";
import CommonBottomBar from "../components/common/navbar/CommonBottomBar";
import Loading from '../components/common/loading';
import { AppContext } from "../AppContext";
import MetaTag from "../utils/MetaTag";
import { breakpoint, ResponsiveUI } from "../utils/responsive";

export default function Activity() {
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState("desc");
    const { session } = useContext(AppContext);
    const { isLoading, isError, data, error, refetch } = useQuery([session?.accessToken, "listActivities"], () => listActivities(session?.accessToken, search, sort));
    useEffect(() => {
        console.log("isRefetching");
        refetch();
    }, [search, sort]);

    if (isLoading || !data) {
        return <Loading></Loading>
    }
    if (isError) {
        return <div>Error: {error.message}</div>
    }
    return (
        <StyledMainDiv>
            <MetaTag page="activity"/>
            <CommonNavBar theme="black" />
            <MainSection></MainSection>

            <StyledActiviteisDiv>
                <ActivitySection sort={sort} setSort={setSort} search={search} setSearch={setSearch} data={data.data}></ActivitySection>
            </StyledActiviteisDiv>

            <StyledSpaceDiv></StyledSpaceDiv>
            
            <CommonBottomBar theme="black" bgColor="purple" />
        </StyledMainDiv>
    )
}

const StyledMainDiv = styled.div`
    background: #C3A8FF;
`;

const StyledSpaceDiv = styled.div`
    height: 60px;
`;


const StyledActiviteisDiv = styled.div`
    margin-top: 50px;
    background: #C3A8FF;
    padding: 0 50px;
    @media ${breakpoint.mobile} {
        padding: 0 20px;
    }
`;
