import styled from "styled-components";
import CommonCloseDialog from "./CommonCloseDialog";

const StyledMediaCloseDialog = styled(CommonCloseDialog)`
  .MuiPaper-root{
    margin: 32px 16px;
    max-width: calc(100% - 32px);
  }
`

export { StyledMediaCloseDialog }