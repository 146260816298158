import React from "react";
import styled from "styled-components";
import Space from "../../components/common/Space";
import CommonText from "../../components/common/typographies/CommonText";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";

function ProfileInfoRow(props) {
  const { title, children, titleStyle } = props;

  const _renderMobile = () => {
    return (
      <StyledMainDiv isMobile={true}>
        <StyledMobileTopDiv {...(titleStyle ? { style: titleStyle } : {})}>
          <CommonText
            style={{ textAlign: "start" }}
            fontSize="24px"
            mFontSize="24px"
            largeFontSize="30px"
            lineHeight="35px"
            fontWeight="600"
          >
            {title}
          </CommonText>
        </StyledMobileTopDiv>
        <Space size="20px" />
        <StyledRightDiv isMobile={true}>{children}</StyledRightDiv>
      </StyledMainDiv>
    );
  };

  const _renderDesktop = () => {
    return (
      <StyledMainDiv isMobile={false}>
        <StyledLeftDiv>
          <CommonText
            style={{ textAlign: "start" }}
            fontSize="24px"
            mFontSize="24px"
            largeFontSize="30px"
            lineHeight="35px"
            fontWeight="600"
          >
            {title}
          </CommonText>
        </StyledLeftDiv>
        <StyledRightDiv>{children}</StyledRightDiv>
      </StyledMainDiv>
    );
  };

  return (
    <ResponsiveUI
      mobile={_renderMobile()}
      desktop={_renderDesktop()}
      mobileOffset={219}
    />
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  width: 100%;
`;

const StyledMobileTopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${breakpoint.desktop} {
    align-items: flex-start;
    margin-bottom: 16px;
  }

  @media ${breakpoint.mobile} {
    align-items: center;
  }
`;

const StyledLeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 305px;
  width: 30%;
  min-width: 26%;
  align-items: flex-start;
`;

const StyledRightDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media ${breakpoint.mobile} {
    padding: 0px 20px;
  }
`;

export default ProfileInfoRow;
