import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { refreshToken } from './apis';
import { useRef } from 'react';
import getMemberType from './utils/getMemberType';
export const AppContext = React.createContext();
export function AppProvider(props) {
  const [init, setInit] = useState(false);
  const [session, setSession] = useState(null);
  const [memberType, setMemberType] = useState(null);
  const [disableMiroReg, setDisableMiroReg] = useState(false);
  const effectRan = useRef(false);
  const { i18n } = useTranslation();
  const value = React.useMemo(() => {
    return {
      init,
      session,
      setSession,
      memberType,
      disableMiroReg,
    };
  }, [init, session, setSession, memberType]);

  console.log(
    '🚀 ~ AppProvider ~ session:',
    !!session?.user,
    session?.user?.miroDayCount
  );
  useEffect(() => {
    setMemberType(getMemberType(session?.user));
  }, [session?.user]);

  useEffect(() => {
    const initFunction = async () => {
      // load localstorage to state
      const sessionJSON = localStorage.getItem('session', '');
      const session = JSON.parse(sessionJSON);
      console.log('check session', session);
      if (
        session?.user?.memberType == 'TMP' &&
        new Date(session?.user?.accountExpiresAt) < new Date()
      ) {
        // clear session if tmp user expired
        console.log('clear session if tmp user expired');
        localStorage.removeItem('session');
      } else if (session?.refreshToken) {
        const result = await refreshToken(session?.refreshToken);
        if (result.success) {
          localStorage.setItem('session', JSON.stringify(result.data));
          setMemberType(getMemberType(result.data.user));
          setSession(result.data);
        } else {
          localStorage.removeItem('session');
        }
      }
      setInit(true);
    };
    console.log(
      '🚀 ~ file: AppContext.js:45 ~ useEffect ~ ignore:',
      effectRan.current
    );
    if (!effectRan.current) {
      initFunction();
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}
