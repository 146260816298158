import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../utils/responsive';

// default: Open Sans, black, 16px, normal weight
// options(bool): uppercase, capitalize, outlined
// options(string): color, fontWeight, fontSize, lineHeight, fontStyle
// type: primary, secondary, common
// Sample Usage: <CommonText outlined uppercase fontWeight={"700"} color={'red'} fontSize={'100px'} type={"primary"}>We Are Mirror</CommonText>

function CommonText(props) {
  // Grouping text style
  let fontFamily = '';
  switch (props.type) {
    case 'primary':
      fontFamily = 'Philosopher';
      break;
    case 'secondary':
      fontFamily = 'Birthstone';
      break;
    case 'common':
      fontFamily = 'Open Sans';
      break;
    default:
      fontFamily = 'Open Sans';
  }

  return (
    <StyledTypography {...props} fontFamily={fontFamily}>
      {props.children}
    </StyledTypography>
  );
}

const StyledTypography = styled.div`
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  color: ${(props) =>
    props.outlined ? 'transparent' : props.color ? props.color : 'black'};
  text-transform: ${(props) =>
    props.uppercase ? 'uppercase' : props.capitalize ? 'capitalize' : 'none'};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.letterSpacing};
  text-align: ${(props) => props.textAlign};
  -webkit-text-stroke-width: ${(props) => (props.outlined ? '2px' : 'none')};
  -webkit-text-stroke-color: ${(props) => (props.outlined ? 'white' : 'none')};
  &:hover {
    -webkit-text-stroke-width: ${(props) => (props.outlined ? 'none' : '')};
    -webkit-text-stroke-color: ${(props) => (props.outlined ? 'none' : '')};
    color: ${(props) => (props.color ? props.color : 'black')};
  }
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : 'inherit')};
  @media ${breakpoint.mobile} {
    font-size: ${(props) =>
      props.mFontSize
        ? props.mFontSize
        : props.fontSize
        ? props.fontSize
        : '16px'};
  }

  @media ${breakpoint.desktop} {
    font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  }
  @media ${breakpoint[1024]} {
    font-size: ${(props) =>
      props.fontSizeFor1024
        ? props.fontSizeFor1024
        : props.fontSize
        ? props.fontSize
        : '16px'};
  }

  @media ${breakpoint.largeDesktop} {
    // check: largeFontSize > fontSizeFor1024 > fontSize > 16px
    font-size: ${(props) =>
      props.largeFontSize
        ? props.largeFontSize
        : props.fontSizeFor1024
        ? props.fontSizeFor1024
        : props.fontSize
        ? props.fontSize
        : '16px'};
  }
`;

export default CommonText;
