import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";
import CommonText from "../../components/common/typographies/CommonText";
import moment from "moment";
import CommonLabel from "../../components/common/labels/CommonLabel";

const Title = ({ data, ...props }) => {
  const navigate = useNavigate();
  const isNewlyPublished =
    moment().diff(moment(data.publishedDate), "hours") < 72;
  const { t, i18n } = useTranslation("mediaSeries");

  return data ? (
    <StyledContainer {...props}>
      <StyledDescriptionContainer>
        {isNewlyPublished && (
          <ResponsiveUI
            mobile={
              <CommonLabel
                style={{
                  backgroundColor: "#E9A800",
                  padding: "8px",
                  borderRadius: 6,
                  fontWeight: 600,
                  lineHeight: "100%",
                  fontSize: "13px",
                }}
              >
                NEW
              </CommonLabel>
            }
            desktop={
              <CommonLabel
                style={{
                  backgroundColor: "#E9A800",
                  padding: "8px 10px",
                  borderRadius: 6,
                  fontWeight: 600,
                  lineHeight: "15px",
                }}
              >
                NEW
              </CommonLabel>
            }
          />
        )}
        <ResponsiveTitle>
          {i18n.language === "en" ? data.nameEn : data.nameZh}
        </ResponsiveTitle>
        <StyledDescription mFontSize="14px">
          <span>{t("list.numberOfItem", { total: data.total })}</span>
          <StyledDescriptionSeparator />
          <span>{t("detail.updateDate")}</span>
          <span>
            {i18n.language === "zh"
              ? moment(data.updatedAt)
                  .locale("zh-hk")
                  .tz("Asia/Hong_Kong")
                  .format("LL")
              : moment(data.updatedAt)
                  .locale("en")
                  .tz("Asia/Hong_Kong")
                  .format("D MMMM YYYY")}
          </span>
        </StyledDescription>
      </StyledDescriptionContainer>
    </StyledContainer>
  ) : null;
};

const ResponsiveTitle = styled(CommonText)`
  line-height: 1.4;
  font-weight: 400;
  text-align: center;
  font-size: 24px;
  @media ${breakpoint.desktop} {
    font-size: 40px;
  }
  @media (min-width: 1200px) {
    font-size: 50px;
  }
  
  max-width: calc(100vw - 160px);
  @media ${breakpoint.largeDesktop}{
    max-width: 1400px;
`;

const StyledContainer = styled.div`
  margin-bottom: 60px;
  @media ${breakpoint.mobile} {
    margin-bottom: 32px;
  }
`;

const StyledBackArrowContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 100px;
  @media ${breakpoint.mobile} {
    height: 60px;
  }
`;

const StyledBackArrow = styled.img`
  height: 15px;
  width: 22px;
  @media ${breakpoint.mobile} {
    height: 12.1px;
    width: 17.6px;
  }
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 8px;
  }
`;

const Clickable = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  top: 61px;
  left: 21px;
  @media ${breakpoint.mobile} {
    top: 32px;
    left: 4px;
  }
  cursor: pointer;
`;

const StyledDescription = styled(CommonText)`
  > span {
    line-height: 26px;
    vertical-align: middle;
  }
`;

const StyledDescriptionSeparator = styled.span`
  margin: 0 20px;
  width: 1px;
  background-color: black;
  display: inline-block;
  height: 26px;
  @media ${breakpoint.mobile} {
    height: 20px;
  }
`;

export default Title;
