import React from "react";
import styled from "styled-components";
import CommonImage from "../../components/common/images/CommonImage";
import CommonText from "../../components/common/typographies/CommonText";
import { useNavigate } from "react-router-dom";
import AlbumTag from "../../components/common/tags/AlbumTag";
import { useTranslation } from "react-i18next";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

// Sample usage:
// <DiscographyItem src={'https://i.kfs.io/album/global/126322086,1v1/fit/500x500.jpg'} song={'不可愛教主'} singer={'盧瀚霆 Anson Lo'}></DiscographyItem>

function DiscographyItem(props) {
  let { data } = props;
  let navigate = useNavigate();

  const onClickHandler = () => {
    navigate(`../discography/${data._id}`);
  };
  const { t, i18n } = useTranslation("discography");

  return (
    <StyledDiv onClick={onClickHandler}>
      <StyledImageDiv>
        <CommonImage
          src={data.coverImage + "-thumbnail"}
          discography={true}
          height="auto"
          width="100%"
          style={{ objectFit: "cover" }}
        ></CommonImage>
        {data.type == "ALBUM" && <AlbumTag></AlbumTag>}
      </StyledImageDiv>

      <StyledInfoDiv>
        <ResponsiveUI
          mobile={
            <div>
              <CommonText
                type="common"
                fontWeight="600"
                fontSize="20px"
                lFontSize="25px"
                lineHeight="1.4"
                uppercase
                color="#F9F9F9"
              >
                {data.nameZh}
              </CommonText>
              <StyledSingerDiv>
                <CommonText
                  type="common"
                  fontWeight="400"
                  fontSize="16px"
                  lFontSize="20px"
                  lineHeight="1.4"
                  capitalize
                  color="#F9F9F9"
                >
                  {i18n.language == "en"
                    ? data.performersEn
                    : data.performersZh}
                </CommonText>
              </StyledSingerDiv>
            </div>
          }
          desktop={
            <div>
              <CommonText
                type="common"
                fontWeight="600"
                fontSize="20px"
                lineHeight="1.4"
                uppercase
                color="#F9F9F9"
              >
                {data.nameZh}
              </CommonText>
              <StyledSingerDiv>
                <CommonText
                  type="common"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="1.4"
                  capitalize
                  color="#F9F9F9"
                >
                  {i18n.language == "en"
                    ? data.performersEn
                    : data.performersZh}
                </CommonText>
              </StyledSingerDiv>
            </div>
          }
        />
      </StyledInfoDiv>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  text-align: left;
  @media ${breakpoint.desktop} {
    width: 100%;
    height: auto;
    margin-top: 35px;
  }

  @media ${breakpoint.mobile} {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
`;

const StyledImageDiv = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

const StyledInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  @media ${breakpoint.desktop} {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }

  @media ${breakpoint.mobile} {
    margin-top: 0px;
  }
`;

const StyledSingerDiv = styled.div`
  opacity: 0.8;
`;

export default DiscographyItem;
