import { resetPassword } from '../apis';
import CommonButton from '../components/common/buttons/CommonButton';
import PasswordTextField from '../components/common/textfields/PasswordTextField';
import { useMemo, useRef, useState } from 'react';
import CommonText from '../components/common/typographies/CommonText';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Space from '../components/common/Space';
import TickIcon from '../components/common/icons/TickIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import constants from '../constants';
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function ForgotPassword({ match }) {
  let query = useQuery();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['common', 'signin']);
  const [page, setPage] = useState('reset');
  const [error, setError] = useState(null);
  const passwordRef = useRef();
  const retypePasswordRef = useRef();
  const validatePassword = () => {
    let error = {};
    const validPassword = new RegExp(constants.PASSWORD_REGEX);
    if (!validPassword.test(passwordRef.current.value))
      error.password = t('signin:register.invalid_password');
    else if (passwordRef.current.value != retypePasswordRef.current.value)
      error.retypePassword = t('signin:register.invalid_retype_password');
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };
  const handleResetPassword = async () => {
    if (validatePassword()) {
      const result = await resetPassword(
        query.get('token'),
        passwordRef.current.value,
        retypePasswordRef.current.value
      );
      console.log(result);
      if (result.success) {
        setPage('resetSuccess');
      } else if (result.error && result.error.code === 'C0001') {
        setError({
          retypePassword: t('signin:forgotpassword.token_invalid'),
        });
      }
    }
  };

  if (page == 'reset') {
    return (
      <Container>
        <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
          {t('signin:forgotpassword.reset_password')}
        </CommonText>
        <Space size="102px" />
        <PasswordTextField
          errorText={error?.password}
          inputRef={passwordRef}
          label={t('signin:forgotpassword.reset_newpassword')}
        />
        <Space size="9px" />
        <CommonText
          style={{ width: '100%', textAlign: 'left' }}
          fontSize="16px"
          lineHeight="20px"
        >
          {t('common:password_hint')}
        </CommonText>
        <Space size="20px" />
        <PasswordTextField
          errorText={error?.retypePassword}
          inputRef={retypePasswordRef}
          label={t('signin:forgotpassword.reset_retypepassword')}
        />
        <Space size="49px" />
        <CommonButton
          size="large"
          style={{ marginLeft: 30 }}
          type={'primary'}
          onClick={handleResetPassword}
        >
          {t('common:submit')}
        </CommonButton>
      </Container>
    );
  }
  if (page == 'resetSuccess')
    return (
      <Container>
        <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
          {t('signin:forgotpassword.reset_password_success')}
        </CommonText>
        <Space size="20px" />
        <TickIcon />
        <Space size="20px" />
        <CommonText fontSize="20px" lineHeight="40px">
          {t('signin:forgotpassword.reset_password_success_hint')}
        </CommonText>
        <Space size="20px" />
        <CommonButton
          size="large"
          type={'primary'}
          onClick={() => navigate('/signin', { replace: true })}
        >
          {t('signin:forgotpassword.relogin')}
        </CommonButton>
      </Container>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  height: 100vh;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;
