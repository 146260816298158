import { Box } from '@mui/material'
import styled from "styled-components";
import CommonText from '../../components/common/typographies/CommonText'
import SelectArrow from '../../assets/SelectArrow.svg';
import SelectArrowWhite from '../../assets/SelectArrow_white.svg';
import ExternalLinkIcon from '../../assets/external_link.svg';
import CommonImage from '../../components/common/images/CommonImage';
import { breakpoint } from '../../utils/responsive';

function MenuItem(props) {

    const { label = '', isExternal = false, selected, ...others } = props

    return (
        <Box minHeight={84} display='flex' flexDirection='row' alignItems='center' bgcolor={selected ? '#FF5C00' : '#fff'} {...others}>
            <StyledText fontWeight="600" lineHeight="50px" type='common' selected={selected}>{label}</StyledText>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30, width: 30, marginRight: 20 }}>
                {
                    isExternal ?
                        <CommonImage height="16.5px" width="16.5px" src={ExternalLinkIcon}></CommonImage>
                        : <StyledImage height="16.5px" width="16.5px" src={selected?SelectArrowWhite:SelectArrow}></StyledImage>
                }
            </div>
        </Box>
    )
}

const StyledImage = styled(CommonImage)`
    transform: rotate(270deg);
`;

const StyledText = styled(CommonText)`
    &&{
        flex: 1;
        margin-left: 18px;
        color: ${props => props.selected ? '#fff' : '#000'};
        font-size: 18px; 

        @media ${breakpoint.desktop}{
            text-align: left;
            line-height: 1.8;
        }
    }
`

export default MenuItem