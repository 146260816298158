import React from "react";
import styled from "styled-components";
import CommonLabel from "../labels/CommonLabel";

// Input default: children 20px, black, 100% width as parent
// size full width depends on parents div
// Options(Boolean): disabled, required(not imp yet), readOnly
// Options(String): label, placeholder, helperText, errorText
// Options(Others): onChange
// Sample Usage: <FormTextfield placeholder="請輸入" label="電郵地址" errorText="錯誤碼位置" helperText="Helper Text"></FormTextfield>

function FormReadOnlyLabel(props) {
  let { children, label } = props;

  return (
    <StyledDiv>
      <StyledLabelDiv>
        <CommonLabel>{label ? label : ""}</CommonLabel>
      </StyledLabelDiv>
      <StyledLabelDiv>
        <CommonLabel
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            textAlign: "left",
          }}
          {...props}
        >
          {children}
        </CommonLabel>
      </StyledLabelDiv>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
`;

const StyledLabelDiv = styled.div`
  display: flex;
  justify-content: left;
`;

export default FormReadOnlyLabel;
