import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import * as animationData from "../../../assets/animation/mirror_loading.json";

export default function Loading() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <StyledMainDiv>
      <div style={{ maxWidth: 100 }}>
        <Lottie options={defaultOptions} />
      </div>
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;
