import styled from 'styled-components';

import { breakpoint } from '../../utils/responsive';

import JoinBadgeEn from '../../assets/revamp2025/home_join_badge_en.png';
import JoinBadgeTc from '../../assets/revamp2025/home_join_badge_tc.png';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledBadge = styled.img`
  cursor: pointer;
  position: absolute;

  height: 200px;

  transform: scale(0.45);
  transform-origin: right;
  right: -10px;

  bottom: 65px;
  z-index: 1;
  @media ${breakpoint.tablet} {
    transform-origin: left;
    transform: scale(0.45);
    left: min(52vw, 88%);
    right: none;
    bottom: 45px;
  }

  @media ${breakpoint.desktop} {
    transform: scale(0.5);
    transform-origin: left;
    left: 100%;
    right: none;
    bottom: 0;
  }

  @media ${breakpoint[1024]} {
    transform-origin: left;
    transform: scale(0.8);
  }
`;

const JoinMiroBadge = (props) => {
  const { i18n } = useTranslation('home');
  return i18n.language === 'zh' ? (
    <StyledBadge {...props} src={JoinBadgeTc} />
  ) : (
    <StyledBadge {...props} src={JoinBadgeEn} />
  );
};

export default JoinMiroBadge;
const WithStyledJoinBadge = (src) => {
  const StyledBadge = styled.img`
    cursor: pointer;
    position: absolute;

    left: 69%;
    bottom: 100%;
    height: 200px;
    transform-origin: left bottom;
    transform: scale(0.5);

    @media ${breakpoint[1024]} {
      left: 100%;
      bottom: 0;
      transform-origin: left;
      transform: scale(0.7);
    }
    @media ${breakpoint.largeDesktop} {
      transform: none;
    }
  `;
  return (props) => <StyledBadge {...props} src={src} />;
};

const WithNavigateBadge = (Component) => {
  const navigate = useNavigate();
  const EnhancedWithNavigation = (props) => {
    const onClick = () => {
      navigate('/join');
    };
    return <Component onClick={onClick} {...props} />;
  };
  return EnhancedWithNavigation;
};

export const StyledTcBadge = WithStyledJoinBadge(JoinBadgeTc);
export const StyledEnBadge = WithStyledJoinBadge(JoinBadgeEn);
