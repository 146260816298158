import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import PersonalForm from "./PersonalForm"
import ProfileInfoRow from "../ProfileInfoRow"

function PersonalInfo(props) {
  const { t } = useTranslation('profile')

  return (
    <StyledMainDiv>
      <ProfileInfoRow title={t('profile:submenu.user_profile')}>
        <PersonalForm {...props} />
      </ProfileInfoRow>
    </StyledMainDiv>
  )
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default PersonalInfo
