import styled from "styled-components"
const StyledImage = styled.img`
&& {
    cursor: pointer;
    width: ${props => props.width || "20px"};
    height: ${props => props.height || "20px"};
    object-fit: contain;
}
`
export default function ImageButton(props) {
    return (
        <StyledImage {...props}>{props.children}</StyledImage>
    )
}