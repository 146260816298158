import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import constants from '../constants';

export default function Gift2025() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language == 'zh' ? 'tc' : 'en';

  useEffect(() => {
    function deleteCookie(name) {
      document.cookie = `${name}=; Max-Age=0; path=/; domain=.mirrorweare.com`;
    }

    deleteCookie('CloudFront-Key-Pair-Id');
    deleteCookie('CloudFront-Policy');
    deleteCookie('CloudFront-Signature');
  }, []);

  return (
    <div
      style={{
        width: '100vw',
        height: '100%',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <iframe
        src={`${constants.JOIN_2025_URL}/${lang}/gift`} // Replace with your desired URL
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  );
}
