import CONSTANTS from '../constants';

const Performers = [
    {
        "nameEn":"Alton Wong",
        "nameZh":"王智德",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/Alton.jpg`
    },
    {
        "nameEn":"Anson Kong",
        "nameZh":"江𤒹生",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/AK.jpg`
    },
    {
        "nameEn":"Anson Lo",
        "nameZh":"盧瀚霆",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/Anson Lo.jpg`
    },
    {
        "nameEn":"Edan Lui",
        "nameZh":"呂爵安",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/Edan.jpg`
    },
    {
        "nameEn":"Frankie Chan",
        "nameZh":"陳瑞輝",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/Frankie.jpg`
    },
    {
        "nameEn":"Ian Chan",
        "nameZh":"陳卓賢",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/Ian.jpg`
    },
    {
        "nameEn":"Jeremy Lee",
        "nameZh":"李駿傑",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/Jeremy.jpg`
    },
    {
        "nameEn":"Jer Lau",
        "nameZh":"柳應廷",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/Jer.jpg`
    },
    {
        "nameEn":"Keung To",
        "nameZh":"姜濤",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/KT.jpg`
    },
    {
        "nameEn":"Lokman Yeung",
        "nameZh":"楊樂文",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/Lokman.jpg`
    },
    {
        "nameEn":"Stanley Yau",
        "nameZh":"邱士縉",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/Stanley.jpg`
    },
    {
        "nameEn":"Tiger Yau",
        "nameZh":"邱傲然",
        "image":`${CONSTANTS.CLOUDFRONT}/membericons/Tiger.jpg`
    }
]

export default Performers;