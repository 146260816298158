import { React, useState } from "react";
import styled from "styled-components";
import CommonText from "../../../components/common/typographies/CommonText";
import SelectArrow from "../../../assets/SelectArrow_white.svg";
import { useTranslation } from "react-i18next";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
// Sample Usage:
// <LyricBox lyric={lyric}></LyricBox>

function LyricBox(props) {
  const [open, setOpen] = useState(false);
  let { lyric } = props;
  const { t, i18n } = useTranslation("discography");

  const OnClickHandler = () => {
    setOpen(true);
  };

  const lyrics = lyric && lyric.split("\n");

  return (
    <StyledMainDiv open={open}>
      <StyledDiv open={open}>
        {lyrics &&
          lyrics.map((phrase, index) => {
            return (
              <ResponsiveUI
                mobile={
                  <CommonText
                    key={index}
                    fontSize="16px"
                    lineHeight="25px"
                    color="white"
                  >
                    {phrase}
                  </CommonText>
                }
                desktop={
                  <CommonText
                    key={index}
                    fontSize="20px"
                    lineHeight="40px"
                    color="white"
                  >
                    {phrase}
                  </CommonText>
                }
              />
            );
          })}
        {!open && <StyledShadowDiv></StyledShadowDiv>}
      </StyledDiv>
      {!open && (
        <StyledMoreDiv open={open} onClick={OnClickHandler}>
          <CommonText fontWeight="600" color="white">
            {t("expandLyrics")}
          </CommonText>
          <StyledMoreImg src={SelectArrow}></StyledMoreImg>
        </StyledMoreDiv>
      )}
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  text-align: left;
  height: ${(props) => (props.open ? "auto" : "409px")};
  background: #252525;
  width: 100%;
  @media ${breakpoint.desktop} {
    width: 456px;
    height: ${(props) => (props.open ? "auto" : "790px")};
  }
`;

const StyledMoreDiv = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  width: 320px;
  min-height: 40px;
  cursor: pointer;
  padding-left: 5px;
  @media ${breakpoint.desktop} {
    width: 456px;
  }
`;

const StyledMoreImg = styled.img`
  color: white;
  cursor: pointer;
  padding-left: 5px;
`;

const StyledDiv = styled.div`
  width: 320px;
  height: ${(props) => (props.open ? "auto" : "409px")};
  font-family: "Open Sans";
  font-size: 20px;
  line-height: 40px;
  text-transform: capitalize;
  color: #f9f9f9;
  position: relative;
  overflow: hidden;
  @media ${breakpoint.desktop} {
    width: 456px;
    height: ${(props) => (props.open ? "auto" : "740px")};
  }
`;

const StyledShadowDiv = styled.div`
  content: "";
  width: 320px;
  height: 164px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(180deg, rgba(36, 36, 36, 0) 0%, #252525 100%);
  @media ${breakpoint.desktop} {
    width: 456px;
    height: 286px;
  }
`;

export default LyricBox;
