import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getDiscography } from "../apis";
import styled from "styled-components";
import SongBanner from "../pageComponents/discography/album/SongBanner";
import InformationSection from "../pageComponents/discography/album/InformationSection";
import VideoSection from "../pageComponents/discography/album/VideoSection";
import GallerySection from "../pageComponents/discography/album/GallerySection";
import MusicSection from "../pageComponents/discography/album/MusicSection";
import CommonDivider from "../components/common/divider/CommonDivider";
import MoreSongSection from "../pageComponents/discography/album/MoreSongSection";
import CommonImage from "../components/common/images/CommonImage";
import Line from "../assets/discographydetail_line.svg";
import Loading from "../components/common/loading";
import MetaTag from "../utils/MetaTag";
import { breakpoint, ResponsiveUI } from "../utils/responsive";
import { useTranslation } from "react-i18next";

export default function DiscographyDetail() {
  const { id } = useParams();
  const { isLoading, isError, data, error } = useQuery(
    ["getDiscography", id],
    () => getDiscography(id)
  );
  const { i18n } = useTranslation();
  if (isLoading) {
    return <Loading></Loading>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  window.scrollTo(0, 0);

  return (
    <StyledDiv>
      <MetaTag page="discography" />

      {i18n.language === "zh" ? (
        <SongBanner
          loopText={data.data.performersEn}
          image={data.data.coverImage}
          song={data.data.nameZh}
          singer={data.data.performersZh}
        ></SongBanner>
      ) : (
        <SongBanner
          loopText={data.data.performersEn}
          image={data.data.coverImage}
          song={data.data.nameEn}
          singer={data.data.performersEn}
        ></SongBanner>
      )}

      <StyledLineWrapper>
        <StyledLineDiv>
          <CommonImage
            style={{ objectFit: "contain" }}
            width="100%"
            height="100%"
            src={Line}
          ></CommonImage>
        </StyledLineDiv>
      </StyledLineWrapper>

      <StyledInfoDiv>
        <InformationSection data={data.data}></InformationSection>
        <VideoSection data={data.data}></VideoSection>
        <GallerySection data={data.data}></GallerySection>
        {data.data &&
          (data.data.moov ||
            data.data.spotify ||
            data.data.kkbox ||
            data.data.joox ||
            data.data.appleMusic ||
            data.data.youtubeMusic) && (
            <MusicSection data={data.data}></MusicSection>
          )}
        <CommonDivider></CommonDivider>
        <MoreSongSection></MoreSongSection>
      </StyledInfoDiv>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  background: #252525;
  z-index: -1;
  padding-bottom: 100px;

  @media ${breakpoint.mobile} {
    padding-bottom: 50px;
  }
`;

const StyledLineWrapper = styled.div`
  position: relative;
`;

const StyledInfoDiv = styled.div`
  position: relative;
  z-index: 10;
  padding: 20px;
  @media ${breakpoint.desktop} {
    padding: 0;
  }
`;

const StyledLineDiv = styled.div`
  position: absolute;
  top: 450px;
  width: 30%;
  z-index: 1;
`;
