import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { getMemberProfile } from "../apis";
import styled from "styled-components";
import Banner from "../pageComponents/memberProfileDetail/Banner";
import AskMember from "../pageComponents/memberProfileDetail/AskMember";
import ImageGallery from "../pageComponents/memberProfileDetail/ImageGallery";
import FollowMember from "../pageComponents/memberProfileDetail/FollowMember";
import ViewNext from "../pageComponents/memberProfileDetail/ViewNext";
import Loading from "../components/common/loading";
import MetaTag from "../utils/MetaTag";

export default function DiscographyDetail() {
  const { id } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  const { isLoading, isError, data, error } = useQuery(
    ["getMemberProfile", id],
    () => getMemberProfile(id)
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <StyledMainDiv>
      <MetaTag page="member-profile" />
      <Banner data={data && data.data} />
      <AskMember data={data && data.data} />
      <ImageGallery data={data && data.data} />
      <FollowMember data={data && data.data} />
      <ViewNext data={data && data.data} />
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  background: #908681;
  overflow: hidden;
`;
