import { enrollActivity } from "../../../apis";
import PhoneTextField from "../../../components/common/textfields/PhoneTextField";
import FormTextField from "../../../components/common/textfields/FormTextField";
import FormSelect from "../../../components/common/selects/FormSelect";
import { useState, useRef, useContext } from "react";
import { AppContext } from "../../../AppContext";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import Space from "../../../components/common/Space";
import CommonButton from "../../../components/common/buttons/CommonButton";
import constants from "../../../constants";
import ReactGA from "react-ga4";
import CheckBox from "../../../components/common/checkboxs/Checkbox";
import CommonText from "../../../components/common/typographies/CommonText";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import ReCAPTCHA from "react-google-recaptcha";
import UploadButton, {
  handleUpload,
} from "../../../components/common/buttons/UploadButton";
import CommonDivider from "../../../components/common/divider/CommonDivider";

export default function ApplyForm({
  tncPath,
  eventname,
  setRegisterResult,
  id,
}) {
  const { t, i18n } = useTranslation(["common", "activity"]);
  const { session, setSession } = useContext(AppContext);
  const [phoneInput, setPhoneInput] = useState(null);
  const [email, setEmail] = useState(null);
  const [comment, setComment] = useState(null);
  const [file, setChosenFile] = useState(null);
  const [fileUrl, setChosenFileUrl] = useState();
  const [address, setAddress] = useState(null);
  const [deliveryName, setDeliveryName] = useState(null);
  const [deliveryPhone, setDeliveryPhone] = useState(null);
  const [agreeCheckbox, setAgreeCheckbox] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const recaptchaRef = useRef(null);
  const fullname = session.user.lastName + " " + session.user.firstName;

  const validate = () => {
    let error = {};
    if (agreeCheckbox === false) {
      error.agreement = t("activity:agreementCheck");
    }
    if (email === null || email === "") {
      error.email = t("activity:input_email");
    } else {
      const validEmail = new RegExp(constants.EMAIL_REGEX);
      if (!validEmail.test(email)) {
        error.email = t("activity:invalid_email");
      }
    }
    if (phoneInput === null || phoneInput === "") {
      error.phone = t("activity:input_phone");
    } else {
      const validPhone = new RegExp(constants.PHONE_REGEX);
      if (!validPhone.test(phoneInput)) {
        error.phone = t("activity:invalid_phone");
      }
    }
    if (comment === null || comment === "") {
      error.comment = t("activity:input_comment");
    }
    if (address === null || address === "") {
      error.address = t("activity:input_delivery_address");
    }
    if (deliveryName === null || deliveryName === "") {
      error.deliveryName = t("activity:input_delivery_name");
    }
    if (deliveryPhone === null || deliveryPhone === "") {
      error.deliveryPhone = t("activity:input_delivery_phone");
    } else {
      const validPhone = new RegExp(constants.PHONE_REGEX);
      if (!validPhone.test(deliveryPhone)) {
        error.deliveryPhone = t("activity:invalid_phone");
      }
    }
    if (!recaptcha) {
      error.recaptcha = t("activity:missing_recaptcha");
      setError(error);
      setRecaptcha(null);
    }
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };

  const onSubmitHandler = async () => {
    // submit if no error
    if (!loading && validate()) {
      let filepath = null;
      if (file) {
        const urlPath = await handleUpload(file);
        filepath = urlPath;
      }
      const body = {
        submitData: {
          memberNo: session.user.memberNo,
          memberName: fullname,
          phone: phoneInput,
          email,
          comment,
          file: filepath,
          address,
          deliveryName,
          deliveryPhone,
        },
      };
      console.log("body", body);
      setLoading(true);
      const result = await enrollActivity(session.accessToken, id, body);
      if (result.success == true) {
        setRegisterResult(true);
      }
      setLoading(false);
    }
    ReactGA.event("activity_join", {
      param: eventname,
    });
  };
  return (
    <StyledDiv>
      <Container>
        <div style={{ textAlign: "left" }}>
          <CommonText mFontSize="14px">{t("activity:fullname")}</CommonText>
          <CommonText mFontSize="16px" fontSize="20px" lineHeight="40px">
            {fullname}
          </CommonText>
        </div>
        <div style={{ textAlign: "left" }}>
          <CommonText mFontSize="14px">{t("activity:miroMemberId")}</CommonText>
          <CommonText mFontSize="16px" fontSize="20px" lineHeight="40px">
            {session.user.memberNo}
          </CommonText>
        </div>
        <FormTextField
          onChange={(event) =>
            setEmail(event.target.value.toLowerCase().trim())
          }
          value={email}
          errorText={error?.email}
          placeholder={t("activity:enter")}
          label={t("activity:email")}
        />
        <PhoneTextField
          onChange={(value) => setPhoneInput(value)}
          value={phoneInput}
          errorText={error?.phone}
          placeholder={t("activity:enter")}
          label={t("activity:phone")}
        />
        {/* <FormTextField inputRef={hkIdRef} errorText={error?.hkid} label={t("activity:identityNum")} /> */}
      </Container>
      <Space size="24px"></Space>
      <StyledStatementDiv>
        <FormTextField
          style={{ height: "auto" }}
          multiline
          minRows={3}
          onChange={(event) => setComment(event.target.value)}
          placeholder={t("activity:enter")}
          value={comment}
          errorText={error?.comment}
          label={t("activity:comment")}
        />
        <Space size="24px"></Space>
        <CommonText mFontSize="14px">{t("activity:file")}</CommonText>
        <Space size="8px"></Space>
        <UploadButton
          setChosenFileUrl={setChosenFileUrl}
          fileUrl={fileUrl}
          setChosenFile={setChosenFile}
          file={file}
        />
        <Space size="40px"></Space>
        <CommonDivider />
        <Space size="40px"></Space>
        <CommonText mFontSize="20px" fontSize="22px" fontWeight={600}>
          {t("activity:address")}
        </CommonText>
        <Space size="40px"></Space>
        <CommonText>{t("activity:city")}</CommonText>
        <CommonText lineHeight="40px" fontSize="18px">
          {t("activity:hongkong")}
        </CommonText>
        <Space size="40px"></Space>
        <FormTextField
          onChange={(event) => setAddress(event.target.value)}
          value={address}
          errorText={error?.address}
          placeholder={t("activity:address_placeholder")}
          label={t("activity:delivery_address")}
        />
        <Space size="40px"></Space>
        <Container>
          <FormTextField
            onChange={(event) => setDeliveryName(event.target.value)}
            value={deliveryName}
            errorText={error?.deliveryName}
            placeholder={t("activity:enter")}
            label={t("activity:delivery_name")}
          />
          <PhoneTextField
            onChange={(value) => setDeliveryPhone(value)}
            value={deliveryPhone}
            errorText={error?.deliveryPhone}
            placeholder={t("activity:enter")}
            label={t("activity:delivery_phone")}
          />
        </Container>
        <Space size="40px"></Space>
        <CommonDivider />
        <Space size="40px"></Space>
        <CheckBox
          onClick={() => setAgreeCheckbox(!agreeCheckbox)}
          isChecked={agreeCheckbox}
        >
          <Trans
            t={t}
            i18nKey="activity:agreement"
            values={{ eventname: eventname }}
            components={[
              <a
                target="_blank"
                style={{ color: "#FF5C00" }}
                key={0}
                href="https://mirrorweare.com/privacy-terms"
                rel="noreferrer"
              >
                個人資料收集聲明
              </a>,
              tncPath && (
                <a
                  target="_blank"
                  style={{ color: "#FF5C00" }}
                  key={1}
                  href={tncPath}
                  rel="noreferrer"
                >
                  條款及條件
                </a>
              ),
            ]}
          />
        </CheckBox>
        {error?.agreement && (
          <CommonText color="#FF5C00">{error.agreement}</CommonText>
        )}
      </StyledStatementDiv>
      <Space size="24px"></Space>
      <div>
        <ReCAPTCHA
          sitekey={constants.CONTACT_US_RECAPTCHA}
          onChange={(value) => setRecaptcha(value)}
          ref={recaptchaRef}
        />
        {error?.recaptcha && (
          <CommonText
            fontSize="12px"
            style={{ textAlign: "left" }}
            color="#FF5C00"
          >
            {error.recaptcha}
          </CommonText>
        )}
      </div>
      <Space size="24px"></Space>
      <ResponsiveUI
        mobile={
          <div>
            {!loading && (
              <CommonButton
                width="300px"
                onClick={() => onSubmitHandler()}
                size={"large"}
                type={"primary"}
              >
                {t("activity:submit")}
              </CommonButton>
            )}
            {loading && (
              <CommonButton disabled width="300px" type={"primary"}>
                {t("activity:submit")}
              </CommonButton>
            )}
          </div>
        }
        desktop={
          <div>
            {!loading && (
              <CommonButton
                onClick={() => onSubmitHandler()}
                size={"large"}
                type={"primary"}
              >
                {t("activity:submit")}
              </CommonButton>
            )}
            {loading && (
              <CommonButton disabled size={"large"} type={"primary"}>
                {t("activity:submit")}
              </CommonButton>
            )}
          </div>
        }
      />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  @media ${breakpoint.desktop} {
    padding: 0px 50px;
    max-width: 1364px;
    margin: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  @media ${breakpoint.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 38px;
    column-gap: 25px;
    max-width: 1264px;
  }
`;

const StyledStatementDiv = styled.div`
  width: 100%;
  text-align: left;
  @media ${breakpoint.desktop} {
    max-width: 1264px;
  }
`;
