import { useContext } from "react";
import { AppContext } from "../../AppContext";
import styled from "styled-components";
import CommonButton from "../../components/common/buttons/CommonButton";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import Space from "../../components/common/Space";
import TickIcon from "../../components/common/icons/TickIcon";
import { useNavigate } from "react-router-dom";
import constants from "../../constants";

export default function RegisterSuccess({ handlePageChange }) {
  const { disableMiroReg } = useContext(AppContext);
  const { t } = useTranslation(["profile", "signin"]);
  let navigate = useNavigate();
  return (
    <Container>
      <CommonText
        style={{ whiteSpace: "pre-line" }}
        fontWeight={600}
        fontSize="30px"
        lineHeight="35px"
      >
        {t("signin:register.web_register_success")}
      </CommonText>
      <Space size="20px" />
      <TickIcon />
      <Space size="20px" />
      {!disableMiroReg && (
        <UpgradeContainer>
          <div style={{ textAlign: "left" }}>
            <CommonText fontWeight={600} fontSize="18px">
              {t("profile:upgrade.become_miro_text")}
            </CommonText>
            <CommonText color="rgba(17, 17, 17, 0.7)">
              {t("profile:upgrade.price", {
                price: constants.MEMBER_PRICE_PER_YEAR,
              })}
            </CommonText>
          </div>
          <UpgradeButtonContainer
            onClick={() => {
              navigate(`/join`);
            }}
          >
            <CommonText color="#ffffff" fontWeight={600} fontSize="18px">
              {t("profile:upgrade.upgrade_button")}
            </CommonText>
          </UpgradeButtonContainer>
        </UpgradeContainer>
      )}
      <Space size="20px" />
      <CommonButton
        size={"large"}
        type={"primary"}
        onClick={() => {
          navigate(`/`);
        }}
      >
        {t("signin:register.go_to_home")}
      </CommonButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 438px;
  justify-content: center;
  align-items: center;
`;
const UpgradeContainer = styled.div`
  display: flex;
  border: 1px solid #ff5c00;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 28px;
  width: 100%;
  justify-content: space-between;
`;
const UpgradeButtonContainer = styled.div`
  display: flex;
  background: #ff5c00;
  border-radius: 5px;
  width: 120px;
  height: 45px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
