import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import CommonBottomBar from '../../components/common/navbar/CommonBottomBar';
import CommonNavBar from '../../components/common/navbar/CommonNavBar';
import CommonText from '../../components/common/typographies/CommonText';
import {
  breakpoint,
  ResponsiveUI,
  size,
  WindowSize,
} from '../../utils/responsive';

import * as animationDataEn from '../../assets/animation/miro_btn_en.json';
import * as animationDataTc from '../../assets/animation/miro_btn_tc.json';
import ImgJoinMiroTypography from '../../assets/revamp2025/home_miro_typography.png';

import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { ReactComponent as BottomLeftLine } from '../../assets/revamp2025/home_line_bottom.svg';
import JoinMiroBadge from './JoinMiroBadge';
import VisitorHero from './VisitorHero';
import { mediaQueryForSpanFullHeight } from './constant';
import ReactGA from 'react-ga4';

const MiroTypography = () => {
  const { disableMiroReg, session } = useContext(AppContext);
  const isMiro = session?.user && session.user.memberType === 'MIRO';
  const { i18n } = useTranslation('home');
  const isMobile = WindowSize() === 'mobile';
  const navigate = useNavigate();

  const handleClick = () => {
    ReactGA.event('button_click', {
      button_text: 'Be Our MIRO button ',
    });
    navigate(`/join`);
  };

  // const shouldShowJoinBadge = true;
  const shouldShowJoinBadge = !isMiro && !disableMiroReg;

  return (
    <JoinTypographyContainer>
      <PositionJoinMiroTypography>
        <StyledImgJoinMiroTypography src={ImgJoinMiroTypography} />
        {shouldShowJoinBadge && <JoinMiroBadge onClick={handleClick} />}
      </PositionJoinMiroTypography>
    </JoinTypographyContainer>
  );
};

const VisitorWelcome = ({ user }) => {
  const { t, i18n } = useTranslation('home');

  const defaultTcOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataTc,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultEnOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataEn,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const _renderDesktop = () => {
    return (
      <div style={{ background: '#f5f5f5' }}>
        <Container>
          <StyleFullHeightHero style={{ position: 'relative', zIndex: 1 }}>
            <CommonNavBar theme="black" style={{ background: '#f5f5f5' }} />
            <StyledContentDiv>
              <VisitorHero />

              <MiroTypography />
            </StyledContentDiv>
          </StyleFullHeightHero>
          <BottomBarContainer>
            <BottomLeftLine className="bottom-left-line"></BottomLeftLine>
            <CommonBottomBar bgColor="white" theme="black" />
          </BottomBarContainer>
        </Container>
      </div>
    );
  };

  const _renderMobile = () => {
    return (
      <Container>
        <StyleFullHeightHero>
          <CommonNavBar theme="black" style={{ background: '#f5f5f5' }} />
          <StyledContentDiv>
            {/* <StyledMobileBgDiv></StyledMobileBgDiv> */}
            <VisitorHero />
            <MiroTypography />
            <MiroClubWrapper>
              <CommonText fontSize="12px" fontWeight="700">
                MIRROR{' '}
                <span style={{ letterSpacing: '0.23em' }}>官方歌迷會</span>
              </CommonText>
              <CommonText fontSize="12px" fontWeight="700">
                MIRROR OFFICIAL FAN CLUB
              </CommonText>
            </MiroClubWrapper>
          </StyledContentDiv>
        </StyleFullHeightHero>
        <BottomBarContainer>
          <CommonBottomBar bgColor="white" theme="black" />
        </BottomBarContainer>
      </Container>
    );
  };

  return (
    <ResponsiveUI
      mobile={_renderMobile()}
      desktop={_renderDesktop()}
      mobileOffset={size[1024] - size.desktop}
    />
  );
};

const MiroClubWrapper = styled.div`
  margin-top: 3px;
  margin-bottom: 50px;
  @media ${breakpoint.desktop} {
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  min-height: 100vh;

  max-width: 1920px;
  background: #f5f5f5;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BottomBarContainer = styled.div`
  z-index: 0;
  margin-top: auto;
  position: relative;
  .bottom-left-line {
    position: absolute;
    transform: translateY(-100%);
    // bottom: 0;
    left: 0;
  }
`;

const JoinTypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 63px;

  @media ${breakpoint[1024]} {
    height: 80px;
  }
`;

const PositionJoinMiroTypography = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media ${breakpoint.tablet} {
    width: auto;
  }

  @media ${breakpoint[1024]} {
    top: -80px;
  }
`;

const StyledImgJoinMiroTypography = styled.img`
  position: relative;
  height: 113px;
  width: auto;
  top: -50px;

  @media ${breakpoint[1024]} {
    height: 160px;
    top: 0;
  }
`;

const StyledContentDiv = styled.div`
  // height: 100%;
  padding: 0px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 0;

  @media ${breakpoint[1024]} {
    padding: 0px 60px;
  }
`;

const StyleFullHeightHero = styled.div`
  @media ${mediaQueryForSpanFullHeight} {
    height: 100vh;
  }
  display: grid;
  grid-template-rows: auto 1fr;
`;

export default VisitorWelcome;
