const ExternalLinkIcon = ({ color }) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5417 5.35798C3.09182 5.35798 2.72712 5.72268 2.72712 6.17256V13.9642C2.72712 14.4141 3.09181 14.7788 3.5417 14.7788H11.3334C11.7833 14.7788 12.1479 14.4141 12.1479 13.9642V9.71422C12.1479 9.3817 12.4175 9.11214 12.75 9.11214C13.0826 9.11214 13.3521 9.3817 13.3521 9.71422V13.9642C13.3521 15.0792 12.4483 15.983 11.3334 15.983H3.5417C2.42678 15.983 1.52295 15.0792 1.52295 13.9642V6.17256C1.52295 5.05763 2.42677 4.15381 3.5417 4.15381H7.7917C8.12422 4.15381 8.39378 4.42337 8.39378 4.75589C8.39378 5.08841 8.12422 5.35798 7.7917 5.35798H3.5417Z"
        fill={color ?? 'black'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0229 2.63089C10.0229 2.29837 10.2925 2.02881 10.625 2.02881H14.875C15.2076 2.02881 15.4771 2.29837 15.4771 2.63089V6.88089C15.4771 7.21341 15.2076 7.48298 14.875 7.48298C14.5425 7.48298 14.2729 7.21341 14.2729 6.88089V3.23298H10.625C10.2925 3.23298 10.0229 2.96341 10.0229 2.63089Z"
        fill={color ?? 'black'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3009 2.20515C15.5361 2.44028 15.5361 2.8215 15.3009 3.05663L7.50927 10.8483C7.27414 11.0834 6.89292 11.0834 6.65779 10.8483C6.42266 10.6132 6.42266 10.2319 6.65779 9.99682L14.4495 2.20515C14.6846 1.97003 15.0658 1.97003 15.3009 2.20515Z"
        fill={color ?? 'black'}
      />
    </svg>
  );
};

export default ExternalLinkIcon;
