import styled from 'styled-components';
import { useState, useContext, useEffect, useRef } from 'react';
import RootRegister from '../pageComponents/miroregister/RootRegister';
import Introduction from '../pageComponents/miroregister/Introduction';
import SocialStep1 from '../pageComponents/miroregister/SocialStep1';
import Step1 from '../pageComponents/miroregister/Step1';
import Step2 from '../pageComponents/miroregister/Step2';
import Step3 from '../pageComponents/miroregister/Step3';
import Step4 from '../pageComponents/miroregister/Step4';
import Step5 from '../pageComponents/miroregister/Step5';
import Step6 from '../pageComponents/miroregister/Step6';
import CloseButton from '../components/common/buttons/CloseButton';
import LoginImage from '../assets/login-image.jpg';
import LoginImage2 from '../assets/login-image2.jpg';
import LoginImage3 from '../assets/login-image3.jpg';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { breakpoint } from '../utils/responsive';
import Dialog from '@mui/material/Dialog';
import FailIcon from '../assets/payment_fail.svg';
import CommonText from '../components/common/typographies/CommonText';
import { useTranslation } from 'react-i18next';
import Space from '../components/common/Space';
import CommonButton from '../components/common/buttons/CommonButton';
import DisableMiroRegister from '../pageComponents/miroregister/DisableMiroRegister';

export default function WebRegister() {
  const { t } = useTranslation('signin');
  const { session, setSession, disableMiroReg } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [page, setPage] = useState(state?.page || 'introduction');
  const [params, setParams] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const rightViewRef = useRef(null);
  useEffect(() => {
    //reset all otp countdown
    localStorage.removeItem('miro_emailOTP');
    localStorage.removeItem('web_emailOTP');
    localStorage.removeItem('parent_emailOTP');
    // if (session?.user?.memberType === "WEB" && session?.user?.memberNo)
    //   navigate("/join", { replace: true });
  }, []);
  useEffect(() => {
    if (disableMiroReg) {
      setPage('disableMiroRegister');
    } else {
      if (state?.page) setPage(state.page);
      if (state?.params) setParams(state.params);
    }
  }, [state]);

  useEffect(() => {
    console.log('page changed', page);
    if (rightViewRef.current) {
      console.log('scroll to top');
      rightViewRef.current.scrollTo(0, 0);
    }
  }, [page]);

  const handleCloseDialog = () => {
    if (session?.user?.memberType == 'TMP') {
      localStorage.removeItem('session');
      setSession(null);
    }
    navigate('/');
  };

  const onCloseSessionExpiredDialog = () => {
    localStorage.removeItem('session');
    setSession(null);
    setSessionExpired(false);
    handlePageChange('introduction');
  };
  const handlePageChange = (page, params) => {
    console.log('handlePageChange', page, params);
    setPage(page);
    setParams(params);
  };
  const getLeftImage = () => {
    if (
      page == 'introduction' ||
      page == 'root' ||
      page == 'disableMiroRegister'
    )
      return LoginImage;
    else if (page == 'step5' || page == 'step6') return LoginImage3;
    else return LoginImage2;
  };

  const renderPage = () => {
    switch (page) {
      case 'disableMiroRegister':
        return <DisableMiroRegister onClick={handleCloseDialog} />;
      case 'introduction':
        return <Introduction handlePageChange={handlePageChange} />;
      case 'root':
        if (session?.user?.memberType && session?.user?.memberType != 'TMP')
          handlePageChange('step2');
        else {
          return <RootRegister handlePageChange={handlePageChange} />;
        }
        break;
      case 'social':
        if (session?.user?.memberType && session?.user?.memberType != 'TMP')
          handlePageChange('step2');
        else {
          return (
            <SocialStep1
              handlePageChange={handlePageChange}
              {...params}
              setSessionExpired={setSessionExpired}
            />
          );
        }
        break;
      case 'step1':
        if (session?.user?.memberType && session?.user?.memberType != 'TMP')
          handlePageChange('step2');
        else if (session?.user?.facebook || session?.user?.google) {
          return (
            <SocialStep1 handlePageChange={handlePageChange} {...params} />
          );
        } else {
          return (
            <Step1
              handlePageChange={handlePageChange}
              setSessionExpired={setSessionExpired}
            />
          );
        }
        break;
      case 'step2':
        if (session)
          return (
            <Step2
              handlePageChange={handlePageChange}
              setSessionExpired={setSessionExpired}
            />
          );
        else setSessionExpired(true);
      case 'step3':
        if (session)
          return (
            <Step3
              handlePageChange={handlePageChange}
              setSessionExpired={setSessionExpired}
            />
          );
        else setSessionExpired(true);
      case 'step4':
        if (session)
          return (
            <Step4
              handlePageChange={handlePageChange}
              setSessionExpired={setSessionExpired}
            />
          );
        else setSessionExpired(true);
      case 'step5':
        if (session)
          return (
            <Step5
              handlePageChange={handlePageChange}
              setSessionExpired={setSessionExpired}
            />
          );
        else setSessionExpired(true);
      case 'step6':
        if (session)
          return (
            <Step6
              handlePageChange={handlePageChange}
              setSessionExpired={setSessionExpired}
            />
          );
        else setSessionExpired(true);
      default:
        return <Introduction handlePageChange={handlePageChange} />;
    }
  };
  return (
    <Container>
      <LeftImageView src={getLeftImage()} alt="" />
      <RightView
        ref={rightViewRef}
        style={{ margin: page == 'step3' ? '0' : 'auto' }}
      >
        <RightViewContainer>{renderPage()}</RightViewContainer>
      </RightView>
      <CloseButton onClick={handleCloseDialog} />
      <Dialog onClose={onCloseSessionExpiredDialog} open={sessionExpired}>
        <div
          style={{
            padding: 80,
            width: 960,
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <CommonText
            whiteSpace="pre-line"
            fontWeight={600}
            fontSize="30px"
            lineHeight="42px"
          >
            {t('signin:register.tmp_account_expired_message')}
          </CommonText>
          <Space size="25px" />
          <img src={FailIcon} alt="" />
          <Space size="25px" />
          <CommonButton
            size="large"
            type={'primary'}
            onClick={onCloseSessionExpiredDialog}
          >
            {t('signin:register.reregister')}
          </CommonButton>
          <CloseButton onClick={onCloseSessionExpiredDialog} />
        </div>
      </Dialog>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
`;
const LeftImageView = styled.img`
  width: 50%;
  object-fit: cover;
  max-width: 950px;
  height: 100%;
  display: block;
  @media ${breakpoint.mobile} {
    display: none;
  }
`;
const RightView = styled.div`
  flex: 1;
  // margin: auto;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
const RightViewContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
