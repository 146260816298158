import React from 'react';
import CommonText from '../typographies/CommonText';
import colors from '../../../utils/colors';

const NavText = (props) => {
  const { theme } = props;
  return (
    <div {...props} style={{ textAlign: 'start', ...props.style }}>
      <div style={{ display: 'flex', marginBottom: 5, textWrap: 'nowrap' }}>
        <CommonText
          fontSize="18px"
          fontWeight="400"
          type="primary"
          color={theme === 'black' ? colors.black : colors.white}
        >
          MIRROR
        </CommonText>
        <CommonText
          fontSize="14px"
          fontWeight="700"
          color={theme === 'black' ? colors.black : colors.white}
          style={{ letterSpacing: '0.23rem', paddingLeft: '0.23rem' }}
        >
          官方歌迷會
        </CommonText>
      </div>
      <CommonText
        fontSize="18px"
        fontWeight="400"
        type="primary"
        color={theme === 'black' ? colors.black : colors.white}
      >
        MIRROR OFFICIAL FAN CLUB
      </CommonText>
    </div>
  );
};

export default NavText;
