import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";

// default: black, 100% width as parent
// Sample Usage:
// <TitleWithCounterBar title="2022" type="activity" number="100" color="green"></TitleWithCounterBar>

function TitleWithCounterBar(props) {
    let {
        title,
        number,
        type,
        color,
    } = props;
    const { t, i18n } = useTranslation(['discography','activity']);

    return (
        <StyledDiv>
            <StyledTitleDiv color={color}>{title}</StyledTitleDiv>
            {type == "activity" && <StyledInfoDiv color={color}>{number} {t('activities', {ns: "activity"})}</StyledInfoDiv>}
            {type == "song" && <StyledInfoDiv color={color}>{number} {t("songs")}</StyledInfoDiv>}
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
`;

const StyledTitleDiv = styled.div`
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    color: ${props => props.color ? props.color : 'black'};
    @media ${breakpoint.desktop} {
        font-size: 30px;
        line-height: 35px;
    }
`;

const StyledInfoDiv = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.color ? props.color : 'black'};
    @media ${breakpoint.desktop} {
        font-size: 20px;
        line-height: 40px;
    }
`;

export default TitleWithCounterBar;
