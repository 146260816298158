import { ResponsiveUI } from "../../../utils/responsive";

import IconLeftArrow from "../../../assets/resource_leftArrow.svg";
import IconRightArrow from "../../../assets/resource_rightArrow.svg";
import styled from "styled-components";

const PrevArrow = ({ handleArrowClick }) => {
  return <ResponsiveUI
    desktop={
      <StyledPrevArrow onClick={(e) => handleArrowClick && handleArrowClick(e, false)}>
        <CommonImage src={IconLeftArrow} />
      </StyledPrevArrow>
    }
    mobile={<div />}
  />
}


const NextArrow = ({ handleArrowClick }) => {
  return <ResponsiveUI
    desktop={
      <StyledNextArrow onClick={(e) => handleArrowClick && handleArrowClick(e, true)}>
        <CommonImage src={IconRightArrow} />
      </StyledNextArrow>
    }
    mobile={<div />}
  />
}

const CommonImage = styled.img`
  user-drag: none;
`
const StyledNextArrow = styled.div`
  top: 50%;
  right: 0;
  position: absolute;
  margin-right: 15px;
  z-index: 100;
  cursor: pointer;
`;

const StyledPrevArrow = styled.div`
  top: 50%;
  left: 0;
  position: absolute;
  margin-left: 15px;
  z-index: 100;
  cursor: pointer;
`;

export { PrevArrow, NextArrow }