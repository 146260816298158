import CommonText from "../typographies/CommonText";
import styled from "styled-components";
import { breakpoint } from "../../../utils/responsive";

export default function TextButton(props) {
    return (
        <StyledText {...props}>{props.children}</StyledText>
    )
}
const StyledText = styled.div`
&& {
    cursor: pointer;
    color: #FF5C00;
    font-family: 'Open Sans';
    font-weight: ${props => props.fontWeight || 400};
    line-height: 20px;
    text-align: center;

    @media ${breakpoint.mobile} {
        font-size: ${(props) =>
          props.mFontSize
            ? props.mFontSize
            : props.fontSize
            ? props.fontSize
            : "14px"};
      }
    
      @media ${breakpoint.desktop} {
        font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
      }
    
      @media ${breakpoint.largeDesktop} {
        font-size: ${(props) =>
          props.largeFontSize
            ? props.largeFontSize
            : props.fontSize
            ? props.fontSize        
            : "16px"};
}
`