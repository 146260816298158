import styled from "styled-components"
import CommonText from "../typographies/CommonText"
import IconArrowBlack from "../../../assets/ic-arrowRight-black.svg"
import IconArrowWhite from "../../../assets/ic-arrowRight-white.svg"
import { breakpoint, ResponsiveUI } from "../../../utils/responsive"
import useTheme from "@mui/system/useTheme"
import colors from "../../../utils/colors"

const ProfileListItem = (props) => {
  const { onClick, highlighted, children } = props
  const theme = useTheme()

  const _renderContent = (isMobile) => {
    return (
      <StyledDiv
        onClick={onClick}
        theme={theme}
        highlighted={highlighted}
        isMobile={isMobile}
        {...props}
      >
        <CommonText
          style={{ color: highlighted ? colors.white : colors.black }}
        >
          {children}
        </CommonText>
        <img src={highlighted ? IconArrowWhite : IconArrowBlack} />
      </StyledDiv>
    )
  }

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  )
}

const StyledDiv = styled.div`
  text-align: left;
  padding: 30px 20px 30px 20px;
  height: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.primary.main
      : "transparent"};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  cursor: pointer;
  width: 100%;

  @media ${breakpoint.desktop} {
    width: ${(props) => props.dWidth};
  }
`

export default ProfileListItem
