import React from 'react';
import styled from 'styled-components';
import CommonLabel from '../labels/CommonLabel';
import CommonSelect from './CommonSelect';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Input, InputLabel } from '@mui/material';

function FormSelect(props) {
  let { helperText, errorText, placeholder, label, required, children } = props;
  return (
    <StyledDiv>
      <StyledLabelDiv>
        <CommonLabel>{label ? label : ''}</CommonLabel>
        {required && <span style={{ color: '#eb0000', marginLeft: 4 }}>*</span>}
      </StyledLabelDiv>
      <StyledFormControl>
        {!props.value && placeholder && (
          <InputLabel disableAnimation shrink={false} focused={false}>
            {placeholder}
          </InputLabel>
        )}
        <CommonSelect {...props} label="">
          {children}
        </CommonSelect>
        <StyledErrorText>{errorText ? errorText : ''}</StyledErrorText>
        <StyledHelperText>{helperText ? helperText : ''}</StyledHelperText>
      </StyledFormControl>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
`;

const StyledLabelDiv = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 8px;
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const StyledErrorText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 0;
    color: #ff5c00;
  }
`;

const StyledHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 0;
  }
`;

export default FormSelect;
