const colors = {
  black: "#111111",
  white: "#F9F9F9",

  black20Alpha: "#11111120",
  black50Alpha: "#11111150",
  black60Alpha: "#11111160",
  white40Alpha: "#F9F9F940",
  white60Alpha: "rgba(249,249,249,0.6)",

  purple: "#4F3FB2",

  lightBrown: "#"
};

export default colors;
