import { useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../../../components/common/buttons/CommonButton';
import {
  HomeMediaImage,
  HomeShopImage,
} from '../../../../components/common/images/ShadowImage';
import HomeTitleGroup from '../../../../components/common/typographies/2025HomeTitleGroup';
import { breakpoint, WindowSize } from '../../../../utils/responsive';
import CurrentItemDescription from '../CurrentItemDescription';
import {
  MirrorShopBackdrop,
  CarouselWrapper,
  StyledHomeCarousel,
} from './styled';
import styled from 'styled-components';
import SliderArrowButton from '../../../../components/common/buttons/SliderArrowButton';

const ProductsContainer = ({ products, idx, afterChange }) => {
  const { t } = useTranslation('home');
  const [hoverIndex, setHoverIndex] = React.useState(-1);
  const isLargerThan1024 = useMediaQuery(breakpoint[1024]);
  const homeMirrorShopImages = products?.map((product, index) =>
    product.link ? (
      <a href={product.link} key={product._id}>
        <HomeShopImage
          src={product.thumbnail}
          onMouseOver={() => {
            setHoverIndex(index);
          }}
          onMouseLeave={() => {
            setHoverIndex(-1);
          }}
        />
      </a>
    ) : (
      <HomeShopImage
        src={product.thumbnail}
        key={product._id}
        onMouseOver={() => {
          setHoverIndex(index);
        }}
        onMouseLeave={() => {
          setHoverIndex(-1);
        }}
      />
    )
  );
  let sliderRef = React.useRef(null);
  return (
    <CarouselWrapper background="#E1E1E1">
      <HomeTitleGroup zhTitle="商店" enTitle="SHOP" />
      <div>
        <CarouselFullWidthWrapper>
          {products.length > 1 && (
            <CarouselControl className="carousel-control">
              <SliderArrowButton
                direction="left"
                onClick={() => {
                  sliderRef.slickPrev();
                }}
                style={{
                  position: 'absolute',
                  left: 20,
                  '&:before': {
                    content: '""',
                  },
                  transform: 'translateY(-50%)',
                }}
              />
              <SliderArrowButton
                direction="right"
                onClick={() => {
                  sliderRef.slickNext();
                }}
                style={{
                  position: 'absolute',
                  right: 20,
                  '&:before': {
                    content: '""',
                  },

                  transform: 'translateY(-50%)',
                }}
              />
            </CarouselControl>
          )}

          <ShopCarousel
            ref={(slider) => {
              sliderRef = slider;
            }}
            setting={{
              slidesToShow: 1,
              centerMode: true,
              arrows: false,
            }}
            afterChange={afterChange}
            fixedBackdrop={
              <MirrorShopBackdrop
                style={{
                  background: 'black',
                  opacity: 0.1,
                }}
                isHovered={hoverIndex === idx}
              />
            }
          >
            {homeMirrorShopImages}
          </ShopCarousel>
        </CarouselFullWidthWrapper>
      </div>
      <CurrentItemDescription arrayOfItem={products} idx={idx} />
      <a href="https://shop.mirrorweare.com" target="_blank" rel="noreferrer">
        <CommonButton type="outlined" style={{ margin: '40px auto' }}>
          {t('button.visit_store')}
        </CommonButton>
      </a>
    </CarouselWrapper>
  );
};

const CarouselControl = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease-out;
`;

const CarouselFullWidthWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // TODO: fix hardcoded + approximated value use to offset the backdrop margin
  margin-top: min(4%, 20px);
  margin-bottom: min(4%, 20px);

  &:hover {
    ${CarouselControl} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const ShopCarousel = styled(StyledHomeCarousel)`
  max-width: calc(
    375px + 2 * var(--image-margin) + 100px
  ); //i don't know why the library have default padding of 100px
  @media ${breakpoint.onlyMobile} {
    min-width: 400px;
  }

  .slick-list {
    margin: 0;
    overflow: visible;
  }
`;

export default ProductsContainer;
