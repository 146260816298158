import styled from "@emotion/styled";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

const StyledButton = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  padding: 8px 0px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  background-color: ${(props) => (props.selected ? "#FF5C00" : "#fff")};
  color: ${(props) => (props.selected ? "#fff" : "#000")};
  @media ${breakpoint.desktop} {
    font-size: 20px;
    min-width: 180px;
  }
  &:hover {
    border: 1px solid rgba(255, 92, 0, 1);
  }
`;

export default StyledButton;
