import React from "react";
import styled from "styled-components";
import SmallTickIcon from "../icons/SmallTickIcon";
import CommonText from "../typographies/CommonText";

const CheckBox = ({
  isChecked,
  onClick,
  children,
  size,
  mFontSize,
  disabled,
  type
}) => {
  return (
    <Container>
      <SmallTickIcon
        isChecked={isChecked}
        onClick={onClick}
        style={{ cursor: disabled ? "not-allowed" : "auto" }}
        size={size}
        type={type}
      />
      <CommonText
        mFontSize={mFontSize || "16px"}
        style={{ marginLeft: "17px", textAlign: "start" }}
      >
        {children}
      </CommonText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export default CheckBox;
