/* eslint-disable react/prop-types */
import { IconButton, useMediaQuery } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { breakpoint, WindowSize } from '../../utils/responsive';
import SliderArrowButton from '../../components/common/buttons/SliderArrowButton';
import CommonCarousel from '../../components/common/carousels/CommonCarousel';
import DiscographyLabel from '../../assets/revamp2025/ic-home-discography-label.png';
import HomeCarousel from '../../components/common/carousels/HomeCarousel';
import HomeStar from '../home/asset/Star';
import { Link, useNavigate } from 'react-router-dom';

/**
 *
 * @param {*} param0
 * @param  {Array} discographies
 * @returns
 */
const DiscographyCarousel = ({
  discographies,
  currentImage,
  setCurrentImage,
}) => {
  const isLargerThan1024 = useMediaQuery(breakpoint[1024]);
  const isMobile = WindowSize() === 'mobile';
  const navigate = useNavigate();

  const BACKDROP_OFFSET_FROM_FIRST_IMAGE = '8%';

  const nextImageIndex = (current) => {
    return (current + 1) % discographies.length;
  };

  const handleChangeToPreviousImage = (e) => {
    e.stopPropagation();
    setCurrentImage((prev) => {
      if (prev === 0) {
        return discographies.length - 1;
      }
      return prev - 1;
    });
  };

  const handleChangeToNextImage = (e) => {
    e.stopPropagation();
    setCurrentImage((prev) => {
      if (prev === discographies.length - 1) {
        return 0;
      }
      return prev + 1;
    });
  };
  const getClassName = (index) => {
    if (index === currentImage) {
      return 'foreground';
    }
    if (index === nextImageIndex(currentImage)) {
      return 'background';
    }
    return 'hidden';
  };
  return (
    <div>
      {isLargerThan1024 ? (
        <Wrapper backdropOffset={BACKDROP_OFFSET_FROM_FIRST_IMAGE}>
          <img
            src={DiscographyLabel}
            alt="discography label"
            style={{
              position: 'absolute',
              width: 200,
              right: -20,
              top: -100,
            }}
          />
          <StyledStar />
          <StyledSlideContainer>
            {discographies?.map((image, index) => {
              return (
                <ImageLink
                  className={`${getClassName(index)} ${index}`}
                  key={image.id ?? index}
                  href={'/discography/' + image?._id}
                >
                  <StackedImages
                    src={image.mvImage}
                    alt={`discography - ${image?.nameEn ?? image?.nameZh}`}
                  ></StackedImages>
                </ImageLink>
              );
            })}
          </StyledSlideContainer>
          <Backdrop className="backdrop" />
          {discographies.length > 1 && (
            <CarouselControl
              className="carousel-control"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/discography/${discographies[currentImage]?._id}`);
              }}
            >
              <SliderArrowButton
                direction="left"
                style={{ transform: 'translateX(-50%)' }}
                onClick={handleChangeToPreviousImage}
              />
              <SliderArrowButton
                direction="right"
                style={{ transform: 'translateX(50%)' }}
                onClick={handleChangeToNextImage}
              />
            </CarouselControl>
          )}
        </Wrapper>
      ) : (
        <HomeCarousel
          style={{ marginTop: 44, maxWidth: '100%' }}
          setting={{ centerMode: true, arrows: false, centerPadding: '27.5px' }}
          afterChange={(index) => setCurrentImage(index)}
        >
          {discographies?.map((image, index) => {
            return (
              <a
                href={'/discography/' + image?._id}
                onMouseUp={(e) => e.preventDefault()}
              >
                <MarginBetweenSlides>
                  <img
                    key={image._id ?? index}
                    src={image.mvImage}
                    alt={`discography - ${image?.nameEn ?? image?.nameZh}`}
                    style={{
                      width: '100%',
                      aspectRatio: '16/9',
                      objectFit: 'cover',
                      borderRadius: isMobile ? 5 : 10,
                    }}
                  />
                </MarginBetweenSlides>
              </a>
            );
          })}
        </HomeCarousel>
      )}
    </div>
  );
};

const MarginBetweenSlides = styled.div`
  margin: 0 10px;
  @media ${breakpoint.desktop} {
    margin: 0 20px;
  }
`;

const StyledStar = styled(HomeStar)`
  position: absolute;
  top: 110%;
  left: -20%;
  @media ${breakpoint.largeDesktop} {
    top: 100%;
`;

const StyledHomeCarouselForMobile = styled(HomeCarousel)``;

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  right: ${(props) => props.backdropOffset};

  width: 100%;

  @media ${breakpoint.desktop} {
    width: 550px;
  }
  @media ${breakpoint.largeDesktop} {
    width: 810px;
  }
  @media ${breakpoint.extraLargeDesktop} {
    width: 1114px;
  }

  .backdrop {
    left: ${(props) => props.backdropOffset};
  }
  :hover {
    .carousel-control {
      opacity: 1;
    }
  }
`;

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  max-height: 453px;
  background-color: #ffa42c;
  position: absolute;
  bottom: -9%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;

const StyledSlideContainer = styled.div`
  perspective: 800px;
  position: relative;
  z-index: 1;
  max-width: 100%;
`;

const ImageLink = styled.a`
  position: absolute;
  top: 0;
  transform-style: preserve-3d;
  display: none;

  @keyframes swap-to-backward {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(12%, 0, -100px);
      filter: brightness(0.5);
    }
  }

  @keyframes swap-to-forward {
    from {
      transform: translate3d(12%, 0, -100px);
      filter: brightness(0.5);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  animation-duration: 300ms;
  animation-timing-function: ease-out;

  &.foreground {
    display: block;
    position: relative;
    animation-name: swap-to-forward;
    transform: translate3d(0, 0, 0);
    z-index: 1;
  }

  &.background {
    display: block;
    animation-name: swap-to-backward;
    pointer-events: none;
    transform: translate3d(12%, 0, -100px);
    filter: brightness(0.5);
  }
`;

const StackedImages = styled.img`
  aspect-ratio: 16/9;
  width: 100%;
  object-fit: cover;
  border-radius: 40px;
  background: url(https://via.placeholder.com/150);
`;

const CarouselControl = styled.div`
  transition: opacity 0.25s ease-out;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  transform: translateY(-50%);
  z-index: 2;
`;
export default DiscographyCarousel;
