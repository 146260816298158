import useMediaQuery from '@mui/material/useMediaQuery';

export const size = {
  onlyMobile: 479,
  tablet: 480,
  smallTablet: 600,
  mobile: 860,
  desktop: 861,
  1024: 1024,
  largeDesktop: 1441,
  extraLargeDesktop: 1920,
};

export const breakpoint = {
  onlyMobile: `(max-width: ${size.onlyMobile}px)`, //only mobile device
  tablet: `(min-width: ${size.tablet}px)`,
  smallTablet: `(max-width: ${size.smallTablet}px)`,
  onlyTablet: `(min-width: ${size.smallTablet + 1}px) and (max-width: ${
    size.desktop
  }px)`,
  1024: `(min-width: 1024px)`,
  mobile: `(max-width: ${size.mobile}px)`, //tablet + mobile device
  desktop: `(min-width: ${size.desktop}px)`,
  largeDesktop: `(min-width: ${size.largeDesktop}px)`,
  extraLargeDesktop: `(min-width: ${size.extraLargeDesktop}px)`,
};

export const ResponsiveUI = ({
  desktop,
  mobile,
  largeDesktop,
  mobileOffset = 0,
  desktopBreakpoint,
}) => {
  const desktopMatchQuery =
    desktopBreakpoint || `(min-width: ${size.desktop + mobileOffset}px)`;
  const matches = useMediaQuery(desktopMatchQuery);
  const matchLarge = useMediaQuery(`(min-width: ${size.largeDesktop}px)`);

  if (matchLarge && largeDesktop) {
    //   console.log("largeDesktop");
    return largeDesktop;
  } else if (matches && desktop) {
    //   console.log("desktop");
    return desktop;
  } else {
    //   console.log("mobile");
    return mobile;
  }
};

export const WindowSize = () => {
  const matches = useMediaQuery(`(min-width: ${size.desktop}px)`);
  const matchLarge = useMediaQuery(`(min-width: ${size.largeDesktop}px)`);
  const match1024 = useMediaQuery(`(min-width: 1024px)`);

  if (matchLarge) {
    return 'largeDesktop';
  } else if (match1024) {
    return '1024';
  } else if (matches) {
    return 'desktop';
  } else {
    return 'mobile';
  }
};
