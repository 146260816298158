import React from 'react';
import styled from 'styled-components';
import CommonImage from '../../components/common/images/CommonImage';
import LiveIndicator from './detail/LiveIndicator';
import CommonText from '../../components/common/typographies/CommonText';
import SelectUndeline from '../../assets/SelectUnderline.svg';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/zh-hk';
import { useTranslation } from 'react-i18next';
import Polygon from '../../assets/Polygon_orange.png';
import { breakpoint, ResponsiveUI } from '../../utils/responsive';
import dayjs from 'dayjs';

// Props(String): type, date, title, alt(for img description)
// Props(Others): src(image src), onClick
// Sample Usage: <ActivityCard type="secondary" date={'2022年4月3日'} title={'mirror 3週年生日會'} src={'https://i.kfs.io/album/global/126322086,1v1/fit/500x500.jpg'} alt="birthday"></ActivityCard>

function ActivityCard({ activity, ...props }) {
  let { type, onClick } = props;
  let navigate = useNavigate();
  const { t, i18n } = useTranslation('activity');

  let liveStart = dayjs(activity.liveStartTime);
  let liveEnd = dayjs(activity.liveEndTime);
  let currentTime = dayjs();
  const isLive =
    activity.isLive && currentTime > liveStart && currentTime < liveEnd;
  const onClickHandler = () => {
    navigate(`./${activity._id}`);
  };

  return (
    <ResponsiveUI
      mobile={
        <div style={{ cursor: 'pointer' }} onClick={onClickHandler}>
          <StyledMainDiv>
            <StyledImageDiv>
              <CommonImage
                style={{ objectFit: 'cover' }}
                height="137px"
                width="320px"
                src={activity.coverImage + '-thumbnail'}
              ></CommonImage>
            </StyledImageDiv>
            <LiveIndicatorContainer>
              <LiveIndicator t={t} isLive={isLive} />
            </LiveIndicatorContainer>
            <StyledInfoDiv>
              <StyledDateDiv>
                <StyledDot src={Polygon}></StyledDot>
                {i18n.language === 'zh' && (
                  <CommonText fontSize="16px">
                    {moment(activity.startTime).locale('zh-hk').format('LLL')}
                  </CommonText>
                )}
                {i18n.language === 'en' && (
                  <CommonText fontSize="16px">
                    {moment(activity.startTime).locale('en').format('LLL')}
                  </CommonText>
                )}
              </StyledDateDiv>

              <StyledTitleDiv>
                {i18n.language === 'zh' ? (
                  <CommonText fontSize="18px" fontWeight="600">
                    {activity.titleZh}
                  </CommonText>
                ) : (
                  <CommonText fontSize="18px" fontWeight="600">
                    {activity.titleEn}
                  </CommonText>
                )}
              </StyledTitleDiv>
            </StyledInfoDiv>
          </StyledMainDiv>
        </div>
      }
      desktop={
        <div>
          <StyledMainDiv>
            <StyledImageDiv>
              <CommonImage
                style={{ objectFit: 'cover' }}
                height={185}
                width={432}
                src={activity.coverImage + '-thumbnail'}
              ></CommonImage>
            </StyledImageDiv>
            <LiveIndicatorContainer>
              <LiveIndicator t={t} isLive={isLive} />
            </LiveIndicatorContainer>
            <StyledInfoDiv>
              <StyledDateDiv>
                <StyledDot src={Polygon}></StyledDot>
                {i18n.language === 'zh' && (
                  <CommonText fontSize="18px">
                    {moment(activity.startTime).locale('zh-hk').format('LLL')}
                  </CommonText>
                )}
                {i18n.language === 'en' && (
                  <CommonText fontSize="18px">
                    {moment(activity.startTime).locale('en').format('LLL')}
                  </CommonText>
                )}
              </StyledDateDiv>

              <StyledTitleDiv>
                {i18n.language === 'zh' ? (
                  <CommonText fontSize="23px" fontWeight="600">
                    {activity.titleZh}
                  </CommonText>
                ) : (
                  <CommonText fontSize="23px" fontWeight="600">
                    {activity.titleEn}
                  </CommonText>
                )}
              </StyledTitleDiv>

              <StyledCheckMore>
                <CommonText
                  fontSize="18px"
                  fontWeight="600"
                  onClick={onClickHandler}
                >
                  {t('checkNow')}
                </CommonText>
              </StyledCheckMore>
            </StyledInfoDiv>
          </StyledMainDiv>
        </div>
      }
      largeDesktop={
        <div>
          <StyledMainDiv>
            <StyledImageDiv>
              <CommonImage
                style={{ objectFit: 'cover' }}
                height={185}
                width={432}
                src={activity.coverImage + '-thumbnail'}
              ></CommonImage>
            </StyledImageDiv>
            <LiveIndicatorContainer>
              <LiveIndicator t={t} isLive={isLive} />
            </LiveIndicatorContainer>
            <StyledInfoDiv>
              <StyledDateDiv>
                <StyledDot src={Polygon}></StyledDot>
                {i18n.language === 'zh' && (
                  <CommonText fontSize="20px">
                    {moment(activity.startTime).locale('zh-hk').format('LLL')}
                  </CommonText>
                )}
                {i18n.language === 'en' && (
                  <CommonText fontSize="20px">
                    {moment(activity.startTime).locale('en').format('LLL')}
                  </CommonText>
                )}
              </StyledDateDiv>

              <StyledTitleDiv>
                {i18n.language === 'zh' ? (
                  <CommonText fontSize="23px" fontWeight="600">
                    {activity.titleZh}
                  </CommonText>
                ) : (
                  <CommonText fontSize="23px" fontWeight="600">
                    {activity.titleEn}
                  </CommonText>
                )}
              </StyledTitleDiv>

              <StyledCheckMore>
                <CommonText
                  fontSize="18px"
                  fontWeight="600"
                  onClick={onClickHandler}
                >
                  {t('checkNow')}
                </CommonText>
              </StyledCheckMore>
            </StyledInfoDiv>
          </StyledMainDiv>
        </div>
      }
    />
  );
}

const StyledMainDiv = styled.div`
  height: auto;
  width: 320px;

  @media ${breakpoint.mobile} {
  }
  @media ${breakpoint.desktop} {
    height: 416px;
    width: 432px;
  }
  @media ${breakpoint.largeDesktop} {
    height: 416px;
    width: 432px;
  }
`;

const StyledInfoDiv = styled.div`
  height: auto;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 29px;
  background: #d1bbff;
  position: relative;
  @media ${breakpoint.mobile} {
    padding: 20px;
  }
  @media ${breakpoint.desktop} {
    height: 227.5px;
    width: 432px;
  }
`;

const StyledDateDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10.42px;
  @media ${breakpoint.desktop} {
    margin-bottom: 22px;
  }
`;

const StyledTitleDiv = styled.div`
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  height: auto;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media ${breakpoint.mobile} {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    height: auto;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const StyledDot = styled.img`
  height: 10px;
  width: 10px;
  margin-right: 10px;
  @media ${breakpoint.desktop} {
    height: 13px;
    width: 13px;
  }
`;

const StyledCheckMore = styled.div`
  padding-bottom: 10px;
  position: absolute;
  left: 31px;
  bottom: 35px;
  cursor: pointer;
  &:hover {
    background-image: url(${SelectUndeline});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
`;

const StyledImageDiv = styled.div`
  height: 137px;
  width: 320px;
  position: relative;
  @media ${breakpoint.desktop} {
    height: 185px;
    width: 432px;
  }
`;

const LiveIndicatorContainer = styled.div`
  position: relative;
  height: 0px;
  top: -20px;
  left: 29px;
  z-index: 1;
  @media ${breakpoint.mobile} {
    top: -15px;
    left: 20px;
  }
`;
export default ActivityCard;
