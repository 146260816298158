import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getDiscographyRelated } from "../../../apis";
import styled from "styled-components";
import DiscographyItem from "../DiscographyItem";
import Loading from "../../../components/common/loading";
import { useTranslation } from "react-i18next";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import TitleGroup from "../../../components/common/typographies/TitleGroup";

export default function MoreSongSection() {
  const { id } = useParams();
  const { isLoading, isError, data, error, refetch } = useQuery(
    ["getDiscographyRelated", id],
    () => getDiscographyRelated(id)
  );
  const { t, i18n } = useTranslation("discography");
  if (isLoading) {
    return <Loading></Loading>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  return (
    data.data?.length >= 1 && (
      <div>
        <StyledTitleDiv>
          <TitleGroup zhTitle="更多歌曲" enTitle="MORE SONGS" color="white" />
        </StyledTitleDiv>

        <StyledItemsDiv>
          {data.data.map((item, index) => {
            return <DiscographyItem key={index} data={item}></DiscographyItem>;
          })}
        </StyledItemsDiv>
      </div>
    )
  );
}

const StyledTitleDiv = styled.div`
  margin-top: 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledItemsDiv = styled.div`
  margin-top: 57px;
  display: grid;
  grid-gap: 15px;
  justify-content: center;
  grid-template-columns: 153px 153px;

  @media ${breakpoint.mobile} {
    grid-template-columns: 1fr 1fr;
    max-width: 640px;
    margin: auto;
    gap: 20px;
  }

  @media ${breakpoint.desktop} {
    padding: 0px 50px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    max-width: 1920px;
  }

`;
