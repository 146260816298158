import { useTranslation } from "react-i18next"
import styled from "styled-components"
import ProfileInfoRow from "../ProfileInfoRow"
import OrderList from "./OrderList"

function OrderInfo(props) {
  const { t } = useTranslation("profile")
  return (
    <StyledMainDiv>
      <ProfileInfoRow title={t("profile:menu.my_order")}>
        <OrderList />
      </ProfileInfoRow>
    </StyledMainDiv>
  )
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default OrderInfo
