import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

// import ActivityCard from "../../activity/ActivityCard";
import { breakpoint } from '../../../utils/responsive';

import { useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-hk';
import Duration from 'dayjs/plugin/duration';
import { useNavigate } from 'react-router-dom';
import Timer from '../../../assets/revamp2025/ic-time.svg';
import CommonButton from '../../../components/common/buttons/CommonButton';
import HomeTitleGroup from '../../../components/common/typographies/2025HomeTitleGroup';
import CommonText from '../../../components/common/typographies/CommonText';
dayjs.extend(Duration);

const FormattedTimeToEnd = ({ endTime }) => {
  const [diff, setDiff] = React.useState({
    days: dayjs(endTime).diff(dayjs(), 'day'),
    hours: dayjs(endTime).diff(dayjs(), 'hour') % 24,
    minutes: dayjs(endTime).diff(dayjs(), 'minute') % 60,
    seconds: dayjs(endTime).diff(dayjs(), 'second') % 60,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setDiff({
        days: dayjs(endTime).diff(dayjs(), 'day'),
        hours: dayjs(endTime).diff(dayjs(), 'hour') % 24,
        minutes: dayjs(endTime).diff(dayjs(), 'minute') % 60,
        seconds: dayjs(endTime).diff(dayjs(), 'second') % 60,
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const { t, i18n } = useTranslation('activity');

  const days = diff.days.toString();
  const hours = diff.hours.toString();
  const minutes = diff.minutes.toString().padStart(2, '0');
  const seconds = diff.seconds.toString().padStart(2, '0');

  return (
    <div style={{ display: 'flex' }}>
      <img
        src={Timer}
        alt="timer"
        style={{ marginRight: 5, display: 'inline' }}
      />
      <CommonText fontWeight="400">
        <Trans
          t={t}
          i18nKey="activity:enroll_expired_format"
          components={{
            expiresIn: <span style={{ fontWeight: 700, fontSize: 16 }}></span>,

            timeSeparator: (
              <span
                style={{
                  fontWeight: 700,
                  opacity: 0.5,
                  fontSize: 13,
                }}
              ></span>
            ),
          }}
          values={{
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
          }}
        />
      </CommonText>
    </div>
  );
};

const ActivityItem = ({ activity, isLast }) => {
  const { t, i18n } = useTranslation(['home', 'activity']);
  const navigate = useNavigate();

  const isLiveOngoing =
    dayjs().isAfter(dayjs(activity.liveStartTime)) &&
    dayjs().isBefore(dayjs(activity.liveEndTime));
  const isAbleToEnroll =
    !activity.isLive &&
    activity.applicationForm &&
    dayjs(activity.endTime).isAfter(dayjs());
  const hasCategoryName = activity.eventCategoryZh || activity.eventCategoryEn;
  // const enrolEndTimeDuration = dayjs.duration(
  //   dayjs(activity.endTime).diff(dayjs())
  // );

  return (
    <StyledActivityItemContainer isLast={isLast}>
      <StyledActivityItem>
        <StyledCoverImage
          src={activity.coverImage}
          alt="activity"
          onClick={() => navigate(`/activity/${activity._id}`)}
        />

        <Description onClick={() => navigate(`/activity/${activity._id}`)}>
          <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
            <CommonText
              fontSize="16px"
              style={{ opacity: 0.7 }}
              fontWeight="500"
            >
              {dayjs(activity.startTime).format(
                i18n.language == 'zh' ? 'YYYY年M月D日' : 'D MMMM YYYY'
              )}
            </CommonText>
          </div>
          <CommonText
            mFontSize="16px"
            fontSize="20px"
            fontSizeFor1024="16px"
            largeFontSize="20px"
            className="title"
          >
            {i18n.language === 'zh-HK'
              ? activity.titleZh
              : activity.titleEn || activity.titleZh}
          </CommonText>
          <ActivityItemAttribute>
            {hasCategoryName && (
              <StyledCommonText
                mFontSize="18px"
                fontSize="24px"
                fontSizeFor1024="18px"
                largeFontSize="24px"
                fontWeight="700"
                lineHeight="1.25em"
                style={{ paddingRight: 18 }}
              >
                {i18n.language === 'en'
                  ? activity.eventCategoryEn
                  : activity.eventCategoryZh}
              </StyledCommonText>
            )}

            {activity.isLive ? (
              <StyledCommonText
                fontSize="16px"
                style={{
                  opacity: 0.8,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 700,
                }}
              >
                {isLiveOngoing && <LiveDot />}
                {isLiveOngoing && t('activity:live')}
              </StyledCommonText>
            ) : (
              isAbleToEnroll && (
                <StyledCommonText fontSize="16px">
                  <FormattedTimeToEnd endTime={activity.endTime} />
                </StyledCommonText>
              )
            )}
          </ActivityItemAttribute>
        </Description>
      </StyledActivityItem>
    </StyledActivityItemContainer>
  );
};

function ActivitySession({ activities }) {
  const { t, i18n } = useTranslation('home');
  const isLargerThan1024 = useMediaQuery(breakpoint[1024]);
  const navigate = useNavigate();

  // 3day before startTime

  return (
    <Container>
      <TitleGroupContainer>
        <HomeTitleGroup zhTitle="特別活動" enTitle="Activities" />
      </TitleGroupContainer>
      <div>
        {activities && activities.map((activity, index) => {
          return (
            <ActivityItem
              activity={activity}
              index={index}
              isLast={index === activities.length - 1}
              key={activity._id}
            />
          );
        })}

        <CommonButton type="outlined" onClick={() => navigate('/activity')}>
          {t('home:button.allActivity')}
        </CommonButton>
      </div>
    </Container>
  );
}

const LiveDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: red;
  display: inline-block;
  margin-right: 5px;
`;

const StyledCommonText = styled(CommonText)`
  display: inline-block;
`;

const Container = styled.div`
  flex: 1;

  @media ${breakpoint[1024]} {
    min-width: 620px;
  }
  @media ${breakpoint.largeDesktop} {
    min-width: 820px;
  }
  @media ${breakpoint.extraLargeDesktop} {
    min-width: 1150px;
  }
`;

const TitleGroupContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  @media ${breakpoint[1024]} {
    justify-content: start;
    padding: 0;
  }
`;

const StyledActivityItemContainer = styled.div`
  border-bottom: ${(props) => !props.isLast && '1px solid #00000033'};
`;

const StyledActivityItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: start;

  @media ${breakpoint.desktop} {
    flex-direction: row;
    gap: 20px;
    padding: 20px;
  }

  &:hover {
    background: #ffffff80;
    border-radius: 20px;
  }

  @media ${breakpoint.mobile} {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
  }

  @media ${breakpoint.extraLargeDesktop} {
    padding: 46px 20px;
  }
`;

const StyledCoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 21/9;
  border-radius: 5px;
  cursor: pointer;
  @media ${breakpoint.desktop} {
    max-width: 240px;
  }
  @media ${breakpoint[1024]} {
    max-width: 140px;
  }
  @media ${breakpoint.largeDesktop} {
    max-width: 240px;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  cursor: pointer;

  .title {
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

const ActivityItemAttribute = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    gap: 16px;
    align-items: start;
  }

  > div:nth-child(n + 2) {
    @media ${breakpoint.desktop} {
      border-left: 1px solid #00000033;
      padding-left: 26px;
    }
  }
`;

export default ActivitySession;
