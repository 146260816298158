import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';

// req: name
// Sample Usage:
// <ProfileButton name="kris"></ProfileButton>

function ProfileButton(props) {
  let navigate = useNavigate();
  let { name, onClick, blackTheme, handleClose } = props;

  function handleNavigateToProfile() {
    navigate('/profile');
  }

  const _renderContent = (isMobile) => (
    <StyledDiv
      onClick={
        isMobile
          ? () => {
              handleNavigateToProfile();
              handleClose && handleClose();
            }
          : onClick
      }
      blackTheme={blackTheme}
    >
      <StyledLetterDiv blackTheme={blackTheme}>
        {name ? name[0] : ''}
      </StyledLetterDiv>
    </StyledDiv>
  );

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  );
}

const StyledDiv = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #ff5c00;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledLetterDiv = styled.div`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  @media ${breakpoint.desktop} {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
`;

export default ProfileButton;
