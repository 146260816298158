import React from 'react';
import NavBlackLogo from '../../../assets/nav_blackLogo.svg';
import NavWhiteLogo from '../../../assets/nav_whiteLogo.svg';
import CommonText from '../typographies/CommonText';
import { breakpoint } from '../../../utils/responsive';
import styled from '@emotion/styled';
import { useMediaQuery, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import NavText from './NavText';

const NavbarLogo = ({ theme }) => {
  const shouldShowText = useMediaQuery('(min-width: 1400px)');
  return (
    <Link to={'/'} style={{ textDecoration: 'none', display: 'flex' }}>
      <StyledLogo
        src={theme === 'black' ? NavBlackLogo : NavWhiteLogo}
        style={{ cursor: 'pointer' }}
      ></StyledLogo>
      {shouldShowText && (
        <NavText style={{ paddingLeft: 15, margin: 'auto' }} theme={theme} />
      )}
    </Link>
  );
};

const StyledLogo = styled.img`
  // position: absolute;
  // position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 76px;
  margin-top: ${(props) => (props.openBanner ? '78px' : '0px')};

  @media ${breakpoint.mobile} {
    height: 44px;
    margin-top: ${(props) => (props.openBanner ? '50px' : '0px')};
  }

  @media ${breakpoint.desktop} {
    height: 60px;
  }
`;

export default NavbarLogo;
