import { useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import DownloadIcon from "../../assets/download.svg";
import IconClose from "../../assets/icon_close.svg";
import CommonText from "../../components/common/typographies/CommonText";
import { breakpoint } from "../../utils/responsive";
import { NextArrow, PrevArrow } from "./Lightbox/Arrow";
import LightboxAnimationWrapper from "./Lightbox/LightboxAnimationWrapper";
import Thumbnail from "./Lightbox/Thumbnail";
import AudioDisplay from "./MediaItem/Audio";
import VideoDisplay from "./MediaItem/Video";
const MediaLightbox = ({
  data,
  lightboxOpen,
  initialIndex,
  setLightboxOpen,
  handleImageDownload,
}) => {
  const { t, i18n } = useTranslation("mediaSeriesDetail");
  const [slide, setSlide] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const selfRef = useRef();
  const AudioRef = useRef({});
  const isDesktop = useMediaQuery(breakpoint.desktop);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


  const mapDataToSlide = () => {
    return (
      data &&
      data.map((d, idx) => ({
        src: d.thumbnail,
        imageFit: "contain",
        index: idx,
        originalItem: d,
      }))
    );
  };

  useEffect(() => {
    console.log('rerender')
    setSlide(mapDataToSlide());
    return () => { setSlide() }
  }, [data]);




  const handleOnFirstPlayMedia = (item, isFirstPlay) => {
    if (isFirstPlay) {
      ReactGA.event(item.type === "AUDIO" ? "audio_start" : "video_start", {
        video_title: item.filename,
      });
    }
  };

  const handleLightboxClose = () => {
    setLightboxOpen(false)
    setCurrentIndex(null)
  }

  const _renderDownloadButton = () => {
    return <DownloadButton
      type={slide[currentIndex] && slide[currentIndex].originalItem.type}
      onClick={() => {
        handleImageDownload(currentIndex);
      }}
    >
      <CommonImage src={DownloadIcon} />
      <CommonText
        style={{ zIndex: 50, marginLeft: 5 }}
        fontSize="16px"
        fontWeight="600"
        lineHeight="200%"
        color="#fff"
      >
        {t("download")}
      </CommonText>
    </DownloadButton>
  }
  const thumbnailStyle = {
    vignette: false,
    imageFit: "cover",
    padding: 0,
    border: 0,
    gap: 0,
    width: isDesktop ? 80 : 70,
    height: isDesktop ? 80 : 70,
  };


  const _renderItems = (item, idx) => {
    // reuse original lightbox
    const type = item.type;
    switch (type) {
      case "VIDEO":
      case "STREAM":
        return (
          <StyledVideo
          >
            {/* <div style={{ margin: "auto", maxHeight: "100%", minHeight: 0 }}>
              <VideoDisplay
                item={item}
                handleOnPlay={(isFirstPlay) =>
                  handleOnFirstPlayMedia(item, isFirstPlay)
                }
              />
            </div> */}
            <VideoDisplay
              item={item}
              isSelected = {currentIndex === idx}
              handleOnPlay={(isFirstPlay) =>
                handleOnFirstPlayMedia(item, isFirstPlay)
              }
            />

            <CommonText
              fontSize="16px"
              lineHeight="19.2px"
              fontWeight="600"
              color="#fff"
              textAlign="center"
              style={{
                width: "100%",
                padding: "0px 20px",
                textShadow: "0px 4px 14px rgba(0, 0, 0, 0.6)",
              }}
            >
              {i18n.language === "zh" ? item.captionZh : item.captionEn}
            </CommonText>
          </StyledVideo>
        );
      case "AUDIO":
        return (
          <SliderAudio>
            <AudioDisplay
              value={item}
              idx={idx}
              audioRef={AudioRef}
              handleOnPlay={(idx, isFirstPlay) =>
                handleOnFirstPlayMedia(item, isFirstPlay)
              }
            />
          </SliderAudio>
        );
      case "VISUAL":
        return (
          <StyledVisual>
            <SliderImage src={item.thumbnail + "-thumbnail"} />
            <CommonText
              fontSize="16px"
              lineHeight="19.2px"
              fontWeight="600"
              color="#fff"
              textAlign="center"
              style={{
                width: "100%",
                padding: "0px 20px",
                textShadow: "0px 4px 14px rgba(0, 0, 0, 0.6)",
              }}
            >
              {i18n.language === "zh" ? item.captionZh : item.captionEn}
            </CommonText>
          </StyledVisual>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div ref={selfRef} style={{ position: 'relative', zIndex: 1 }}></div>
      <LightboxAnimationWrapper
        isCustomAnimated={true}
        renderItem={(animation, injectedPlugin, controller, { handleArrowClick, handleThumbnailClick }) => {
          return <StyledLightBox
            controller={controller}
            open={lightboxOpen !== false}
            index={initialIndex ?? 0}
            slides={slide}
            carousel={{ preload: 16, finite: true }} // to fill the whole thumbnail track on a 4k screen
            thumbnails={thumbnailStyle}
            plugins={[Thumbnails, ...injectedPlugin]}
            close={handleLightboxClose}
            portal={{ root: selfRef.current }}
            on={{
              view: ({ index }) => {
                setCurrentIndex(index)
                window.document
                  .querySelectorAll("video")
                  .forEach((video) => video.pause());
                window.document
                  .querySelectorAll("audio")
                  .forEach((audio) => audio.pause());
              },
            }}
            render={{
              slide: ({ slide }) => {
                return _renderItems(slide.originalItem, slide.index);
              },
              thumbnail: ({ slide, render }) => {
                return <Thumbnail item={slide.originalItem} index={slide.index} onClick={handleThumbnailClick} />
              },
              controls: () => _renderDownloadButton(),
              buttonPrev: (slide?.length <= 1 || currentIndex === 0) ? () => null : undefined,
              buttonNext: (slide?.length <= 1 || currentIndex === slide?.length - 1) ? () => null : undefined,
              iconPrev: () => <PrevArrow handleArrowClick={handleArrowClick} />,
              iconNext: () => <NextArrow handleArrowClick={handleArrowClick} />,
              iconClose: () => {
                return (
                  <CloseButton>
                    <CloseButtonIcon src={IconClose} />
                  </CloseButton>
                );
              },
            }}
            animation={animation}
            isSafari={isSafari}
          />
        }}
      >

      </LightboxAnimationWrapper>

    </>
  );
};

const StyledLightBox = styled(Lightbox)`
  --yarl__color_backdrop: none;
  --yarl__container_background_color: ${(props) =>
    props.isSafari ? "rgba(0, 0, 0, 0.85)" : "rgba(0, 0, 0, 0.7)"};
  --yarl__thumbnails_container_background_color: ${(props) =>
    props.isSafari ? "rgba(0, 0, 0, 0.85)" : "rgba(0, 0, 0, 0.7)"};

  --yarl__toolbar_padding: 0;

  .yarl__toolbar{
    .yarl__button{
      filter: none;
    }
  }

  .yarl__slide{
    padding-bottom: 0;
  }
  

  .yarl__thumbnails {
    backdrop-filter: ${(props) => !props.isSafari && "blur(20px)"};
  }

  .yarl__thumbnails_thumbnail {
    background: transparent;
  }

  .yarl__thumbnails_track {
    .yarl__thumbnails_thumbnail_fadein,
    yarl__thumbnails_thumbnail_placeholder,
    .yarl__thumbnails_thumbnail_fadeout {
      a {
        opacity: 0.5;
      }
    }

    .yarl__thumbnails_thumbnail_active {
      a {
        opacity: 1;
        > img {
          border: 3px white solid;
        }
      }
    }
  }
`;

const StyledVisual = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
const StyledVideo = styled.div`
  display: flex;
  flex-direction: column;
  object-fit: contain;
  width: auto;
  height: 100%;

  @media ${breakpoint.mobile} {
    width: 100%;
  }
  @media ${breakpoint.desktop} {
    width: 70%;
  }


  video {
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;
const SliderAudio = styled.div`
  margin: auto;

  @media ${breakpoint.mobile} {
    width: 100%;
    padding: 0 20px;
  }

  @media ${breakpoint.desktop} {
    width: 780px;
  }
`;





const CommonImage = styled.img`
  user-drag: none;
`;



const SliderImage = styled.img`
  user-drag: none;
  pointer-events: none;
  -webkit-touch-callout: none;

  object-fit: contain;
  margin: auto;
  max-width: 100%;
  min-height: 0;
  flex: 1;
`;

const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  
  position: absolute;
  right: 30px;
  top: 30px;


  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  z-index: 100;
`;

const CloseButtonIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const DownloadButton = styled.div`
  width: auto;
  height: 40px;
  padding: 12.5px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);

  position: absolute;
  left: 30px;
  top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  white-space: nowrap;
  cursor: pointer;

  opacity: ${(props) => props.type === 'VISUAL' ? 1 : 0};
  pointer-events: ${(props) => props.type !== 'VISUAL' && 'none'};
  transition: 200ms;

`;

export default MediaLightbox;
