import { useTranslation } from "react-i18next";
import constants from "../../constants";
import PaymentStep from "./PaymentStep";

export default function Step6({ handlePageChange, setSessionExpired }) {
  return (
    <PaymentStep
      step=" 6/6"
      price={constants.MEMBER_PRICE_PER_YEAR}
      handlePageChange={() => handlePageChange("step5")}
    />
  );
}
