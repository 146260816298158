import { useState } from "react";
import styled from "styled-components";
import IconArrowDown from "../../../assets/ic-arrowDown.svg";
import IconArrowUp from "../../../assets/ic-arrowUp.svg";
import CommonText from "../typographies/CommonText";
import { breakpoint } from "../../../utils/responsive";
import colors from "../../../utils/colors";

const DropdownCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderClose = () => {
    return (
      <StyledDiv isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} {...props}>
        <CommonText color={colors.white}>{props.title}</CommonText>
        <img src={IconArrowDown} />
      </StyledDiv>
    );
  };

  const renderOpen = () => {
    return (
      <StyledDiv isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} {...props}>
        <StyledTitle>
          <CommonText color={colors.white} fontWeight="600">{props.title}</CommonText>
          <img src={IconArrowUp} />
        </StyledTitle>
        <CommonText color={colors.white}>{props.content}</CommonText>
      </StyledDiv>
    );
  };

  return isOpen ? renderOpen() : renderClose();
};

const StyledDiv = styled.div`
  text-align: left;
  padding: 20px;
  height: auto;
  width: ${(props) => props.mWidth};
  background: ${(props) => (props.isOpen ? colors.black50Alpha : colors.black20Alpha )};
  opacity: 0.8;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.isOpen ? "column" : "row")};
  cursor: pointer;
  margin-bottom: 15px;

  @media ${breakpoint.desktop} {
    width: 460px;
  }

  @media ${breakpoint.largeDesktop} {
    width: 560px;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export default DropdownCard;
