import React, { useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonText from "../common/typographies/CommonText";
import SelectUndeline from "../../assets/memProfile_underlineIcon.svg";
import colors from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { breakpoint } from "../../utils/responsive";
import _ from "lodash";

const MemberCarousel = ({ images, data, gotoSlick, handleClick }) => {
  const { t } = useTranslation("memberProfile");
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(null);
  const [animate, setAnimate] = useState(true);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    variableWidth: true,
    centerMode: true,
    arrows: false,
    afterChange: (current) => setCurrentSlide(current),
  };

  useEffect(() => {
    if (typeof gotoSlick == "number") {
      sliderRef.current.slickGoTo(gotoSlick);
      setCurrentSlide(gotoSlick);
    }
  }, [gotoSlick]);

  useEffect(() => {
    if (
      typeof gotoSlick == "number" &&
      currentSlide !== gotoSlick &&
      currentSlide !== null
    ) {
      setAnimate(false);
    }
  }, [currentSlide]);

  const handleSlideClick = (e, slide) => {
    e.stopPropagation();
    if (typeof slide == "number") {
      sliderRef.current.slickGoTo(slide);
    }
  }

  return (
    <div>
      <StyledSlider {...settings} ref={sliderRef}>
        {Object.values(images).map((image, index) => {
          if (index === currentSlide) {
            return (
              <StyledMainDiv>
                <StyledDiv>
                  <NickNameDiv>
                    <CommonText fontSize="8.854vw" color="#F27845" uppercase>
                      {data && data[index].memberNameEn.split(" ")[0]}
                    </CommonText>
                  </NickNameDiv>
                  <LastNameDiv>
                    <CommonText fontSize="8.854vw" color="#F27845" uppercase>
                      {data && data[index].memberNameEn.split(" ")[1]}
                    </CommonText>
                  </LastNameDiv>

{/* <ImageDiv> */}

                  <StyledCurrentImg src={image} animate={animate} />
{/* </ImageDiv> */}
                </StyledDiv>

                <StyledViewNow>
                  <div
                    style={{ width: 100 }}
                    onClick={() => handleClick(index)}
                  >
                    <CommonText
                      fontSize="18px"
                      fontWeight="600"
                      color={colors.white}
                    >
                      {t("button.viewNow")}
                    </CommonText>
                    <StyledSelectImg src={SelectUndeline} />
                  </div>
                </StyledViewNow>
              </StyledMainDiv>
            );
          } else {
            return (
              <StyledMainDiv>
                <StyledImg src={image} onClick={(event) => handleSlideClick(event, index)}/>
              </StyledMainDiv>
            );
          }
        })}
      </StyledSlider>
    </div>
  );
};

const StyledImg = styled.img`
  padding: 0 15px;
  height: 47vh;
  filter: grayscale(100%);
  mix-blend-mode: overlay;
  object-fit: cover;
  cursor: ${(props) => (props.onClick ? "pointer" : "unset")};
`;

// const ImageDiv = styled.div`

// // animation-name: ${(props) => (props.animate ? "cropandfull" : "")};
// animation-name: cropandfull;
// animation-duration: 1.5s;
// animation-fill-mode: forwards;
// animation-timing-function: ease-in-out;
// // // width: 100%;

// @keyframes cropandfull {
//   0% {
//     transform: scaleX(0.2); 
//   }
//   100% {
//     transform: scaleX(1); 
//   }
// }
// `

const StyledCurrentImg = styled.img`
  padding: 0 15px;
  height: 47vh;
  object-fit: cover;
  cursor: ${(props) => (props.onClick ? "pointer" : "unset")};

  // width: 200px;
`;

const StyledMainDiv = styled.div`
  position: relative;
  height: calc(47vh + 50px);
  // width: 100%;


`;

const StyledDiv = styled.div`
  position: relative;
`;

const NickNameDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(-60%);
  z-index: 10;
  opacity: 0;

  animation-name: showUp;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  @keyframes showUp {
    0% {
      top: 30px;
      opacity: 0;
    }
    100% {
      top: 0px;
      opacity: 1;
    }
  }
`;

const LastNameDiv = styled.div`
  position: absolute;
  bottom: 30px;
  right: 0px;
  transform: translate(50%);
  z-index: 10;
  opacity: 0;

  animation-name: showUp;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  @keyframes showUp {
    0% {
      bottom: 30px;
      opacity: 0;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }
`;

const StyledSelectImg = styled.img`
  visibility: hidden;
`;

const StyledViewNow = styled.div`
  padding-top: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  &:hover {
    ${StyledSelectImg} {
      visibility: visible;
    }
  }

  animation-name: showUp;
  animation-duration: 1s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  @keyframes showUp {
    0% {
      padding-top: 30px;
      opacity: 0;
    }
    100% {
      padding-top: 15px;
      opacity: 1;
    }
  }
`;

const StyledSlider = styled(Slider)`
  .slick-slide {
    opacity: 0.3;
    transition: all 0.5s;

  }
  .slick-center {
    opacity: 1;

    animation-name: cropandfull;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    
    @keyframes cropandfull {
      0% {
        transform: scaleX(0.2); 
      }
      100% {
        transform: scaleX(1); 
      }
    }

`;

export default MemberCarousel;
