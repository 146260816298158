import styled from 'styled-components';
import CommonButton from '../../components/common/buttons/CommonButton';
import CommonText from '../../components/common/typographies/CommonText';
import Space from '../../components/common/Space';
import { useTranslation, Trans } from 'react-i18next';
import AddressInputForm from '../../components/AddressInputForm';
import { Link } from 'react-router-dom';
import NoticeIcon from '../../assets/register_notice.svg';
import { useEffect, useState } from 'react';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
// import { types } from "../../components/AddressInputForm";
import _ from 'lodash';
import { breakpoint } from '../../utils/responsive';
import { formatAddress } from '../../utils/addressFormat';
import { getOrderCounter } from '../../apis';
import { useQuery } from 'react-query';
import moment from 'moment';
import TickIcon from '../../components/common/icons/TickIcon';
import SmallTickIcon from '../../components/common/icons/SmallTickIcon';
import CloseButton from '../../components/common/buttons/CloseButton';

function insert(str, index, value) {
  return str.substr(0, index) + value + str.substr(index);
}

const DeliveryForm = ({ step, session, handleNext, address, setAddress }) => {
  const { t, i18n } = useTranslation(['signin', 'common']);
  const [disableChecker, setDisableChecker] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [optin, setOptin] = useState(true);

  const { isLoading, isError, data } = useQuery(
    ['getOrderCounter', session?.accessToken],
    () => getOrderCounter(session?.accessToken)
  );

  const deliveryDateOptions = data?.data;

  // useEffect(() => {
  //   if (!optin) setDisableChecker(false);
  //   else setDisableChecker(true);
  // }, [optin]);

  return (
    <Container>
      <CommonText lineHeight="140%">{t('register.step') + step}</CommonText>
      <Space size="30px" />
      <CommonText
        style={{ alignSelf: 'start' }}
        fontSize="20px"
        mFontSize="20px"
      >
        {t('register.delivery_option')}
      </CommonText>
      <OptinContainer>
        <CommonButton
          style={{
            flexGrow: 1,
            borderRadius: 0,
            justifyContent: 'start',
            padding: '0px 12px',
            borderColor: optin ? '#FF5C00' : '#E2E2E2',
          }}
          noMaxWidth
          disableRipple
          onClick={() => {
            setOptin(true);
          }}
        >
          <OptButtonContainer>
            <SmallTickIcon type="gray" isChecked={optin} />
            <CommonText fontSize="18px" mFontSize="18px">
              {t('register.opt_in_delivery')}
            </CommonText>
          </OptButtonContainer>
        </CommonButton>
        <CommonButton
          style={{
            flexGrow: 1,
            borderRadius: 0,
            justifyContent: 'start',
            padding: '0px 12px',
            borderColor: !optin ? '#FF5C00' : '#E2E2E2',
          }}
          noMaxWidth
          disableRipple
          onClick={() => {
            setOptin(false);
          }}
        >
          <OptButtonContainer>
            <SmallTickIcon type="gray" isChecked={!optin} />
            <CommonText fontSize="18px" mFontSize="18px">
              {t('register.opt_out_delivery')}
            </CommonText>
          </OptButtonContainer>
        </CommonButton>
      </OptinContainer>
      <Space size="30px" />
      {optin ? (
        <DeliveryAddress
          step={step}
          setDisableChecker={setDisableChecker}
          session={session}
          address={address}
          setAddress={setAddress}
        />
      ) : (
        <>
          <CommonText
            style={{
              width: '100%',
              padding: '20px 16px',
              backgroundColor: '#F2F2F2',
              borderStyle: 'groove',
              borderWidth: '1px',
              borderColor: '#E2E2E2',
            }}
            fontSize="16px"
            mFontSize="16px"
          >
            {t('register.opt_out_delivery_remark')}
          </CommonText>
          <Space size="30px" />
        </>
      )}

      <Dialog open={isOpen} fullScreen={fullScreen}>
        <DialogContainer>
          {fullScreen && (
            <div>
              <Space size="70px" />
              <CloseButton
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            </div>
          )}
          <CommonText textAlign="center" style={{ whiteSpace: 'pre-line' }}>
            {t('register.address_remark')}
          </CommonText>

          <Space size="30px" />
          <CommonText fontSize="14px">{t('common:address.type')}</CommonText>
          <CommonText fontSize="18px" mFontSize="16px">
            {t('common:address.' + address?.type)}
          </CommonText>
          <Space size="20px" />
          <CommonText fontSize="14px">{t('common:address.date')}</CommonText>
          <CommonText fontSize="18px" mFontSize="16px">
            {deliveryDateOptions &&
            deliveryDateOptions.length > 0 &&
            address?.date
              ? deliveryDateOptions.find((x) => x._id === address?.date)[
                  `deliveryDate${i18n.language === 'zh' ? 'Zh' : 'En'}`
                ]
              : ''}
          </CommonText>
          <Space size="20px" />
          <CommonText fontSize="14px">
            {t('common:address.Express_address')}
          </CommonText>
          <CommonText fontSize="18px" mFontSize="16px">
            {formatAddress(address, i18n.language)}
          </CommonText>
          <Space size="20px" />
          <CommonText fontSize="14px">{t('common:address.name')}</CommonText>
          <CommonText fontSize="18px" mFontSize="16px">
            {address?.name}
          </CommonText>
          <Space size="20px" />
          <CommonText fontSize="14px">{t('common:address.phone')}</CommonText>
          <CommonText fontSize="18px" mFontSize="16px">
            {address?.phone && insert(address?.phone, 4, '')}
          </CommonText>

          <Space size="40px" />
          <ButtonContainer>
            <CommonButton
              style={{ width: '50%', maxWidth: 440 }}
              onClick={() => setIsOpen(false)}
            >
              {t('common:update')}
            </CommonButton>
            <CommonButton
              style={{ width: '50%', maxWidth: 440, marginLeft: 30 }}
              type={'primary'}
              onClick={() => handleNext(false)}
            >
              {t('common:confirm')}
            </CommonButton>
          </ButtonContainer>
        </DialogContainer>
      </Dialog>

      <ButtonContainer>
        <CommonButton
          style={{ width: '50%', maxWidth: 440 }}
          onClick={() => handleNext(true)}
        >
          {t('register.previous')}
        </CommonButton>
        <CommonButton
          style={{ width: '50%', maxWidth: 440, marginLeft: 30 }}
          type={'primary'}
          disabled={optin ? disableChecker : false}
          onClick={() => (optin ? setIsOpen(true) : handleNext(false, false))}
        >
          {t('register.next')}
        </CommonButton>
      </ButtonContainer>
    </Container>
  );
};

const DeliveryAddress = ({ setDisableChecker, address, setAddress }) => {
  const { t, i18n } = useTranslation(['signin', 'common']);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DeliveryAddressContainer>
      <CommonText
        fontSize="20px"
        mFontSize="16px"
        lineHeight="140%"
        whiteSpace="pre-line"
      >
        {t('register.fill_in_address_1')}
        <b>{t('register.fill_in_address_2')}</b>
        {t('register.fill_in_address_3')}
      </CommonText>
      <Space size="30px" />
      <AddressInputForm
        address={address}
        onChange={(address) => setAddress(address)}
        setDisableChecker={setDisableChecker}
      />

      <Space size="30px" />
      <RemarkContainer>
        <RemarkOne>
          <QuotationMarkImg src={NoticeIcon} />
          <div style={{ marginLeft: 5 }}>
            <CommonText fontSize="18px" mFontSize="16px">
              <Trans
                t={t}
                i18nKey="common:address.sf_delivery_remark1"
                components={
                  <a
                    style={{
                      color: '#FF5C00',
                      whiteSpace: 'nowrap',
                      fontWeight: 600,
                      textDecoration: 'none',
                    }}
                    key={0}
                    target="_blank"
                    href={
                      i18n.language === 'zh'
                        ? 'https://htm.sf-express.com/hk/tc/dynamic_function/range/'
                        : 'https://htm.sf-express.com/hk/en/dynamic_function/range/'
                    }
                  />
                }
              />
            </CommonText>
          </div>
        </RemarkOne>
        <Space size="12px" />
        <CommonText fontSize="16px" mFontSize="14px">
          {t('common:address.sf_delivery_remark2')}
        </CommonText>
        <Space size="12px" />
        <CommonText fontSize="16px" mFontSize="14px">
          {t('common:address.sf_delivery_remark3')}
        </CommonText>
      </RemarkContainer>

      <Space size="40px" />
    </DeliveryAddressContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  padding: 100px 20px;
  align-items: center;
`;

const DeliveryAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const QuotationMarkImg = styled.img`
  margin-top: 4px;

  @media ${breakpoint.mobile} {
    margin-top: 3px;
  }
`;

const OptinContainer = styled.div`
  width: 100%;
  padding: 10px 0px;
  display: flex;
  gap: 8px;
`;

const OptButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const RemarkContainer = styled.div`
  background: #f2f2f2;
  border: 1px solid #e2e2e2;
  text-align: left;
  padding: 20px 16px;
`;

const RemarkOne = styled.div`
  display: flex;
  align-items: flex-start;
`;

const DialogContainer = styled.div`
  @media ${breakpoint.desktop} {
    padding: 60px;
  }

  @media ${breakpoint.mobile} {
    padding: 20px;
  }
`;

export default DeliveryForm;
