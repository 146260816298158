import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import CommonButton from '../../components/common/buttons/CommonButton';
import FacebookIcon from '../../assets/login-facebook-icon.svg';
import { socialLogin } from '../../apis';
import { AppContext } from '../../AppContext';
import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../../constants';
import ReactGA from 'react-ga4';

export default function FacebookSignin({ login = false, handlePageChange }) {
  const { t } = useTranslation('signin');
  const { setSession } = useContext(AppContext);
  let navigate = useNavigate();
  const responseFacebook = async (response) => {
    // call api facebook token
    console.log('fb login success', response);
    if (response.accessToken) {
      const result = await socialLogin('facebook', response.accessToken);
      console.log(result);
      if (result.success) {
        localStorage.setItem('session', JSON.stringify(result.data));
        setSession(result.data);
        if (result.data.user.memberType == 'TMP') {
          navigate('/miro-register', {
            replace: true,
            state: { page: 'step1' },
          });
        } else {
          ReactGA.event('login', {
            method: 'facebook',
          });
        }
      } else if (result.error?.code === 'C0001') {
        navigate('/miro-register', {
          replace: true,
          state: {
            page: 'social',
            params: { type: 'facebook', profile: result.error.profile },
          },
        });
        // handlePageChange("social", { type: 'facebook', profile: result.error.profile })
      }
    }
  };
  return (
    <FacebookLogin
      appId={CONSTANTS.FB_ID}
      autoLoad={false}
      fields="id,name,emails,picture"
      version="17.0"
      callback={responseFacebook}
      isMobile={false}
      render={(renderProps) => (
        <CommonButton
          size="large"
          startIcon={<img src={FacebookIcon} />}
          onClick={renderProps.onClick}
        >
          {login ? t('signin.facebook_login') : t('register.facebook_register')}
        </CommonButton>
      )}
    />
  );
}
