import { useCallback, useEffect } from "react"
import { EVENT_ON_KEY_DOWN, PLUGIN_THUMBNAILS, createModule, useController, useLightboxState } from "yet-another-react-lightbox"

function ResetKeyboardWrapper({ children, ...props }) {

  const { slides, currentIndex } = useLightboxState()
  const { containerRef, subscribeSensors, prev, next } = useController()
  /* useEffect(() => {
    const el = containerRef.current;
    console.log("🚀 ~ file: useCustomNavigationAnimation.js:64 ~ useEffect ~ el:", el)
    if (!el) return;

    const stop = (e) => {
      console.log("stop")
      e.preventDefault();
      e.stopPropagation();
    }
    el.addEventListener("keyDown", stop);

    return () => {
      el.removeEventListener("keyDown", stop);
    };
  }, [containerRef.current]); */

  const customArrowClick = useCallback((e) => { }, [])
  const customKeyDown = useCallback((e) => {
    console.log("override keydown")
    //e.stopPropagation()
  }, [])

  useEffect(() => subscribeSensors(EVENT_ON_KEY_DOWN, customKeyDown), [subscribeSensors, customKeyDown]);
  return <>{children} <div className="my_component">{currentIndex}</div></>;
}

const resetKeyboardEventModule = createModule("resetKeyboardEventModule", ResetKeyboardWrapper);
export function ResetKeyboardEventPlugin({ addModule, augment }) {
  addModule(resetKeyboardEventModule);
}



function ResetFocusWrapper({ children }) {

  // const { containerRef, focus } = useController()

  // useEffect(() => {
  //   const focusOnController = () => {
  //     console.log("focus")
  //     // focus()
  //   }
  //   if (containerRef.current) {

  //     window.addEventListener('click', focusOnController)
  //   }
  //   return () => {
  //     window.removeEventListener('click', focusOnController)
  //   }
  // }, [containerRef.current])
  return <>{children}</>
}

export function ResetFocusModule({ addModule, contains }) {
  if (contains(PLUGIN_THUMBNAILS)) { addModule(createModule("ResetFocusModule", ResetFocusWrapper)) }
}