import React from 'react';

const HomeStar = ({ color, ...props }) => {
  return (
    <svg
      width="106"
      height="115"
      viewBox="0 0 106 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.8965 22.9253C41.5847 21.0756 41.3499 19.2469 41.1982 17.4179C40.9696 14.6745 41.1109 11.9101 41.0298 9.16668C40.9945 8.00281 40.8552 4.96846 40.9114 4.53201C41.4206 0.728635 44.4903 0.146628 45.3881 0.0427102C45.8225 -0.0196402 50.2826 -0.518221 51.2449 4.42825C52.4254 10.4762 52.8037 16.5657 52.3859 22.5306C53.3607 27.6017 55.0275 32.652 57.2285 37.2244C65.9866 55.4099 82.6757 57.1765 100.757 57.0103C103.538 56.9687 105.847 59.1509 105.993 61.9151C106.138 64.7001 104.068 67.111 101.3 67.3604C91.1929 68.3372 73.2608 76.4429 67.0611 84.881C65.7642 86.6476 65.0098 90.9082 64.0122 95.0857C62.6197 100.884 60.9633 106.62 59.1073 109.031C58.8288 109.405 55.4431 113.23 54.4331 113.791C52.0055 115.121 50.0623 114.352 48.9587 113.645C47.8551 112.939 46.606 111.546 46.1072 109.239C45.5856 106.828 46.0262 101.882 45.9451 100.822C45.65 96.9768 44.8811 89.4324 43.3119 82.948C42.466 79.4564 41.564 76.2349 40.0717 74.6346C34.5475 68.7113 25.7145 69.5843 18.2761 70.5611C17.4219 70.6651 16.5677 70.7898 15.7156 70.8937C12.6874 71.7874 9.47215 72.4524 6.07406 72.8473C0.942618 73.45 0.0988658 68.7321 0.0531422 68.4204C-0.0424618 67.7345 -0.397897 64.2014 3.46991 62.7881C3.89181 62.6427 7.00521 61.9567 8.20441 61.6866C9.87541 61.3125 11.5651 61.0216 13.261 60.7514C30.7794 55.3061 40.2941 40.0093 41.8965 22.9253ZM47.9092 41.8177C54.5225 55.4932 64.5588 62.1023 76.4552 65.0951H76.4532C69.0252 68.9816 62.2684 73.845 58.6874 78.7291C57.3282 80.5789 56.0812 84.299 54.9568 88.4765C54.3479 84.59 53.5249 80.4539 52.4379 76.9207C51.2013 72.8887 49.5365 69.5428 47.6784 67.5476C44.0912 63.7027 39.5895 61.562 34.749 60.502C40.5434 55.3685 44.9371 48.9672 47.9092 41.8177Z"
        fill={color || '#AEE01F'}
      />
    </svg>
  );
};

export default HomeStar;
