import React from 'react';
import CommonText from './CommonText';
import styled from 'styled-components';
import { breakpoint } from '../../../utils/responsive';
import { useTranslation } from 'react-i18next';

// mobile: chinese 20px, mobile: 32px, flex-col
// 860 < x  < 1024: chinese 32px, english 36px, flex-row
// > 1024: chinese 28px, english 28px
// > 1440: chinese 32px, english 36px
const HomeTitleGroup = ({ zhTitle, enTitle, zhStyle, enStyle, style }) => {
  const { i18n } = useTranslation();
  return (
    <StyledContainer className="home-title-group" style={style}>
      {i18n.language === 'zh' && (
        <ChineseText style={zhStyle} className="home-title-group-chinese">
          {zhTitle}
        </ChineseText>
      )}
      <EnglishText style={enStyle} className="home-title-group-english">
        {enTitle}
      </EnglishText>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoint.desktop} {
    flex-direction: row;
    gap: 12px;
    height: 40px;
  }

  @media ${breakpoint.largeDesktop} {
    height: 45px;
  }
`;

const ChineseText = styled.span`
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 400;

  @media ${breakpoint.mobile} {
    font-weight: 700;
  }

  @media ${breakpoint.desktop} {
    font-size: 28px;
  }

  @media ${breakpoint.largeDesktop} {
    font-size: 32px;
  }
`;

const EnglishText = styled.span`
  font-family: 'Philosopher';
  font-size: 32px;
  text-transform: uppercase;

  @media ${breakpoint.onlyTablet} {
    font-size: 36px;
  }

  @media ${breakpoint.desktop} {
    font-size: 28px;
  }

  @media ${breakpoint.largeDesktop} {
    font-size: 36px;
  }
`;

export default HomeTitleGroup;
