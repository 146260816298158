import { useTranslation } from "react-i18next";
import Space from "../Space";
import CommonText from "../typographies/CommonText";
import styled from "styled-components"
import CommonButton from "../buttons/CommonButton";
import IconWarning from "../../../assets/ic-warning.svg"

const ConfirmView = ({ onConfirmClick, onCancelClick, isMobile }) => {
  const { t } = useTranslation("profile")
  return (
    <Container>
      <Space size="24px" />
      <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
        {t('profile:add_new_address.confirm_remove_address')}
      </CommonText>
      <Space size="24px" />
      <img src={IconWarning} />
      <Space size="24px" />
      <ButtonContainer isMobile={isMobile}>
        <CommonButton
          { ...(isMobile ? { width: '100%' } : {})}
          type={"outlined"}
          normalCase={true}
          onClick={onCancelClick}
        >
          {t("profile:button.back")}
        </CommonButton>
        <div style={{ width: '20px', height: '20px' }} />
        <CommonButton
          { ...(isMobile ? { width: '100%' } : {})}
          type={"primary"}
          normalCase={true}
          onClick={onConfirmClick}
        >
          {t("profile:button.confirm")}
        </CommonButton>
      </ButtonContainer>
    </Container>
  )
}

export default ConfirmView;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
