import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import constants from '../constants';

export default function Join2025({ memberType }) {
  const { t, i18n } = useTranslation();
  const { session, setSession, disableMiroReg } = useContext(AppContext);
  const navigate = useNavigate();
  const lang = i18n.language == 'zh' ? 'tc' : 'en';

  const isMiro = session?.user?.memberType === 'MIRO';

  useEffect(() => {
    if (disableMiroReg) navigate('/');
    if (isMiro && session?.user?.renewPrice < 0) navigate('/');
    const originalOverflow = document.body.style.overflow;

    // Set the body overflow to hidden
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore the original overflow value
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  return (
    <div
      style={{
        width: '100vw',
        height: '100%',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <iframe
        src={`${constants.JOIN_2025_URL}/${lang}?member=${memberType}`}
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  );
}
