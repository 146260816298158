import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import PlayIcon from "../../../assets/resource_radioPlay.svg";
import PauseIcon from "../../../assets/resource_radioPause.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { useState } from "react";

const RadioPlayer = ({ descriptionZh, descriptionEn, url, idx, audioRef, handleOnPlay }) => {
  const { t, i18n } = useTranslation("mediaSeriesDetail");
  const [firstPlayed, setFirstPlayed] = useState(true);

  const handleAudioPlay = () => {
    if (firstPlayed) {
      setFirstPlayed(false)
    }
    handleOnPlay(idx, firstPlayed)
  }

  return (
    <StyledPlayer
      style={{
        backgroundColor: "rgba(0, 0, 0, 0)",
        boxShadow: "none",
        padding: 0,
      }}
      ref={(el) => {
        if (el) {
          audioRef.current[idx] = el;
        } else {
          delete audioRef.current[idx];
        }
      }}
      src={url}
      onPlay={handleAudioPlay}
      header={
        <Description>
          {i18n.language === "zh" ? descriptionZh : descriptionEn}
        </Description>
      }
      showJumpControls={false}
      customVolumeControls={[]}
      customAdditionalControls={[]}
      customControlsSection={[]}
      customProgressBarSection={[
        RHAP_UI.MAIN_CONTROLS,
        RHAP_UI.PROGRESS_BAR,
        <div style={{ color: "#fff", marginRight: 3 }}>- </div>,
        RHAP_UI.CURRENT_LEFT_TIME,
      ]}
      customIcons={{
        play: <img src={PlayIcon} />,
        pause: <img src={PauseIcon} />,
      }}
    />
  );
};

const StyledPlayer = styled(AudioPlayer)`
  .rhap_time {
    color: #fff;
  }
  .rhap_current-left-time {
    @media (max-width: 1200px) {
      font-size: 12px;
      font-weight: 600;
    }
  }
  .rhap_progress-bar {
    background-color: rgba(255, 255, 255, 0.6);
  }
  .rhap_progress-filled {
    background-color: #fff;
  }
  .rhap_progress-indicator {
    background: #fff;
    width: 16px;
    height: 16px;
    top: -6px;
    margin-left: -8px;
    border: 1px solid #efefef;
    box-shadow: 0 5px 10px 0 hsl(0deg 0% 0% / 30%);
  }
  .rhap_download-progress {
    background-color: rgba(255, 255, 255, 0.6);
  }
  .rhap_header {
    margin-bottom: 4px;
  }
  .rhap_progress-section {
    padding-left: 8px;
  }
  .rhap_stacked .rhap_controls-section {
    margin-top: 0px;
  }
`;

const Description = styled.div`
  margin-left: 15px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #fff;
  font-weight: 600;
  line-height: 140%;

  @media (min-width: 1201px) {
    font-size: 16px;
  }
  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export default RadioPlayer;
