import { useQuery } from 'react-query';
import { listMemberProfiles } from '../apis';
import ListMember from '../pageComponents/memberProfile/ListMember';
import Loading from '../components/common/loading';
import MetaTag from '../utils/MetaTag';
import colors from '../utils/colors';

export default function MemberProfile() {
  const { isLoading, isError, data, error } = useQuery(
    ['listMemberProfiles'],
    () => listMemberProfiles()
  );
  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div style={{ background: colors.purple }}>
      <MetaTag page="member-profile" />
      <ListMember data={data && data.data} />
    </div>
  );
}
