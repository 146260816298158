
import { React, useState } from 'react';
import styled from 'styled-components';
import CommonText from "../../../components/common/typographies/CommonText";
import VerticalDivider from '../../../components/common/divider/VerticalDivider';
import { useTranslation } from 'react-i18next';
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";


// Props(String): releaseDate, composer, lyricist, arranger, producer
// Sample Usage:
// <SongInfoBox composer="陳懷恩YNot/吳汶芳/蔡欣弦/邵建維" lyricist="小克" arranger="王雙駿" producer="王雙駿"></SongInfoBox>

function SongInfoBox(props) {
    let { 
        composer,
        lyricist,
        arranger,
        producer
     } = props;
     const { t, i18n } = useTranslation("discography");

    return (
        <StyledMainDiv>
            <ResponsiveUI
                mobile={
                    <div>
                        <StyledFlexDiv>
                            <StyledInfoDiv lang={i18n.language}>
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{t('composer')}</CommonText>
                            </StyledInfoDiv>
                            <VerticalDivider color="#666666" size="20px"/> 
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{composer}</CommonText>
                        </StyledFlexDiv>
            
                        <StyledFlexDiv>
                            <StyledInfoDiv lang={i18n.language}>
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{t('lyricist')}</CommonText>
                            </StyledInfoDiv>
                            <VerticalDivider color="#666666" size="20px"/> 
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{lyricist}</CommonText>
                        </StyledFlexDiv>
            
                        <StyledFlexDiv>
                            <StyledInfoDiv lang={i18n.language}>
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{t('arranger')}</CommonText>
                            </StyledInfoDiv>
                            <VerticalDivider color="#666666" size="20px"/> 
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{arranger}</CommonText>
                        </StyledFlexDiv>
            
                        <StyledFlexDiv>
                            <StyledInfoDiv lang={i18n.language}>
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{t('producer')}</CommonText>
                            </StyledInfoDiv>
                            <VerticalDivider color="#666666" size="20px"/> 
                                <CommonText color="#F9F9F9" fontSize="16px" fontWeight="400" lineHeight="25px">{producer}</CommonText>
                        </StyledFlexDiv>
                    </div>
                }

                desktop={
                    <div>
                        <StyledFlexDiv>
                            <StyledInfoDiv lang={i18n.language}>
                                <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{t('composer')}</CommonText>
                            </StyledInfoDiv>
                            <VerticalDivider color="#666666" size="20px"/> 
                                <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{composer}</CommonText>
                        </StyledFlexDiv>
            
                        <StyledFlexDiv>
                            <StyledInfoDiv lang={i18n.language}>
                                <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{t('lyricist')}</CommonText>
                            </StyledInfoDiv>
                            <VerticalDivider color="#666666" size="20px"/> 
                                <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{lyricist}</CommonText>
                        </StyledFlexDiv>
            
                        <StyledFlexDiv>
                            <StyledInfoDiv lang={i18n.language}>
                                <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{t('arranger')}</CommonText>
                            </StyledInfoDiv>
                            <VerticalDivider color="#666666" size="20px"/> 
                                <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{arranger}</CommonText>
                        </StyledFlexDiv>
            
                        <StyledFlexDiv>
                            <StyledInfoDiv lang={i18n.language}>
                                <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{t('producer')}</CommonText>
                            </StyledInfoDiv>
                            <VerticalDivider color="#666666" size="20px"/> 
                                <CommonText color="#F9F9F9" fontSize="20px" fontWeight="400" lineHeight="40px">{producer}</CommonText>
                        </StyledFlexDiv>
                    </div>
                }
            />




        </StyledMainDiv>
    );
}

const StyledMainDiv = styled.div`;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    width: 100%;
    margin-bottom: 25px;
    @media ${breakpoint.desktop} {
        height: 280px;
        width: 433px;
        margin-left: 50px;
    }
`;

const StyledInfoDiv = styled.div`;
    display: flex;
    min-width: ${props => props.lang == "en" ? "100px" : "0px"};
`;

const StyledFlexDiv = styled.div`;
    display: flex;
    align-items: center;
    min-height: 25px;
    text-align: left;
    @media ${breakpoint.desktop} {
        min-height: 40px;
    }
`;

export default SongInfoBox;