import { ImageListItem } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MoreContentItem = ({ item }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();


  return (
    <StyledImageListItem
      key={item._id}
      onClick={() => navigate(`/media-series/${item._id}`, { replace: true })}
    >
      <Wrapper className="thumbnail-wrapper">
        <ImageBackgroundShape className="my-background" />
        <StyledImage src={item.thumbnail + "-thumbnail"} className="my-image" />
      </Wrapper>
      <StyledCommonText
        textAlign="start"
        mFontSize="18px"
        fontSize="24px"
        text
      >
        {i18n.language === "en" ? item.nameEn : item.nameZh}
      </StyledCommonText>
    </StyledImageListItem>
  );
};


const StyledImageListItem = styled(ImageListItem)`
  cursor: pointer;
  &:hover{
    .thumbnail-wrapper{
      .my-background{
        transition: ease-out 300ms;
        transform: rotate(-5.2deg) scale(1.025);
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.08) 0%,
            rgba(0, 0, 0, 0.08) 100%
          ),
          #90a2b4;
      }
      .my-image{
        transition: ease-out 300ms;
        transform: rotate(1.2deg);
      }
    }
 }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
/*   &:hover {
    div {
      transition: ease-out 300ms;
      transform: rotate(-5.2deg) scale(1.025);
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.08) 0%,
          rgba(0, 0, 0, 0.08) 100%
        ),
        #90a2b4;
    }
    img {
      transition: ease-out 300ms;
      transform: rotate(1.2deg);
    }
  } */
`;

const StyledCommonText = styled(CommonText)`
  padding-top: 40px;
  @media ${breakpoint.mobile}{
    padding-top: 24px;
  }
`

const ImageBackgroundShape = styled.div`
  position: absolute;
  z-index: 0;
  background-color: #90a2b4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: ease-out 300ms;
  transform: rotate(-4deg) scale(1.025);
`;

const StyledImage = styled.img`
  position: relative;
  border-radius: 10px;
  aspect-ratio: 16/9;
  object-fit: cover;
  width: 100%;

  transition: ease-out 300ms;
`;

export default MoreContentItem;
