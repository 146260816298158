import styled from "styled-components";
import CommonDivider from "../../../components/common/divider/CommonDivider";
import Space from "../../../components/common/Space";
import { ResponsiveUI } from "../../../utils/responsive";
import AddressInfo from "./AddressInfo";
import PersonalInfo from "./PersonalInfo";
import PwManageInfo from "./PwManageInfo";

export default function ProfileInfo(props) {
  const _renderContent = (isMobile) => {
    return (
      <StyleContentDiv isMobile={isMobile}>
        <StyleFormDiv isMobile={isMobile}>
          <PersonalInfo {...props} />
          <Space size={isMobile ? "30px" : "40px"} />
          <CommonDivider />
          <Space size={isMobile ? "40px" : "60px"} />

          <PwManageInfo {...props} />
          <Space size={isMobile ? "40px" : "60px"} />
          <CommonDivider />
          <Space size={isMobile ? "40px" : "60px"} />

          {/* <AddressInfo {...props} />
          <Space size={isMobile ? "40px" : "80px"} /> */}
        </StyleFormDiv>
      </StyleContentDiv>
    );
  };

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  );
}

const StyleContentDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyleFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.isMobile ? "20px" : "0")};
`;
