import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import SliderArrowButton from '../buttons/SliderArrowButton';
import { StyledBackdrop } from '../images/ShadowImage';

const HomeCarousel = React.forwardRef(
  ({ images, children, setting = {}, fixedBackdrop, ...props }, ref) => {
    const settings = {
      className: 'slider',
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: false,
      centerMode: false,
      arrows: children?.length > 1,
      nextArrow: (
        <SliderArrowButton
          direction="right"
          sx={{
            position: 'absolute',
            right: 20,
            '&:before': {
              content: '""',
            },
          }}
        />
      ),
      prevArrow: (
        <SliderArrowButton
          direction="left"
          sx={{
            position: 'absolute',
            zIndex: 1,
            left: 20,
            '&:before': {
              content: '""',
            },
          }}
        />
      ),
      ...setting,
    };

    return (
      <div
        className={props.className}
        style={{
          // maxWidth: 575,
          width: '100%',
          margin: 'auto',
          position: 'relative',
          ...props.style,
        }}
      >
        {fixedBackdrop}
        <StyledSlider {...settings} afterChange={props?.afterChange} ref={ref}>
          {children}
        </StyledSlider>
      </div>
    );
  }
);

const StyledSlider = styled(Slider)`
  .slick-slide {
    opacity: 0.2;
    transition: opacity 0.5s;
    &.slick-active {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }
`;

export default HomeCarousel;
