import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { breakpoint } from "../../../utils/responsive";

// options:
// mWidth/ dWidth: "100px"
// mHeight/ dHeight: "50px"
// font_color: "white"
// Sample Usage: <FilterButton mWidth="100px" dWidth="100px" mHeight="50px" dHeight="50px" fontColor="white">全部</FilterButton>

function FilterButton(props) {
  let { children, select } = props;
  let isSelected = false;
  if( !select.album && (select.nameZh.toLowerCase() == children.toLowerCase() || select.nameEn.toLowerCase() == children.toLowerCase())){
    isSelected = true;
  } else if( select.album && (select.name.toLowerCase() == children.toLowerCase() )){
    isSelected = true;
  }

  return (
    <StyledButton
      onClick={props.onClick}
      isSelected={isSelected}
      { ...props }
    >
      <StyledTypography>{ props.children }</StyledTypography>
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  && {
    background: ${props => props.isSelected? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.2)"};
    color: ${props => props.isSelected? props.fontColor : "white"};
    border-radius: 10px;
    height: ${props => props.mHeight};
    width: ${props => props.mWidth};
    /* margin: 10px; */
    &:hover {
      background: ${props => props.isSelected? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"};
      color: ${props => props.fontColor};
    }

    @media ${breakpoint.desktop} {
        width: ${(props) => props.dWidth};
        height: ${(props) => props.dHeight};
    }
  }

`;

const StyledTypography = styled.div`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
`;

export default FilterButton;