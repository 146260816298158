const downloadMediaImage = async (path, filename) => {


  let blob = await fetch(path, {
    credentials: "include",
  }).then((r) => r.blob());
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  // the filename you want
  a.download = filename ?? "image.png";
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  return;

};

export { downloadMediaImage }