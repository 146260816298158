import { Menu, MenuItem, useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../../../AppContext';
import { ReactComponent as TopRightLine } from '../../../assets/revamp2025/home_line_top.svg';
import GlobalIcon from '../../../pageComponents/home/asset/GlobalIcon';
import VerticalDivider from '../divider/VerticalDivider';
import CommonText from '../typographies/CommonText';
import CustomMenuItem from './MenuItem';
import useMenus from './useMenus';
import { breakpoint } from '../../../utils/responsive';
import colors from '../../../utils/colors';

/**
 * Container for the right side of the navbar, which currently shows the language switcher and the menu items.
 * @param {Object} props
 * @param {String} props.theme - The theme of the navbar.
 * @param {ReactNode} props.children - The children to be rendered in the container.
 * @returns
 */
const NavbarRightContainer = ({ theme }) => {
  const { session } = useContext(AppContext);
  const { i18n } = useTranslation();
  const isHomepage = useMatch('/');
  const login =
    session?.user?.memberType == 'MIRO' || session?.user?.memberType == 'WEB';
  const isLargerThan1024 = useMediaQuery(breakpoint[1024]);
  const withLargeGutter = useMediaQuery('(min-width: 1350px)');
  const handleChangeLanguage = (newLang) => {
    localStorage.setItem('language', newLang);
    i18n.changeLanguage(newLang);
    ReactGA.event('selected_language', {
      param: newLang == 'en' ? 'english' : 'chinese',
    });
  };
  const navigate = useNavigate();

  const shouldShowRightLine = isLargerThan1024 && !login && isHomepage;

  const [anchorEl, setAnchorEl] = React.useState({
    target: null,
    id: null,
  });
  const { menusFromApi } = useMenus();
  return (
    <>
      {shouldShowRightLine && (
        <TopRightLine
          style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}
        />
      )}

      <MenuContainer>
        {menusFromApi
          .filter((menu) => menu.menuItemEn !== 'HOME')
          .map((menu) => {
            return (
              <CustomMenuItem
                key={menu.name}
                menuItem={menu}
                theme={theme}
                onClick={(e) => {
                  if (menu.children) {
                    setAnchorEl({
                      target: e.currentTarget,
                      id: menu.id,
                    });
                    return;
                  }
                }}
              >
                {menu.name}
              </CustomMenuItem>
            );
          })}
      </MenuContainer>

      <VerticalDivider
        style={{
          margin: '0 20px',
          opacity: 0.5,
          color: theme === 'black' ? colors.black : colors.white,
          height: 28,
        }}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          marginRight: 20,
          cursor: 'pointer',
        }}
        onClick={() =>
          handleChangeLanguage(i18n.language == 'en' ? 'zh' : 'en')
        }
      >
        <GlobalIcon color={theme === 'black' ? colors.black : colors.white} />
        <CommonText
          fontSize="18px"
          fontWeight="700"
          color={theme === 'black' ? colors.black : colors.white}
        >
          {i18n.language == 'en' ? '繁' : 'EN'}
        </CommonText>
      </div>
      <PopupMenu
        anchorEl={anchorEl?.target}
        open={Boolean(anchorEl?.target)}
        onClose={() =>
          setAnchorEl({
            target: null,
            id: null,
          })
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -9,
          horizontal: 'center',
        }}
      >
        {menusFromApi
          .find((menu) => menu.id === anchorEl.id)
          ?.children?.map((child) => {
            return (
              <MenuItem key={child.link} onClick={() => navigate(child.link)}>
                <CommonText
                  fontSize="18px"
                  fontWeight="700"
                  style={{
                    width: 100,
                    padding: 10,
                    margin: 'auto',
                    textAlign: 'center',
                  }}
                  type={'common'}
                >
                  {child.name}
                </CommonText>
              </MenuItem>
            );
          })}
      </PopupMenu>
    </>
  );
};

const PopupMenu = styled(Menu)`
  .MuiPaper-root {
    width: 132px;
    box-shadow: 0px 4px 17.7px 0px #00000033;
    border-radius: 10px;
  }
  .MuiMenuItem-root {
    padding: 0;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  @media ${breakpoint[1024]} {
    gap: 30px;
    justify-content: flex-start;
  }
`;

export default NavbarRightContainer;
