import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import ProfileInfoRow from "../ProfileInfoRow"
import PwManageForm from "./PwManageForm"

function PwManageInfo(props) {
  const { t } = useTranslation("profile")

  return (
    <StyledMainDiv>
      <ProfileInfoRow
        title={t("profile:submenu.pw_mange")}
        titleStyle={{ alignItems: "flex-start" }}
      >
        <PwManageForm />
      </ProfileInfoRow>
    </StyledMainDiv>
  )
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default PwManageInfo
