import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CommonNavBar from '../components/common/navbar/CommonNavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import WebTnc from '../pageComponents/tnc/web';
import MembershipTnc from '../pageComponents/tnc/membership';
import GreySelectMenu from '../components/common/menus/GreySelectMenu';
import Loading from '../components/common/loading';
import { getStaticPage } from '../apis';
import styled from 'styled-components';

function useQueryParam() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Tnc() {
  const { t } = useTranslation('tnc');
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQueryParam();
  const currentId = query.get('id') || '';

  const MENU_ITEMS = Object.keys(t('menu', { returnObjects: true }));

  const {
    isLoading: isTncLoading,
    isError: isTncError,
    data: tncData,
    error: tncError,
  } = useQuery(['tnc'], () => getStaticPage('tnc'));

  const {
    isLoading: isMiroTncLoading,
    isError: isMiroTncError,
    data: miroTncData,
    error: miroTncError,
  } = useQuery(['miro_tnc'], () => getStaticPage('miro_tnc'));

  const webTncContentZh = useMemo(() => {
    if (tncData && tncData.data) {
      return tncData.data.zh.replace(/\n/g, '');
    }
    return '';
  }, [tncData]);

  const webTncContentEn = useMemo(() => {
    if (tncData && tncData.data) {
      return tncData.data.en.replace(/\n/g, '');
    }
    return '';
  }, [tncData]);

  const membershipTncContentZh = useMemo(() => {
    if (miroTncData && miroTncData.data) {
      return miroTncData.data.zh.replace(/\n/g, '');
    }
    return '';
  }, [miroTncData]);

  const membershipTncContentEn = useMemo(() => {
    if (miroTncData && miroTncData.data) {
      return miroTncData.data.en.replace(/\n/g, '');
    }
    return '';
  }, [miroTncData]);

  const webTnc = WebTnc(webTncContentZh, webTncContentEn);
  const membershipTnc = MembershipTnc(
    membershipTncContentZh,
    membershipTncContentEn
  );

  useEffect(() => {
    if (
      location.pathname === '/tnc' &&
      (!location.search || !currentId || !MENU_ITEMS.includes(currentId))
    ) {
      navigate(`/tnc?id=${MENU_ITEMS[0]}`, { replace: true });
    }
  }, [location, query]);

  console.log('check', isTncLoading, tncData, isMiroTncLoading, miroTncData);
  if (isTncLoading || !tncData || isMiroTncLoading || !miroTncData) {
    return <Loading></Loading>;
  }

  return (
    <StyledMainDiv>
      <CommonNavBar theme="black" />
      <GreySelectMenu
        pageId="tnc"
        title={t('landing.title')}
        subTitle="Terms and Conditions"
        menuItems={MENU_ITEMS}
        currentId={currentId}
      >
        {location.search === '?id=web' && <div>{webTnc}</div>}

        {location.search === '?id=membership' && <div>{membershipTnc}</div>}
      </GreySelectMenu>
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  background: white;
  position: relative;
`;

export default Tnc;
