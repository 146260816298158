import Grid from "@mui/material/Grid";
import styled from "styled-components";
import CommonText from "../typographies/CommonText";
import IconHexagon from "../../../assets/ic-hexagon.svg";
import IconPentagon from "../../../assets/ic-pentagon.svg";

const IconList = (type) => {
  switch (type) {
    case "hex":
      return IconHexagon;
    case "pen":
      return IconPentagon;
  }
};

const ListItem = ({ text, type, color }) => {
  return (
    <Grid container item spacing={2} xs={12}>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledImageDiv src={IconList(type)}></StyledImageDiv>
      </Grid>
      <Grid item xs={11} style={{ display: "flex", alignItems: "center" }}>
        <CommonText color={color}>{text}</CommonText>
      </Grid>
    </Grid>
  );
};

const StyledImageDiv = styled.img`
  object-fit: contain;
`;

export default ListItem;
