import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledToggleButton = styled(ToggleButton)`
    && {
        border-radius: 0;
        width: 163px;
        height: 53px;
        color: #111111;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        :hover {
            background-color: #ffffff;
            color: #111111;
        }
        &.Mui-selected, &.Mui-selected:hover {
            background-color: #FF5C00;
            color: white;
        }
    }
`
export default function EmailPhoneToggleButton({ value, onChange }) {
    const { t } = useTranslation('signin');
    return (
        <ToggleButtonGroup value={value} onChange={onChange} exclusive>
            <StyledToggleButton value="email">
                {t('signin.email_login')}
            </StyledToggleButton>
            <StyledToggleButton value="phone">
                {t('signin.phone_login')}
            </StyledToggleButton>
        </ToggleButtonGroup>
    )
}
