import styled from "styled-components";
import AudioIcon from "../../../assets/resource_audio.svg";
import PlayIcon from "../../../assets/resource_play.svg";
import RadioImage from "../../../assets/resource_radioImage.png";
import { breakpoint } from "../../../utils/responsive";

const Thumbnail = ({ item, index, onClick }) => {
  return (
    <ThumbnailContainer onClick={(e) => onClick && onClick(e, index)}>
      {item.type !== "VISUAL" && (
        <PlayerButton>
          <CommonImage
            style={{ width: 14, height: 14 }}
            src={item.type === "VIDEO" || item.type === "STREAM" ? PlayIcon : AudioIcon}
          />
        </PlayerButton>
      )}
      {item.type !== "AUDIO" && (
        <SliderNav src={item.thumbnail + "-thumbnail"} />
      )}
      {item.type === "AUDIO" && (
        <SliderNav
          src={item.thumbnail ? item.thumbnail + "-thumbnail" : RadioImage}
        />
      )}
    </ThumbnailContainer>
  );
};

const CommonImage = styled.img`
  user-drag: none;
`;
const ThumbnailContainer = styled.a`
  width: 100%;
  height: 100%;
  padding: 5px;
  display: block;
`;

const SliderNav = styled.img`
  user-drag: none;
  pointer-events: none;
  -webkit-touch-callout: none;
  width: 100%;
  height: 100%;

  object-fit: cover;
  padding: 3px;
  border-radius: 14px;
`;

const PlayerButton = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 99px;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(249, 249, 249, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  backdrop-filter: blur(5px);
  @media ${breakpoint.desktop} {
    top: 24px;
    left: 24px;
  }
  @media ${breakpoint.mobile} {
    top: 18px;
    left: 18px;
  }
`;


export default Thumbnail