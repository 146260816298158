import React from 'react';
import styled from 'styled-components';
import MemberCardLogo from '../../assets/MemberCardLogo.svg';
import MobileMemberCardLogo from '../../assets/MemberCardLogo_mobile.svg';
import { breakpoint, WindowSize } from '../../utils/responsive';

// Props(String): name, id
// Sample usage:
// <MemberCard name="lau wai sze" id="220000121"></MemberCard>

function MemberCard(props) {
  let { name, id, idFontSize } = props;
  const isMobile = WindowSize() == 'mobile';

  return (
    <StyledDiv {...props}>
      <StyledLeftDiv>
        <StyledLogoDiv
          image={isMobile ? MobileMemberCardLogo : MemberCardLogo}
        />

        <StyledInfoDiv>
          <StyledNameDiv>{name}</StyledNameDiv>
          <StyledIdDiv fontSize={idFontSize}>{id}</StyledIdDiv>
        </StyledInfoDiv>
      </StyledLeftDiv>

      <StyledRightDiv>mirror official fan club</StyledRightDiv>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  border-radius: 10px;
  background: #d1d3d4;
  width: 100%;
  height: auto;
  position: relative;
  padding: 20px;
  aspect-ratio: 9/6;
  max-height: 230px;

  @media ${breakpoint.mobile} {
    max-width: 340px;
  }
`;

const StyledLeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;

  @media ${breakpoint.desktop} {
    width: 100%;
    height: auto;
  }

  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

const StyledRightDiv = styled.div`
  writing-mode: vertical-lr;
  color: white;
  text-transform: uppercase;
  transform: rotate(180deg);
  white-space: nowrap;
  width: auto;
  height: 100%;
  font-size: 11px;
  text-align: center;
  position: absolute;
  right: 12px;
  top: 0px;
  bottom: 0px;
`;

const StyledInfoDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 6px;

  @media ${breakpoint.desktop} {
    font-size: 20px;
  }

  @media ${breakpoint.mobile} {
    font-size: 15px;
  }
`;

const StyledNameDiv = styled.div`
  text-transform: uppercase;
  text-align: start;
  align-self: end;
  font-size: 16px;

  @media ${breakpoint.largeDesktop} {
    font-size: 20px;
  }
`;

const StyledIdDiv = styled.div`
  text-transform: uppercase;
  text-align: end;
  align-self: end;
  max-width: 43%;
  min-width: 43%;
  font-size: 16px;

  @media ${breakpoint.largeDesktop} {
    font-size: 20px;
  }
`;

const StyledLogoDiv = styled.div`
  background: url(${(props) => props.image});
  margin: auto;

  @media ${breakpoint.desktop} {
    height: 94px;
    width: 102px;
  }

  @media ${breakpoint.mobile} {
    height: 78px;
    width: 85px;
  }
`;

export default MemberCard;
