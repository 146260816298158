import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import ReactGA from "react-ga4";

function LanguageButton() {
  const { t, i18n } = useTranslation();
  console.log("current language", i18n.language);
  const handleChangeLanguage = (newLang) => {
    localStorage.setItem("language", newLang);
    i18n.changeLanguage(newLang);
    ReactGA.event("selected_language", {
      param: newLang == "en" ? "english" : "chinese",
    });
  };
  return (
    <StyledDiv>
      <StyledMenuItems
        style={{ opacity: i18n.language == "en" ? 1 : 0.3 }}
        onClick={() => handleChangeLanguage("en")}
      >
        EN
      </StyledMenuItems>
      <StyledMenuItems
        style={{ opacity: i18n.language == "zh" ? 1 : 0.3 }}
        onClick={() => handleChangeLanguage("zh")}
      >
        繁
      </StyledMenuItems>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  @media ${breakpoint.mobile} {
    width: 60px;
    justify-content: space-between;
  }
  @media ${breakpoint.desktop} {
    padding-left: 19px;
  }
`;

const StyledMenuItems = styled.div`
  cursor: pointer;
  color: #111111;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  @media ${breakpoint.desktop} {
    margin-left: 38px;
  }
`;

export default LanguageButton;
