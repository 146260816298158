import styled from 'styled-components';
import CommonText from '../../components/common/typographies/CommonText';
import Space from '../../components/common/Space';
import CommonButton from '../../components/common/buttons/CommonButton';
import constants from '../../constants';
import VISA from '../../assets/VISA.png';
import Mastercard from '../../assets/Mastercard.png';
import JCB from '../../assets/JCB.png';
import AlipayHK from '../../assets/AlipayHK.png';
import FPS from '../../assets/FPS.png';
import PayMe from '../../assets/PayMe.png';
import UnionPay from '../../assets/UnionPay.png';
import WeChatPay from '../../assets/WeChatPay.png';
import PaymentIcons from '../../assets/PaymentMethodIcons.png';
import TickIcon from '../../assets/white_tick.svg';
import { breakpoint, ResponsiveUI } from '../../utils/responsive';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { subscribe } from '../../apis';
import { AppContext } from '../../AppContext';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NoticeIcon from '../../assets/register_notice.svg';

const Payment = ({ step, price, handlePageChange, onError }) => {
  const { t, i18n } = useTranslation(['common', 'signin']);
  const { session, setSession } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(price == 0 ? 'FREE' : '');
  const navigate = useNavigate();
  const handlePayment = async ({ extraParams = {}, onError }) => {
    try {
      setLoading(true);
      const payload = {
        paymentMethod,
        locale: i18n.language,
      };
      const result = await subscribe(session.accessToken, payload);
      console.log(payload, result);
      if (result.success) {
        if (paymentMethod == 'FREE') {
          navigate('/payment-complete?reference_number=' + result.data?._id, {
            replace: true,
          });
        } else {
          let params = result.data;
          if (params.action) {
            const form = document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute('action', params.action);
            for (const key in params) {
              if (key != 'action' && params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);
                form.appendChild(hiddenField);
              }
            }
            document.body.appendChild(form);
            form.submit();
          }
        }
      } else {
        onError && onError(result.error?.code, result?.error?.message || '');
      }
      setLoading(false);
    } catch (error) {
      console.log('onCheckout-error', error);
      setLoading(false);
      onError && onError();
    }
  };
  const getImage = (payment) => {
    switch (payment) {
      case 'credit_card': {
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridGap: 12,
              paddingRight: 15,
              justifyContent: 'end',
            }}
          >
            <PaymentIcon src={VISA} alt="VISA" />
            <PaymentIcon src={Mastercard} alt="Mastercard" />
            <PaymentIcon src={JCB} alt="JCB" />
          </div>
        );
      }

      case 'recon': {
        return (
          <ResponsiveUI
            mobile={
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gridGap: 12,
                  paddingRight: 15,
                }}
              >
                <PaymentIcon src={AlipayHK} alt="AlipayHK" />
                <PaymentIcon src={WeChatPay} alt="WeChat Pay HK" />
                <PaymentIcon src={UnionPay} alt="Union Pay" />
                <div></div>
                <PaymentIcon src={PayMe} alt="PayMe from HSBC" />
                <PaymentIcon src={FPS} alt="FPS" />
              </div>
            }
            desktop={
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                  gridGap: 12,
                  paddingRight: 15,
                }}
              >
                <PaymentIcon src={AlipayHK} alt="AlipayHK" />
                <PaymentIcon src={WeChatPay} alt="WeChat Pay HK" />
                <PaymentIcon src={UnionPay} alt="Union Pay" />
                <PaymentIcon src={PayMe} alt="PayMe from HSBC" />
                <PaymentIcon src={FPS} alt="FPS" />
              </div>
            }
          />
        );
      }

      default: {
        return null;
      }
    }
  };
  return (
    <Container>
      <CommonText lineHeight="140%">
        {t('signin:register.step') + step}
      </CommonText>
      <Space size="30px" />
      <CommonText fontSize="20px" mFontSize="16px" lineHeight="140%">
        {t('signin:register.select_payment_method')}
      </CommonText>
      <Space size="30px" />
      <PaymentMethodContainer
        style={{ opacity: price == 0 ? 0.3 : 1 }}
        selected={paymentMethod == 'GLOBAL_PAYMENT'}
        onClick={() => price > 0 && setPaymentMethod('GLOBAL_PAYMENT')}
      >
        <TickBox item container justifyContent="center" alignItems="center">
          {paymentMethod == 'GLOBAL_PAYMENT' && (
            <img height="100%" src={TickIcon} alt="Tick" />
          )}
        </TickBox>
        <CommonText
          style={{
            padding: 15,
            borderLeft:
              paymentMethod == 'GLOBAL_PAYMENT'
                ? '1px solid rgb(249, 249, 249, 0.3)'
                : '1px solid rgba(17, 17, 17, 0.1)',
            textAlign: 'left',
            whiteSpace: 'pre-line',
          }}
          color={paymentMethod == 'GLOBAL_PAYMENT' ? '#f9f9f9' : '#111111'}
          fontWeight={600}
          fontSize="18px"
          mFontSize="14px"
          lineHeight="25px"
        >
          {t('common:payment.credit_card')}
        </CommonText>
        <div style={{ flex: 1 }} />
        {getImage('credit_card')}
      </PaymentMethodContainer>
      <PaymentMethodContainer
        style={{ opacity: price == 0 ? 0.3 : 1 }}
        selected={paymentMethod == 'RECON'}
        onClick={() => price > 0 && setPaymentMethod('RECON')}
      >
        <TickBox item container justifyContent="center" alignItems="center">
          {paymentMethod == 'RECON' && <img src={TickIcon} alt="Tick" />}
        </TickBox>
        <CommonText
          style={{
            padding: 15,
            borderLeft:
              paymentMethod == 'RECON'
                ? '1px solid rgb(249, 249, 249, 0.3)'
                : '1px solid rgba(17, 17, 17, 0.1)',
            textAlign: 'left',
            whiteSpace: 'pre-line',
          }}
          color={paymentMethod == 'RECON' ? '#f9f9f9' : '#111111'}
          fontWeight={600}
          fontSize="18px"
          mFontSize="14px"
          lineHeight="25px"
        >
          {t('common:payment.recon')}
        </CommonText>
        <div style={{ flex: 1 }} />
        {getImage('recon')}
      </PaymentMethodContainer>
      <Space size="22px" />
      <img width={193} src={PaymentIcons} alt="payment icons" />
      <Space size="19px" />
      {price == 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f2f2f2',
            border: '1px solid #e2e2e2',
            width: '100%',
            padding: '22.5px 17.5px',
          }}
        >
          <img style={{ marginRight: 7.5 }} src={NoticeIcon} />
          <CommonText
            textAlign="left"
            color="#111111"
            mFontSize="16px"
            fontSize="18px"
          >
            {t('signin:renew.free_renew_msg')}
          </CommonText>
        </div>
      )}
      <Space size="64px" />
      <ButtonContainer>
        <ResponsiveUI
          mobile={
            <CommonButton
              style={{ width: '50%', maxWidth: 440, marginRight: 15 }}
              onClick={handlePageChange}
            >
              {t('signin:register.previous')}
            </CommonButton>
          }
          desktop={
            <CommonButton
              style={{ width: '50%', maxWidth: 440, marginRight: 30 }}
              onClick={handlePageChange}
            >
              {t('signin:register.previous')}
            </CommonButton>
          }
        />
        {price > 0 ? (
          <CommonButton
            style={{ width: '50%', maxWidth: 440 }}
            disabled={!paymentMethod || loading}
            type={'primary'}
            onClick={handlePayment}
          >
            {`${t('signin:register.confirm_pay')} ${price}`}
          </CommonButton>
        ) : (
          <CommonButton
            style={{ width: '50%', maxWidth: 440 }}
            disabled={!paymentMethod || loading}
            type={'primary'}
            onClick={handlePayment}
          >
            {`${t('signin:landing.renew_free_confirm')}`}
          </CommonButton>
        )}
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  padding: 100px 20px;
  justify-content: center;
  align-items: center;
`;
const PaymentMethodContainer = styled.div`
  cursor: pointer;
  display: flex;
  background: ${(props) => (props.selected ? '#FF5C00' : 'transparent')};
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(17, 17, 17, 0.1);
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const TickBox = styled(Grid)`
  && {
    width: 50px;
    flex-shrink: 0;
    @media ${breakpoint.desktop} {
      width: 60px;
    }
  }
`;

const PaymentIcon = styled.img`
  width: 29px;
`;

export default Payment;
