import styled from "styled-components"
import CloseIcon from '../../../assets/nav_cross.svg';
import { breakpoint } from "../../../utils/responsive";

const StyledImage = styled.img`
  && {
    cursor: pointer;
    width: ${(props) => props.width || "20px"};
    height: ${(props) => props.height || "20px"};
    object-fit: contain;
    position: absolute;
    z-index: 100;

    @media ${breakpoint.mobile} {
      top: 40px;
      right: 32px;
    }

    @media ${breakpoint.desktop} {
      top: 59px;
      right: 55px;
    }
  }
`;
export default function ImageButton(props) {
    return (
        <StyledImage src={CloseIcon} {...props} >{props.children}</StyledImage>
    )
}