import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { breakpoint, WindowSize } from '../../../utils/responsive';
import DiscographyCarousel from '../../revamp2025/DiscographyCarousel';
import HomeTitleGroup from '../../../components/common/typographies/2025HomeTitleGroup';
import styled from 'styled-components';
import CommonButton from '../../../components/common/buttons/CommonButton';
import CommonText from '../../../components/common/typographies/CommonText';
import { useMediaQuery } from '@mui/material';
import { ReactComponent as HomeStar2 } from '../../../assets/revamp2025/ic-home-star-variant2.svg';
import { ReactComponent as DecoratingLine } from '../../../assets/revamp2025/ic-home-discography-decorating-line.svg';

const DiscographyDescription = ({ data, currentImage }) => {
  const isDesktop = useMediaQuery(breakpoint.desktop);
  const isLargerThan1024 = useMediaQuery(breakpoint[1024]);
  const isLargeDesktop = useMediaQuery(breakpoint.largeDesktop);
  const { t, i18n } = useTranslation('home');
  const navigate = useNavigate();

  const { performersZh, performersEn, nameZh, nameEn } =
    data[currentImage] || {};

  const renderWithFallback = (en, zh) => {
    if (i18n.language === 'en') {
      return en || zh;
    } else {
      return zh;
    }
  };

  const currentImageId = data[currentImage]?._id;

  return (
    <TitleAndActor>
      <Link
        to={`/discography/${currentImageId}`}
        style={{ textDecoration: 'none' }}
      >
        <CommonText
          mFontSize="18px"
          fontSize="24px"
          fontSizeFor1024="36px"
          largeFontSize="48px"
          fontWeight="700"
          color={isLargerThan1024 ? 'black' : 'white'}
          lineHeight="1.25em"
          style={{
            marginBottom: isDesktop ? 20 : 10,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {renderWithFallback(nameEn, nameZh)}
        </CommonText>
        <CommonText
          mFontSize="14px"
          fontSize="20px"
          largeFontSize="28px"
          fontWeight="700"
          color={isLargerThan1024 ? '#474747' : 'white'}
          lineHeight="1.25em"
        >
          {renderWithFallback(performersEn, performersZh)}
        </CommonText>
      </Link>
      <CommonButton
        style={{
          margin: !isLargerThan1024 && '0 auto',
          marginTop: 20,
          color: isLargerThan1024 ? 'black' : 'white',
          background: 'transparent',
          border: isLargerThan1024
            ? '1px solid #0000004d'
            : '1px solid #ffffff80',
        }}
        width={isLargeDesktop && '260px'}
        onClick={() => navigate('/discography')}
      >
        {t('button.allDiscography')}
      </CommonButton>
    </TitleAndActor>
  );
};

function DiscographySession({ data }) {
  const { t, i18n } = useTranslation('home');
  const navigate = useNavigate();
  const isLargerThan1024 = useMediaQuery(breakpoint[1024]);

  const handleClick = (currentSlide, discographyId) => {
    if (currentSlide != null) {
      const id = data[currentSlide]._id;
      navigate(`/discography/${id}`);
    } else {
      navigate(`/discography/${discographyId}`);
    }
  };

  const [currentImage, setCurrentImage] = React.useState(0);
  return (
    <Container>
      {' '}
      {isLargerThan1024 && (
        <>
          <HomeStar2 style={{ position: 'absolute', top: -147, left: 0 }} />
        </>
      )}
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: isLargerThan1024 ? 'space-between' : 'center',
        }}
      >
        <DiscographyDescriptionContainer>
          <HomeTitleGroup
            enTitle={'discography'}
            zhTitle={'音樂作品'}
            style={{
              justifyContent: !isLargerThan1024 && 'center',
              color: !isLargerThan1024 && 'white',
            }}
          />
          {isLargerThan1024 && (
            <div style={{ marginRight: 60 }}>
              <DiscographyDescription data={data} currentImage={currentImage} />
            </div>
          )}
        </DiscographyDescriptionContainer>

        {isLargerThan1024 && (
          <DiscographyCarousel
            discographies={data}
            currentImage={currentImage}
            setCurrentImage={setCurrentImage}
          />
        )}
      </div>
      {!isLargerThan1024 && (
        <>
          <DiscographyCarousel
            discographies={data}
            currentImage={currentImage}
            setCurrentImage={setCurrentImage}
          />
          <div style={{ marginTop: 20 }}>
            <DiscographyDescription data={data} currentImage={currentImage} />
          </div>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  background: orange;
  position: relative;
  padding: 40px 0;
  @media ${breakpoint.desktop} {
    padding: 60px 0;
  }

  @media ${breakpoint[1024]} {
    padding: 0;
    background: none;
    margin: 200px 0;
    margin-right: -60px;
    display: flex;
    justify-content: space-between;
  }

  @media ${breakpoint.largeDesktop} {
  
    padding-left: 45px;
    margin-bottom: 250px;
  }
  @media ${breakpoint.extraLargeDesktop} {
    margin-bottom: 300px;
`;

const DiscographyDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 40px;
  @media ${breakpoint.largeDesktop} {
    gap: 60px;
  }
  justify-content: center;
`;
const TitleAndActor = styled.div`
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${breakpoint[1024]} {
    text-align: left;
    gap: 20px;
  }
`;

export default DiscographySession;
