import styled from 'styled-components';
import { Grid } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import CommonText from '../typographies/CommonText';
import { Link, useNavigate } from 'react-router-dom';
import ImageButton from '../buttons/ImageButton';
import CloseIcon from '../../../assets/nav_cross.svg';
import Space from '../Space';
import { WindowSize } from '../../../utils/responsive';
import ReactGA from 'react-ga4';
import { useContext } from 'react';
import { AppContext } from '../../../AppContext';

const JoinBanner = ({ type, handleClose }) => {
  const { t, i18n } = useTranslation(['common', 'profile', 'menu']);
  const { session } = useContext(AppContext);
  const isMobile = WindowSize() == 'mobile';

  return (
    <JoinMiroBanner container alignItems="center">
      <Grid
        item
        container
        wrap="nowrap"
        justifyContent={isMobile ? 'flex-start' : 'center'}
        xs={11.5}
        md={11}
        mx="auto"
      >
        <CommonText
          mFontSize="12px"
          style={{ textAlign: 'start', color: '#FF5C00' }}
        >
          <Trans
            t={t}
            i18nKey={'menu:' + type}
            components={[
              <Link
                to={`/join`}
                style={{
                  color: '#FF5C00',
                  whiteSpace: 'nowrap',
                  fontWeight: 600,
                }}
                onClick={() => {
                  ReactGA.event('button_click', {
                    button_text: 'Sticky banner_Join now button',
                  });
                }}
                key={0}
              />,
            ]}
          />
        </CommonText>
      </Grid>
      <Grid style={{ position: 'absolute' }} item right={15}>
        <ImageButton onClick={handleClose} src={CloseIcon} />
      </Grid>
    </JoinMiroBanner>
  );
};

export default JoinBanner;

const JoinMiroBanner = styled(Grid)`
  background-color: #f9f9f9;
  height: auto;
  padding: 15px;
  // position: fixed;
  // position: sticky;
  // top: 0;
  z-index: 999;
`;
