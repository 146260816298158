import WhiteTick from '../../../assets/white_tick.svg';
import OrangeCircle from '../../../assets/orange_circle.svg';
import styled from 'styled-components';

export default function TickIcon() {
    return <Container>
        <Circle src={OrangeCircle} />
        <Tick src={WhiteTick} />
    </Container>
}
const Container = styled.div`
position: relative;
width: 53px;
height: 53px;
`
const Circle = styled.img`
position: absolute;
top: 0;
left: 0;
width: 53px;
height: 53px;
`
const Tick = styled.img`
position: absolute;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
width: 22px;
height: 22px;
`