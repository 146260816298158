import { useState, useEffect, useMemo } from 'react';
import CommonText from '../components/common/typographies/CommonText';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CollapsibleItem from '../pageComponents/faq/collapsibleItem';
import CommonNavBar from '../components/common/navbar/CommonNavBar';
import styled from 'styled-components';
import Space from '../components/common/Space';
import GreySelectMenu from '../components/common/menus/GreySelectMenu';
import { getStaticPage } from '../apis';
import Loading from '../components/common/loading';

const MENU_ITEMS = ['membership', 'delivery', 'payment'];

function parseContent(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const result = [];

  const items = doc.querySelectorAll('ol > li');
  console.log('items', items);

  for (let i = 0; i < items.length; i++) {
    const answer = items[i].querySelector('ul > li').innerHTML.trim();
    items[i].querySelector('ul').remove();
    const question = items[i].innerHTML.trim();
    console.log('question', question);
    console.log('answer', answer);
    result.push({ question, answer: answer.replace(/<br>/g, '\n') });
  }

  return result;
}

function useQueryParam() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Faq() {
  const { t, i18n } = useTranslation('faq');
  const [faqContent, setFaqContent] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQueryParam();
  const currentId = query.get('id') || '';

  const { isLoading, isError, data, error } = useQuery(['faq'], () =>
    getStaticPage('faq')
  );

  useEffect(() => {
    if (data && data.data) {
      var localizedData = {};
      if (i18n.language == 'en') {
        localizedData = JSON.parse(data.data.en);
      } else {
        localizedData = JSON.parse(data.data.zh);
      }

      const faqContent = {
        membership: parseContent(localizedData.membership),
        delivery: parseContent(localizedData.delivery),
        payment: parseContent(localizedData.payment),
      };
      setFaqContent(faqContent);
    }
  }, [data, i18n.language]);

  useEffect(() => {
    if (
      location.pathname === '/faq' &&
      (!location.search || !currentId || !MENU_ITEMS.includes(currentId))
    ) {
      navigate(`/faq?id=${MENU_ITEMS[0]}`, { replace: true });
    }
  }, [location, query]);

  const MEMBERSHIP_ITEMS = Object.keys(
    t('membership.questions', { returnObjects: true })
  );
  const DELIVERY_ITEMS = Object.keys(
    t('delivery.questions', { returnObjects: true })
  );
  const PAYMENT_ITEMS = Object.keys(
    t('payment.questions', { returnObjects: true })
  );

  if (
    isLoading ||
    !data ||
    !faqContent.membership ||
    !faqContent.delivery ||
    !faqContent.payment
  ) {
    return <Loading></Loading>;
  }

  return (
    <StyledMainDiv>
      <CommonNavBar theme="black" />
      <GreySelectMenu
        pageId="faq"
        title={t('landing.title')}
        subTitle="FAQ"
        menuItems={MENU_ITEMS}
        currentId={currentId}
      >
        {location.search == '?id=membership' && (
          <div>
            {faqContent.membership.map((item, index) => {
              return (
                <CollapsibleItem header={item.question} content={item.answer} />
              );
            })}
          </div>
        )}

        {location.search == '?id=delivery' && (
          <div>
            {faqContent.delivery.map((item, index) => {
              return (
                <CollapsibleItem header={item.question} content={item.answer} />
              );
            })}
          </div>
        )}

        {location.search == '?id=payment' && (
          <div>
            {faqContent.payment.map((item, index) => {
              return (
                <CollapsibleItem header={item.question} content={item.answer} />
              );
            })}
          </div>
        )}
        {i18n.language === 'zh' ? (
          <CommonText
            style={{ textAlign: 'center' }}
            type="primary"
          >{`*以上常見問題只供參考之用。有關詳情，請參閲適用的條款及條件 。`}</CommonText>
        ) : (
          <CommonText
            style={{ textAlign: 'center' }}
            type="primary"
          >{`*The information provided in this FAQ is for reference only. For details, please refer to the applicable terms and conditions.`}</CommonText>
        )}
        <Space size="30px" />
      </GreySelectMenu>
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  background: white;
  position: relative;
`;

export default Faq;
