import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';

function SearchTextfield(props) {
    return (
        <StyledTextfield
            {...props}
            variant="outlined"
        >
            {props.children}
        </StyledTextfield>
    );
}

const StyledTextfield = styled(TextField)`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    color: ${props => props.theme };
    background: transparent;
    & .MuiOutlinedInput-root {
        width: 100%;
        color: ${props => props.theme };
        & fieldset {
            border: none;
            border-radius: 0;
        }
        & input {
            padding:10px;
        }
        &:hover fieldset {
            /* border-color: #FF5C00; */
        }
        &.Mui-focused fieldset {
            /* border-color: #F6C790; */
        }
    }
  `;

export default SearchTextfield;
