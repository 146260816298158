import { forgotPassword, resetPassword } from "../../apis";
import CommonButton from "../../components/common/buttons/CommonButton";
import PhoneTextField from "../../components/common/textfields/PhoneTextField";
import FormTextField from "../../components/common/textfields/FormTextField";
import EmailPhoneToggleButton from "../../components/common/buttons/EmailPhoneToggleButton";
import { useRef, useState } from "react";
import CommonText from "../../components/common/typographies/CommonText";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Space from "../../components/common/Space";
import TickIcon from "../../components/common/icons/TickIcon";
import constants from "../../constants";
export default function ForgotPassword({ handlePageChange }) {
  const { t, i18n } = useTranslation(["common", "signin"]);
  const [page, setPage] = useState("email");
  const [error, setError] = useState(null);
  const [phoneInput, setPhoneInput] = useState(null);
  const [resetPasswordToken, setResetPasswordToken] = useState(null);
  const emailRef = useRef();
  const phoneOTPRef = useRef();
  const passwordRef = useRef();
  const retypePasswordRef = useRef();
  const validateEmail = () => {
    let error = {};
    const validEmail = new RegExp(constants.EMAIL_REGEX);
    if (!validEmail.test(emailRef.current.value))
      error.email = t("signin:register.invalid_email");
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };
  const validatePassword = () => {
    let error = {};
    const validPassword = new RegExp(constants.PASSWORD_REGEX);
    if (!validPassword.test(passwordRef.current.value))
      error.password = t("signin:register.invalid_password");
    else if (passwordRef.current.value != retypePasswordRef.current.value)
      error.retypePassword = t("signin:register.invalid_retype_password");
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };
  const handleResetPassword = async () => {
    const result = await resetPassword(
      "phone",
      phoneInput,
      resetPasswordToken,
      passwordRef.current.value,
      retypePasswordRef.current.value
    );
    console.log(result);
    if (result.success) {
      setPage("resetSuccess");
    }
  };
  const handleForgotPassword = async () => {
    if (page == "email" && validateEmail()) {
      const result = await forgotPassword(page, emailRef.current.value);
      console.log(result);
      if (result.success) {
        setPage("emailSuccess");
      } else {
        if (result.error?.code == "C0001") setPage("emailSuccess");
        else setError({ email: t("signin:register.invalid_email") });
      }
    } else if (page == "phone" && validatePassword()) {
      const result = await forgotPassword(
        page,
        phoneInput,
        phoneOTPRef.current.value
      );
      console.log(result);
      if (result.success) {
        setResetPasswordToken(result.data.token);
        setPage("phoneReset");
      }
    }
  };
  if (page == "email") {
    return (
      <Container>
        <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
          {t("signin:forgotpassword.title")}
        </CommonText>
        <Space size="22px" />
        <CommonText
          style={{ width: 360, textAlign: "center", whiteSpace: "pre-line" }}
          fontSize="20px"
          lineHeight="40px"
        >
          {t("signin:forgotpassword.email_description")}
        </CommonText>
        <Space size="80px" />
        {/* <EmailPhoneToggleButton value={page} onChange={(event) => setPage(event.target.value)} />
                <Space size="24px" /> */}
        <FormTextField
          inputRef={emailRef}
          errorText={error?.email}
          label={t("signin:register.email")}
        />
        <Space size="20px" />
        <ButtonContainer>
          <CommonButton onClick={() => handlePageChange("password")}>
            {t("common:back")}
          </CommonButton>
          <CommonButton
            style={{ marginLeft: 30 }}
            type={"primary"}
            onClick={handleForgotPassword}
          >
            {t("common:submit")}
          </CommonButton>
        </ButtonContainer>
      </Container>
    );
  }

  if (page == "phone") {
    return (
      <Container>
        <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
          {t("signin:forgotpassword.title")}
        </CommonText>
        <Space size="22px" />
        <CommonText
          style={{ width: 360, textAlign: "center" }}
          fontSize="20px"
          lineHeight="40px"
        >
          {t("signin:forgotpassword.phone_description")}
        </CommonText>
        <Space size="84px" />
        <EmailPhoneToggleButton
          value={page}
          onChange={(event) => setPage(event.target.value)}
        />
        <Space size="24px" />
        <PhoneTextField
          onChange={(value) => setPhoneInput(value)}
          label={t("common:phone")}
          requestOTP
        />
        <FormTextField inputRef={phoneOTPRef} label={t("common:otp")} />
        <Space size="20px" />
        <ButtonContainer>
          <CommonButton onClick={() => handlePageChange("password")}>
            {t("common:back")}
          </CommonButton>
          <CommonButton
            style={{ marginLeft: 30 }}
            type={"primary"}
            onClick={handleForgotPassword}
          >
            {t("common:submit")}
          </CommonButton>
        </ButtonContainer>
      </Container>
    );
  }
  if (page == "phoneReset") {
    return (
      <Container>
        <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
          {t("signin:forgotpassword.reset_password")}
        </CommonText>
        <Space size="102px" />
        <FormTextField
          errorText={error?.password}
          inputRef={passwordRef}
          label={t("signin:forgotpassword.reset_newpassword")}
        />
        <Space size="9px" />
        <CommonText
          style={{ width: "100%", textAlign: "left" }}
          fontSize="16px"
          lineHeight="20px"
        >
          {t("common:password_hint")}
        </CommonText>
        <Space size="20px" />
        <FormTextField
          errorText={error?.retypePassword}
          inputRef={retypePasswordRef}
          label={t("signin:forgotpassword.reset_retypepassword")}
        />
        <Space size="49px" />
        <ButtonContainer>
          <CommonButton onClick={() => setPage("phone")}>
            {t("common:back")}
          </CommonButton>
          <CommonButton
            style={{ marginLeft: 30 }}
            type={"primary"}
            onClick={handleResetPassword}
          >
            {t("common:submit")}
          </CommonButton>
        </ButtonContainer>
      </Container>
    );
  }
  if (page == "emailSuccess")
    return (
      <Container>
        <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
          {t("signin:forgotpassword.email_success_title")}
        </CommonText>
        <Space size="20px" />
        <TickIcon />
        <Space size="20px" />
        <CommonText
          style={{ width: 340, textAlign: "center" }}
          fontSize="20px"
          lineHeight="40px"
        >
          {t("signin:forgotpassword.email_success_description")}
        </CommonText>
        <Space size="20px" />
        <CommonButton
          size="large"
          type={"primary"}
          onClick={() => handlePageChange("password")}
        >
          {t("common:ok")}
        </CommonButton>
      </Container>
    );
  if (page == "resetSuccess")
    return (
      <Container>
        <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
          {t("signin:forgotpassword.reset_password_success")}
        </CommonText>
        <Space size="20px" />
        <TickIcon />
        <Space size="20px" />
        <CommonText fontSize="20px" lineHeight="40px">
          {t("signin:forgotpassword.reset_password_success_hint")}
        </CommonText>
        <Space size="20px" />
        <CommonButton
          size="large"
          type={"primary"}
          onClick={() => handlePageChange("password")}
        >
          {t("signin:forgotpassword.relogin")}
        </CommonButton>
      </Container>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;
