import styled from 'styled-components';
import { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from '../AppContext';
import Step1 from '../pageComponents/renew/Step1';
import Step2 from '../pageComponents/renew/Step2';
import Step3 from '../pageComponents/renew/Step3';
import CloseButton from '../components/common/buttons/CloseButton';
import LoginImage2 from '../assets/login-image2.jpg';
import { useLocation, useNavigate } from 'react-router-dom';
import { breakpoint } from '../utils/responsive';
import CommonText from '../components/common/typographies/CommonText';
import Space from '../components/common/Space';
import Dialog from '@mui/material/Dialog';
import FailIcon from '../assets/payment_fail.svg';
import CommonButton from '../components/common/buttons/CommonButton';
import { useTranslation } from 'react-i18next';

export default function Signin() {
  const { t } = useTranslation(['signin', 'common']);
  const { state } = useLocation();
  const [page, setPage] = useState(state?.page || 'root');
  const navigate = useNavigate();
  const { session } = useContext(AppContext);
  const [sessionExpired, setSessionExpired] = useState(false);
  const rightViewRef = useRef(null);

  useEffect(() => {
    if (session.user.renewPrice < 0) {
      if (window.history.state && window.history.state.idx > 0) navigate(-1);
      else navigate('/', { replace: true });
    }
  }, [session]);

  useEffect(() => {
    console.log('page changed', page);
    if (rightViewRef.current) {
      console.log('scroll to top');
      rightViewRef.current.scrollTo(0, 0);
    }
  }, [page]);

  const handlePageChange = (page) => {
    console.log('handlePageChange', page);
    setPage(page);
  };

  const onCloseSessionExpiredDialog = () => {
    setSessionExpired(false);
    handlePageChange('root');
  };

  const renderPage = () => {
    switch (page) {
      case 'root':
        return <Step1 handlePageChange={handlePageChange} data={session} />;
      case 'step2':
        return (
          <Step2
            handlePageChange={handlePageChange}
            setSessionExpired={setSessionExpired}
          />
        );
      case 'step3':
        return (
          <Step3
            handlePageChange={handlePageChange}
            setSessionExpired={setSessionExpired}
          />
        );
      default:
        return <Step1 handlePageChange={handlePageChange} data={session} />;
    }
  };
  return (
    <Container>
      <LeftImageView src={LoginImage2} alt="" />
      <RightView
        ref={rightViewRef}
        style={{ margin: page == 'step2' ? '0' : 'auto' }}
      >
        <RightViewContainer>{renderPage()}</RightViewContainer>
      </RightView>
      <CloseButton
        onClick={() => {
          if (window.history.state && window.history.state.idx > 0)
            navigate(-1);
          else navigate('/', { replace: true });
        }}
      />
      <Dialog onClose={onCloseSessionExpiredDialog} open={sessionExpired}>
        <div
          style={{
            padding: 80,
            width: 960,
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <CommonText
            whiteSpace="pre-line"
            fontWeight={600}
            fontSize="30px"
            lineHeight="42px"
          >
            {t('common:unknown_error')}
          </CommonText>
          <Space size="25px" />
          <img src={FailIcon} alt="" />
          <Space size="25px" />
          <CommonButton
            size="large"
            type={'primary'}
            onClick={onCloseSessionExpiredDialog}
          >
            {t('common:ok')}
          </CommonButton>
          <CloseButton onClick={onCloseSessionExpiredDialog} />
        </div>
      </Dialog>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
`;
const LeftImageView = styled.img`
  width: 50%;
  object-fit: cover;
  max-width: 950px;
  height: 100%;
  display: block;
  @media ${breakpoint.mobile} {
    display: none;
  }
`;
const RightView = styled.div`
  flex: 1;
  // margin: auto;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
const RightViewContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
