import colors from "../../utils/colors";
import DropdownCard from "../common/card/DropdownCard";
import ListItem from "../common/listItem";
import { useTranslation } from 'react-i18next';

const InfoCard = ({data}) => {
  const { t, i18n } = useTranslation();
  return Object.entries(data).map(([key, value]) => {
    return (
      <DropdownCard
        title={value.question[i18n.language=="en"?'En': 'Zh']}
        content={<ListItem text={value.answer[i18n.language=="en"?'En': 'Zh']} type="pen" color={colors.white}/>}
        dWidth="600px"
        mWidth="100%"
      />
    );
  });
};

export default InfoCard;
