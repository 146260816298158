import React from "react";
import styled from "styled-components";
import RadioPlayer from "./RadioPlayer";
import RadioImage from "../../../assets/resource_radioImage.png";
import { useState } from "react";

const AudioDisplay = ({ value, idx, audioRef, handleOnPlay }) => {
  return (
    <AudioContainer
      image={value?.thumbnail ? value?.thumbnail + "-thumbnail" : RadioImage}
    >
      <AudioChildContainer>
        <AudioImage
          src={value?.thumbnail ? value?.thumbnail + "-thumbnail" : RadioImage}
        />
        <RadioPlayer
          descriptionZh={value?.captionZh}
          descriptionEn={value?.captionEn}
          url={value.audio}
          idx={idx}
          audioRef={audioRef}
          handleOnPlay={handleOnPlay}
        />
      </AudioChildContainer>
    </AudioContainer>
  );
};

const AudioContainer = styled.div`
  height: auto;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  border-radius: 6px;

  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const AudioChildContainer = styled.div`
  backdrop-filter: blur(50px);
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  border-radius: 6px;

  @media (min-width: 1201px) {
    padding: 22px 18px;
  }
  @media (max-width: 1200px) {
    padding: 14px 12px;
  }
`;

const AudioImage = styled.img`
  user-drag: none;
  pointer-events: none;
  -webkit-touch-callout: none;
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 6px;
  flex-shrink: 0;
`;

export default AudioDisplay;
