import styled from "styled-components";
import ImgEdanSign from "../../assets/memProfile_edanSign.png";
import ImgAnsonSign from "../../assets/memProfile_ansonSign.png";
import ImgAkSign from "../../assets/memProfile_akSign.png";
import ImgAltonSign from "../../assets/memProfile_altonSign.png";
import ImgFrankieSign from "../../assets/memProfile_frankieSign.png";
import ImgIanSign from "../../assets/memProfile_ianSign.png";
import ImgJeremySign from "../../assets/memProfile_jeremySign.png";
import ImgJerSign from "../../assets/memProfile_jerSign.png";
import ImgLokmanSign from "../../assets/memProfile_lokmanSign.png";
import ImgStanleySign from "../../assets/memProfile_stanleySign.png";
import ImgTigerSign from "../../assets/memProfile_tigerSign.png";
import ImgKeungSign from "../../assets/memProfile_keungSign.png";
import { breakpoint } from "../../utils/responsive";

const imageList = (type) => {
  let memberName = type.toLowerCase();
  switch (memberName) {
    case "frankie chan":
      return ImgFrankieSign;
    case "alton wong":
      return ImgAltonSign;
    case "jeremy lee":
      return ImgJeremySign;
    case "tiger yau":
      return ImgTigerSign;
    case "lokman yeung":
      return ImgLokmanSign;
    case "anson lo":
      return ImgAnsonSign;
    case "keung to":
      return ImgKeungSign;
    case "jer lau":
      return ImgJerSign;
    case "anson kong":
      return ImgAkSign;
    case "stanley yau":
      return ImgStanleySign;
    case "ian chan":
      return ImgIanSign;
    case "edan lui":
      return ImgEdanSign;
    default:
      return null;
  }
};

const SignImage = ({ name }) => {
  return <ImageSign src={imageList(name)} />;
};

const ImageSign = styled.img`
  width: 120px;
  height: 80px;
  position: absolute;
  bottom: -10%;

  @media ${breakpoint.mobile} {
    left: 130px;
  }

  @media ${breakpoint.desktop} {
    left: 35%;
    width: 240px;
    height: 160px;
    position: static;
  }
`;

export default SignImage;
