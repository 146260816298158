import React, { useContext } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';
import { AppContext } from '../../../AppContext';

function LoginRegisterButton({ whiteMenu, onClick, ...props }) {
  const { t } = useTranslation('menu');
  const { disableMiroReg } = useContext(AppContext);
  const loginType = disableMiroReg ? t('login') : t('login0rRegister');

  return (
    <StyledButton
      whiteMenu={whiteMenu}
      onClick={(e) => {
        if (onClick) {
          onClick(e, {
            loginType,
          });
        }
      }}
      {...props}
    >
      <ResponsiveUI
        mobile={<StyledTypography>{t('login')}</StyledTypography>}
        desktop={<StyledTypography>{loginType}</StyledTypography>}
      />
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  && {
    filter: ${(props) => (props.whiteMenu ? 'invert(1)' : 'invert(0)')};
    width: auto;
    background-color: #ff5c00;
    border-color: 1px solid red;
    border-radius: 50px;
    @media ${breakpoint.mobile} {
      height: 30px;
      padding: 0px 16px;
    }
    @media ${breakpoint.desktop} {
      height: 40px;
      padding: 0px 24px;
    }
    @media ${breakpoint.largeDesktop} {
      height: 40px;
      padding: 0px 32px;
    }
    &:hover {
      background-color: #ff5c00;
    }
  }
`;

const StyledTypography = styled.div`
  font-family: 'Open Sans';
  font-weight: 600;
  color: white;
  text-align: center;
  @media ${breakpoint.mobile} {
    font-size: 14px;
  }
  @media ${breakpoint.desktop} {
    font-size: 16px;
  }
  @media ${breakpoint.largeDesktop} {
    font-size: 18px;
  }
`;

export default LoginRegisterButton;
