import { useQuery } from 'react-query';
import { AppContext } from '../AppContext';
import { useContext } from 'react';
import { getMenu } from '../apis';
import Loading from '../components/common/loading';
import styled from 'styled-components';
import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import CommonMenuItem from './common/menuItems/CommonMenuItem';
import DisabledMenuItem from './common/menuItems/DisabledMenuItem';
import DropDownMenuItem from './common/menuItems/DropDownMenuItem';
import CommonNavBar from './common/navbar/CommonNavBar';
import Grow from '@mui/material/Grow';
import CommonImage from './common/images/CommonImage';
import ImgLeft from '../assets/menu_left.png';
import ImgRight from '../assets/menu_right.png';
import CommonText from './common/typographies/CommonText';
import { useTranslation } from 'react-i18next';
import { breakpoint, ResponsiveUI, size } from '../utils/responsive';
import LanguageButton from './common/buttons/LanguageButton';
import License from './common/footers/License';
import ItemsGroup from './common/footers/ItemsGroup';
import CommonDivider from './common/divider/CommonDivider';

const Transition = React.forwardRef(function Transition(props, ref) {
  console.log(props);
  return <Grow style={{ transformOrigin: '0 0 0' }} {...props} ref={ref} />;
});
export default function Menu({ open, handleClose }) {
  const { t } = useTranslation('menu');
  const [dropdownOpen, setDropdown] = useState(false);
  const [imageLeft, setImageLeft] = useState(ImgLeft);
  const [imageRight, setImageRight] = useState(ImgRight);

  const { session, memberType } = useContext(AppContext);
  const { isLoading, isError, data, error } = useQuery(
    ['getMenu', session?.accessToken],
    () => getMenu(session?.accessToken)
  );

  if (isLoading || !data) {
    return open && <div></div>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  if (data) {
    console.log('data', data);
  }

  const setImages = (image1, image2) => {
    if (imageLeft != image1 || imageRight != image2) {
      setImageLeft(image1 + '-thumbnail');
      setImageRight(image2 + '-thumbnail');
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{ zIndex: 9998 }}
    >
      <div
        style={{
          background: '#F5F5F5',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CommonNavBar handleClose={handleClose} theme="black" />
        <MainContainer>
          <ResponsiveUI
            mobile={<div></div>}
            desktop={
              <StyledLeftDiv>
                <CommonImage
                  style={{ objectFit: 'cover' }}
                  src={imageLeft}
                  height="16vw"
                  width="16vw"
                ></CommonImage>
              </StyledLeftDiv>
            }
            mobileOffset={300}
          />

          <MenuContainer>
            <div style={{ marginBottom: '22px' }}>
              <ResponsiveUI mobile={<LanguageButton />} desktop={<div></div>} />
            </div>
            {data.data.results.map((menuItem) => {
              switch (menuItem.menuItemEn) {
                case 'WE ARE MIRROR':
                  return (
                    <div
                      onMouseEnter={() =>
                        setImages(menuItem.image1, menuItem.image2)
                      }
                    >
                      <DropDownMenuItem
                        handleClose={handleClose}
                        open={dropdownOpen}
                        setDropdown={setDropdown}
                        option1={t('team')}
                        link1={'/team-profile'}
                        option2={t('members')}
                        link2={'/member-profile'}
                        zhTitle={menuItem.menuItemZh}
                        enTitle={menuItem.menuItemEn}
                        link="/team-profile"
                      />
                    </div>
                  );
                case 'HOME':
                  return (
                    <div
                      onMouseEnter={() =>
                        setImages(menuItem.image1, menuItem.image2)
                      }
                    >
                      <CommonMenuItem
                        onClick={handleClose}
                        zhTitle={menuItem.menuItemZh}
                        enTitle={menuItem.menuItemEn}
                        link="/"
                      />
                    </div>
                  );
                case 'DISCOGRAPHY':
                  return (
                    <div
                      onMouseEnter={() =>
                        setImages(menuItem.image1, menuItem.image2)
                      }
                    >
                      <CommonMenuItem
                        onClick={handleClose}
                        zhTitle={menuItem.menuItemZh}
                        enTitle={menuItem.menuItemEn}
                        link="/discography"
                      />
                    </div>
                  );
                case 'ACTIVITY':
                  return (
                    <div
                      onMouseEnter={() =>
                        setImages(menuItem.image1, menuItem.image2)
                      }
                    >
                      {session?.user?.memberType === 'MIRO' ? (
                        <CommonMenuItem
                          onClick={handleClose}
                          zhTitle={menuItem.menuItemZh}
                          enTitle={menuItem.menuItemEn}
                          link="/activity"
                        />
                      ) : (
                        <DisabledMenuItem
                          type="miro"
                          zhTitle={menuItem.menuItemZh}
                          enTitle={menuItem.menuItemEn}
                          link="/activity"
                        ></DisabledMenuItem>
                      )}
                    </div>
                  );
                case 'MEDIA':
                  return (
                    <div
                      onMouseEnter={() =>
                        setImages(menuItem.image1, menuItem.image2)
                      }
                    >
                      {session?.user?.memberType === 'MIRO' ? (
                        <CommonMenuItem
                          onClick={handleClose}
                          zhTitle={menuItem.menuItemZh}
                          enTitle={menuItem.menuItemEn}
                          link="/media-series"
                        />
                      ) : (
                        <DisabledMenuItem
                          type="miro"
                          zhTitle={menuItem.menuItemZh}
                          enTitle={menuItem.menuItemEn}
                          link="/media-series"
                        ></DisabledMenuItem>
                      )}
                    </div>
                  );
                case 'SHOP':
                  return (
                    <div
                      onMouseEnter={() =>
                        setImages(menuItem.image1, menuItem.image2)
                      }
                    >
                      <CommonMenuItem
                        onClick={handleClose}
                        zhTitle={menuItem.menuItemZh}
                        enTitle={menuItem.menuItemEn}
                        link="https://shop.mirrorweare.com"
                        external={true}
                      />
                    </div>
                  );
              }
            })}
          </MenuContainer>

          <ResponsiveUI
            mobile={<div></div>}
            desktop={
              <StyledRightDiv>
                <CommonImage
                  style={{ objectFit: 'cover' }}
                  src={imageRight}
                  height="25vw"
                  width="25vw"
                ></CommonImage>
              </StyledRightDiv>
            }
            mobileOffset={300}
          />

          <ItemsGroup handleClose={handleClose}></ItemsGroup>
        </MainContainer>
        <ResponsiveUI
          mobile={
            <StyledFooterBottomDiv>
              <CommonDivider style={{ borderColor: '#111111' }}></CommonDivider>
              <div style={{ padding: '9px 15px' }}>
                <License />
              </div>
            </StyledFooterBottomDiv>
          }
          desktop={
            <StyledFooterRightDiv>
              <License />
            </StyledFooterRightDiv>
          }
        />
      </div>
    </Dialog>
  );
}

const MainContainer = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
`;

const StyledLeftDiv = styled.div`
  position: absolute;
  left: 50px;
  top: 30%;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 100px;
  background: #f5f5f5;

  &::-webkit-scrollbar {
    display: none;
  }

  // height: calc(100% - 36px - 2 * 9px);
  :before {
    position: absolute;
    z-index: 200;
    content: '';
    width: 100%;
    height: calc(100% - 116px);
    pointer-events: none;
  }

  @media ${breakpoint.mobile} {
  }
  @media ${breakpoint.desktop} {
    height: 100%;
    :before {
      height: calc(100% - 100px);
    }
  }
  @media ${breakpoint.largeDesktop} {
    :before {
      height: calc(100% - 130px);
    }
  }
`;

const StyledRightDiv = styled.div`
  position: absolute;
  right: 50px;
  top: 30%;
`;

const StyledSocialMediaMobileDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  cursor: pointer;
  margin-right: 5%;
`;

const StyledMediaItem = styled.div`
  margin: 5px 0;
`;

const StyledSocialMediaDiv = styled.div`
  position: absolute;
  bottom: 0;
  right: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
`;

const StyledFooterRightDiv = styled.div`
  position: absolute;
  z-index: 200;
  bottom: 10px;
  right: 50px;
`;

const StyledFooterBottomDiv = styled.div`
  background-color: #f5f5f5;
  z-index: 200;
  width: 100%;
  bottom: 0;
  @media ${breakpoint.desktop} {
    width: 325px;
    bottom: 25px;
    left: 50px;
  }
  @media ${breakpoint.largeDesktop} {
    width: 380px;
    bottom: 25px;
    left: 50px;
  }
`;
