/* eslint-disable */
export default function (user) {
  if (user) {
    if (user.memberType === "MIRO") {
      if (
        user.lastMemberExpiresAt &&
        new Date(user.lastMemberExpiresAt) >= new Date()
      ) {
        return "MIRO";
      } else if (user.memberStartAt && user.memberExpiresAt) {
        if (
          new Date(user.memberStartAt) <= new Date() &&
          new Date(user.memberExpiresAt) >= new Date()
        ) {
          return "MIRO";
        }
      } else if (
        user.memberExpiresAt &&
        new Date(user.memberExpiresAt) >= new Date()
      ) {
        return "MIRO";
      }
    }
    return "WEB";
  }
  return "ANONYMOUS";
}
