import React from 'react';
import styled from 'styled-components';

function CommonDivider(props) {

    return (
        <StyledHr {...props}/>
    );
}

const StyledHr = styled.hr`
    width: 100%;
    opacity: 0.3;
`;

export default CommonDivider;