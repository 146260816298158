import React, { useState } from "react";
import styled from "styled-components";
import CommonText from "../../../components/common/typographies/CommonText";
import WhiteDot from "../../../assets/white_dot.svg";
import Space from "../../../components/common/Space";
import { breakpoint } from "../../../utils/responsive";

function LiveIndicator({ t, isLive }) {
  return (
    <div>
      {isLive && (
        <Tag>
          <Dot src={WhiteDot} />
          <CommonText
            color="white"
            fontSize="15px"
            mFontSize="13px"
            fontWeight={500}
          >
            {t("live")}
          </CommonText>
        </Tag>
      )}
    </div>
  );
}

const Dot = styled.img`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  @media ${breakpoint.mobile} {
    margin-right: 6px;
  }
`;
const Tag = styled.div`
  width: 81px;
  height: 31px;
  background: #d32525;
  border: 1px solid rgb(235, 88, 88);
  box-shadow: 0px 3px 10px rgba(255, 0, 0, 0.2);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${breakpoint.mobile} {
    width: 69px;
    height: 31px;
  }
`;

export default LiveIndicator;
