import styled from "styled-components"
import OrderInfo from "../pageComponents/profile/order/OrderInfo"
import { ResponsiveUI } from "../utils/responsive"

export default function ProfileOrder() {
  const _renderContent = (isMobile) => {
    return (
      <StyleContentDiv isMobile={isMobile}>
        <OrderInfo />
      </StyleContentDiv>
    )
  }

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  )
}

const StyleContentDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
