import styled from "styled-components";
import { ResponsiveUI, breakpoint } from "../../../utils/responsive";
import CommonImage from "../images/CommonImage";
import RightArrow from "../../../assets/Arrow_black.svg";
import WhiteArrow from "../../../assets/Arrow_white.svg";

const BackArrowButton = ({ whiteTheme, onClick }) => {
  return (
    <ResponsiveUI
      mobile={
        <ArrowButton>
          <CommonImage
            height="12.1px"
            width="17.6px"
            onClick={onClick}
            src={whiteTheme ? WhiteArrow : RightArrow}
          ></CommonImage>
        </ArrowButton>
      }
      desktop={
        <ArrowButton>
          <CommonImage
            height="15px"
            width="22px"
            onClick={onClick}
            src={whiteTheme ? WhiteArrow : RightArrow}
          ></CommonImage>
        </ArrowButton>
      }
    />
  );
};

const ArrowButton = styled.div`
  position: absolute;
  cursor: pointer;
  width: 32px;
  height: 32px;

  @media ${breakpoint.mobile} {
    top: 30px;
    left: 20px;
  }

  @media ${breakpoint.desktop} {
    top: 61px;
    left: 71px;
  }
`;

export default BackArrowButton;
