import { Dialog, IconButton } from "@mui/material"
import React from "react"
import styled from "styled-components"
import IconClose from "../../../assets/nav_cross.svg"
import { breakpoint } from "../../../utils/responsive"

export function CommonDialog(props) {
  const { onClose, visible, children, fullScreen, ...other } = props
  return (
    <Dialog maxWidth={false} onClose={onClose} open={visible} fullScreen={fullScreen} {...other}>
      {children}
    </Dialog>
  )
}

export default function CommonCloseDialog(props) {
  const { onClose, visible, showCloseButton = true, children, fullScreen, padding, ...other } = props

  return (
    <CommonDialog maxWidth={false} onClose={onClose} open={visible} fullScreen={fullScreen} {...other}>
      <Container fullScreen={fullScreen} padding={padding} style={{ backgroundColor: "#F9F9F9" }}>
        {
          showCloseButton && <IconButton style={{ alignSelf: "end" }} onClick={onClose}>
            <img src={IconClose} alt="Close" />
          </IconButton>
        }
        {children}
      </Container>
    </CommonDialog>
  )
}

const Container = styled.div`
  flex-direction: column;
  display: flex;
  padding: ${props => props.padding ?
    props.padding :
    props.fullScreen ?
      '20px' :
      '60px'
  };
  width: 100%;
  height: 100%;
  @media ${breakpoint.mobile} {
    padding: 32px 20px 32px 20px;
  }
`
