import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CommonLabel from "../../../components/common/labels/CommonLabel";
import CommonText from "../../../components/common/typographies/CommonText";
import moment from "moment";
import "moment/locale/zh-hk";
import "moment-timezone";
import { ResponsiveUI } from "../../../utils/responsive";
import Space from "../../../components/common/Space";

const InfoView = (props) => {
  const { title, description, style } = props;
  return (
    <div
      style={{
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        textAlign: "start",
        overflowWrap: "anywhere",
        ...style,
      }}
    >
      <CommonLabel>{title}</CommonLabel>
      <CommonText>{description}</CommonText>
    </div>
  );
};

function OrderDetailHeader({ order }) {
  const { t, i18n } = useTranslation("profile");

  const _renderDesktop = () => {
    return [
      <CommonText lineHeight="1.2" fontSize="20px" color="#E04620">
        {t("order.total_price", { price: order.item.amount })}
      </CommonText>,
      <StyledHeadInfoDiv>
        <InfoView
          title={t("order.order_date")}
          description={moment(order.createdAt)
            .tz("Asia/Hong_Kong")
            .locale(i18n.language === "en" ? "en" : "zh-hk")
            .format("YYYY.MM.DD hh:mma")}
        />
        <InfoView
          title={t("order.payment_method")}
          description={
            order.recon_payment
              ? t("order.payment." + order.recon_payment)
              : order.payment
          }
        />
        {order.payment_no && (
          <InfoView
            title={t("order.payment_no")}
            description={order.payment_no}
          />
        )}
      </StyledHeadInfoDiv>,
    ];
  };

  const _renderMobile = () => {
    return (
      <>
        <CommonText
          fontSize="14px"
          color="#E04620"
          style={{ marginTop: "10px" }}
        >
          {t("order.total_price", { price: order.item.amount })}
        </CommonText>
        <StyledHeadInfoDiv isMobile={true}>
          <InfoView
            style={{ width: "50%" }}
            title={t("order.order_date")}
            description={moment(order.createdAt)
              .tz("Asia/Hong_Kong")
              .locale(i18n.language === "en" ? "en" : "zh-hk")
              .format("YYYY.MM.DD hh:mma")}
          />
          <InfoView
            style={{ width: "50%" }}
            title={t("order.payment_method")}
            description={
              order.recon_payment
                ? t("order.payment." + order.recon_payment)
                : order.payment
            }
          />
        </StyledHeadInfoDiv>
        {order.payment_no && (
          <>
            <Space size="15px" />
            <InfoView
              style={{ width: "100%" }}
              title={t("order.payment_no")}
              description={order.payment_no}
            />
          </>
        )}
      </>
    );
  };

  return <ResponsiveUI mobile={_renderMobile()} desktop={_renderDesktop()} />;
}

const StyledHeadInfoDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
  justify-content: space-between;
  align-items: ${(props) => (props.isMobile ? "flex-start" : "center")};
`;

export default OrderDetailHeader;
