import styled from "styled-components";
import { useTranslation } from "react-i18next";

import CommonText from "../../components/common/typographies/CommonText";
import colors from "../../utils/colors";
import InfoCard from "../../components/memberProfileDetail/InfoCard";
import SignImage from "../../components/memberProfileDetail/SignImage";
import ImgStickerHeartEye from "../../assets/memProfile_heartEyeSticker.svg";
import ImgMobileStickerHeartEye from "../../assets/memProfile_heartEyeMobileSticker.svg";
import ImgStickerLetMeKnow from "../../assets/memProfile_letMeKnowSticker.svg";
import ImgMobileStickerLetMeKnow from "../../assets/memProfile_letMeKnowMobileSticker.svg";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

const AskMember = ({ data }) => {
  const { i18n } = useTranslation();

  return (
    <StyledMainDiv>
      <StyledTitleDiv>
        <div>
          <StyledZhNameDiv>
            {i18n.language === "zh" && (
              <CommonText fontSize="30px" mFontSize="16px" color={colors.white}>
                想問{data && data.memberNameZh}
              </CommonText>
            )}
          </StyledZhNameDiv>
          <div>
            <CommonText
              uppercase
              fontSize="60px"
              mFontSize="33px"
              type="primary"
              color={colors.white}
            >
              Ask {data && data.memberNameEn.split(" ")[0]}
            </CommonText>
          </div>
        </div>
        <SignImage name={data && data.memberNameEn} />
      </StyledTitleDiv>

      <StyledFlexDiv>
        <StyledLeftDiv>
          <StyledImageDiv>
            <DoorImage image={data && data.images[1] && data.images[1]} />
            <CircleImage image={data && data.images[2] && data.images[2]} />
            <ResponsiveUI
              mobile={<StickerMobileHeartEye src={ImgMobileStickerHeartEye} />}
              desktop={<StickerHeartEye src={ImgStickerHeartEye} />}
            />
          </StyledImageDiv>
        </StyledLeftDiv>

        <StyledRightDiv>
          <InfoCard data={data && data.questions} />
        </StyledRightDiv>
        <ResponsiveUI
          mobile={<StickerMobileLetMeKnow src={ImgMobileStickerLetMeKnow} />}
          desktop={<StickerLetMeKnow src={ImgStickerLetMeKnow} />}
        />
      </StyledFlexDiv>
    </StyledMainDiv>
  );
};

const StyledMainDiv = styled.div`
  padding: 15px;
  text-align: left;

  @media ${breakpoint.desktop} {
    padding: 80px 60px;
  }

  @media ${breakpoint.largeDesktop} {
    padding: 80px 150px;
  }
`;

const StyledTitleDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  margin-top: 50px;

  @media ${breakpoint.desktop} {
    margin-top: 0px;
  }
`;

const StyledZhNameDiv = styled.div`
  height: 24px;

  @media ${breakpoint.desktop} {
    height: 42px;
  }
`;

const StyledFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: column-reverse;

  @media ${breakpoint.desktop} {
    flex-direction: row;
  }
`;

const StyledImageDiv = styled.div`
  position: relative;
  padding: 25px;

  @media ${breakpoint.desktop} {
    flex-direction: row;
    padding: 0px;
  }
`;

const StyledLeftDiv = styled.div`
  margin-top: 50px;

  @media ${breakpoint.desktop} {
    margin-top: 100px;
  }
`;

const StickerHeartEye = styled.img`
  position: absolute;
  right: 50px;
  bottom: -90px;
  user-drag: none;
`;

const StickerMobileHeartEye = styled.img`
  position: absolute;
  left: 80px;
  bottom: -20px;
  user-drag: none;

  @media (min-width: 750px) {
    left: 36%;
  }
`;

const CircleImage = styled.div`
  width: 175px;
  height: 175px;
  background-image: url(${(props) => props.image});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  position: absolute;
  bottom: -50px;
  right: 20px;
  object-fit: cover;
  user-drag: none;

  @media (min-width: 750px) {
    right: 30%;
  }

  @media ${breakpoint.desktop} {
    width: 280px;
    height: 280px;
    bottom: -170px;
    right: -170px;
  }

  @media ${breakpoint.largeDesktop} {
    width: 350px;
    height: 350px;
    bottom: -140px;
    right: -240px;
  }
`;

const DoorImage = styled.div`
  width: 175px;
  height: 215px;
  background-image: url(${(props) => props.image});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50% 50% 0% 0%;
  position: relative;
  object-fit: cover;
  user-drag: none;

  @media (min-width: 750px) {
    left: 30%;
  }

  @media ${breakpoint.desktop} {
    border-radius: 200px 200px 0% 0%;
    width: 290px;
    height: 360px;
    left: 0px;
  }

  @media ${breakpoint.largeDesktop} {
    width: 350px;
    height: 430px;
  }
`;

const StyledRightDiv = styled.div`
  margin-top: 70px;

  @media ${breakpoint.desktop} {
    margin-top: 100px;
    // width: 60%;
  }
`;

const StickerLetMeKnow = styled.img`
  position: absolute;
  right: 60px;
  top: -50px;
  user-drag: none;
`;

const StickerMobileLetMeKnow = styled.img`
  position: absolute;
  right: 0px;
  top: -10px;
  user-drag: none;
`;

export default AskMember;
