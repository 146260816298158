import { Grid } from '@mui/material';
import styled from 'styled-components';

const Content = ({ lang, contentZh, contentEn }) => {
  const _renderEnVersion = () => {
    return (
      <Grid item xs container direction="row" style={{ marginTop: 50 }}>
        <StyledDiv>
          <StyledTitle>Personal Information Collection Statement</StyledTitle>
          <StyledContent dangerouslySetInnerHTML={{ __html: contentEn }} />
        </StyledDiv>
      </Grid>
    );
  };

  const _renderZhVersion = () => {
    return (
      <Grid item xs container direction="row" style={{ marginTop: 50 }}>
        <StyledDiv>
          <StyledTitle>個人資料收集聲明</StyledTitle>
          <StyledContent dangerouslySetInnerHTML={{ __html: contentZh }} />
        </StyledDiv>
      </Grid>
    );
  };

  return lang === 'en' ? _renderEnVersion() : _renderZhVersion();
};

const StyledContent = styled.div`
  text-align: justify;
  p {
    margin-bottom: 20px;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  text-decoration: underline;
`;

const StyledDiv = styled.div`
  text-align: justify;
`;

export default Content;
