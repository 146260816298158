import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { listDiscographies } from '../apis';
import styled from 'styled-components';
import ItemGroup from '../pageComponents/discography/ItemGroup';
import FilterGroup from '../pageComponents/discography/FilterGroup';
import CommonText from '../components/common/typographies/CommonText';
import CommonNavBar from '../components/common/navbar/CommonNavBar';
import { useTranslation } from 'react-i18next';
import CommonImage from '../components/common/images/CommonImage';
import Line from '../assets/discography_line.svg';
import CDImg from '../assets/discography_cd.svg';
import CommonBottomBar from '../components/common/navbar/CommonBottomBar';
import Loading from '../components/common/loading';
import { AppContext } from '../AppContext';
import MetaTag from '../utils/MetaTag';
import { breakpoint, ResponsiveUI } from '../utils/responsive';
import ActivitySpin from '../components/common/animations/ActivitySpin';
import TitleGroup from '../components/common/typographies/TitleGroup';

export default function Discography() {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('desc');
  const [selected, setSelect] = useState({ nameZh: '全部', nameEn: 'all' });
  const { t, i18n } = useTranslation('discography');
  const { isLoading, isError, data, error, refetch } = useQuery(
    ['listDiscographies'],
    () => listDiscographies(search, selected.nameEn, selected.nameZh, sort)
  );
  useEffect(() => {
    console.log('isRefetching');
    refetch();
  }, [search, selected, sort]);

  if (isLoading || !data.data) {
    return <Loading></Loading>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  console.log('data', data);
  return (
    <StyledMainDiv>
      <MetaTag page="discography" />

      <StyledLineDiv>
        <CommonImage
          style={{ objectFit: 'contain' }}
          width="100%"
          height="100%"
          src={Line}
        ></CommonImage>
      </StyledLineDiv>

      <CommonNavBar theme="white" />
      <StyledBodyDiv>
        <ResponsiveUI
          mobile={
            <StyledTitleDiv>
              <TitleGroup
                zhTitle="音樂作品"
                enTitle="DISCOGRAPHY"
                color="white"
              />
              <div style={{ position: 'absolute', top: -50, right: -20 }}>
                <ActivitySpin image={CDImg} />
              </div>
            </StyledTitleDiv>
          }
          desktop={
            <StyledTitleDiv>
              <TitleGroup
                zhTitle="音樂作品"
                enTitle="DISCOGRAPHY"
                color="white"
              />
              <div style={{ position: 'absolute', top: -80, right: -200 }}>
                <ActivitySpin image={CDImg} />
              </div>
            </StyledTitleDiv>
          }
        />

        <StyledFilterGroupDiv>
          <FilterGroup
            total={data.data.total}
            open={open}
            setOpen={setOpen}
            selected={selected}
            setSelect={setSelect}
            setSearch={setSearch}
            sort={sort}
            setSort={setSort}
          ></FilterGroup>
        </StyledFilterGroupDiv>

        {data.data.total > 0 && (
          <div>
            {data.data.results.map((itemsInYear, index) => {
              return <ItemGroup key={index} data={itemsInYear}></ItemGroup>;
            })}
          </div>
        )}

        {data.data.total <= 0 && (
          <ResponsiveUI
            mobile={
              <StyledNotFoundDiv>
                <CommonText
                  type="common"
                  color="white"
                  fontSize="16px"
                  lineHeight="40px"
                  uppercase
                >
                  {t('noResult')}
                </CommonText>
              </StyledNotFoundDiv>
            }
            desktop={
              <StyledNotFoundDiv>
                <CommonText
                  type="common"
                  color="white"
                  fontSize="30px"
                  lineHeight="40px"
                  uppercase
                >
                  {t('noResult')}
                </CommonText>
              </StyledNotFoundDiv>
            }
          />
        )}
      </StyledBodyDiv>
      <CommonBottomBar bgColor="orange"></CommonBottomBar>
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  background: #ec9022;
  position: relative;
`;

const StyledBodyDiv = styled.div`
  padding: 0 20px;
  @media ${breakpoint.desktop} {
    padding: 50px;
  }
`;

const StyledLineDiv = styled.div`
  position: absolute;
  top: 0;
  width: 36%;
`;

const StyledTitleDiv = styled.div`
  width: min-content;
  position: relative;
  margin: 49.6px auto 91px auto;
  @media ${breakpoint.desktop} {
    margin-bottom: 100px;
    margin-top: 77.5px;
  }
`;

const StyledNotFoundDiv = styled.div`
  margin-bottom: 440px;
  margin-top: 115px;
  color: white;
`;

const StyledFilterGroupDiv = styled.div`
  margin-bottom: 20px;
  @media ${breakpoint.desktop} {
    margin-bottom: 50px;
  }
`;
