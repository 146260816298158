/* eslint-disable */
const members_name_en = {
  王智德: 'Alton Wong',
  江𤒹生: 'Anson Kong',
  盧瀚霆: 'Anson Lo',
  呂爵安: 'Edan Lui',
  陳瑞輝: 'Frankie Chan',
  陳卓賢: 'Ian Chan',
  李駿傑: 'Jeremy Lee',
  柳應廷: 'Jer Lau',
  姜濤: 'Keung To',
  楊樂文: 'Lokman Yeung',
  邱士縉: 'Stanley Yau',
  邱傲然: 'Tiger Yau',
};
export default function (supporting_members, language) {
  if (language == 'zh') return supporting_members?.join(', ');
  else {
    return supporting_members
      .map((name_zh) => members_name_en[name_zh])
      .join(', ');
  }
}
