import { useState } from 'react'
import { Collapse, Grid, Box } from '@mui/material';
import styled from '@emotion/styled';
import CommonText from '../../components/common/typographies/CommonText';
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

const GreyBorderContainer = styled(Grid)`
    border: 1px solid rgba(0, 0, 0, .1);
    margin-bottom: 25px;
    text-align: left;
`

const Header = ({ header, open }) => {
    return (
        <ResponsiveUI
            mobile={
                <Box pt={4} pb={4} pl={3} pr={3} display='flex' style={{ cursor: 'pointer' }}>
                    <CommonText type='common' style={{ fontSize: 16, flex: 1 }}>{header}</CommonText>
                    <CommonText type='common' style={{ fontSize: 16 }}>{open ? "-" : "+"}</CommonText>
                </Box>
            }

            desktop={
                <Box pt={4} pb={4} pl={3} pr={3} display='flex' style={{ cursor: 'pointer' }}>
                    <CommonText type='common' fontSize="24px" largeFontSize="30px" style={{ flex: 1 }}>{header}</CommonText>
                    <CommonText type='common' fontSize="30px">{open ? "-" : "+"}</CommonText>
                </Box>
            }
        />
    )
}

function CollapsibleItem(props) {
    const { header, content } = props
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <GreyBorderContainer container direction='column' onClick={handleClick}>
            <Header open={open} header={header} />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box pt={2} pl={3} pr={3} pb={3} style={{ textAlign:'left', cursor: 'pointer' }}>
                    <ResponsiveUI 
                        mobile={
                            <CommonText type='common' style={{ fontSize: 16 }}>{content}</CommonText>
                        }
                    
                        desktop={
                            <CommonText type='common' fontSize="18px" largeFontSize="20px">{content}</CommonText>
                        }
                    />
                </Box>
            </Collapse>
        </GreyBorderContainer>
    )
}

export default CollapsibleItem