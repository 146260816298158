export const formatAddress = (address, language) => {
  if (!address) return;
  if (address.type) {
    if (address.type == "Express") {
      if (language === "en") {
        return `${
          address.address?.flat ? "Flat " + address.address?.flat : ""
        },  ${address.address?.floor ? address.address?.floor + "/F" : ""}, ${
          address.address?.block ? "Block " + address.address?.block : ""
        }, ${address.address?.building || ""} ${
          address.address?.estate || ""
        } ${address.address?.street || ""} ${address.areaEn} ${
          address.regionEn
        }`?.trim();
      } else {
        return `${address.regionZh} ${address.areaZh} ${
          address.address?.street || ""
        } ${address.address?.estate || ""} ${address.address?.building || ""} ${
          address.address?.block ? address.address?.block + "座" : ""
        } ${address.address?.floor ? address.address?.floor + "樓" : ""} ${
          address.address?.flat ? address.address?.flat + "室" : ""
        }`?.trim();
      }
    } else {
      if (language === "en") return address.address?.en?.address;
      else return address.address?.zh?.address;
    }
  } else {
    var formatted = address?.address1?.trim() || "";
    if (language === "en") {
      // address1 address2 area region
      if (address && address.address2) {
        formatted = `${formatted} ${address.address2}`?.trim();
        formatted = `${formatted} ${address.area} ${address.region}`?.trim();
      }
    } else {
      // region area address1 address2
      formatted = `${address.region} ${address.area} ${formatted}`?.trim();
      if (address && address.address2) {
        formatted = `${formatted} ${address.address2}`?.trim();
      }
    }
    return formatted;
  }
};
