import React from 'react';
import styled from 'styled-components';
import CommonText from '../../components/common/typographies/CommonText';
import CommonImage from '../../components/common/images/CommonImage';
import Banner from '../../assets/activity_banner.jpg';
import ActivitySpin from '../../components/common/animations/ActivitySpin';
import { breakpoint, ResponsiveUI } from "../../utils/responsive";
import { useTranslation } from 'react-i18next';
import TitleGroup from '../../components/common/typographies/TitleGroup';

export default function MainSection({data}) {
    const { t, i18n } = useTranslation();
    return (
        <StyledDiv>
            <ResponsiveUI
                mobile={
                    <StyledTitleDiv>
                        <TitleGroup zhTitle="特別活動" enTitle="ACTIVITY" />
                        <StyledAnimationDiv>
                            <ActivitySpin></ActivitySpin>
                        </StyledAnimationDiv>
                    </StyledTitleDiv>
                }
                desktop={
                    <StyledTitleDiv>
                        <TitleGroup zhTitle="特別活動" enTitle="ACTIVITY" />
                        <StyledAnimationDiv>
                            <ActivitySpin></ActivitySpin>
                        </StyledAnimationDiv>
                    </StyledTitleDiv>
                }
            />



            <StyledImgDiv>
                <ResponsiveUI
                    mobile={
                        <CommonImage style={{ objectFit: 'contain' }} height="100%" width='100%' src={Banner}></CommonImage>
                    }

                    desktop={
                        <CommonImage style={{ objectFit: 'contain' }} height='100%' width='100%' src={Banner}></CommonImage>
                    }
                />
            </StyledImgDiv>
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledTitleDiv = styled.div`
    margin-top: 50px;
    margin-bottom: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    @media ${breakpoint.desktop} {
        margin-top: 70px;
        margin-bottom: 110px;
    }
`;

const StyledImgDiv = styled.div`
    width: 100%;
`;

const StyledAnimationDiv = styled.div`
    position: absolute;
    left: 50%;
    margin: -30px 0px 0px 40px;
    z-index: -1;
    @media ${breakpoint.desktop} {
        margin: -20px 0px 0px 70px;
    }
`;