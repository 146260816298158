import { useQuery } from "react-query";
import { getTeamProfile } from "../apis";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../components/common/loading";
import MetaTag from "../utils/MetaTag";
import { TeamProfilePage, i18nHelper } from "mofc-component";
import CommonNavBar from "../components/common/navbar/CommonNavBar";
import CommonBottomBar from "../components/common/navbar/CommonBottomBar";
import ScrollIndicator from '../components/common/scrollIndicator';

export default function TeamProfile() {
  const { isLoading, isError, data, error } = useQuery(["getTeamProfile"], () =>
    getTeamProfile()
  );
  const { i18n } = useTranslation()
  const [isi18nLoaded, setIsi18nLoaded] = useState(false)
  const [openBanner, setOpenBanner] = useState(null);
  useEffect(()=>{
    if(i18n){
      i18nHelper.loadTeamProfileRes(i18n)
      setIsi18nLoaded(true)
    }
  },[i18n])

  if (!isi18nLoaded || isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div style={{ background: "#4f3fb2" }}>
      <MetaTag page="team-profile" />
      <CommonNavBar theme="white" onOpenBanner={setOpenBanner}/>
      <TeamProfilePage
        data={data}
        isLoading={isLoading}
        ScrollIndicator={ScrollIndicator}
        openBanner={openBanner}
        footer={<CommonBottomBar theme="white" bgColor="black" />}
      />
    </div>
  );
}
