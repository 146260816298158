import { useTranslation } from 'react-i18next';
import CommonNavBar from '../components/common/navbar/CommonNavBar';
import CommonText from '../components/common/typographies/CommonText';
import { Stack } from '@mui/material';
import TitleGroup from '../components/common/typographies/TitleGroup';
import styled from 'styled-components';
import IconBlackIg from '../assets/exterinalLink_igBlackIcon.svg';
import IconWhiteIg from '../assets/exterinalLink_igWhiteIcon.svg';
import IconFb from '../assets/ic-facebook-black.svg';
import IconTwitter from '../assets/ic-twitter-black.svg';
import IconYoutube from '../assets/ic-youtube-black.svg';
import { breakpoint, ResponsiveUI } from '../utils/responsive';
import Space from '../components/common/Space';
import { Grid, Box } from '@mui/material';
import ReactGA from 'react-ga4';
import colors from '../utils/colors';

const Title = ({ text }) => {
  return (
    <ResponsiveUI
      mobile={
        <CommonText
          fontSize="16px"
          textAlign="left"
          style={{ width: '100%', padding: '0px 20px' }}
        >
          {text}
        </CommonText>
      }
      desktop={
        <CommonText
          fontSize="30px"
          mFontSize="16px"
          textAlign="left"
          style={{ width: '100%' }}
        >
          {text}
        </CommonText>
      }
      mobileOffset={200}
    />
  );
};

const ExternalLink = () => {
  const { t, i18n } = useTranslation('externalLink');

  const fansClub = [
    { name: t('altonWongfanPage'), instagram: '@altonwong.hkfc' },
    { name: t('ansonKongFanPage'), instagram: '@ansonkeverything' },
    { name: t('ansonLoFanPage'), instagram: '@ansonlohontingfanclub' },
    { name: t('edanLuiFanPage'), instagram: '@edanlui_fansclub' },
    { name: t('frankieChanFanPage'), instagram: '@frankiechanfans_hk' },
    { name: t('ianChanFanPage'), instagram: '@ianchan.hk' },
    { name: t('jerLauFanPage'), instagram: '@jerlaufanclub.hk' },
    { name: t('jeremyLeeFanPage'), instagram: '@jeremyleefansclub' },
    { name: t('keungShowFanPage'), instagram: '@keungshowhkfanclub' },
    { name: t('lokmanYeungFanPage'), instagram: '@hello.llokmann' },
    { name: t('stanleyYauFanPage'), instagram: '@stanley_fansclub' },
    { name: t('tigerYnyFanPage'), instagram: '@tiger_conservation' },
  ];

  const socialMedia = [
    {
      title: 'MIRROR OFFICIAL FAN CLUB',
      instagram: {
        name: '@miro.weallare',
        link: 'https://www.instagram.com/miro.weallare/',
      },
      facebook: {
        name: '@MIRO.weallare',
        link: 'https://www.facebook.com/MIRO.weallare',
      },
    },
    {
      title: 'MIRROR',
      instagram: {
        name: '@mirror.weare',
        link: 'https://www.instagram.com/mirror.weare/',
      },
      facebook: {
        name: '@MIRROR.WeAre',
        link: 'https://www.facebook.com/MIRROR.WeAre/',
      },
      youtube: {
        name: '@MIRRORweare',
        link: 'https://www.youtube.com/c/MIRRORweare',
      },
      twitter: {
        name: '@MIRROR_weare',
        link: 'https://twitter.com/MIRROR_weare',
      },
    },
  ];

  const handleUrlClick = (link) => {
    ReactGA.event('outbound_click_from_external_links', {
      param: link,
    });
    window.open(link, '_blank');
  };

  return (
    <StyledMainDiv>
      <CommonNavBar theme="black" style={{ background: 'white' }} />

      <TitleGroup zhTitle="外部連結" enTitle="External Links" />
      <ResponsiveUI mobile={<Space size="40px" />} desktop={<div />} />
      <Container>
        <Title text={t('followOurSocialMedia')} />
        <StyledTable>
          {socialMedia.map((item, idx) => {
            return (
              <StyledTrNoHover>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ height: 'auto', padding: 30 }}
                  spacing={2}
                >
                  <Grid item xs={12} lg={6} xl={2.88}>
                    <CommonText
                      fontSize="20px"
                      mFontSize="12px"
                      textAlign="left"
                    >
                      {item.title}
                    </CommonText>
                  </Grid>
                  <Grid item xs={6} lg={3} xl={2.28}>
                    <SocialMedia
                      onClick={() => handleUrlClick(item.instagram.link)}
                    >
                      <MediaIcon src={IconBlackIg} />
                      <CommonText
                        fontSize="20px"
                        mFontSize="12px"
                        textAlign="left"
                      >
                        {item.instagram.name}
                      </CommonText>
                    </SocialMedia>
                  </Grid>
                  <Grid item xs={6} lg={3} xl={2.28}>
                    <SocialMedia
                      onClick={() => handleUrlClick(item.facebook.link)}
                    >
                      <MediaIcon src={IconFb} />
                      <CommonText
                        fontSize="20px"
                        mFontSize="12px"
                        textAlign="left"
                      >
                        {item.facebook.name}
                      </CommonText>
                    </SocialMedia>
                  </Grid>
                  <Box
                    component={Grid}
                    item
                    lg={6}
                    display={{ xs: 'none', lg: 'block', xl: 'none' }}
                  ></Box>
                  {item.youtube && (
                    <Grid item xs={6} lg={3} xl={2.28}>
                      <SocialMedia
                        onClick={() => handleUrlClick(item.youtube.link)}
                      >
                        <MediaIcon src={IconYoutube} />
                        <CommonText
                          fontSize="20px"
                          mFontSize="12px"
                          textAlign="left"
                        >
                          {item.youtube.name}
                        </CommonText>
                      </SocialMedia>
                    </Grid>
                  )}
                  {item.twitter && (
                    <Grid item xs={6} lg={3} xl={2.28}>
                      <SocialMedia
                        onClick={() => handleUrlClick(item.twitter.link)}
                      >
                        <MediaIcon src={IconTwitter} />
                        <CommonText
                          fontSize="20px"
                          mFontSize="12px"
                          textAlign="left"
                        >
                          {item.twitter.name}
                        </CommonText>
                      </SocialMedia>
                    </Grid>
                  )}
                </Grid>
              </StyledTrNoHover>
            );
          })}
        </StyledTable>
      </Container>

      <Space size="50px" />

      <Container>
        <Title text={t('specialthanks')} />
        <StyledTable>
          {fansClub.map((member, idx) => {
            return (
              <StyledTr
                onClick={() => {
                  ReactGA.event('outbound_click_from_external_links', {
                    param: `https://www.instagram.com/${member.instagram.replace(
                      '@',
                      ''
                    )}`,
                  });
                  window.open(
                    `https://www.instagram.com/${member.instagram.replace(
                      '@',
                      ''
                    )}`,
                    '_blank'
                  );
                }}
              >
                <td width="50%" style={{ paddingLeft: 15, paddingRight: 10 }}>
                  <StyledNameText fontSize="20px" mFontSize="12px">
                    {member.name}
                  </StyledNameText>
                </td>
                <td width="50%" style={{ paddingRight: 15 }}>
                  <Stack direction="row">
                    <IgIcon />
                    <StyledIgLink fontSize="20px" mFontSize="12px">
                      {member.instagram}
                    </StyledIgLink>
                  </Stack>
                </td>
              </StyledTr>
            );
          })}
        </StyledTable>
      </Container>
    </StyledMainDiv>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${breakpoint.desktop} {
    max-width: 1840px;
    padding: 0px 50px;
    margin: auto;
  }

  @media ${breakpoint.mobile} {
    padding: 16px;
    max-width: 640px;
    margin: auto;
  }
`;

const StyledMainDiv = styled.div`
  @media ${breakpoint.desktop} {
    margin-bottom: 100px;
  }

  @media ${breakpoint.mobile} {
    margin-bottom: 40px;
  }
`;

const StyledNameText = styled.div`
  font-family: Open Sans;
  font-size: ${(props) => props.fontSize};

  @media ${breakpoint.mobile} {
    font-size: ${(props) => props.mFontSize};
    text-align: left;
  }
`;

const StyledIgLink = styled.div`
  font-family: Open Sans;
  font-size: ${(props) => props.fontSize};

  @media ${breakpoint.mobile} {
    font-size: ${(props) => props.mFontSize};
  }
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  @media ${breakpoint.mobile} {
    margin: 15px 20px 0px 20px;
  }

  @media ${breakpoint.desktop} {
    margin: 30px 10% 0px 10%;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const MediaIcon = styled.img`
  @media ${breakpoint.desktop} {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }

  @media ${breakpoint.mobile} {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
`;

const SocialMediaIcon = styled.div`
  background: ${(props) => `url(${props.img})`};
  background-size: contain;
  background-repeat: no-repeat;

  @media ${breakpoint.desktop} {
    height: 24px;
    width: 24px;
    margin-right: 24px;
  }

  @media ${breakpoint.mobile} {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
`;

const IgIcon = styled.div`
  background: url(${IconBlackIg});
  background-size: contain;

  @media ${breakpoint.desktop} {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }

  @media ${breakpoint.mobile} {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
`;

const StyledTrNoHover = styled.tr`
  border: 1px solid #dddddd;
  cursor: pointer;
  padding: 15px;

  @media ${breakpoint.mobile} {
    height: 60px;
  }

  @media ${breakpoint.desktop} {
    height: auto;
  }
`;

const StyledTr = styled.tr`
  border: 1px solid #dddddd;
  cursor: pointer;
  padding: 15px;

  @media ${breakpoint.mobile} {
    height: 60px;
  }

  @media ${breakpoint.desktop} {
    height: 100px;
  }

  &:hover {
    background: #ff5c00;
    ${StyledIgLink} {
      color: #fff;
      text-decoration: underline;
    }
    ${StyledNameText} {
      color: #fff;
    }
    ${IgIcon} {
      background: url(${IconWhiteIg});
      background-size: contain;
    }
  }
`;

export default ExternalLink;
