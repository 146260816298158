import styled from "styled-components";
import CommonText from "../typographies/CommonText";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import { useTranslation } from 'react-i18next';

export default function AlbumTag(props) {
    const { t, i18n } = useTranslation("discography");
    return (
        <StyledDiv>
            <ResponsiveUI
                mobile={
                    <CommonText lineHeight="19px" type="common" fontSize="14px" fontWeight="600" color="white">{t("album")}</CommonText>

                }

                desktop={
                    <CommonText lineHeight="21.8px" type="common" fontSize="16px" fontWeight="600" color="white">{t("album")}</CommonText>
                }
            />
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 9px;
    right: 9px;
    border-radius: 5px;
    background: #E427FF;
    height: 22.8px;
    width: 45.5px;
    @media ${breakpoint.desktop} {
        border-radius: 10px;
        height: 40px;
        width: 98px;
        bottom: 15px;
        right: 15px;
    }
`